//#region Imports

import { Directive, Input, NgModule } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

//#endregion

export interface IPercentageMax {
    percentage: number;
    value: number;
    digits: number;
}

/**
 * @public
 */
@Directive({
    selector: '[l7PercentageMax]',
    providers: [{ provide: NG_VALIDATORS, useExisting: PercentageMaxValidatorDirective, multi: true }]
})
export class PercentageMaxValidatorDirective implements Validator {

    //#region Properties

    @Input('l7PercentageMax')
    public max: IPercentageMax;

    //#endregion

    public validate(control: AbstractControl): ValidationErrors | null {
        return this.max
            ? percentageValidator(this.max.percentage, this.max.value, this.max.digits)(control)
            : null;
    }

}

/**
 * @public
 */
export function percentageValidator(percentage: number, value?: number, digits: number = 0): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const controlValue = Number.parseFloat(control.value);
        const x = (value ?? controlValue) / 100 * percentage;
        const result = Number.parseFloat(((value ?? controlValue) + x).toFixed(digits));

        return controlValue > result
            ? {
                percentage: {
                    value: control.value,
                    max: result
                }
            }
            : null;
    };
}

/**
 * @public
 */
@NgModule({
    declarations: [
        PercentageMaxValidatorDirective
    ],
    exports: [
        PercentageMaxValidatorDirective
    ]
})
export class PercentageMaxValidatorModule { }
