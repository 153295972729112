// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  width: 100%;
  height: 100%;
  text-align: initial;
}
:host .dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  padding: 0 24px;
  height: 65px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIlNldHRsZW1lbnROYXZFcnJvckRpYWxvZ1ZpZXcuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLGNBQUE7RUFDQSxXQUFBO0VBQ0EsWUFBQTtFQUNBLG1CQUFBO0FBQ0o7QUFDSTtFQUNJLGFBQUE7RUFDQSw4QkFBQTtFQUNBLG1CQUFBO0VBQ0EsaUJBQUE7RUFDQSxlQUFBO0VBQ0EsWUFBQTtBQUNSIiwiZmlsZSI6IlNldHRsZW1lbnROYXZFcnJvckRpYWxvZ1ZpZXcuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgICBkaXNwbGF5OiBibG9jaztcbiAgICB3aWR0aDogMTAwJTtcbiAgICBoZWlnaHQ6IDEwMCU7XG4gICAgdGV4dC1hbGlnbjogaW5pdGlhbDtcblxuICAgIC5kaWFsb2ctdGl0bGUge1xuICAgICAgICBkaXNwbGF5OiBmbGV4O1xuICAgICAgICBqdXN0aWZ5LWNvbnRlbnQ6IHNwYWNlLWJldHdlZW47XG4gICAgICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAgICAgIHRleHQtYWxpZ246IHN0YXJ0O1xuICAgICAgICBwYWRkaW5nOiAwIDI0cHg7XG4gICAgICAgIGhlaWdodDogNjVweDtcbiAgICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/backoffice/src/Views/Dialogs/SettlementNavErrorDialogView.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;AACJ;AACI;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,iBAAA;EACA,eAAA;EACA,YAAA;AACR;AACA,gzBAAgzB","sourcesContent":[":host {\n    display: block;\n    width: 100%;\n    height: 100%;\n    text-align: initial;\n\n    .dialog-title {\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n        text-align: start;\n        padding: 0 24px;\n        height: 65px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
