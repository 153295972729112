// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host legend {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: #83929b;
}
:host fieldset {
  margin-bottom: 16px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImt1ZXYtY29tcGFyaXNvbi5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDSTtFQUNJLGVBQUE7RUFDQSxpQkFBQTtFQUNBLGlCQUFBO0VBQ0EsY0FBQTtBQUFSO0FBR0k7RUFDSSxtQkFBQTtBQURSIiwiZmlsZSI6Imt1ZXYtY29tcGFyaXNvbi5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgICBsZWdlbmQge1xuICAgICAgICBmb250LXNpemU6IDE0cHg7XG4gICAgICAgIGxpbmUtaGVpZ2h0OiAyMHB4O1xuICAgICAgICBmb250LXdlaWdodDogYm9sZDtcbiAgICAgICAgY29sb3I6ICM4MzkyOWI7XG4gICAgfVxuXG4gICAgZmllbGRzZXQge1xuICAgICAgICBtYXJnaW4tYm90dG9tOiAxNnB4O1xuICAgIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/contractManagement/src/Components/kuev-comparison/kuev-comparison.component.scss"],"names":[],"mappings":"AACI;EACI,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,cAAA;AAAR;AAGI;EACI,mBAAA;AADR;AACA,4lBAA4lB","sourcesContent":[":host {\n    legend {\n        font-size: 14px;\n        line-height: 20px;\n        font-weight: bold;\n        color: #83929b;\n    }\n\n    fieldset {\n        margin-bottom: 16px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
