//#region Imports

import { NgModule } from '@angular/core';
import { SelectOnDblClickDirective } from './SelectOnDblClickDirective';

//#endregion

/**
 * The `FormTrackModule`.
 *
 * @public
 */
@NgModule({
    declarations: [
        SelectOnDblClickDirective
    ],
    exports: [
        SelectOnDblClickDirective
    ]
})
export class SelectOnDblClickDirectiveModule { }
