import {
    ContractsDocumentService,
    IInvoiceDto,
    IInvoiceOcrDto,
    IProcessingStatusDto,
    ISalesChannelDto
} from '@abcfinlab/api/contract';
import { RemoteIbanCheckService } from '@abcfinlab/api/global';
import { Validators as CoreValidators, TranslationFacade } from '@abcfinlab/core';
import { AddClassToFieldDirective, minDateValidator, notEqualValidator } from '@abcfinlab/presentation';
import {
    AfterContentInit,
    ChangeDetectionStrategy,
    Component,
    DoCheck,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChildren
} from '@angular/core';
import { NgControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import _ from 'underscore';
import { NotificationService } from '../../../../apps/shell/src/app/shared/services/notification/notification.service';
import { formValuesAreEqual, getDirtyValues } from '../../../presentation/src/Helpers/FormHelper';

@UntilDestroy()
@Component({
    selector: 'l7-bo-invoice-form',
    templateUrl: './invoice-form.component.html',
    styleUrls: ['./invoice-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceFormComponent implements OnInit, AfterContentInit, DoCheck {
    @Input() set invoiceFormData(value) {
        if (value) {
            this.originalValues = value;
            Object.keys(value).forEach(name => {
                if (this.invoiceForm.controls[name]) {
                    this.invoiceForm.controls[name].patchValue(value[name], { onlySelf: true, emitEvent: false });
                }
            });
            this.invoiceForm.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        }
    }

    @Input() ocrData: IInvoiceOcrDto;

    @Input() invoiceData: IInvoiceDto;

    @Input() contractStatus: IProcessingStatusDto;

    @Input() documentId: string;

    @Input() purchaseEntryDate: Date;

    @Input() approvalDate: Date;

    @Input() ackLeasingValue: number;

    @Input() navStatus: number;

    @Input() isReadonlyUser: boolean;

    @Input() isSlb: boolean;

    @Input() salesChanel: ISalesChannelDto;

    @Input() contractNumber: string;

    @Output() closeDialog: EventEmitter<boolean> = new EventEmitter<boolean>();

    invoiceForm: UntypedFormGroup;

    minDeliveryDate: Date;

    showFields: boolean = true;

    areValuesChanged$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    @ViewChildren(AddClassToFieldDirective) highlightFields: QueryList<AddClassToFieldDirective>;

    public originalValues: IInvoiceDto;

    public minDeliveryDateErrorMessage: string;

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly contractsDocumentService: ContractsDocumentService,
        private readonly notification: NotificationService,
        private readonly translationFacade: TranslationFacade,
        private readonly _ibanCheckService: RemoteIbanCheckService
    ) {
        this.invoiceForm = this.formBuilder.group({
            deliveryDate: [{ value: null, disabled: false }],
            invoiceBiller: [{ value: null, disabled: false }, [Validators.required]],
            invoiceDate: [{ value: null, disabled: false }, [Validators.required]],
            invoiceNetAmount: [{ value: null, disabled: false }, [Validators.required]],
            invoiceNumber: [{ value: null, disabled: false }, [Validators.required]],
            invoiceRecipient: [{ value: null, disabled: false }, [Validators.required]],
            vatNumber: [{ value: null, disabled: false }, [Validators.required], [CoreValidators.validateVatNumber(this.contractsDocumentService)]],
            invoiceIban: [{ value: null, disabled: false }, [Validators.required], [CoreValidators.validateIban(this._ibanCheckService)]],
            objectName: [{ value: null, disabled: true }],
            invoiceConfirmed: [{ value: false, disabled: false }, [Validators.required]]
        });
    }

    ngOnInit() {
        this.invoiceForm.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(values => {
                const changedValues = getDirtyValues(this.invoiceForm);
                if (changedValues) {
                    Object.keys(changedValues).forEach(key => {
                        this.addRemoveClass(key);
                    });
                    const originalValuesWithoutObjectName = _.omit(this.originalValues, 'objectName');
                    this.areValuesChanged$.next(!formValuesAreEqual(originalValuesWithoutObjectName, values));
                }
            });
        this.minDeliveryDateErrorMessage = this.salesChanel === ISalesChannelDto.Retailer ? 'deliveryDateRetailerMinDateError' : 'deliveryDateMinDateError';
        if ((this.contractStatus !== 'BILLABLE' && this.contractStatus !== 'TO_BE_DISCUSSED') || this.navStatus >= 50) {
            this.invoiceForm.disable();
        }
        this.invoiceForm.get('invoiceNumber').addAsyncValidators(CoreValidators.validateInvoiceNumberUniqueAsync(this.contractsDocumentService, this.documentId, this.originalValues.invoiceNumber));
        this.invoiceForm.get('invoiceNumber').updateValueAndValidity();
        if (this.salesChanel === ISalesChannelDto.Retailer) {
            this.invoiceForm.controls.invoiceIban.addAsyncValidators(CoreValidators.isIbanValid(this.contractsDocumentService, this.contractNumber));
            this.invoiceForm.controls.invoiceIban.updateValueAndValidity();
        }
    }
    ngDoCheck() {
        if (!this.isSlb) {
            this.minDeliveryDate = this.salesChanel === ISalesChannelDto.Retailer ? this.approvalDate : this.purchaseEntryDate;
            this.invoiceForm.controls.deliveryDate.setValidators([Validators.required, minDateValidator(this.minDeliveryDate)]);
            this.invoiceForm.controls.deliveryDate.updateValueAndValidity();
        }
        this.invoiceForm.controls.invoiceNetAmount.setValidators([Validators.required, notEqualValidator(this.ackLeasingValue)]);
        this.invoiceForm.controls.invoiceNetAmount.updateValueAndValidity();
    }
    ngAfterContentInit() {
        let _areValuesEmpty = false;
        Object.keys(this.ocrData).forEach(key => {
            if (this.invoiceData[key]) {
                return;
            }
            _areValuesEmpty = true;
        });
        this.areValuesChanged$.next(_areValuesEmpty);
    }

    public showOcrFields(): void {
        this.highlightFields.forEach(highlightField => {
            const formControl: NgControl = highlightField.parent._control.ngControl as NgControl;
            if (this.showFields) {
                highlightField.addOcrClass(this.ocrData[formControl.name]);
            } else {
                if (highlightField.parent._elementRef.nativeElement.classList.value.includes('purple-rain')) {
                    highlightField.removeClass('purple-rain');
                }
            }
        });
        this.showFields = !this.showFields;
    }

    public saveInvoice() {
        this.areValuesChanged$.next(false);
        const subscription = this.contractsDocumentService.updateInvoice({
            body: {
                ...this.invoiceForm.getRawValue(),
                ...{ documentId: this.documentId }
            }
        })
            .subscribe(() => {
                this.originalValues = this.invoiceForm.getRawValue();
                this.notification.showSuccess(this.translationFacade.instant('global.save_successful'));
                this.closeDialog.emit(true);
            },
                _error => {
                    this.notification.showError(this.translationFacade.instant('error.generic_error'));
                    this.areValuesChanged$.next(true);
                },
                () => {
                    subscription.unsubscribe();
                });
    }

    public handleEmptyString(evt) {
        this.invoiceForm.get(evt.formControlName).patchValue(null);
    }

    public transformToUpperCase(evt) {
        const value: string = evt.target.value.toUpperCase();
        this.invoiceForm.get('invoiceIban').patchValue(value);
    }

    private addRemoveClass(key: string) {
        const highlightField = this.highlightFields.find(h => {
            const formControl: NgControl = h.parent._control.ngControl as NgControl;
            return formControl.name === key;
        });
        if (highlightField) {
            const classList = highlightField.parent._elementRef.nativeElement.classList;
            const hasNoValueClass = classList.value.includes('no-value');
            if (hasNoValueClass && this.invoiceForm.controls[key].value) {
                highlightField.removeClass('no-value');
            }
            if (!hasNoValueClass && !this.invoiceForm.controls[key].value) {
                highlightField.addClass('no-value');
            }
        }
    }

}
