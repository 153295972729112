import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MandatoryCheckboxWithErrorDirective} from './MandatoryCheckboxWithErrorDirective';
import {FormGroupDirective} from '@angular/forms';

@NgModule({
    declarations: [
        MandatoryCheckboxWithErrorDirective
    ],
    providers: [FormGroupDirective],
    exports:[MandatoryCheckboxWithErrorDirective],
    imports: [
        CommonModule
    ]
})
export class MandatoryCheckboxWithErrorDirectiveModule { }
