import { BusyBoxService } from '@abcfinlab/ui';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { GetCanHaveSlb, GetSlbCodes } from '../actions/Slb.actions';
import { SlbCodes } from '../models/enums/SlbCodes.enum';
import { SlbService } from '../private/services/slb/slb.service';

export class SlbStateModel {
    canHaveSlb: boolean;
    codes: Array<SlbCodes>;
}

@State<SlbStateModel>({
    name: 'slb',
    defaults: {
        canHaveSlb: false,
        codes: []
    }
})

@Injectable()
export class SlbState {

    constructor(
        private readonly _slb: SlbService,
        private readonly _store: Store,
        private readonly _busyBoxService: BusyBoxService
    ) {
    }

    @Selector()
    static getCodes(state: SlbStateModel) {
        return state.codes;
    }

    @Selector()
    static getCanHaveSlb(state: SlbStateModel): boolean {
        return state.canHaveSlb;
    }

    @Action(GetSlbCodes)
    getCodes(context: StateContext<SlbStateModel>) {
        const state = context.getState();
        return this._slb.fetchCodes()
            .pipe(
                switchMap(response => {
                    context.setState({
                        ...state,
                        codes: response
                    });
                    return of(response);
                })
            );
    }

    @Action(GetCanHaveSlb)
    getCanHaveSlb({ patchState, getState }: StateContext<SlbStateModel>, { value }: GetCanHaveSlb) {
        const state = getState();
        return this._slb.getCanHaveSlb(value).pipe(
            switchMap(response => {
                patchState({
                    ...state,
                    canHaveSlb: response
                });

                return of(response);
            }));
    }
}
