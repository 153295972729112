/* eslint-disable @typescript-eslint/naming-convention */

/**
 * A config file to provide the PorjectID for the Clarity.io service
 * @tutorial
 * - To test things locally please comment in the *local* stage within the map. In addition, add the *local* string
 *   to the *supportedStages* array export. Same thing for test env.
 */

const projectIdMapByStage = new Map();
// projectIdMapByStage.set('local', { LOGINS: 'jocbdjmgpw', RETAILER: 'jocbdjmgpw' });
projectIdMapByStage.set('test', { LOGINS: 'k0t68rcg9q', RETAILER: 'k0t6f5cdxc' });
projectIdMapByStage.set('acc', { LOGINS: 'k15cvws0b4', RETAILER: 'k15d2c3esy' });
projectIdMapByStage.set('prod', { LOGINS: 'k15dknpfaf', RETAILER: 'k15dq2uan6' });
projectIdMapByStage.set('demo', { LOGINS: 'k15d8q5dir', RETAILER: 'k15delkka4' });

/**
 * Add / remove stages that should or should not be enabled
 * Available stages are:
 * - local
 * - dev
 * - test
 * - acc
 * - prod
 * - demo
 */
const supportedStages = ['acc', 'prod', 'demo', 'test'];

/**
 * Add / remove realms that should or should not be enabled
 * Available realms are:
 * - LOGINS (Eigenvertrieb)
 * - RETAILER (Fachhändler)
 */
const supportedRealms = ['LOGINS', 'RETAILER'];

export {
    projectIdMapByStage,
    supportedRealms,
    supportedStages
};
