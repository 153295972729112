import {ContractTypeId} from '../models/enums/ContractTypeId.enum';

export class GetSlbCodes {
  static readonly type = '[SLB] Get codes';
  constructor() {
  }
}

export class GetCanHaveSlb {
  static readonly type = '[SLB] Get if contract type allows SLB';
  constructor(public value: ContractTypeId) {
  }
}
