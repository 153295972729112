//#region Imports

import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
    IContractTypeDto, IInhouseCalculationRequestDto, IRefinancingInterestResponseDto
} from '@abcfinlab/api/global';
import { ControlsOf } from '@abcfinlab/core';

//#endregion

/**
 * The RefinancingInterestComponent view.
 *
 * @public
 */
@Component({
    selector: 'l7-refinancing-interest',
    templateUrl: './RefinancingInterestComponent.html',
    styleUrls: ['./RefinancingInterestComponent.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RefinancingInterestComponent implements OnInit, OnDestroy {
    private _contractType: IContractTypeDto;
    private _userPanelOpenState: boolean = true;

    //#region Fields

    //#endregion

    //#region Ctor
    @Input() form: FormGroup<ControlsOf<IInhouseCalculationRequestDto>>;

    @Input() refinancingInterestResponse: IRefinancingInterestResponseDto;

    @Input() exploitationCode: number;

    @Input() set contractType(value: IContractTypeDto) {
        this._contractType = value;
        if (value && this._userPanelOpenState) {
            this.panelOpenState = true;
            return;
        }

        this.panelOpenState = false;
    }

    public get contractType(): IContractTypeDto {
        return this._contractType;
    }

    public panelOpenState: boolean;

    /**
     * Constructs a new instance of the refinancingInterestComponent class.
     *
     * @public
     */
    public constructor() {
    }

    //#endregion

    //#region Properties

    //#endregion

    //#region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {

    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
    }

    public togglePanelState(evt: boolean): void {
        this._userPanelOpenState = evt;
    }

    //#endregion

}
