//#region Imports

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { IMessageBoxComponentData } from './IMessageBoxComponentData';
import { IMessageBoxOptions } from './IMessageBoxOptions';
import { MessageBoxButton } from './MessageBoxButton';
import { MessageBoxComponent } from './MessageBoxComponent';
import { MessageBoxResult } from './MessageBoxResult';

//#endregion

/**
 * @public
 */
@Injectable({ providedIn: 'root' })
export class MessageBoxService {

    //#region Fields

    private readonly _dialog: MatDialog;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `MessageBoxService` class.
     *
     * @public
     */
    public constructor(dialog: MatDialog) {
        this._dialog = dialog;
    }

    //#endregion

    //#region Methods

    public show(title: string | Observable<string>, message: string | Observable<string>, button: MessageBoxButton, options?: IMessageBoxOptions): Observable<MessageBoxResult> {
        const dialogRef = this._dialog.open<MessageBoxComponent, IMessageBoxComponentData, MessageBoxResult>(MessageBoxComponent, {
            closeOnNavigation: true,
            id: `${options?.id ?? ''}MessageBox`,
            role: 'alertdialog',
            width: options?.width?.value,
            minWidth: options?.width?.minValue,
            maxWidth: options?.width?.maxValue,
            height: options?.height?.value,
            minHeight: options?.height?.minValue,
            maxHeight: options?.height?.maxValue,
            data: {
                title: title,
                message: message,
                button: button,
                options: options,
            },
        });

        return dialogRef.afterClosed();
    }

    //#endregion

}
