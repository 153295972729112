import {ContactDTO} from '../models/ContactDTO.interface';

export class SetLesseeID {
  static readonly type = '[LESSEE] Set UUID';

  constructor(public payload: string) {
  }
}

export class SetLessee {
  static readonly type = '[LESSEE] Set lessee';

  constructor(public payload: ContactDTO) {
  }
}

export class ResetLesseeState {
  static readonly type = '[LESSEE] Reset lessee sate';

  constructor() {
  }
}

/**
 * Fetch a lessee by his UUID
 */
export class FetchLesseeByUUID {
  static readonly type = '[LESSEE] Fetch by UUID';

  constructor(public lesseeUUID: string) {
  }
}
