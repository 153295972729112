
//#region Imports

import { Injectable } from '@angular/core';
import { BehaviorSubject, type Observable } from 'rxjs';

//#endregion

/**
 * Busy observer - The busy observer is used to observe the busy state of a particular observable.
 *                 It's useful for showing a loading spinner or similar UI element when a api call is in progress.
 *
 * @public
 * @abstract
 */
@Injectable({ providedIn: 'root' })
export abstract class BusyObserver {

    //#region Fields

    private readonly _isBusySubject: BehaviorSubject<boolean>;

    private _activeOperations: number;

    //#endregion

    //#region Ctor

    /**
    * Constructs a new instance of the `BusyObserver` class.
    *
    * @public
    */
    public constructor() {
        this._isBusySubject = new BehaviorSubject(false);

        this._activeOperations = 0;
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `isLoading` property.
     *
     * @public
     * @readonly
     */
    public get isBusy(): Observable<boolean> {
        return this._isBusySubject.asObservable();
    }

    //#endregion

    //#region Methods

    /**
     * @public
     */
    public busy(value: boolean): void {
        if (value) {
            this._activeOperations++;
        } else {
            if (this._activeOperations > 0) {
                this._activeOperations--;
            }
        }

        if (this._activeOperations === 0) {
            this._isBusySubject.next(false);
        } else {
            this._isBusySubject.next(true);
        }
    }

    //#endregion

}
