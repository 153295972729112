//#region Imports

import { TranslationFacade } from '@abcfinlab/core';
import { Inject, Injectable } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { ISettlementNavErrorDialogData } from '../../Models/ISettlementNavErrorDialogData';

//#endregion

/**
 * The presenter of the @{link SettlementNavErrorDialogView} view.
 *
 * @internal
 */
@Injectable()
export class SettlementNavErrorDialogViewPresenter {

    //#region Fields

    private readonly _dataSubject: BehaviorSubject<ISettlementNavErrorDialogData>;

    private readonly _isContractActivatedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private readonly _hasOnlyDocumentsErrorsSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private readonly _translationFacade: TranslationFacade;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `SettlementNavErrorDialogViewPresenter` class.
     *
     * @public
     */
    public constructor(@Inject(MAT_DIALOG_DATA) data: ISettlementNavErrorDialogData, translationFacade: TranslationFacade) {
        const fileteredValues = data.errors.filter(error =>
            !(error.value.name === 'save_bo_annotation' && error.value.message === '')
        );
        this._dataSubject = new BehaviorSubject({ ...data, errors: fileteredValues });
        this._translationFacade = translationFacade;
        data.errors.forEach(error => {
            if (error.value.name === 'contract_activate') {
                this._isContractActivatedSubject.next(error.value.success);
            }

            if (error.value.name.includes('document') && !error.value.success) {
                this._hasOnlyDocumentsErrorsSubject.next(true);
            }
            if (error.value.name === 'set_manual_processing_reasons') {
                if (error.value.message !== '') {
                    const reasons = error.value.message.split(',');
                    const translatedReason = reasons.map(reason => {
                        const trimmedReason = reason.trim();
                        const translationKey = `states.manual_processing_reasons.${trimmedReason}`;
                        return this._translationFacade.instant(translationKey);
                    });
                    error.value.message = translatedReason.join(', ');
                }
            }
        });
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `data` property.
     *
     * @public
     * @readonly
     */
    public get data(): Observable<ISettlementNavErrorDialogData> {
        return this._dataSubject.asObservable();
    }

    /**
     * Returns the `isContractActivated` property.
     *
     * @public
     * @readonly
     */
    public get isContractActivated(): Observable<boolean> {
        return this._isContractActivatedSubject.asObservable();
    }

    /**
     * Returns the `hasOnlyDocumentsErrors` property.
     *
     * @public
     * @readonly
     */
    public get hasOnlyDocumentsErrors(): Observable<boolean> {
        return this._hasOnlyDocumentsErrorsSubject.asObservable();
    }

    //#endregion

    //#region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
    }

    //#endregion

}
