// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-card {
  border-radius: 2px;
}

::ng-deep .content {
  width: 100%;
}
::ng-deep .content .row {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
::ng-deep .content .row .col-50 {
  display: flex;
  flex-direction: column;
  width: 50%;
}
::ng-deep .content .row .col-50 h4 {
  font-weight: bold;
  margin: 0;
}
::ng-deep .content .row .col-50 h4,
::ng-deep .content .row .col-50 p {
  font-size: 14px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFkZHJlc3MtY2FyZC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGtCQUFBO0FBQ0Y7O0FBRUU7RUFDRSxXQUFBO0FBQ0o7QUFBSTtFQUNFLGFBQUE7RUFDQSxtQkFBQTtFQUNBLG1CQUFBO0FBRU47QUFBTTtFQUNFLGFBQUE7RUFDQSxzQkFBQTtFQUNBLFVBQUE7QUFFUjtBQUFRO0VBQ0UsaUJBQUE7RUFDQSxTQUFBO0FBRVY7QUFDUTs7RUFFRSxlQUFBO0FBQ1YiLCJmaWxlIjoiYWRkcmVzcy1jYXJkLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLm1hdC1tZGMtY2FyZCB7XG4gIGJvcmRlci1yYWRpdXM6IDJweDtcbn1cbjo6bmctZGVlcCB7XG4gIC5jb250ZW50IHtcbiAgICB3aWR0aDogMTAwJTtcbiAgICAucm93IHtcbiAgICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgICBmbGV4LWRpcmVjdGlvbjogcm93O1xuICAgICAgbWFyZ2luLWJvdHRvbTogMjBweDtcblxuICAgICAgLmNvbC01MCB7XG4gICAgICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gICAgICAgIHdpZHRoOiA1MCU7XG5cbiAgICAgICAgaDQge1xuICAgICAgICAgIGZvbnQtd2VpZ2h0OiBib2xkO1xuICAgICAgICAgIG1hcmdpbjogMDtcbiAgICAgICAgfVxuXG4gICAgICAgIGg0LFxuICAgICAgICBwIHtcbiAgICAgICAgICBmb250LXNpemU6IDE0cHg7XG4gICAgICAgIH1cbiAgICAgIH1cbiAgICB9XG4gIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/presentation/src/Components/address-card/address-card.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEE;EACE,WAAA;AACJ;AAAI;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AAEN;AAAM;EACE,aAAA;EACA,sBAAA;EACA,UAAA;AAER;AAAQ;EACE,iBAAA;EACA,SAAA;AAEV;AACQ;;EAEE,eAAA;AACV;AACA,4iCAA4iC","sourcesContent":[".mat-mdc-card {\n  border-radius: 2px;\n}\n::ng-deep {\n  .content {\n    width: 100%;\n    .row {\n      display: flex;\n      flex-direction: row;\n      margin-bottom: 20px;\n\n      .col-50 {\n        display: flex;\n        flex-direction: column;\n        width: 50%;\n\n        h4 {\n          font-weight: bold;\n          margin: 0;\n        }\n\n        h4,\n        p {\n          font-size: 14px;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
