// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host legend {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: #83929b;
}
:host fieldset {
  margin-bottom: 16px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIkNvbnRyYWN0T3ZlcnZpZXdLdWV2Vmlldy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVJO0VBQ0ksZUFBQTtFQUNBLGlCQUFBO0VBQ0EsaUJBQUE7RUFDQSxjQUFBO0FBRFI7QUFJSTtFQUNJLG1CQUFBO0FBRlIiLCJmaWxlIjoiQ29udHJhY3RPdmVydmlld0t1ZXZWaWV3LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJcbjpob3N0IHtcbiAgICBsZWdlbmQge1xuICAgICAgICBmb250LXNpemU6IDE0cHg7XG4gICAgICAgIGxpbmUtaGVpZ2h0OiAyMHB4O1xuICAgICAgICBmb250LXdlaWdodDogYm9sZDtcbiAgICAgICAgY29sb3I6ICM4MzkyOWI7XG4gICAgfVxuXG4gICAgZmllbGRzZXQge1xuICAgICAgICBtYXJnaW4tYm90dG9tOiAxNnB4O1xuICAgIH1cblxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/contractManagement/src/Views/contract-overview-kuev-view/ContractOverviewKuevView.scss"],"names":[],"mappings":"AAEI;EACI,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,cAAA;AADR;AAII;EACI,mBAAA;AAFR;AACA,4lBAA4lB","sourcesContent":["\n:host {\n    legend {\n        font-size: 14px;\n        line-height: 20px;\n        font-weight: bold;\n        color: #83929b;\n    }\n\n    fieldset {\n        margin-bottom: 16px;\n    }\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
