import { SetLoadingState } from '@abcfinlab/core';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { StateReset } from 'ngxs-reset-plugin';
import { of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import {
    CalculateInsurance,
    ChangeObjectGroupOrContractType,
    CreateQuote,
    ResetCalculationState,
    SetCalculationForScreen,
    SetContractType,
    SetLesseeByUUID,
    SetTotalLeasingValue,
    SetValueToCalculate,
    UpdateCalculation,
    UpdateCalculationUIState
} from '../actions/Calculation.actions';
import { calculateInsuranceProMilleValue, getPercent } from '../helper/calculation.helper';
import {
    CalculationFormDTO,
    InsuranceFormDTO,
    NgxsCalculationFormDTO,
    NgxsInsuranceFormDTO,
    NgxsSlbCodeFormDTO,
    SlbCodeFormDTO
} from '../models/CalculationFormDTO';
import { ContactDTO } from '../models/ContactDTO.interface';
import { ContractTypeId } from '../models/enums/ContractTypeId.enum';
import { InsuranceType } from '../models/enums/InsuranceType.enum';
import { ValueToCalculate } from '../models/enums/ValueToCalculate.enum';
import { HirePurchaseCalculationDTO, LeasingQuoteCalculationDTO } from '../models/LeasingQuoteCalculationDTO.interface';
import { LeasingQuoteDTO } from '../models/LeasingQuoteDTO.interface';
import { ObjectGroupDTO } from '../models/ObjectGroupDTO.interface';
import { LesseeRepositoryService } from '../private/repository/lessee-repository.service';
import { QuoteRepositoryService } from '../private/repository/quote-repository.service';
import { CalculationService } from '../private/services/calculation/calculation.service';
import { UsedCondition } from '../models/enums/UsedCondition.enum';

export enum CalculationUIState {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    TRANSIENT = 'TRANSIENT'
}

export class CalculationStateModel {
    leasingQuoteCalculation?: LeasingQuoteDTO;
    lessee?: ContactDTO;
    calculationUIState: CalculationUIState;
    mainCalculationForm: NgxsCalculationFormDTO;
    insuranceForm: NgxsInsuranceFormDTO;
    slbCodeForm: NgxsSlbCodeFormDTO;
}

export const defaultsMainCalculationForm: CalculationFormDTO = {
    basic: {
        contract_type: null,
        total_leasing_value: null,
        object_group: null,
        total_terms: null,
    },
    calculation: {
        down_payment: null,
        down_payment_percent: null,
        hire_purchase_terms: 12,
        instalment: null,
        instalment_percent: null,
        handling_first_instalment: false,
        first_instalment: null,
        first_instalment_percent: null,
        handling_last_instalment: false,
        last_instalment: null,
        last_instalment_percent: null,
        total_instalments: null,
        total_instalments_vat: null,
        residual_value: null,
        residual_value_percent: 10,
        yearly_interest: 5,
    },
    provision: {
        dealer_commission: 0,
        dealer_commission_percent: 0,
        refinancing_rate: null,
        risk_discount: null,
        present_value_profit: null,
        present_value_profit_percent: null,
        leasing_factor: null,
        exploitation_code: null,
    },
    object: {
        name: null,
    },
};

export const defaultsInsuranceForm: InsuranceFormDTO = {
    insurance: false,
    insurance_fee: 0,
    insurance_type: InsuranceType.NO,
    total_insurance_instalments: null,
    total_insurance_instalments_vat: null,
    handling_fee: true,
    handling_fee_value: 200,
    insurance_pro_mille: 0
};

export const defaultsSlbCodeForm: SlbCodeFormDTO = {
    active: false,
    code: null
};

export const defaultsQuoteCalculation: LeasingQuoteCalculationDTO & HirePurchaseCalculationDTO = {
    quote_calculation: null,
    handling_fee: true,
    handling_fee_value: 200,
    default_refinancing_interest: null,
    refinancing_rate: null,
    contract_type: null,
    dealer_commission: 0,
    dealer_commission_percent: 0,
    instalment: null,
    insurance: false,
    insurance_fee: 0,
    insurance_type: InsuranceType.NO,
    leasing_factor: null,
    name: null,
    object_group: null,
    object_value: null,
    present_value_profit: null,
    risk_discount: null,
    total_terms: null,
    total_leasing_value: null,
    value_to_calculate: ValueToCalculate.INSTALMENT,
    yearly_interest: 5,
    down_payment: null,
    down_payment_percent: null,
    residual_value: null,
    residual_value_percent: 10,
    hire_purchase_terms: 12,
    instalment_percent: null,
    handling_first_instalment: false,
    first_instalment: null,
    first_instalment_percent: null,
    handling_last_instalment: false,
    last_instalment: null,
    last_instalment_percent: null,
    total_instalments: null,
    total_instalments_vat: null,
    total_insurance_instalments: null,
    total_insurance_instalments_vat: null,
};

// @todo: Rename leasingQuoteCalculation to quote because it is not only the calculation
@State<CalculationStateModel>({
    name: 'calculation',
    defaults: {
        leasingQuoteCalculation: {} as LeasingQuoteDTO,
        lessee: {} as ContactDTO,
        calculationUIState: CalculationUIState.TRANSIENT,
        mainCalculationForm: {
            model: defaultsMainCalculationForm,
            dirty: false,
            status: 'INVALID',
            errors: {}
        },
        insuranceForm: {
            model: defaultsInsuranceForm,
            dirty: false,
            status: 'INVALID',
            errors: {}
        },
        slbCodeForm: {
            model: defaultsSlbCodeForm,
            dirty: false,
            status: 'INVALID',
            errors: {}
        }
    }
})

@UntilDestroy()
@Injectable()
export class CalculationState {

    constructor(
        private readonly _store: Store,
        private readonly _quoteRepositoryService: QuoteRepositoryService,
        private readonly _lesseeRepositoryService: LesseeRepositoryService,
        private readonly _calculationService: CalculationService
    ) {
    }

    /**
   * Get the UI Mode of the calculation screen
   * @param state     Context
   * @final
   */
    @Selector()
    static UIMode(state: CalculationStateModel): CalculationUIState {
        return state.calculationUIState;
    }

    @Selector()
    static snapShotLeasingQuote(state: CalculationStateModel): LeasingQuoteDTO {
        return state.leasingQuoteCalculation;
    }

    @Selector()
    static GetState(state: CalculationStateModel): CalculationStateModel {
        return state;
    }

    @Selector()
    static GetCalculation(state: CalculationStateModel): LeasingQuoteCalculationDTO | HirePurchaseCalculationDTO {
        return state.leasingQuoteCalculation.quote_calculation;
    }

    @Selector()
    static GetLessee(state: CalculationStateModel): ContactDTO {
        return state.lessee;
    }

    @Selector()
    static GetValueToCalculate(state: CalculationStateModel): ValueToCalculate {
        return state.leasingQuoteCalculation.quote_calculation.value_to_calculate;
    }

    @Selector()
    static GetObjectGroup(state: CalculationStateModel): ObjectGroupDTO {
        return state.mainCalculationForm.model.basic.object_group;
    }

    @Selector()
    static GetContractType(state: CalculationStateModel): ContractTypeId {
        return state.mainCalculationForm.model.basic.contract_type;
    }

    @Selector()
    static GetTotalLeasingValue(state: CalculationStateModel): number {
        return state.mainCalculationForm.model.basic.total_leasing_value;
    }

    @Selector()
    static GetSlbValues(state: CalculationStateModel): SlbCodeFormDTO {
        return state.slbCodeForm.model;
    }

    @Selector()
    static GetInsurance(state: CalculationStateModel): InsuranceFormDTO {
        return state.insuranceForm.model;
    }

    @Selector()
    static GetCalculationForm(state: CalculationStateModel): CalculationFormDTO {
        return state.mainCalculationForm.model;
    }

    @Action(SetCalculationForScreen)
    setCalculationForScreen({ patchState, getState }: StateContext<CalculationStateModel>, { leasingQuoteCalculation }: SetCalculationForScreen) {
        patchState({
            leasingQuoteCalculation
        });
        const ctx = getState();
        switch (ctx.calculationUIState) {
            case CalculationUIState.TRANSIENT: {
                patchState({
                    lessee: {} as ContactDTO
                });
                break;
            }
            case CalculationUIState.CREATE: {
                return;
            }
            case CalculationUIState.UPDATE: {
                break;
            }
        }

    }

    @Action(SetLesseeByUUID)
    setLesseeByUUID({ patchState, getState }: StateContext<CalculationStateModel>, { lesseeUUID, quoteID }: SetLesseeByUUID) {
        const ctx = getState();
        return this._lesseeRepositoryService.getLessee(lesseeUUID).pipe(
            tap((lessee: ContactDTO) => {
                patchState({
                    ...ctx,
                    leasingQuoteCalculation: {
                        ...ctx.leasingQuoteCalculation,
                        ...{ quote_id: quoteID, lessee_id: lesseeUUID },
                    },
                    lessee
                });
            })
        );
    }

    @Action(UpdateCalculation)
    updateCalculation({ patchState, getState }: StateContext<CalculationStateModel>, { request }: UpdateCalculation) {
        const state = getState();
        const objectGroup: ObjectGroupDTO = request?.object_group;
        const objectName: string = request?.quote_calculation?.name;

        const handlingRates = {
            // @ts-ignore
            first_instalment: request?.quote_calculation?.handling_first_instalment,
            // @ts-ignore
            last_instalment: request?.quote_calculation?.handling_last_instalment,
            // @ts-ignore
            hire_purchase_terms: request?.quote_calculation.hire_purchase_terms
        };

        return this._calculationService.calculate(request).pipe(
            catchError(error => {
                this._store.dispatch(new SetLoadingState(false));
                throw error;
            }),
            switchMap((calculation: LeasingQuoteCalculationDTO | HirePurchaseCalculationDTO) => {
                const _quoteCalculation = {
                    ...calculation,
                    ...calculation.quote_calculation,
                    ...{ value_to_calculate: state.leasingQuoteCalculation.quote_calculation.value_to_calculate }
                };

                patchState({
                    ...state,
                    leasingQuoteCalculation: {
                        ...state.leasingQuoteCalculation,
                        quote_calculation: _quoteCalculation,
                        errors: calculation.calculation_errors
                    }
                });

                const group = _quoteCalculation.contract_type === ContractTypeId.MIETKAUF ? 'calculationHirePurchase' : 'calculationLeasing';

                this._store.dispatch(new UpdateFormValue({
                    value: {
                        ...state.mainCalculationForm.model.basic,
                        object_group: objectGroup,
                        contract_type: _quoteCalculation.contract_type,
                        total_terms: _quoteCalculation.total_terms
                    },
                    path: 'calculation.mainCalculationForm',
                    propertyPath: 'basic'
                }));

                this.updateCalculationFormValues(group, _quoteCalculation, handlingRates);

                this._store.dispatch(new UpdateFormValue({
                    value: {
                        ...state.mainCalculationForm.model.provision,
                        dealer_commission: _quoteCalculation.dealer_commission,
                        dealer_commission_percent: _quoteCalculation.dealer_commission_percent,
                        refinancing_rate: calculation.quote_calculation.refinancing_rate,
                        risk_discount: calculation.risk_discount,
                        present_value_profit: calculation.present_value_profit,
                        present_value_profit_percent: getPercent(state.mainCalculationForm.model.basic.total_leasing_value, calculation.present_value_profit),
                        leasing_factor: calculation.leasing_factor,
                        exploitation_code: objectGroup.exploitation_code
                    },
                    path: 'calculation.mainCalculationForm',
                    propertyPath: 'provision'
                }));

                this._store.dispatch(new UpdateFormValue({
                    value: {
                        insurance: _quoteCalculation.insurance,
                        insurance_fee: _quoteCalculation.insurance_fee,
                        insurance_type: _quoteCalculation.insurance_type ? _quoteCalculation.insurance_type : InsuranceType.NO,
                        total_insurance_instalments: _quoteCalculation.total_insurance_instalments,
                        total_insurance_instalments_vat: _quoteCalculation.total_insurance_instalments_vat,
                        handling_fee: _quoteCalculation.handling_fee,
                        handling_fee_value: _quoteCalculation.handling_fee_value,
                        insurance_pro_mille: _quoteCalculation.insurance_type === InsuranceType.STANDARD && _quoteCalculation.insurance_fee ?
                            calculateInsuranceProMilleValue(_quoteCalculation.total_leasing_value, _quoteCalculation.insurance_fee) : 0
                    },
                    path: 'calculation.insuranceForm',
                }));

                this._store.dispatch(new UpdateFormValue({
                    value: {
                        ...state.mainCalculationForm.model.object,
                        name: objectName
                    },
                    path: 'calculation.mainCalculationForm',
                    propertyPath: 'object'
                }));

                this._store.dispatch(new SetLoadingState(false));

                return of(true);
            })
        );
    }

    @Action(CalculateInsurance)
    calculateInsurance({ patchState }: StateContext<CalculationStateModel>, { request }: CalculateInsurance) {
        return this._calculationService.calculateInsurance(request).pipe(
            catchError(error => {
                throw error;
            }),
            switchMap((calculation: any) => {
                this._store.dispatch(new UpdateFormValue({
                    value: {
                        insurance: request.insurance,
                        insurance_fee: request.insurance_value,
                        insurance_type: request.insurance_type ? request.insurance_type : InsuranceType.NO,
                        handling_fee: request.handling_fee,
                        handling_fee_value: request.handling_fee_value,
                        total_insurance_instalments: calculation.total_insurance_instalments,
                        total_insurance_instalments_vat: calculation.total_insurance_instalments_vat,
                    },
                    path: 'calculation.insuranceForm',
                }));

                return of(true);
            })
        );
    }

    @Action(ChangeObjectGroupOrContractType)
    changeObjectGroup({ patchState, getState }: StateContext<CalculationStateModel>, { objectGroup, contractType, objectCondition }: ChangeObjectGroupOrContractType) {
        const state = getState();
        const minPeriod = objectCondition === UsedCondition.USED ? 15 : objectGroup.amortization_period_min;
        let totalTerms = this._calculationService.getAmortizationRange(minPeriod, objectGroup.amortization_period_max)[0];
        if (contractType === ContractTypeId.MIETKAUF) {
            totalTerms = 12;
        }

        const defaultValuesForObjectGroup = {
            ...defaultsMainCalculationForm,
            basic: {
                ...defaultsMainCalculationForm.basic,
                object_group: objectGroup,
                total_terms: totalTerms,
                contract_type: contractType
            },
            provision: {
                ...defaultsMainCalculationForm.provision,
                exploitation_code: objectGroup.exploitation_code
            },
            object: {
                name: state.mainCalculationForm.model.object.name
            }
        };
        patchState({
            ...state,
            leasingQuoteCalculation: {
                ...state.leasingQuoteCalculation,
                quote_calculation: {
                    ...defaultsQuoteCalculation,
                    name: state.mainCalculationForm.model.object.name,
                    object_group: objectGroup,
                    total_terms: totalTerms,
                    contract_type: contractType
                }
            },
            mainCalculationForm: {
                model: defaultValuesForObjectGroup,
                dirty: false,
                status: 'INVALID',
                errors: {}
            },
            insuranceForm: {
                model: defaultsInsuranceForm,
                dirty: false,
                status: 'INVALID',
                errors: {}
            },
            slbCodeForm: {
                model: defaultsSlbCodeForm,
                dirty: false,
                status: 'INVALID',
                errors: {}
            }
        });
    }

    @Action(ResetCalculationState)
    resetCalculationState({ }: StateContext<CalculationStateModel>, { }: ResetCalculationState) {
        this._store.dispatch(new StateReset(CalculationState));
    }

    @Action(UpdateCalculationUIState)
    updateCalculationUIState({ patchState, getState, dispatch }: StateContext<CalculationStateModel>, { state }: UpdateCalculationUIState) {
        const ctx = getState();
        patchState({
            calculationUIState: state
        });

        dispatch(new SetCalculationForScreen(ctx.leasingQuoteCalculation));
    }

    @Action(CreateQuote)
    createQuote({ patchState, getState }: StateContext<CalculationStateModel>, { contact }: CreateQuote) {
        const state = getState();

        return this._quoteRepositoryService.createQuoteV2(contact).pipe(
            untilDestroyed(this),
            tap(leasingQuote => {
                this._store.dispatch(new SetLesseeByUUID(leasingQuote.lessee_id, leasingQuote.quote_id));
                patchState({
                    ...state,
                    lessee: contact,
                    leasingQuoteCalculation: leasingQuote
                });
            })).subscribe();
    }

    @Action(SetValueToCalculate)
    setValueToCalculate({ patchState, getState }: StateContext<CalculationStateModel>, { value }: SetValueToCalculate) {
        const state = getState();
        patchState({
            ...state,
            leasingQuoteCalculation: {
                ...state.leasingQuoteCalculation,
                quote_calculation: {
                    ...state.leasingQuoteCalculation.quote_calculation,
                    value_to_calculate: value
                }
            }
        });
    }

    @Action(SetContractType)
    setContractType({ patchState, getState }: StateContext<CalculationStateModel>, { value }: SetContractType) {
        const state = getState();
        patchState({
            ...state,
            leasingQuoteCalculation: {
                ...state.leasingQuoteCalculation,
                quote_calculation: {
                    ...state.leasingQuoteCalculation.quote_calculation,
                    contract_type: value
                }
            }
        });
    }

    @Action(SetTotalLeasingValue)
    setTotalLeasingValue({ patchState, getState }: StateContext<CalculationStateModel>, { value }: SetTotalLeasingValue) {
        const state = getState();
        patchState({
            ...state,
            leasingQuoteCalculation: {
                ...state.leasingQuoteCalculation,
                quote_calculation: {
                    ...state.leasingQuoteCalculation.quote_calculation,
                    total_leasing_value: value
                }
            }
        });
    }

    private updateCalculationFormValues(group: string, values, handlingValues) {
        let fields;
        switch (group) {
            case 'calculationLeasing':
                fields = {
                    down_payment: values?.down_payment,
                    down_payment_percent: values?.down_payment_percent,
                    instalment: values.instalment,
                    residual_value: values?.residual_value,
                    residual_value_percent: values?.residual_value_percent,
                    yearly_interest: values.yearly_interest
                };
                break;
            case 'calculationHirePurchase':
                fields = {
                    handling_first_instalment: handlingValues.first_instalment,
                    first_instalment: values?.first_instalment === 0 ? null : values?.first_instalment,
                    first_instalment_percent: values?.first_instalment_percent === 0 ? null : values?.first_instalment_percent,
                    hire_purchase_terms: handlingValues.hire_purchase_terms,
                    instalment: values.instalment,
                    instalment_percent: values.instalment_percent,
                    handling_last_instalment: handlingValues.last_instalment,
                    last_instalment: values?.last_instalment === 0 ? null : values?.last_instalment,
                    last_instalment_percent: values?.last_instalment_percent === 0 ? null : values?.last_instalment_percent,
                    total_instalments: values?.total_instalments,
                    total_instalments_vat: values?.total_instalments_vat,
                    yearly_interest: values.yearly_interest
                };
                break;
        }

        this._store.dispatch(new UpdateFormValue({
            value: fields,
            path: 'calculation.mainCalculationForm',
            propertyPath: 'calculation'
        }));
    }

}
