import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators as NgValidators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

import {
    IRetailerAcknowledgementSignRemoteRequestDto,
    IRetailerRemoteAcknowledgementResultDto
} from '@abcfinlab/api/contract';
import { TranslationFacade, Validators } from '@abcfinlab/core';
import { ToastService } from '@abcfinlab/ui';
import { CurrencyPipe, DatePipe } from '@angular/common';

interface IDigitalSignDialogViewPresenterFormData {
    general: FormGroup<{
        signingCheckBox1: FormControl<boolean>;
        signingCheckBox2: FormControl<boolean>;
        signingCheckBox3: FormControl<boolean>;
        signedByDate: FormControl<string>;
        signedByFirstName: FormControl<string>;
        signedByLastName: FormControl<string>;
    }>;
}
export interface IRetailerAcknowledgmentSingDataDTO {
    signRequestData: IRetailerAcknowledgementSignRemoteRequestDto;
    signatureFile: Blob;
}

@Component({
    selector: 'l7-retailer-acknowledgment-signature',
    templateUrl: './retailer-acknowledgment-signature.component.html',
    styleUrls: ['./retailer-acknowledgment-signature.component.scss']
})
export class RetailerAcknowledgmentSignatureComponent implements OnInit {
    private readonly _toastService: ToastService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _currencyPipe: CurrencyPipe;
    private readonly _datePipe: DatePipe;
    private readonly _showSuccess: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _checkBoxLabelIfPriceChanged: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    @Input() singingInfo: IRetailerRemoteAcknowledgementResultDto;
    @Output() contractSigned: EventEmitter<IRetailerAcknowledgmentSingDataDTO> = new EventEmitter<IRetailerAcknowledgmentSingDataDTO>();
    @Output() showSerialNumbers: EventEmitter<boolean> = new EventEmitter<boolean>();
    public isSignatureDirty$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public resetSignature$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public signature$: BehaviorSubject<Blob> = new BehaviorSubject<Blob>(null);
    private _form: FormGroup<IDigitalSignDialogViewPresenterFormData>;

    constructor(
        toastService: ToastService,
        translationFacade: TranslationFacade,
        currencyPipe: CurrencyPipe,
        datePipe: DatePipe
    ) {
        this._toastService = toastService;
        this._translationFacade = translationFacade;
        this._currencyPipe = currencyPipe;
        this._datePipe = datePipe;
    }

    public get signForm(): FormGroup<IDigitalSignDialogViewPresenterFormData> {
        return this._form;
    }

    public get remoteSingingInfo(): IRetailerRemoteAcknowledgementResultDto {
        return this.singingInfo;
    }

    public get checkBoxLabelIfPriceChanged(): Observable<string> {
        return this._checkBoxLabelIfPriceChanged.asObservable();
    }

    /**
     * Get the current date to validate against
     */
    public get currentDate(): String {
        return this._datePipe.transform(new Date(), 'yyyy-MM-dd');
    }

    /**
     * Get the minimum date to validate against
     */
    public get minDate(): String {
        return this._datePipe.transform(new Date(this.singingInfo.creationDate), 'yyyy-MM-dd');
    }

    private _initForm(): void {
        this._form = new FormGroup<IDigitalSignDialogViewPresenterFormData>({
            general: new FormGroup({
                signedByFirstName: new FormControl<string>('', NgValidators.required),
                signedByLastName: new FormControl<string>('', NgValidators.required),
                signedByDate: new FormControl<string>(this.remoteSingingInfo.expectedAcknowledgementDate,
                    [
                        NgValidators.required,
                        Validators.minDate(this.singingInfo.creationDate),
                        Validators.futureDateValidator()
                    ]
                ),
                signingCheckBox1: new FormControl<boolean>(false),
                signingCheckBox2: new FormControl<boolean>(false, NgValidators.requiredTrue),
                signingCheckBox3: new FormControl<boolean>(false, NgValidators.requiredTrue)
            })
        });
    }

    public ngOnInit(): void {
        this._initForm();

        let checkBoxLabel = null;
        if (this.singingInfo.firstInstalment === 0 && this.singingInfo.lastInstalment === 0) {
            checkBoxLabel = 'checkBoxConditional';
        } else if (this.singingInfo.firstInstalment !== 0 && this.singingInfo.lastInstalment !== 0) {
            checkBoxLabel = 'checkBoxConditionalWithResidualValueAndDownPayment';
        } else if (this.singingInfo.firstInstalment !== 0 && this.singingInfo.lastInstalment === 0) {
            checkBoxLabel = 'checkBoxConditionalWithDownPayment';
        } else if (this.singingInfo.firstInstalment === 0 && this.singingInfo.lastInstalment !== 0) {
            checkBoxLabel = 'checkBoxConditionalWithResidualValue';
        }

        const translatedLabel = this._translationFacade.instant(`contract_management.retailers.dialogs.digitalSigning.step4.${checkBoxLabel}`, {
            instalment: this._currencyPipe.transform(this.singingInfo.instalment),
            downPayment: this._currencyPipe.transform(this.singingInfo.firstInstalment),
            residualValue: this._currencyPipe.transform(this.singingInfo.lastInstalment)
        });
        this._checkBoxLabelIfPriceChanged.next(translatedLabel);
    }
    public get showSuccess(): Observable<boolean> {
        return this._showSuccess.asObservable();
    }

    public showSerialNumberDocument(): void {
        this.showSerialNumbers.emit(true);
    }
    public doAcknowledgmentSigning(): void {
        if (this.signForm.valid && this.signature$.getValue() && !(this.singingInfo.isPriceChanged && !this.signForm.controls.general.controls.signingCheckBox1.value)) {
            const formData = this.signForm.getRawValue();
            const signReqData: IRetailerAcknowledgementSignRemoteRequestDto = { acknowledgementDate: formData.general.signedByDate, signedByFirstName: formData.general.signedByFirstName, signedByLastName: formData.general.signedByLastName };
            this.contractSigned.emit({ signatureFile: this.signature$.getValue(), signRequestData: signReqData });
        } else {
            this._toastService.show('Bitte überprüfen Sie ihre Angaben.', 'danger');
        }
    }
}
