import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { ConfirmationFormDto } from 'libs/legacy/gwg/src/lib/models/confirmation-form.dto';

@UntilDestroy()
@Component({
  selector: 'gwg-juristic-person-confirmation',
  templateUrl: './juristic-person-confirmation.component.html',
  styleUrls: ['./juristic-person-confirmation.component.scss']
})
export class JuristicPersonConfirmationComponent implements OnInit {

  conclusionForm: UntypedFormGroup;

  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() formValues: EventEmitter<ConfirmationFormDto> = new EventEmitter<ConfirmationFormDto>();

  constructor(private readonly fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.conclusionForm = this.fb.group({
      signerJuristicPerson: false,
      registerInformationConfirmation: false,
      transparencyRegistryNotification:  null
    });

    this.conclusionForm.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(values => this.checkState(values));
  }

  private checkState(state: any): void {
    if (this.validState(state)) {
      this.valid.emit(true);
    } else {
      this.valid.emit(false);
    }
    this.formValues.emit(this.conclusionForm.getRawValue());
  }

  private readonly validState: (state: any) => boolean = (state: any) =>
    state.signerJuristicPerson
    && state.registerInformationConfirmation && state.transparencyRegistryNotification;
}
