import {
  Component,
  EventEmitter, Input,
  Output
} from '@angular/core';
import { TranslationFacade } from '../../../../../../../libs/core/src/Services/TranslationFacade';
import { ErrorParser } from '../../../models/classes/ErrorParser.class';
import { ServerErrorDTO } from '../../../models/ServerErrorDTO.interface';

@Component({
  selector: 'l7-top-bar-error',
  templateUrl: './top-bar-error.component.html',
  styleUrls: ['./top-bar-error.component.scss']
})
export class TopBarErrorComponent {

  @Input() set errorMessage(error: ServerErrorDTO) {
    error = typeof error == 'string' ? JSON.parse(error) : error;
    const errorTranslationParams = error.error_params ? error.error_params : ErrorParser.getNumericErrorParams(error.error_description);
    const translation = this._translationFacade.instant(`error.${error.error}`, errorTranslationParams);

    if (ErrorParser.translationExists(error.error, translation)) {
      this.errorMessageTranslated = translation;
    } else {
      const message = error.error_description || error.message;
      this.errorMessageTranslated = ErrorParser.addMissingText(message);
    }
  }

  errorMessageTranslated: string;

  @Output() closeErrorMessage: EventEmitter<any> = new EventEmitter<any>();

  constructor(public _translationFacade: TranslationFacade) {
  }

}
