import { Type } from '@angular/core';

/**
 * @public
 */
export interface ITileInfo {

    /**
     * The internal key of the tile.
     * This is only used for internal purposes and should not be displayed to the user.
     */
    readonly key: string;

    /**
     * The order of the tile.
     * This is used to determine the order of the tiles in the dashboard.
     */
    readonly order: number;

    /**
     * The minimum and maximum column span of the tile (width).
     */
    readonly columnSpan: { min: number; max: number };

    /**
     * The minimum and maximum row span of the tile (height).
     */
    readonly rowSpan: { min: number; max: number };

    /**
     * The views of the tile.
     */
    readonly views?: {
        front: Type<unknown>;
        back?: Type<unknown>;
    };

}
