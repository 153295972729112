import {
    IAcknowledgementWithoutSigningDto,
    IContractInfoDto,
    IInvoiceDto,
    IInvoiceOcrDto,
    IProcessingStatusDto,
    IPurchaseEntryDto,
    ISalesChannelDto, ISlbGetResponseDto
} from '@abcfinlab/api/contract';
import { IContractManagementDetailsDto, ICreditCheckDto } from '@abcfinlab/api/global';
import { convertNavStatusToNumber } from '@abcfinlab/presentation';
import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UploadDocumentType } from '../../../../../apps/shell/src/app/models/enums/UploadDocumentType.enum';
import { environment } from '../../../../../apps/shell/src/environments/environment';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'l7-bo-file-dialog',
    templateUrl: './document-dialog.component.html',
    styleUrls: ['./document-dialog.component.scss']
})
export class DocumentDialogComponent {
    salesChanel: ISalesChannelDto;
    options = {
        withCredentials: true,
        url: null
    };
    public ignoreReadOnlyBanner: boolean = false;
    isLoading: boolean = true;

    documentTitle: string = 'Übernahmebestätigung';

    data: {
        documentId: string;
        lesseeName: string;
        isReadonlyUser?: boolean;
        isSlb?: boolean;
        navContractNumber: string;
        type: UploadDocumentType;
        status: IProcessingStatusDto;
        ackData: {
            ack: IAcknowledgementWithoutSigningDto;
            quoteDetails: IContractManagementDetailsDto;
            purchaseEntry: IPurchaseEntryDto;
            navStatus: IContractInfoDto;
        };
        invoice: {
            data: IInvoiceDto;
            ocr: IInvoiceOcrDto;
            details: IContractManagementDetailsDto;
            ack: IAcknowledgementWithoutSigningDto;
            purchaseEntry: IPurchaseEntryDto;
            navStatus: IContractInfoDto;
            creditCheck: ICreditCheckDto;
        };
        kuevData: {
            kuev: ISlbGetResponseDto;
            quoteDetails: IContractManagementDetailsDto;
            navStatus: IContractInfoDto;
        };
        salesChanel: ISalesChannelDto;
        isConfirmed: boolean;
    };

    ackFormData: {
        acknowledgement_date: Date;
        serial_number: string;
        chassis_number: boolean;
        object_name: string;
        lessee_name: string;
        vendor_name: string;
        lessee_address: string;
        purchase_price: number;
        condition: string;
        year_of_construction: number;
        confirmed: boolean;
    };

    invoiceFormData: {
        deliveryDate: string;
        invoiceBiller: string;
        invoiceDate: string;
        invoiceNetAmount: number;
        invoiceNumber: string;
        invoiceRecipient: string;
        vatNumber: string;
        invoiceIban: string;
        objectName: string;
        invoiceConfirmed: boolean;
    };

    invoiceOcr: IInvoiceOcrDto;

    invoiceData: IInvoiceDto;

    purchaseEntryDate: Date;

    approvalDate: Date;

    ackLeasingValue: number;

    ackDto: IAcknowledgementWithoutSigningDto;

    navStatus: number;

    taxNumberLessee: string;

    constructor(
        private readonly dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) data: {
            documentId: string;
            lesseeName: string;
            isReadonlyUser?: boolean;
            navContractNumber: string;
            type: UploadDocumentType;
            status: IProcessingStatusDto;
            ackData: {
                ack: IAcknowledgementWithoutSigningDto;
                quoteDetails: IContractManagementDetailsDto;
                purchaseEntry: IPurchaseEntryDto;
                navStatus: IContractInfoDto;
            };
            invoice: {
                data: IInvoiceDto;
                ocr: IInvoiceOcrDto;
                details: IContractManagementDetailsDto;
                ack: IAcknowledgementWithoutSigningDto;
                slb: ISlbGetResponseDto;
                purchaseEntry: IPurchaseEntryDto;
                navStatus: IContractInfoDto;
                creditCheck: ICreditCheckDto;
            };
            kuevData: {
                kuev: ISlbGetResponseDto;
                quoteDetails: IContractManagementDetailsDto;
                navStatus: IContractInfoDto;
            };
            salesChanel: ISalesChannelDto;
            isConfirmed: boolean;
        },
        @Inject(DOCUMENT) private readonly document: Document
    ) {
        this.dialog.afterAllClosed.subscribe(_ => this.document.body.classList.remove('active-full-screen-dialog'));
        this.document.body.classList.add('active-full-screen-dialog');
        this.data = data;
        this.salesChanel = data.salesChanel;
        this.options.url = this.documentUrl(data.documentId);
        let ackDate;
        switch (data.type){
            case UploadDocumentType.INVOICE:
                this.documentTitle = 'Rechnung';
                this.invoiceFormData = this.setInvoiceFormData(data.invoice);
                this.invoiceOcr = data.invoice.ocr;
                this.purchaseEntryDate = new Date(data.invoice.purchaseEntry.creation_date);
                this.purchaseEntryDate.setHours(0, 0, 0, 0);
                this.approvalDate = new Date(data.invoice.creditCheck.timestamp);
                this.approvalDate.setHours(0, 0, 0, 0);
                this.ackLeasingValue = data.invoice.ack.purchase_price && (data.invoice.ack.purchase_price !== data.invoice.details.object.value) ?
                    data.invoice.ack.purchase_price : data.invoice.details.object.value;
                this.navStatus = convertNavStatusToNumber(data.invoice.navStatus.status);
                this.invoiceData = data.invoice.data;
                break;
            case UploadDocumentType.PRE_VENDOR_INVOICE:
                this.documentTitle = 'Vorlieferantenrechnung';
                this.invoiceFormData = this.setInvoiceFormData(data.invoice);
                this.invoiceOcr = data.invoice.ocr;
                this.purchaseEntryDate = new Date(data.invoice.purchaseEntry.creation_date);
                this.purchaseEntryDate.setHours(0, 0, 0, 0);
                this.approvalDate = new Date(data.invoice.creditCheck.timestamp);
                this.approvalDate.setHours(0, 0, 0, 0);
                this.ackLeasingValue = data.invoice.slb?.activeSlbResponse.purchasePrice && (data.invoice.slb?.activeSlbResponse.purchasePrice !== data.invoice.details.object.value) ?
                    data.invoice.slb.activeSlbResponse.purchasePrice : data.invoice.details.object.value;
                this.navStatus = convertNavStatusToNumber(data.invoice.navStatus.status);
                this.invoiceData = data.invoice.data;
                break;
            case UploadDocumentType.PROOF_OF_PAYMENT:
                this.documentTitle = 'Bezahltnachweis';
                break;
            case UploadDocumentType.KUEV:
                this.documentTitle = 'Kauf- und Übereingnungsvertrag';
                this.ackDto = {
                    acknowledgement_date: data.kuevData.kuev.activeSlbResponse.acknowledgementDate,
                    chassis_number: data.kuevData.kuev.activeSlbResponse.chassisNumber,
                    confirmed: data.isConfirmed,
                    first_instalment: data.kuevData.kuev.activeSlbResponse.calculation.firstInstalment,
                    id: data.navContractNumber,
                    instalment: data.kuevData.kuev.activeSlbResponse.calculation.instalment,
                    last_instalment: data.kuevData.kuev.activeSlbResponse.calculation.lastInstalment,
                    leasing_quote_id: null,
                    purchase_price: data.kuevData.kuev.activeSlbResponse.calculation.totalLeasingValueNet,
                    purchase_price_changed: data.kuevData.kuev.activeSlbResponse.calculation.totalLeasingValueNet !== data.kuevData.quoteDetails.object.value,
                    serial_number: data.kuevData.kuev.activeSlbResponse.objectSerialNumber,
                    signing_link_date: null,
                    signing_link_invalid_date: null,
                    year_of_construction: data.kuevData.kuev.activeSlbResponse.yearOfConstruction,
                    down_payment: null, // @TODO this has to be checked when do the new mapping from BE,
                    residual_value: null // @TODO this has to be checked when do the new mapping from BE,
                };
                 this.taxNumberLessee = data.kuevData.kuev.activeSlbResponse.taxNumberLessee;
                ackDate = new Date(data.kuevData.kuev.activeSlbResponse.acknowledgementDate);
                ackDate.setHours(0, 0, 0, 0);
                this.ackFormData = {
                    acknowledgement_date: ackDate,
                    serial_number: data.kuevData.kuev.activeSlbResponse.objectSerialNumber,
                    chassis_number: data.kuevData.kuev.activeSlbResponse.chassisNumber,
                    object_name: data.kuevData.quoteDetails.object.name,
                    lessee_name: data.kuevData.quoteDetails.lessee.name,
                    vendor_name: data.kuevData.quoteDetails.object.vendor.name,
                    lessee_address: `${ data.kuevData.quoteDetails.lessee.street } ${ data.kuevData.quoteDetails.lessee.house_number }`,
                    condition: this.condition_translated,
                    year_of_construction: data.kuevData.kuev.activeSlbResponse.yearOfConstruction,
                    confirmed: data.isConfirmed,
                    purchase_price: data.kuevData.kuev.activeSlbResponse.calculation.totalLeasingValueNet,
                };
                this.navStatus = convertNavStatusToNumber(data.kuevData.navStatus.status);
                break;
            case UploadDocumentType.DELIVERY_NOTE:
            case UploadDocumentType.ACKNOWLEDGEMENT_SIGNED:
            case UploadDocumentType.ACKNOWLEDGEMENT_SIGNED_PAPER:
                this.documentTitle = 'Übernahmebestätigung';
                this.ackDto = data.ackData.ack;
                ackDate = new Date(data.ackData.ack.acknowledgement_date);
                ackDate.setHours(0, 0, 0, 0);
                this.ackFormData = {
                    acknowledgement_date: ackDate,
                    serial_number: data.ackData.ack.serial_number,
                    chassis_number: data.ackData.ack.chassis_number,
                    object_name: data.ackData.quoteDetails.object.name,
                    lessee_name: data.ackData.quoteDetails.lessee.name,
                    vendor_name: data.ackData.quoteDetails.object.vendor.name,
                    lessee_address: `${data.ackData.quoteDetails.lessee.street  } ${  data.ackData.quoteDetails.lessee.house_number}`,
                    condition: this.condition_translated,
                    year_of_construction: data.ackData.ack.year_of_construction,
                    confirmed: data.ackData.ack.confirmed,
                    purchase_price: data.ackData.ack.purchase_price
                };
                this.navStatus = convertNavStatusToNumber(data.ackData.navStatus.status);
                break;
        }
    }

    private get condition_translated(): string {
        return this.data?.ackData?.quoteDetails.object.condition === 'USED' || this.data?.kuevData?.quoteDetails.object.condition === 'USED' ? 'Gebraucht' : 'Fabrikneu';
    }

    public documentUrl(id: string): string {
        return `${environment.host  }/contract-service/api/v1/contracts/documents/${  id}`;
    }

    private setInvoiceFormData(invoice: { data: IInvoiceDto; ocr: IInvoiceOcrDto; details: IContractManagementDetailsDto }) {
        const data = {
            deliveryDate: invoice.data.deliveryDate,
            invoiceBiller: invoice.data.invoiceBiller ? invoice.data.invoiceBiller : invoice.ocr.invoiceBiller,
            invoiceDate: invoice.data.invoiceDate ? invoice.data.invoiceDate : invoice.ocr.invoiceDate,
            invoiceNetAmount: invoice.data.invoiceNetAmount ? invoice.data.invoiceNetAmount : invoice.ocr.invoiceNetAmount,
            invoiceNumber: invoice.data.invoiceNumber ? invoice.data.invoiceNumber : invoice.ocr.invoiceNumber,
            invoiceRecipient: invoice.data.invoiceRecipient ? invoice.data.invoiceRecipient : invoice.ocr.invoiceRecipient,
            vatNumber: invoice.data.vatNumber ? invoice.data.vatNumber : invoice.ocr.vatNumber,
            invoiceIban: invoice.data.invoiceIban ? invoice.data.invoiceIban : invoice.ocr.invoiceIban,
            objectName: invoice.details.object.name,
            invoiceConfirmed: invoice.data.invoiceConfirmed
        };
        return data;
    }

    public closeDialog(evt: boolean): void {
        if (evt) {
            this.document.body.classList.remove('active-full-screen-dialog');
            this.dialog.closeAll();
        }
    }
}
