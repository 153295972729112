//#region Imports

import { AppConfig, getSubdomain, hasSubdomain, IAppConfigData, isLocalhost } from '@abcfinlab/core';
import { ApplicationRef, enableProdMode } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { injectSpeedInsights } from '@vercel/speed-insights';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

//#endregion

injectSpeedInsights({});

const subDomain = isLocalhost(window.location.hostname)
    ? ''
    : hasSubdomain(window.location.hostname)
        ? getSubdomain(window.location.hostname)
        : 'prod';

let configJson = subDomain
    ? `config.${subDomain}.json`
    : 'config.json';

configJson = window.location.hostname.includes('lease-seven.dev')
    ? `k8s-${configJson}`
    : configJson;

configJson = window.location.hostname.includes('vercel')
    ? 'config.mr.json'
    : configJson;

AppConfig
    .instance<IAppConfigData>()
    .load(configJson)
    .then(config => {
        if (config.success) {
            configureLegacy(config.value);
            configureServiceWorker(config.value);
            configureMonitoring(config.value);
        }

        return config;
    })
    .catch(reason => {
        console.error(reason);
    })
    .finally(() => {
        bootstrapAngular(AppConfig.instance<IAppConfigData>().config());
    });

/**
 * @private
 */
function configureLegacy(config: IAppConfigData): void {
    // legacy - todo remove this after static environment refactoring
    Object.assign(environment, config);
}

/**
 * @private
 */
function configureServiceWorker(config: IAppConfigData): void {
    if (config.production) {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/ngsw-worker.js').then(registration => {
                console.info('Service worker registration succeeded.');

                registration.addEventListener('updatefound', event => {
                    console.info('Service worker registration update found');

                    if (registration.installing) {
                        const installingWorker = registration.installing;
                        installingWorker.onstatechange = () => {
                            switch (installingWorker.state) {
                                case 'installed':
                                    if (navigator.serviceWorker.controller) {
                                        console.info('refreshMsg');
                                        location.reload();
                                    } else {
                                        console.info('availableMsg');
                                    }
                                    break;
                                case 'redundant':
                                    console.info('redundantMsg');
                                    break;
                                default:
                                    break;
                            }
                        };
                    }

                });
            }, error => {
                console.error('Service worker registration failed.', error);
            });
        } else {
            console.error('Service workers are not supported.');
        }
    }
}

/**
 * @private
 */
function configureMonitoring(config: IAppConfigData): void {
    if (config.stage === 'acc' || config.stage === 'demo' || config.stage === 'prod') {
        Sentry.init({
            dsn: 'https://9c72f13c490a4c1d9b2f86f13885f016@o308953.ingest.sentry.io/5405699',
            environment: config.stage
        });
    }
}

/**
* @private
*/
function bootstrapAngular(config: IAppConfigData): void {
    if (config.production) {
        enableProdMode();
    }

    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .then(ref => {
            if (!config.production) {
                const applicationRef = ref.injector.get(ApplicationRef);
                const componentRef = applicationRef.components[0];
                enableDebugTools(componentRef);
            }
        })
        .catch(reason => console.error(reason));
}
