// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host mat-dialog-content h2 {
  margin: 16px 0;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNlcnZlci1lcnJvci1kaWFsb2cuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRVE7RUFDSSxjQUFBO0FBRFoiLCJmaWxlIjoic2VydmVyLWVycm9yLWRpYWxvZy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgICBtYXQtZGlhbG9nLWNvbnRlbnQge1xuICAgICAgICBoMiB7XG4gICAgICAgICAgICBtYXJnaW46IDE2cHggMDtcbiAgICAgICAgfVxuICAgIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./apps/shell/src/app/shared/modals/server-error-dialog/server-error-dialog.component.scss"],"names":[],"mappings":"AAEQ;EACI,cAAA;AADZ;AACA,gaAAga","sourcesContent":[":host {\n    mat-dialog-content {\n        h2 {\n            margin: 16px 0;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
