// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host img {
  max-height: 25vh;
  width: 25vw;
}
:host .mat-mdc-card-title {
  margin-left: 18px;
}
:host .mat-mdc-card-content.center {
  text-align: center;
}
:host legend {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: #83929b;
}
:host fieldset {
  margin-bottom: 16px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNoZWNrLXNpZ25hdHVyZXMtZGlhbG9nLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNJO0VBQ0ksZ0JBQUE7RUFDQSxXQUFBO0FBQVI7QUFHSTtFQUNJLGlCQUFBO0FBRFI7QUFLUTtFQUNJLGtCQUFBO0FBSFo7QUFPSTtFQUNJLGVBQUE7RUFDQSxpQkFBQTtFQUNBLGlCQUFBO0VBQ0EsY0FBQTtBQUxSO0FBUUk7RUFDSSxtQkFBQTtBQU5SIiwiZmlsZSI6ImNoZWNrLXNpZ25hdHVyZXMtZGlhbG9nLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICAgIGltZyB7XG4gICAgICAgIG1heC1oZWlnaHQ6IDI1dmg7XG4gICAgICAgIHdpZHRoOiAyNXZ3O1xuICAgIH1cblxuICAgIC5tYXQtbWRjLWNhcmQtdGl0bGUge1xuICAgICAgICBtYXJnaW4tbGVmdDogMThweDtcbiAgICB9XG5cbiAgICAubWF0LW1kYy1jYXJkLWNvbnRlbnQge1xuICAgICAgICAmLmNlbnRlciB7XG4gICAgICAgICAgICB0ZXh0LWFsaWduOiBjZW50ZXI7XG4gICAgICAgIH1cbiAgICB9XG5cbiAgICBsZWdlbmQge1xuICAgICAgICBmb250LXNpemU6IDE0cHg7XG4gICAgICAgIGxpbmUtaGVpZ2h0OiAyMHB4O1xuICAgICAgICBmb250LXdlaWdodDogYm9sZDtcbiAgICAgICAgY29sb3I6ICM4MzkyOWI7XG4gICAgfVxuXG4gICAgZmllbGRzZXQge1xuICAgICAgICBtYXJnaW4tYm90dG9tOiAxNnB4O1xuICAgIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/presentation/src/Components/check-signatures/check-signatures-dialog.component.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;EACA,WAAA;AAAR;AAGI;EACI,iBAAA;AADR;AAKQ;EACI,kBAAA;AAHZ;AAOI;EACI,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,cAAA;AALR;AAQI;EACI,mBAAA;AANR;AACA,4gCAA4gC","sourcesContent":[":host {\n    img {\n        max-height: 25vh;\n        width: 25vw;\n    }\n\n    .mat-mdc-card-title {\n        margin-left: 18px;\n    }\n\n    .mat-mdc-card-content {\n        &.center {\n            text-align: center;\n        }\n    }\n\n    legend {\n        font-size: 14px;\n        line-height: 20px;\n        font-weight: bold;\n        color: #83929b;\n    }\n\n    fieldset {\n        margin-bottom: 16px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
