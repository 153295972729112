import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {
  FetchQuotesListByLesseeUUID, ResetSelectedQuoteID,
} from '../../../actions/Quote.actions';
import {Observable} from 'rxjs';
import {LeasingQuoteOverviewDTO} from '../../../models/LeasingQuoteOverviewDTO.interface';
import {QuoteState} from "../../../state/Quote.state";
import {finalize, take} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class QuoteService {
  constructor(
    private readonly _store: Store
  ) {
  }

  public resetSelectedQuoteID(): Observable<any> {
    return this._store.dispatch(new ResetSelectedQuoteID());
  }

  /**
   * Fetch all quotes by the UUID of a chosen lessee
   * @param lesseeUUID      The UUID of the lessee
   */
  public fetchQuotesListByLesseeUUID(lesseeUUID: string): Observable<LeasingQuoteOverviewDTO> {
    this._store.dispatch(new FetchQuotesListByLesseeUUID(lesseeUUID));
    return this._store.select(QuoteState.getQuotesListForLessee).pipe(take(1));
  }
}
