export function addDays(self: Date, amount: number): Date {
    self.setDate(self.getDate() + amount);

    return self;
}

export function includes(self: Date, start: Date, end: Date): boolean {
    const gtOrEqualStart = (+self >= +start);
    const ltOrEqualEnd = +self <= +end;
    const result = gtOrEqualStart && ltOrEqualEnd;

    return result;
}

export function date(self: Date): Date {
    const date = new Date(+self);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    return date;
}

export function convertToISODate(value: Date): string {
    const current = new Date();
    value.setHours(current.getHours());
    value.setMinutes(current.getMinutes());
    value.setSeconds(current.getSeconds());
    value.setMilliseconds(current.getMilliseconds());
    const iso = value.toISOString();
    return iso;
}

export function monthDiff(from: Date, to: Date): number {
    return to.getMonth() - from.getMonth() + (12 * (to.getFullYear() - from.getFullYear()));
}

export function isDateBeforeOrEqualOtherDate(date: Date, other: Date): boolean {
    return new Date(date.toDateString()) <= new Date(other.toDateString());
}

export function isDateAfterOtherDate(date: Date, other: Date): boolean {
    return new Date(date.toDateString()) > new Date(other.toDateString());
}
