//#region Imports

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MenuGroupComponent } from './MenuGroupComponent';

//#endregion

/**
 * @public
 */
@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        MenuGroupComponent,
    ],
    exports: [
        MenuGroupComponent,
    ]
})
export class MenuGroupModule {
}
