import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { IContactDto, ILeasingQuoteOverviewDto } from '@abcfinlab/api/global';
import { FetchLesseeByUUID, SetLesseeID } from '../../../../../apps/shell/src/app/actions/Lessee.actions';

@Component({
  selector: 'l7-page-quote-list',
  templateUrl: './page-quote-list.component.html',
  styleUrls: ['./page-quote-list.component.scss']
})
export class PageQuoteListComponent {
    private readonly _selectedLessee$: BehaviorSubject<IContactDto> = new BehaviorSubject<IContactDto>(null);
    private readonly _lesseeQuotes$: BehaviorSubject<ILeasingQuoteOverviewDto> = new BehaviorSubject<ILeasingQuoteOverviewDto>(null);
    constructor(
        private readonly _router: Router,
        private readonly _store: Store,
        private readonly _activatedRoute: ActivatedRoute
    ) {
        this._activatedRoute.data.subscribe(d => {
            this._selectedLessee$.next(d.lessee);
            this._lesseeQuotes$.next(d.quotes);
            this._store.dispatch(new SetLesseeID(d.lessee.id));
            this._store.dispatch(new FetchLesseeByUUID(d.lessee.id));
        });
    }

    public get selectedLessee$(): Observable<IContactDto> {
        return this._selectedLessee$.asObservable();
    }

    public get lesseeQuotes(): Observable<ILeasingQuoteOverviewDto> {
        return this._lesseeQuotes$.asObservable();
    }

    public onBackToStart(): Promise<boolean> {
        return this._router.navigate(['../../'], {
          relativeTo: this._activatedRoute
        });
    }

}
