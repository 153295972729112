import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RemoteContactDTO } from '../../../../../../../apps/shell/src/app/models/RemoteContactDTO.interface';
import { BreakpointService } from '../../../../../../../libs/layout/src/Services/BreakpointService';
@UntilDestroy()
@Component({
  selector: 'gwg-sales-lead-information',
  templateUrl: './sales-lead-information.component.html',
  styleUrls: ['./sales-lead-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SalesLeadInformationComponent implements OnInit {
  @Input() salesLead: RemoteContactDTO;

  isMobile: boolean;

  isCollapsed: boolean;

  constructor(private readonly breakpoints: BreakpointService) {
    breakpoints.isMobileViewport$
      .pipe(untilDestroyed(this))
      .subscribe(isIt => this.isMobile = isIt);
  }

  ngOnInit() {
    this.isCollapsed = this.isMobile;
  }

  toggleMobile(event) {
    event.preventDefault();

    if (this.isMobile) {
      this.isCollapsed = !this.isCollapsed;
    }
  }

}
