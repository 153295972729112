// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  width: 100%;
  color: #5e6f79;
}
:host p {
  margin-bottom: 25px;
}
:host .gwg-confirmation-container {
  margin-top: 24px;
  padding: 0 24px;
}
:host .checkbox-container {
  display: flex;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNvbmZpcm1hdGlvbi5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGNBQUE7RUFDQSxXQUFBO0VBQ0EsY0FBQTtBQUNGO0FBQ0U7RUFDRSxtQkFBQTtBQUNKO0FBRUU7RUFDRSxnQkFBQTtFQUNBLGVBQUE7QUFBSjtBQUdFO0VBQ0UsYUFBQTtBQURKIiwiZmlsZSI6ImNvbmZpcm1hdGlvbi5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgZGlzcGxheTogYmxvY2s7XG4gIHdpZHRoOiAxMDAlO1xuICBjb2xvcjogIzVlNmY3OTtcblxuICBwIHtcbiAgICBtYXJnaW4tYm90dG9tOiAyNXB4O1xuICB9XG5cbiAgLmd3Zy1jb25maXJtYXRpb24tY29udGFpbmVyIHtcbiAgICBtYXJnaW4tdG9wOiAyNHB4O1xuICAgIHBhZGRpbmc6IDAgMjRweDtcbiAgfVxuXG4gIC5jaGVja2JveC1jb250YWluZXIge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/legacy/gwg/src/lib/confirmation/confirmation/confirmation.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,WAAA;EACA,cAAA;AACF;AACE;EACE,mBAAA;AACJ;AAEE;EACE,gBAAA;EACA,eAAA;AAAJ;AAGE;EACE,aAAA;AADJ;AACA,wsBAAwsB","sourcesContent":[":host {\n  display: block;\n  width: 100%;\n  color: #5e6f79;\n\n  p {\n    margin-bottom: 25px;\n  }\n\n  .gwg-confirmation-container {\n    margin-top: 24px;\n    padding: 0 24px;\n  }\n\n  .checkbox-container {\n    display: flex;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
