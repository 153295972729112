// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .gray-color {
  color: #83929b;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIkNvbXBhcmVUb0NvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNJO0VBQ0ksY0FBQTtBQUFSIiwiZmlsZSI6IkNvbXBhcmVUb0NvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICAgIC5ncmF5LWNvbG9yIHtcbiAgICAgICAgY29sb3I6ICM4MzkyOWI7XG4gICAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/ui/src/Controls/Components/CompareTo/CompareToComponent.scss"],"names":[],"mappings":"AACI;EACI,cAAA;AAAR;AACA,gVAAgV","sourcesContent":[":host {\n    .gray-color {\n        color: #83929b;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
