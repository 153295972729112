// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  margin-top: 32px;
}
:host .icon-clearfix {
  padding-bottom: 1.34375em;
}
:host .mat-mdc-card {
  padding-bottom: 30px;
}
:host .mat-mdc-card-actions {
  padding-right: 10px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIkNvbnRyYWN0T3ZlcnZpZXdSZWNhbGN1bGF0aW9uVmlldy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksY0FBQTtFQUNBLGdCQUFBO0FBQ0o7QUFDSTtFQUNJLHlCQUFBO0FBQ1I7QUFFSTtFQUNJLG9CQUFBO0FBQVI7QUFHSTtFQUNJLG1CQUFBO0FBRFIiLCJmaWxlIjoiQ29udHJhY3RPdmVydmlld1JlY2FsY3VsYXRpb25WaWV3LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XG4gICAgZGlzcGxheTogYmxvY2s7XG4gICAgbWFyZ2luLXRvcDogMzJweDtcblxuICAgIC5pY29uLWNsZWFyZml4IHtcbiAgICAgICAgcGFkZGluZy1ib3R0b206IDEuMzQzNzVlbTtcbiAgICB9XG5cbiAgICAubWF0LW1kYy1jYXJkIHtcbiAgICAgICAgcGFkZGluZy1ib3R0b206IDMwcHg7XG4gICAgfVxuXG4gICAgLm1hdC1tZGMtY2FyZC1hY3Rpb25zIHtcbiAgICAgICAgcGFkZGluZy1yaWdodDogMTBweDtcbiAgICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/contractManagement/src/Views/ContractOverviewRecalculationView.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,gBAAA;AACJ;AACI;EACI,yBAAA;AACR;AAEI;EACI,oBAAA;AAAR;AAGI;EACI,mBAAA;AADR;AACA,wtBAAwtB","sourcesContent":[":host {\n    display: block;\n    margin-top: 32px;\n\n    .icon-clearfix {\n        padding-bottom: 1.34375em;\n    }\n\n    .mat-mdc-card {\n        padding-bottom: 30px;\n    }\n\n    .mat-mdc-card-actions {\n        padding-right: 10px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
