import { IAppConfigData } from '@abcfinlab/core';

/**
 * @deprecated Please use `AppConfig` over ctor injection.
 */
export const environment: IAppConfigData = {
  production: false,
  stage: 'local',
  appName: '',
  host: '',
  analytics: false,
  features: {
    downPaymentWarningMessage: {
      active: false
    },
    upload_file: {
      active: false,
      file_types: [],
      max_file_size: 0
    },
    configuration: {
        active: false
    },
    newCalculation: {
        active: false
    }
  }
};
