//#region Imports

import { DirectusService, INewsItemDto } from '@abcfinlab/api/global';
import { BusyObserver } from '@abcfinlab/ui';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { NewsManager } from '../../Services/NewsManager';

//#endregion

/**
 * The presenter of the {@link WhatsNewWidgetView} view.
 *
 * @internal
 */
@Injectable()
export class WhatsNewWidgetViewPresenter {

    //#region Fields

    private readonly _directusService: DirectusService;
    private readonly _newsManager: NewsManager;
    private readonly _busyObserver: BusyObserver;
    private readonly _newsSubject: BehaviorSubject<Array<INewsItemDto>>;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `WhatsNewWidgetViewPresenter` class.
     *
     * @public
     */
    public constructor(busyObserver: BusyObserver, newsManager: NewsManager, directusService: DirectusService) {
        this._busyObserver = busyObserver;
        this._newsManager = newsManager;
        this._directusService = directusService;
        this._newsSubject = new BehaviorSubject([]);
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `news` property.
     *
     * @public
     * @readonly
     */
    public get news(): Observable<Array<INewsItemDto>> {
        return this._newsSubject.asObservable();
    }

    /**
     * Returns the `isBusy` property.
     *
     * @public
     * @readonly
     */
    public get isBusy(): Observable<boolean> {
        return this._busyObserver.isBusy;
    }

    /**
     * Returns the `isEmpty` property.
     *
     * @public
     * @readonly
     */
    public get isEmpty(): Observable<boolean> {
        return combineLatest([this.news, this.isBusy]).pipe(
            map(([tasks, isBusy]) => tasks.length === 0 && !isBusy)
        );
    }

    //#endregion

    //#region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
        this.initializeNews();
        this.initializeTrigger();
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
        this._busyObserver.busy(false);
    }

    /**
     * @protected
     */
    public onHandle(item: INewsItemDto): void {
        this._newsManager.show('news', item.id);
    }

    /**
     * @private
     */
    private initializeNews(): void {
        this._busyObserver.busy(true);
        this._directusService.forWhomTheBellTolls().pipe(
            finalize(() => this._busyObserver.busy(false)),
        ).subscribe(news => {
            this._newsSubject.next(news);
        });
    }

    /**
     * @private
     */
    private initializeTrigger(): void {
        // trigger when news dialog will be closed the reset the view.
        this._newsManager.unreadCount.subscribe(() => {
            this.initializeNews();
        });
    }

    //#endregion

}
