import { Component } from '@angular/core';

@Component({
  selector: 'l7-container-quotes',
  templateUrl: './container-quotes.component.html',
  styleUrls: ['./container-quotes.component.scss']
})
export class ContainerQuotesComponent {

  constructor() {
  }
}
