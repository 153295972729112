export function equals<T = unknown>(self: Array<T>, other: Array<T>): boolean {
    // if the other array is a falsy value, return
    if (!other) {
        return false;
    }

    // compare lengths - can save a lot of time
    if (self.length !== other.length) {
        return false;
    }

    for (let i = 0, l = self.length; i < l; i++) {
        // Check if we have nested arrays
        if (self[i] instanceof Array && other[i] instanceof Array) {
            // recurse into the nested arrays
            if (equals(self[i] as unknown as Array<T>, other[i] as unknown as Array<T>)) {
                return false;
            }
        } else if (self[i] !== other[i]) {
            // Warning - two different object instances will never be equal: {x:20} != {x:20}
            return false;
        }
    }
    return true;
}

export function remove<T>(self: Array<T>, ...values: Array<T>): Array<T> {
    values.forEach(x => {
        const index: number = self.indexOf(x);
        if (index > -1) {
            self.splice(index, 1);
        }
    });
    return self;
}
