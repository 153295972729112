//#region Imports

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OfPipeModule } from '../../../Pipes/OfPipe';
import { TextBlockModule } from '../TextBlock/TextBlockModule';
import { BusyBoxComponent } from './BusyBoxComponent';

//#endregion

/**
 * @public
 */
@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        TextBlockModule,
        FlexLayoutModule,
        OfPipeModule,
        MatProgressSpinnerModule
    ],
    declarations: [
        BusyBoxComponent
    ],
    exports: [
        BusyBoxComponent
    ]
})
export class BusyBoxModule {
}
