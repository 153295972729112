//#region Imports

import { CdkStep, CdkStepper } from '@angular/cdk/stepper';
import { Component, Inject, forwardRef } from '@angular/core';
import { WizardComponent } from './WizardComponent';

//#endregion

@Component({
    selector: 'l7-wizard-step',
    templateUrl: './WizardStepComponent.html',
    styleUrls: ['./WizardStepComponent.scss'],
    providers: [
        { provide: CdkStep, useExisting: WizardStepComponent }
    ]
})
export class WizardStepComponent extends CdkStep {

    //#region Ctor

    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility, @typescript-eslint/no-parameter-properties
    public constructor(@Inject(forwardRef(() => WizardComponent)) wizard: WizardComponent) {
        super(wizard as unknown as CdkStepper);
    }

    //#endregion
}
