//#region Imports

import { ChangeDetectionStrategy, Component } from '@angular/core';

//#endregion

/**
 * The `PageFooterComponent` component.
 *
 * @public
 */
@Component({
    selector: 'l7-page-footer',
    templateUrl: './PageFooterComponent.html',
    styleUrls: ['PageFooterComponent.scss'],
    exportAs: 'l7PageContent',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageFooterComponent {

    //#region Ctor

    /**
     * Constructs a new instance of the `PageFooterComponent` class.
     *
     * @public
     */
    public constructor() {
    }

    //#endregion

}
