import { IInvoiceOcrDto } from '@abcfinlab/api/contract';
import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';

@Directive({
    selector: '[l7AddClass]'
})
export class AddClassToFieldDirective implements OnInit {
    @Input() ocrValues: IInvoiceOcrDto;

    constructor(private readonly element: ElementRef, private readonly control: NgControl, public parent: MatFormField) { }

    ngOnInit() {
        if (!this.control.value) {
            this.parent._elementRef.nativeElement.classList.add('no-value');
        }
    }

    public addOcrClass(value: string | number | null) {
        if (this.control.value === value) {
            this.parent._elementRef.nativeElement.classList.add('purple-rain');
        }
    }

    public removeClass(className: string) {
        this.parent._elementRef.nativeElement.classList.remove(className);
    }

    public addClass(className: string) {
        this.parent._elementRef.nativeElement.classList.add(className);
    }
}

