import { AppConfig } from '@abcfinlab/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { hasUsedCondition } from '../../../../../../../apps/shell/src/app/helper/LeasingQuote.helper';
import { DialogService } from '../../../../../../../apps/shell/src/app/private/services/dialog/dialog.service';
import {
    GenericDialogComponent,
    GenericDialogData
} from '../../../../../../../apps/shell/src/app/shared/modals/generic-dialog/generic-dialog.component';
import { BusyBoxService } from '../../../../../../ui/src/Index';
import { ConfirmationDTO } from '../../models/confirmation-dto.class';
import { ConfirmationService } from '../../services/confirmation.service';
import { LeasingQuoteIdService } from '../../services/leasing-quote-id.service';
import { IContractTypeDto, IInhouseQuoteDto, ISaleAndLeaseBackCodeDto, QuoteService } from '@abcfinlab/api/global';

@UntilDestroy()
@Component({
    selector: 'gwg-standard-confirmation',
    templateUrl: './standard-confirmation.component.html',
    styleUrls: ['./standard-confirmation.component.scss']
    })
export class StandardConfirmationComponent implements OnInit {

    private _confirmationsPayload: ConfirmationDTO;
    private readonly _hostUrl: string;

    private readonly _hasSlbConfirmation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public standardConfirmationGroup: UntypedFormGroup;

    public privacypolicyFile: string;

    public unsignedContractFile: string;

    // String reference to the download path of the pdf document if used condition needed
    public eowDocumentFile: string;

    // Responsible for hiding / enabling the EOW checkbox container
    public showEOWConfirmation: boolean;

    public slbDocumentFile: string;

    public isDataAvailable: boolean;

    public contractType: IContractTypeDto;

    public get hasSlbConfirmation(): Observable<boolean> {
        return this._hasSlbConfirmation.asObservable();
    }

    // Contains the "quoteSummary" property for the summary component
    public quoteSummary: IInhouseQuoteDto;

    // Payload for backend confirmation request

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly fb: UntypedFormBuilder,
        private readonly confirmationService: ConfirmationService,
        private readonly leasingQuoteIdService: LeasingQuoteIdService,
        private readonly _quoteService: QuoteService,
        private readonly _dialogService: DialogService,
        private readonly _busyBoxService: BusyBoxService,
        public dialog: MatDialog,
        private readonly appConfig: AppConfig,
        private readonly _cdr: ChangeDetectorRef
    ) {
        this._hostUrl = this.appConfig.get('host');
        this.privacypolicyFile = btoa(`${this._hostUrl}/api/v1/gwg` + '/privacypolicy');
    }

    public ngOnInit(): void {
        this._busyBoxService.show(null, null, this._quoteService.getQuoteByIdV({quoteId: this.leasingQuoteIdService.getLeasingQuoteId()}))
            .pipe(untilDestroyed(this))
            .subscribe(quote => {
                this.quoteSummary = quote;
                this.isDataAvailable = true;
                this.contractType = quote.inhouseQuoteCalculation.contractType;
                this.slbDocumentFile = btoa(`${this._hostUrl}/api/v1/slb` + `/pdf/draft/${quote.quoteId}`);
                this._confirmationsPayload = ConfirmationDTO.createStandardConfirmations(quote.quoteId, quote.objects[0], quote.saleAndLeaseBack);

                this._hasSlbConfirmation.next(this.showSlbConfirmation(quote?.saleAndLeaseBack?.code, quote?.saleAndLeaseBack?.completed));
                this.standardConfirmationGroup = this.fb.group({
                    gdprConfirmation: [false, Validators.requiredTrue],
                    agbConfirmation: [false, Validators.requiredTrue],
                    sepaConfirmation: [false, Validators.requiredTrue],
                    slbConfirmation: [false, this._hasSlbConfirmation.getValue() ? [Validators.requiredTrue] : null]
                });
                if (hasUsedCondition(quote.objects)) {
                    this.showEOWConfirmation = hasUsedCondition(quote.objects);
                    const eowConfirmationFormControl = new UntypedFormControl(false, [Validators.requiredTrue]);
                    this.standardConfirmationGroup.addControl('eowConfirmation', eowConfirmationFormControl);
                    this.eowDocumentFile = btoa(`${this._hostUrl}/api/v1/pdf/${quote.quoteId}/eow`);
                }
                this._cdr.detectChanges();
            });

        this.unsignedContractFile = btoa(
            `${this._hostUrl
            }/api/v1/contracts` +
            `/pdf/download/${this.leasingQuoteIdService.getLeasingQuoteId()}`
        );

    }

    /**
     * Open the corresponding document-file within the pdf document viewer component
     * @param documentType  The document typ that should be loaded within the pdf viewer
     */
    public openDocumentViewer(documentType: string, event: MouseEvent): void {
        event.preventDefault();
        const url = this.router.serializeUrl(
            this.router.createUrlTree(['../pdf-view/', documentType], {
                relativeTo: this.route
            })
        );
        window.open(url, '_blank');
        event.stopPropagation();
    }

    public submitConfirmations(): void {
        this.confirmationService
            .postStandardConfirmations(this._confirmationsPayload)
            .pipe(untilDestroyed(this))
            .subscribe(
                _ => this.router.navigate(['../signature'], { relativeTo: this.route }),
                _error => {
                    if (_error instanceof HttpErrorResponse && _error.status === 409 && _error.error.error === 'beneficial_owner_missing') {
                        // TODO: aw: find error message!!!
                        const data: GenericDialogData = {
                            id: 'dialog_beneficial_owner_missing',
                            image: 'assets/images/image-failure.svg',
                            body: `dialogs.${_error.error.error}`,
                            positiveText: 'global.close',
                        };
                        this._dialogService.openDialog(GenericDialogComponent, { minHeight: undefined }, data).afterClosed()
                            .pipe(untilDestroyed(this))
                            .subscribe(() => {
                                this.dialog.closeAll();
                            });
                    }
                }
            );
    }

    private showSlbConfirmation(code: ISaleAndLeaseBackCodeDto, isCompleted: boolean): boolean {
        if ((code === ISaleAndLeaseBackCodeDto.UnechtliV2 || code === ISaleAndLeaseBackCodeDto.UnechtlnV2 || code === ISaleAndLeaseBackCodeDto.Unechtlnmk || code === ISaleAndLeaseBackCodeDto.Unechtlimk) && isCompleted) {
            return true;
        } else if (code && code !== ISaleAndLeaseBackCodeDto.UnechtliV2 && code !== ISaleAndLeaseBackCodeDto.UnechtlnV2 && code !== ISaleAndLeaseBackCodeDto.Unechtlnmk && code !== ISaleAndLeaseBackCodeDto.Unechtlimk) {
            return true;
        }

        return false;

    }

}
