/**
 * Container/page component
 */

import { CameraStreamComponent } from '@abcfinlab/presentation';
import { Component, ViewChild } from '@angular/core';

@Component({
  selector: 'l7-page-identity-card',
  templateUrl: './page-identity-card.component.html',
  styleUrls: ['./page-identity-card.component.scss']
})
export class PageIdentityCardComponent {

  @ViewChild('camera', { static: true }) cameraStreamComponent: CameraStreamComponent;
  public streamReady: boolean;
  public pictureCaptured: boolean;
  public image: string;

  constructor() {
  }

  public capture(): void {
    try {
      this.image = this.cameraStreamComponent.captureImage();
      this.pictureCaptured = true;
    } catch (e) {
      alert('Es ist ein Fehler während der Aufnahme aufgetreten!');
    }
  }

  public saveIdentityCard(): void {
  }

  public resetIdentityCard(): void {
    this.cameraStreamComponent.resetImage();
    this.image = null;
    this.pictureCaptured = false;
  }
}
