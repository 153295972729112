// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}
:host ::ng-deep l7-persona {
  float: left;
  margin-left: -8px;
  margin-right: 0px;
  transition: margin-right 0.2s;
}
:host ::ng-deep l7-persona:hover {
  margin-right: 8px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIlBlcnNvbmFHcm91cENvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksY0FBQTtBQUNKO0FBRVE7RUFDSSxXQUFBO0VBQ0EsaUJBQUE7RUFDQSxpQkFBQTtFQUNBLDZCQUFBO0FBQVo7QUFFWTtFQUNJLGlCQUFBO0FBQWhCIiwiZmlsZSI6IlBlcnNvbmFHcm91cENvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICAgIGRpc3BsYXk6IGJsb2NrO1xuXG4gICAgOjpuZy1kZWVwIHtcbiAgICAgICAgbDctcGVyc29uYSB7XG4gICAgICAgICAgICBmbG9hdDogbGVmdDtcbiAgICAgICAgICAgIG1hcmdpbi1sZWZ0OiAtOHB4O1xuICAgICAgICAgICAgbWFyZ2luLXJpZ2h0OiAwcHg7XG4gICAgICAgICAgICB0cmFuc2l0aW9uOiBtYXJnaW4tcmlnaHQgMC4ycztcblxuICAgICAgICAgICAgJjpob3ZlciB7XG4gICAgICAgICAgICAgICAgbWFyZ2luLXJpZ2h0OiA4cHg7XG4gICAgICAgICAgICB9XG4gICAgICAgIH1cbiAgICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/ui/src/Controls/Components/Persona/PersonaGroupComponent.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;AAEQ;EACI,WAAA;EACA,iBAAA;EACA,iBAAA;EACA,6BAAA;AAAZ;AAEY;EACI,iBAAA;AAAhB;AACA,4vBAA4vB","sourcesContent":[":host {\n    display: block;\n\n    ::ng-deep {\n        l7-persona {\n            float: left;\n            margin-left: -8px;\n            margin-right: 0px;\n            transition: margin-right 0.2s;\n\n            &:hover {\n                margin-right: 8px;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
