import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SignerDTO } from '../models/signer-dto.interface';
import {AppConfig} from '@abcfinlab/core';

@Injectable({
  providedIn: 'root'
})
export class SignerService {

  private readonly signerSubject: BehaviorSubject<SignerDTO> = new BehaviorSubject<SignerDTO>(undefined);
  signer$: Observable<SignerDTO> = this.signerSubject.asObservable();
  private readonly hostUrl: string;
  constructor(
    private readonly http: HttpClient,
    private readonly appConfig: AppConfig
  ) {
      this.hostUrl = appConfig.get('host');
  }

  postSignerToBackend(): Observable<void> {
    // @Todo: Get leasingQuoteId from outer system
    return this.http.post<void>(
        `${this.hostUrl  }/api/v1/gwg` + '/signer',
      this.signerSubject.getValue(),
      { withCredentials: true }
    );
  }

  updateSigner(signer: SignerDTO): void {
    this.signerSubject.next(signer);
  }

}
