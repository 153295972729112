export function getSubdomain(hostname: string): string {
    const regexParse = new RegExp('[a-z\-0-9]{2,63}\.[a-z\.]{2,5}$');
    const urlParts = regexParse.exec(hostname);
    const subdomain = hostname.replace(urlParts[0], '').slice(0, -1);
    // special case for www
    return subdomain.toLowerCase() === 'www' ? '' : subdomain;
}

export function hasSubdomain(hostname: string): boolean {
    return getSubdomain(hostname) !== '';
}

export function isLocalhost(hostname: string): boolean {
    return hostname.toLowerCase() === 'localhost';
}
