//#region Imports

import { NgModule } from '@angular/core';
import { DetailRowDirective } from './DetailRowDirective';
import { DetailRowService } from './DetailRowService';

//#endregion

/**
 * The `DetailRowModule` module.
 *
 * @public
 */
@NgModule({
    declarations: [
        DetailRowDirective
    ],
    exports: [
        DetailRowDirective
    ],
    providers: [
        DetailRowService
    ],
})
export class DetailRowModule { }
