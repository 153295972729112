//#region Imports

import { AfterContentInit, ChangeDetectorRef, ContentChildren, Directive, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Optional, Output, QueryList, Renderer2, SimpleChanges } from '@angular/core';
import { Event, IsActiveMatchOptions, NavigationEnd, Router, RouterLink, RouterLinkWithHref } from '@angular/router';
import { Subscription, from, of } from 'rxjs';
import { mergeAll } from 'rxjs/operators';
import { RouterLinkActiveOptions, RouterLinkRelatedToLink, isRouterLinkRelatedToLink } from './RouterLinkActiveOptions';
import { RouterLinkWhenActive } from './RouterLinkWhenActive';

//#endregion

const WEAK_MACHT_OPTIONS: IsActiveMatchOptions = {
    matrixParams: 'ignored',
    queryParams: 'ignored',
    paths: 'subset',
    fragment: 'ignored',
};

/**
 * @apublic
 */
@Directive({
    selector: '[l7RouterLinkActive]',
    exportAs: 'routerLinkActive',
})
export class RouterLinkActiveDirective implements OnChanges, OnDestroy, AfterContentInit {

    //#region Fields

    @ContentChildren(RouterLink, { descendants: true })
    private readonly _links!: QueryList<RouterLink>;
    @ContentChildren(RouterLinkWithHref, { descendants: true })
    private readonly _linksWithHrefs!: QueryList<RouterLinkWithHref>;

    private readonly _router: Router;
    private readonly _element: ElementRef;
    private readonly _renderer: Renderer2;
    private readonly _cdr: ChangeDetectorRef;
    private readonly _link?: RouterLink;
    private readonly _linkWithHref?: RouterLinkWithHref;

    private readonly _isActiveChange: EventEmitter<boolean>;
    private readonly _routerEventsSubscription: Subscription;

    private _classes: Array<string> = [];
    private _linkInputChangesSubscription?: Subscription;
    private _ariaCurrentWhenActive: RouterLinkWhenActive | undefined;
    private _routerLinkActiveOptions: RouterLinkActiveOptions;

    //#endregion

    //#region Ctor

    public constructor(router: Router, element: ElementRef, renderer: Renderer2, cdr: ChangeDetectorRef,
        @Optional() link?: RouterLink, @Optional() linkWithHref?: RouterLinkWithHref) {
        this._router = router;
        this._element = element;
        this._renderer = renderer;
        this._cdr = cdr;
        this._link = link;
        this._linkWithHref = linkWithHref;
        this._routerLinkActiveOptions = { exact: false };
        this._isActiveChange = new EventEmitter();

        this._routerEventsSubscription = this._router.events.subscribe((s: Event) => {
            if (s instanceof NavigationEnd) {
                this.update();
            }
        });
    }

    //#endregion

    //#region Properties

    public readonly isActive: boolean = false;

    /**
     * Gets or sets the `routerLinkActiveOptions` property.
     *
     * @public
     */
    @Input()
    public get routerLinkActiveOptions(): RouterLinkActiveOptions {
        return this._routerLinkActiveOptions;
    }
    public set routerLinkActiveOptions(value: RouterLinkActiveOptions) {
        this._routerLinkActiveOptions = value;
    }

    /**
     * Gets or sets the `ariaCurrentWhenActive` property.
     *
     * @public
     */
    @Input()
    public get ariaCurrentWhenActive(): RouterLinkWhenActive | undefined {
        return this._ariaCurrentWhenActive;
    }
    public set ariaCurrentWhenActive(value: RouterLinkWhenActive | undefined) {
        this._ariaCurrentWhenActive = value;
    }

    /**
     * Gets or sets the `routerLinkActive` property.
     *
     * @public
     */
    @Input('l7RouterLinkActive')
    public get routerLinkActive(): Array<string> {
        return this._classes;
    }
    public set routerLinkActive(value: Array<string> | string) {
        const classes = Array.isArray(value) ? value : value.split(' ');
        this._classes = classes.filter(c => !!c);
    }

    /**
     * Called when <ACTION>.
     *
     * @public
     * @readonly
     * @eventProperty
     * @type EventEmitter<void}>
     */
    @Output()
    public get isActiveChange(): EventEmitter<boolean> {
        return this._isActiveChange;
    }

    //#endregion

    //#region Methods

    public ngAfterContentInit(): void {
        // `of(null)` is used to force subscribe body to execute once immediately (like `startWith`).
        of(this._links.changes, this._linksWithHrefs.changes, of(null)).pipe(mergeAll()).subscribe(_ => {
            this.update();
            this.subscribeToEachLinkOnChanges();
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.update();
    }

    public ngOnDestroy(): void {
        this._routerEventsSubscription.unsubscribe();
        this._linkInputChangesSubscription?.unsubscribe();
    }

    private subscribeToEachLinkOnChanges(): void {
        this._linkInputChangesSubscription?.unsubscribe();

        const allLinkChanges =
            [...this._links.toArray(), ...this._linksWithHrefs.toArray(), this._link, this._linkWithHref]
                .filter((link): link is RouterLink | RouterLinkWithHref => !!link)
                // we need to cast this as any, because 'onChanges' is internal in angular
                .map(link => (link as any).onChanges);
        this._linkInputChangesSubscription = from(allLinkChanges).pipe(mergeAll()).subscribe(link => {
            if (this.isActive !== this.isLinkActive(this._router)(link as any)) {
                this.update();
            }
        });
    }

    private update(): void {
        if (!this._links || !this._linksWithHrefs || !this._router.navigated) {
            return;
        }

        void Promise.resolve().then(() => {
            const hasActiveLinks = this.hasActiveLinks();
            if (this.isActive !== hasActiveLinks) {
                (this as any).isActive = hasActiveLinks;
                this._cdr.markForCheck();
                this._classes.forEach(c => {
                    if (hasActiveLinks) {
                        this._renderer.addClass(this._element.nativeElement, c);
                    } else {
                        this._renderer.removeClass(this._element.nativeElement, c);
                    }
                });
                if (hasActiveLinks && this.ariaCurrentWhenActive !== undefined) {
                    this._renderer.setAttribute(this._element.nativeElement, 'aria-current', this.ariaCurrentWhenActive.toString());
                } else {
                    this._renderer.removeAttribute(this._element.nativeElement, 'aria-current');
                }

                this.isActiveChange.emit(hasActiveLinks);
            }
        });
    }

    private hasActiveLinks(): boolean {
        const isActiveCheckFn = this.isLinkActive(this._router);
        const has = this._link && isActiveCheckFn(this._link) ||
            this._linkWithHref && isActiveCheckFn(this._linkWithHref) ||
            this._links.some(isActiveCheckFn) || this._linksWithHrefs.some(isActiveCheckFn);

        const isRelated = (this._routerLinkActiveOptions.relatedTo ?? []).some(isActiveCheckFn);
        return has || isRelated;
    }

    private isLinkActive(router: Router): (link: (RouterLink | RouterLinkWithHref | RouterLinkRelatedToLink)) => boolean {
        const options: boolean | IsActiveMatchOptions = isActiveMatchOptions(this.routerLinkActiveOptions)
            ? this.routerLinkActiveOptions
            : (this.routerLinkActiveOptions.exact || false);

        // eslint-disable-next-line arrow-body-style
        return (link: RouterLink | RouterLinkWithHref | RouterLinkRelatedToLink) => {
            let isActive = false;

            if (isRouterLinkRelatedToLink(link)) {
                if (typeof link === 'string') {
                    isActive = router.isActive(link, (options as any));
                } else {
                    if (link.options) {
                        isActive = router.isActive(link.link, {
                            ...WEAK_MACHT_OPTIONS,
                            ...link.options,
                        });
                    } else {
                        isActive = router.isActive(link.link, options as any);
                    }
                }
            } else {
                if (link.urlTree) {
                    isActive = router.isActive(link.urlTree, options as any);
                }
            }

            return isActive;
        };
    }

    //#endregion

}

/**
 * Use instead of `'paths' in options` to be compatible with property renaming
 */
function isActiveMatchOptions(options: { exact: boolean } | IsActiveMatchOptions): options is IsActiveMatchOptions {
    return !!(options as IsActiveMatchOptions).paths;
}
