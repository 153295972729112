// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
  height: 100%;
  display: flex;
}
:host .feedback-button {
  background-color: #f03e3e;
  color: #ffffff;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIkZlZWRiYWNrV2lkZ2V0Vmlldy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksV0FBQTtFQUNBLFlBQUE7RUFDQSxhQUFBO0FBQ0o7QUFDSTtFQUNJLHlCQUFBO0VBQ0EsY0FBQTtBQUNSIiwiZmlsZSI6IkZlZWRiYWNrV2lkZ2V0Vmlldy5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIGhlaWdodDogMTAwJTtcbiAgICBkaXNwbGF5OiBmbGV4O1xuXG4gICAgLmZlZWRiYWNrLWJ1dHRvbiB7XG4gICAgICAgIGJhY2tncm91bmQtY29sb3I6ICNmMDNlM2U7XG4gICAgICAgIGNvbG9yOiAjZmZmZmZmO1xuICAgIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/feedback/src/Views/Widgets/FeedbackWidgetView.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,aAAA;AACJ;AACI;EACI,yBAAA;EACA,cAAA;AACR;AACA,4hBAA4hB","sourcesContent":[":host {\n    width: 100%;\n    height: 100%;\n    display: flex;\n\n    .feedback-button {\n        background-color: #f03e3e;\n        color: #ffffff;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
