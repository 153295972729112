import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgControl } from '@angular/forms';
import { IbanCheckService, IBankAccountDto, IIbanInfoResultDto, RemoteIbanCheckService } from '@abcfinlab/api/global';
import { once } from '@abcfinlab/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
    selector: '[l7ValidateBankAccount]'
})
export class ValidateIbanDirective implements OnInit {

    //#region Fields

    private readonly _formControl: NgControl;
    private readonly _ibanCheckService: IbanCheckService;
    private readonly _remoteIbanCheckService: RemoteIbanCheckService;

    private _isRemote: boolean;

    //#endregion

    @Output() bankAccount: EventEmitter<IBankAccountDto | IIbanInfoResultDto> = new EventEmitter<IBankAccountDto | IIbanInfoResultDto>();

    //#region Ctor

    public constructor(formControl: NgControl, ibanCheckService: IbanCheckService, remoteIbanCheckService: RemoteIbanCheckService) {
        this._formControl = formControl;
        this._ibanCheckService = ibanCheckService;
        this._remoteIbanCheckService = remoteIbanCheckService;
    }

    //#endregion

    //#region Properties

    /**
     * Gets or sets the `isRemote` property.
     *
     * @public
     */

    @Input('l7isRemote')
    public get isRemote(): boolean {
        return this._isRemote;
    }
    public set isRemote(value: boolean) {
        if (this._isRemote !== value) {
            this._isRemote = value;
        }
    }

    //#endregion

    //#region Methods

    /**
     * @public
     */
    public ngOnInit(): void {
        this._formControl.statusChanges.pipe(untilDestroyed(this)).subscribe(status => {
            const iban = this._formControl.control.getRawValue();
            const ibanChecker$ = this._isRemote ? this._remoteIbanCheckService.getBankAccountRemoteByIban({iban}) : this._ibanCheckService.getBankAccountbyIban({iban});
            if (status === 'VALID') {
                once(ibanChecker$,
                    res => this.bankAccount.emit(res),
                    _ => this.bankAccount.emit(null)
                );
            } else if (status === 'INVALID') {
                this.bankAccount.emit(null);
            }
        });
    }

    //#endregion

}
