import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'l7-retailer-bonicheck-pending',
  templateUrl: './retailer-bonicheck-pending.component.html',
  styleUrls: ['./retailer-bonicheck-pending.component.scss']
})
export class RetailerBonicheckPendingComponent {

  public constructor(
    private _dialogRef: MatDialogRef<RetailerBonicheckPendingComponent>,
    private _router: Router
  ) {}

  public onClose (): void {
    this._dialogRef.close();
  }

  public onLinkClick(): void {
    this._router.navigate(['/presentation/quote/retailer/create-quote']);
    this._dialogRef.close();
  }
}
