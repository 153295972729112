//#region Imports

import { Injectable } from '@angular/core';

//#endregion

/**
 * @public
 */
export type AppConfigResult<TData> = {
    success: true;
    value: TData;
} | {
    success: false;
    error: Error;
};

/**
 * @public
 */
@Injectable({ providedIn: 'root' })
export class AppConfig<TData = unknown> {

    //#region Fields

    private static _instance: AppConfig<any>;

    private _config: TData | null;

    //#endregion

    //#region Ctor

    public constructor() {
        this._config = null;
    }

    //#endregion

    //#region Methods

    /**
     * @public
     * @static
     */
    public static instance<TData>(): AppConfig<TData> {
        if (!AppConfig._instance) {
            AppConfig._instance = new AppConfig<TData>();
        }

        return AppConfig._instance;
    }

    public get<TResult = unknown>(key: string): TResult {
        return this._config ? this._config[key] : null;
    }

    public config(): TData {
        return this._config;
    }

    public load(configFile: string): Promise<AppConfigResult<TData>> {
        return new Promise((resolve, _): void => {
            // Why does this construct use the fetch api?
            // The fetch-api is vanilla javascript and it is faster to use it like any http package.
            window.fetch(configFile).then(response => response.ok ? response.json() : {}).then((config: TData) => {
                this._config = config;
                resolve({ success: true, value: this._config });
            }).catch(error => {
                console.error(`Error while reading ${configFile} configuration file.`, error);
                resolve({ success: false, error });
            });
        });
    }

    // endregion

}

