// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .camera-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
:host .camera-indicator.disabled mat-icon {
  color: #bf4128;
}
:host .camera-indicator.enabled mat-icon {
  color: #55ed9c;
}
:host mat-icon {
  color: #84939d;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNhbWVyYS1pbmRpY2F0b3IuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0U7RUFDRSxhQUFBO0VBQ0EsdUJBQUE7RUFDQSxtQkFBQTtFQUNBLFlBQUE7QUFBSjtBQUdNO0VBQ0UsY0FBQTtBQURSO0FBTU07RUFDRSxjQUFBO0FBSlI7QUFTRTtFQUNFLGNBQUE7QUFQSiIsImZpbGUiOiJjYW1lcmEtaW5kaWNhdG9yLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICAuY2FtZXJhLWluZGljYXRvciB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgIGhlaWdodDogMTAwJTtcblxuICAgICYuZGlzYWJsZWQge1xuICAgICAgbWF0LWljb24ge1xuICAgICAgICBjb2xvcjogI2JmNDEyODtcbiAgICAgIH1cbiAgICB9XG5cbiAgICAmLmVuYWJsZWQge1xuICAgICAgbWF0LWljb24ge1xuICAgICAgICBjb2xvcjogIzU1ZWQ5YztcbiAgICAgIH1cbiAgICB9XG4gIH1cblxuICBtYXQtaWNvbiB7XG4gICAgY29sb3I6ICM4NDkzOWQ7XG4gIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/presentation/src/Components/camera/camera-indicator/camera-indicator.component.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AAAJ;AAGM;EACE,cAAA;AADR;AAMM;EACE,cAAA;AAJR;AASE;EACE,cAAA;AAPJ;AACA,40BAA40B","sourcesContent":[":host {\n  .camera-indicator {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n\n    &.disabled {\n      mat-icon {\n        color: #bf4128;\n      }\n    }\n\n    &.enabled {\n      mat-icon {\n        color: #55ed9c;\n      }\n    }\n  }\n\n  mat-icon {\n    color: #84939d;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
