// @TODO: Rename to LesseeCreditState
export enum LesseeState {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  EXPIRED = 'EXPIRED',
  OPEN = 'OPEN',
  PENDING = 'PENDING',
  DISCARDED = 'DISCARDED',
  APPROVED_WITH_CONDITIONS = 'APPROVED_WITH_CONDITIONS',
  APPROVED_WITH_CONDITIONS_FULFILLED = 'APPROVED_WITH_CONDITIONS_FULFILLED'
}
