/**
 * @public
 */
export enum CountryCode {
    AD = 'AD',
    AM = 'AM',
    AR = 'AR',
    AS = 'AS',
    AT = 'AT',
    AU = 'AU',
    AX = 'AX',
    AZ = 'AZ',
    BA = 'BA',
    BB = 'BB',
    BD = 'BD',
    BE = 'BE',
    BG = 'BG',
    BH = 'BH',
    BM = 'BM',
    BN = 'BN',
    BR = 'BR',
    BY = 'BY',
    CA = 'CA',
    CC = 'CC',
    CH = 'CH',
    CK = 'CK',
    CL = 'CL',
    CN = 'CN',
    CR = 'CR',
    CS = 'CS',
    CV = 'CV',
    CX = 'CX',
    CY = 'CY',
    CZ = 'CZ',
    DE = 'DE',
    DK = 'DK',
    DO = 'DO',
    DZ = 'DZ',
    EC = 'EC',
    EE = 'EE',
    EG = 'EG',
    ES = 'ES',
    ET = 'ET',
    FI = 'FI',
    FK = 'FK',
    FM = 'FM',
    FO = 'FO',
    FR = 'FR',
    GB = 'GB',
    GE = 'GE',
    GF = 'GF',
    GG = 'GG',
    GL = 'GL',
    GN = 'GN',
    GP = 'GP',
    GR = 'GR',
    GS = 'GS',
    GT = 'GT',
    GU = 'GU',
    GW = 'GW',
    HM = 'HM',
    HN = 'HN',
    HR = 'HR',
    HT = 'HT',
    HU = 'HU',
    ID = 'ID',
    IE = 'IE',
    IL = 'IL',
    IM = 'IM',
    IN = 'IN',
    INTL = 'INTL',
    IO = 'IO',
    IQ = 'IQ',
    IS = 'IS',
    IT = 'IT',
    JE = 'JE',
    JO = 'JO',
    JP = 'JP',
    KE = 'KE',
    KG = 'KG',
    KH = 'KH',
    KR = 'KR',
    KW = 'KW',
    KZ = 'KZ',
    LA = 'LA',
    LB = 'LB',
    LI = 'LI',
    LK = 'LK',
    LR = 'LR',
    LS = 'LS',
    LT = 'LT',
    LU = 'LU',
    LV = 'LV',
    MA = 'MA',
    MC = 'MC',
    MD = 'MD',
    ME = 'ME',
    MG = 'MG',
    MH = 'MH',
    MK = 'MK',
    MN = 'MN',
    MP = 'MP',
    MQ = 'MQ',
    MT = 'MT',
    MU = 'MU',
    MV = 'MV',
    MX = 'MX',
    MY = 'MY',
    NC = 'NC',
    NE = 'NE',
    NF = 'NF',
    NG = 'NG',
    NI = 'NI',
    NL = 'NL',
    NO = 'NO',
    NP = 'NP',
    NZ = 'NZ',
    OM = 'OM',
    PF = 'PF',
    PG = 'PG',
    PH = 'PH',
    PK = 'PK',
    PL = 'PL',
    PM = 'PM',
    PN = 'PN',
    PR = 'PR',
    PT = 'PT',
    PW = 'PW',
    PY = 'PY',
    RE = 'RE',
    RO = 'RO',
    RS = 'RS',
    RU = 'RU',
    SA = 'SA',
    SE = 'SE',
    SG = 'SG',
    SH = 'SH',
    SI = 'SI',
    SJ = 'SJ',
    SK = 'SK',
    SM = 'SM',
    SN = 'SN',
    SO = 'SO',
    SZ = 'SZ',
    TC = 'TC',
    TH = 'TH',
    TJ = 'TJ',
    TM = 'TM',
    TN = 'TN',
    TR = 'TR',
    TW = 'TW',
    UA = 'UA',
    UK = 'UK',
    US = 'US',
    UY = 'UY',
    UZ = 'UZ',
    VA = 'VA',
    VE = 'VE',
    VI = 'VI',
    VN = 'VN',
    WF = 'WF',
    XK = 'XK',
    YT = 'YT',
    YU = 'YU',
    ZA = 'ZA',
    ZM = 'ZM',
}
