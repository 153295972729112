//#region Imports

import { Inject, Injectable } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { ISettlementNavErrorDialogData } from '../../Models/ISettlementNavErrorDialogData';

//#endregion

/**
 * The presenter of the @{link ManualProcessingErrorDialogPresenter} view.
 *
 * @internal
 */
@Injectable()
export class ManualProcessingErrorDialogPresenter {

    //#region Fields

    private readonly _dataSubject: BehaviorSubject<ISettlementNavErrorDialogData>;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `ManualProcessingErrorDialogPresenter` class.
     *
     * @public
     */
    public constructor(@Inject(MAT_DIALOG_DATA) data: ISettlementNavErrorDialogData) {
        this._dataSubject = new BehaviorSubject(data);
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `data` property.
     *
     * @public
     * @readonly
     */
    public get data(): Observable<ISettlementNavErrorDialogData> {
        return this._dataSubject.asObservable();
    }

    //#endregion

    //#region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
    }

    //#endregion

}
