import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PicturesService } from './services/pictures.service';

@UntilDestroy()
@Component({
    selector: 'gwg-gwg',
    templateUrl: './gwg.component.html'
})
export class GwgComponent implements OnInit {

    showAbortLink: boolean = true;

    constructor(
        private readonly router: Router,
        private readonly _pictureService: PicturesService,
        private readonly _activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        if (this.router.url.includes('/gwg/pdf-view')) {
            this.showAbortLink = false;
        }

        this.router.events
            .pipe(untilDestroyed(this))
            .subscribe((event: Event) => {
                // Hide 'Abbrechen' on contract success page / CLASSIC FLOW
                if (event instanceof NavigationEnd && event.urlAfterRedirects.includes('/gwg/success')) {
                    this.showAbortLink = false;
                }
                // Hide 'Abbrechen' on signing-link successfully created page / REMOTE FLOW
                if (event instanceof NavigationStart && event.url.includes('/gwg/signing-link')) {
                    this.showAbortLink = false;
                }
            });
    }

    /**
   * Call GWG storage cleanup method an *Abbrechen*-Button
   */
    public reset(): void {
        this._pictureService.clearDocumentStorage()
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                void this.router.navigate(['./presentation/quotes/detail']);
            });
    }
}
