// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-list-item {
  background: #ffffff;
}
.mat-mdc-list-item .lesseeInfo {
  color: #cccccc;
}

.subTitle {
  color: rgba(0, 0, 0, 0.87);
  font-weight: bold;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInJldGFpbGVyLXVwZGF0ZS1sZXNzZWUtb3ZlcnZpZXcuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxtQkFBQTtBQUNKO0FBQ0k7RUFDSSxjQUFBO0FBQ1I7O0FBRUE7RUFDSSwwQkFBQTtFQUNBLGlCQUFBO0FBQ0oiLCJmaWxlIjoicmV0YWlsZXItdXBkYXRlLWxlc3NlZS1vdmVydmlldy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5tYXQtbWRjLWxpc3QtaXRlbSB7XG4gICAgYmFja2dyb3VuZDogI2ZmZmZmZjtcblxuICAgIC5sZXNzZWVJbmZvIHtcbiAgICAgICAgY29sb3I6ICNjY2NjY2M7XG4gICAgfVxufVxuLnN1YlRpdGxle1xuICAgIGNvbG9yOiByZ2JhKGJsYWNrLCAwLjg3KTtcbiAgICBmb250LXdlaWdodDogYm9sZDtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/quote/src/Views/retailer-update-lessee-overview/retailer-update-lessee-overview.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;AACI;EACI,cAAA;AACR;;AAEA;EACI,0BAAA;EACA,iBAAA;AACJ;AACA,4mBAA4mB","sourcesContent":[".mat-mdc-list-item {\n    background: #ffffff;\n\n    .lesseeInfo {\n        color: #cccccc;\n    }\n}\n.subTitle{\n    color: rgba(black, 0.87);\n    font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
