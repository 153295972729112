import { Component } from '@angular/core';
import {PictureType} from '../../models/picture-type.enum';

@Component({
  selector: 'gwg-upload-file-backside',
  templateUrl: './upload-file-backside.component.html',
  styleUrls: ['./upload-file-backside.component.css']
})
export class UploadFileBacksideComponent {
  side: PictureType = PictureType.Backside;

  text: string = 'rückseite';

  constructor() { }

}
