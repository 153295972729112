import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import {
  CreateQuote,
  ResetCalculationState,
  UpdateCalculationUIState
} from '../../actions/Calculation.actions';
import { CalculationState, CalculationStateModel, CalculationUIState } from '../../state/Calculation.state';
import { LesseeState } from '../../state/Leesee.state';

@Injectable({
  providedIn: 'root'
})
export class ResetCalculationStateResolver implements Resolve<CalculationStateModel> {
  constructor(private readonly store: Store) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const selectedLessee: any = this.store.selectSnapshot(LesseeState.getSelectedLessee);
    return this.store.dispatch(new ResetCalculationState()).pipe(
      map(() => {
        if (state.url.endsWith('quote/create/calculation')) {
          this.store.dispatch(new UpdateCalculationUIState(CalculationUIState.CREATE));
          this.store.dispatch(new CreateQuote(selectedLessee));
        }

        return this.store.selectSnapshot(CalculationState.GetState);
      })
    );
  }
}
