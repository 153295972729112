//#region Imports

import { ClipboardModule } from '@angular/cdk/clipboard';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { OfPipeModule } from '../../../Pipes/OfPipe';
import { TextBlockModule } from '../TextBlock/TextBlockModule';
import { MessageBoxComponent } from './MessageBoxComponent';

//#endregion

/**
 * @public
 */
@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        TextBlockModule,
        FlexLayoutModule,
        OfPipeModule,
        MatExpansionModule,
        MatInputModule,
        ClipboardModule,
        MatIconModule,
        TextFieldModule
    ],
    declarations: [
        MessageBoxComponent
    ],
    exports: [
        MessageBoxComponent
    ]
})
export class MessageBoxModule {
}
