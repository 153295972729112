//#region Imports

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { AuthorizeViewPresenter } from './AuthorizeViewPresenter';

//#endregion

/**
 * The `AuthorizeView` view.
 *
 * @public
 */
@Component({
    selector: 'l7-authorize-view',
    templateUrl: './AuthorizeView.html',
    styleUrls: ['./AuthorizeView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        AuthorizeViewPresenter
    ]
})
export class AuthorizeView implements OnInit, OnDestroy {

    //#region Fields

    private readonly _presenter: AuthorizeViewPresenter;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `AuthorizeView` class.
     *
     * @public
     */
    public constructor(presenter: AuthorizeViewPresenter) {
        this._presenter = presenter;
    }

    //#endregion

    //#region Proeprties

    /**
     * Returns the presenter of the `AuthorizeView`
     *
     * @public
     * @readonly
     */
    public get presenter(): AuthorizeViewPresenter {
        return this._presenter;
    }

    //#endregion

    //#region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize();
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    //#endregion

}
