//#region Imports

import { DOCUMENT } from '@angular/common';
import { inject, InjectionToken } from '@angular/core';

//#endregion

/**
 * @public
 */
export const WINDOW = new InjectionToken<Window>('WINDOW', {
    factory: (): Window => {
        const view = inject(DOCUMENT).defaultView;

        if (!view) {
            throw new Error('Window is not available.');
        }

        return view;
    },
});
