import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { from } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CameraService {

    public cameraCanBeUsed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public initialCheckPerformed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public defaultConstraints: MediaStreamConstraints;

    constructor(
    ) {
        this.defaultConstraints = {
            video: {
                facingMode: { ideal: 'user' },
                width: { min: 640, ideal: 1024, max: 1280 }, height: { min: 320, ideal: 768, max: 1024 },
            }, audio: false
        };
    }

    get camera_is_available(): boolean {
        return this.cameraCanBeUsed$.value;
    }

    get initial_check_done(): boolean {
        return this.initialCheckPerformed$.value;
    }

    /**
   * Check if browser is capable of the user media object
   */
    private _browserIsCapable(): boolean {
        return 'mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices;
    }

    /**
   *
   */
    public requestUserPermission() {
        if (this._browserIsCapable()) {
            return from(navigator.mediaDevices.getUserMedia(this.defaultConstraints));
        }

        console.warn('Camera not supported.');
    }

    /**
   * Get a concrete MediaStream object to work with.
   * Inform the subject if the camera can be used or not.
   */
    public getVideoStream(): Observable<MediaStream> {
        return from(navigator.mediaDevices.getUserMedia(this.defaultConstraints)).pipe(
            map(stream => {
                if (!!stream) {
                    this.cameraCanBeUsed$.next(!!stream);
                }
                return stream;
            }),
            catchError((err: Error) => {
                this.cameraCanBeUsed$.next(false);
                return throwError('Camera not supported.');
            })
        );
    }

    /**
   * Release / stop tracks of a stream.
   */
    public stopTracks(stream: MediaStream): void {
        const tracks = stream.getTracks();
        tracks.forEach(track => {
            track.stop();
        });
    }

    public initCamera(): Observable<any> {
        if (this._browserIsCapable()) {
            return from(navigator.mediaDevices.getUserMedia(this.defaultConstraints)).pipe(
                map(stream => {
                    this.stopTracks(stream);
                    this.initialCheckPerformed$.next(true);
                    this.cameraCanBeUsed$.next(true);
                    return of(true);
                }),
                catchError((error: unknown) => {
                    this.initialCheckPerformed$.next(true);
                    return of(false);
                })
            );
        } else {
            this.initialCheckPerformed$.next(true);
            return of(throwError(new Error('Camera not supported.')));
        }
    }
}
