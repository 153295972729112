//#region Imports

import { Directive, ElementRef, forwardRef, NgModule, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromEvent, Subscription } from 'rxjs';

//#endregion

/**
 * @public
 */
@Directive({
    selector: 'input[formControlName][type=tel], input[formControl][type=tel], input[ngModel][type=tel]',
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PhoneControlValueAccessor), multi: true },
    ],
})
export class PhoneControlValueAccessor implements ControlValueAccessor, OnInit, OnDestroy {

    //#region Fields

    private readonly _element: ElementRef<HTMLInputElement>;

    private _blurSubscription: Subscription;
    private _inputSubscription: Subscription;
    private _onTouchedCallback: () => void;
    private _onChangeCallback: (_: any) => void;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `CheckboxGroupControlValueAccessorDirective` class.
     *
     * @public
     */
    public constructor(element: ElementRef) {
        this._element = element;
        this._blurSubscription = Subscription.EMPTY;
        this._inputSubscription = Subscription.EMPTY;
        this._onTouchedCallback = () => { };
        this._onChangeCallback = () => { };
    }

    //#endregion

    //#region Methods

    /**
     * @public
     */
    public ngOnInit(): void {
        this._blurSubscription = fromEvent(this._element.nativeElement, 'blur').subscribe(() => this.onBlur());
        this._inputSubscription = fromEvent(this._element.nativeElement, 'input').subscribe(() => this.onInput());
    }

    /**
     * @public
     */
    public ngOnDestroy(): void {
        this._blurSubscription.unsubscribe();
    }

    /**
     * @public
     */
    public writeValue(value: string): void {
        this._element.nativeElement.value = value;
    }

    /**
     * @public
     */
    public setDisabledState(isDisabled: boolean): void {
        this._element.nativeElement.disabled = isDisabled;
    }

    /**
     * @public
     */
    public registerOnChange(fn: (_: any) => void): void {
        this._onChangeCallback = fn;
    }

    /**
     * @public
     */
    public registerOnTouched(fn: () => void): void {
        this._onTouchedCallback = fn;
    }

    /**
     * @private
     */
    private onBlur(): void {
        this._onTouchedCallback();
    }

    /**
     * @private
     */
    private onInput(): void {
        // Remove whitespace, parentheses, and the minus symbol
        const value = this._element.nativeElement.value.replace(/[\s()-]/g, '');
        this._onChangeCallback(value);
    }

    //#endregion

}


/**
 * @public
 */
@NgModule({
    declarations: [
        PhoneControlValueAccessor
    ],
    exports: [
        PhoneControlValueAccessor
    ]
})
export class PhoneControlValueAccessorModule { }