// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .mat-icon {
  font-size: 20px;
  display: inline-flex;
  margin-left: 8px;
}
:host .disabled {
  color: #CCCCCC;
}
:host .disabled:hover {
  transform: unset !important;
  cursor: not-allowed !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImRlbGV0ZS1xdW90ZS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDSTtFQUNJLGVBQUE7RUFDQSxvQkFBQTtFQUNBLGdCQUFBO0FBQVI7QUFFSTtFQUNJLGNBQUE7QUFBUjtBQUNRO0VBQ0ksMkJBQUE7RUFDQSw4QkFBQTtBQUNaIiwiZmlsZSI6ImRlbGV0ZS1xdW90ZS5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgICAubWF0LWljb24ge1xuICAgICAgICBmb250LXNpemU6IDIwcHg7XG4gICAgICAgIGRpc3BsYXk6IGlubGluZS1mbGV4O1xuICAgICAgICBtYXJnaW4tbGVmdDogOHB4O1xuICAgIH1cbiAgICAuZGlzYWJsZWQge1xuICAgICAgICBjb2xvcjogI0NDQ0NDQztcbiAgICAgICAgJjpob3ZlciB7XG4gICAgICAgICAgICB0cmFuc2Zvcm06IHVuc2V0ICFpbXBvcnRhbnQ7XG4gICAgICAgICAgICBjdXJzb3I6IG5vdC1hbGxvd2VkICFpbXBvcnRhbnQ7XG4gICAgICAgIH1cbiAgICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/quote/src/Components/delete-quote/delete-quote.component.scss"],"names":[],"mappings":"AACI;EACI,eAAA;EACA,oBAAA;EACA,gBAAA;AAAR;AAEI;EACI,cAAA;AAAR;AACQ;EACI,2BAAA;EACA,8BAAA;AACZ;AACA,wuBAAwuB","sourcesContent":[":host {\n    .mat-icon {\n        font-size: 20px;\n        display: inline-flex;\n        margin-left: 8px;\n    }\n    .disabled {\n        color: #CCCCCC;\n        &:hover {\n            transform: unset !important;\n            cursor: not-allowed !important;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
