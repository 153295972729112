import { ILeasingQuoteCreditRatingDto } from '@abcfinlab/api/global';

export const colorMapByStatus = new Map();
colorMapByStatus.set(null, 'danger');
colorMapByStatus.set(ILeasingQuoteCreditRatingDto.Open, 'primary');
colorMapByStatus.set(ILeasingQuoteCreditRatingDto.Positive, 'success');
colorMapByStatus.set(ILeasingQuoteCreditRatingDto.ApprovedWithConditions, 'highlight');
colorMapByStatus.set(ILeasingQuoteCreditRatingDto.ApprovedWithConditionsFulfilled, 'success');
colorMapByStatus.set(ILeasingQuoteCreditRatingDto.Negative, 'danger');

export const iconMapByStatus = new Map();
iconMapByStatus.set(null, 'Shield_Cross_Filled');
iconMapByStatus.set(ILeasingQuoteCreditRatingDto.Open, 'Shield');
iconMapByStatus.set(ILeasingQuoteCreditRatingDto.Positive, 'Shield_Check_Filled');
iconMapByStatus.set(ILeasingQuoteCreditRatingDto.ApprovedWithConditions, 'Time_Filled');
iconMapByStatus.set(ILeasingQuoteCreditRatingDto.ApprovedWithConditionsFulfilled, 'Shield_Check_Filled');
iconMapByStatus.set(ILeasingQuoteCreditRatingDto.Negative, 'Shield_Cross_Filled');
