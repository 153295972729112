// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  width: 100%;
  height: 100%;
}
:host .clear-all-container {
  margin: 16px 0;
}
:host .quick-action-container {
  margin: 16px 0;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIlJldGFpbGVyc092ZXJ2aWV3Vmlldy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksY0FBQTtFQUNBLFdBQUE7RUFDQSxZQUFBO0FBQ0o7QUFDSTtFQUNJLGNBQUE7QUFDUjtBQUVJO0VBQ0ksY0FBQTtBQUFSIiwiZmlsZSI6IlJldGFpbGVyc092ZXJ2aWV3Vmlldy5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICAgIGRpc3BsYXk6IGJsb2NrO1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIGhlaWdodDogMTAwJTtcblxuICAgIC5jbGVhci1hbGwtY29udGFpbmVyIHtcbiAgICAgICAgbWFyZ2luOiAxNnB4IDA7XG4gICAgfVxuXG4gICAgLnF1aWNrLWFjdGlvbi1jb250YWluZXIge1xuICAgICAgICBtYXJnaW46IDE2cHggMDtcbiAgICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/administration/src/Views/RetailersOverviewView.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,WAAA;EACA,YAAA;AACJ;AACI;EACI,cAAA;AACR;AAEI;EACI,cAAA;AAAR;AACA,4lBAA4lB","sourcesContent":[":host {\n    display: block;\n    width: 100%;\n    height: 100%;\n\n    .clear-all-container {\n        margin: 16px 0;\n    }\n\n    .quick-action-container {\n        margin: 16px 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
