//#region Imports

import {
    AcknowledgementService,
    IInstalmentCalculationDataDto,
    InstalmentCalculationService, SlbService
} from '@abcfinlab/api/contract';
import { QuoteService } from '@abcfinlab/api/global';
import { EventHub, HttpErrorResponseExtractor, once, TranslationFacade } from '@abcfinlab/core';
import { ToastService } from '@abcfinlab/ui';
import { Injectable } from '@angular/core';
import { IContractRecalculationInfo } from 'libs/contractManagement/src/Models/IContractRecalculationInfo';
import { KnownEvents } from 'libs/contractManagement/src/Models/KnownEvents';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

//#endregion

/**
 * The presenter of the {@link ContractOverviewRecalculationView} view.
 *
 * @internal
 */
@Injectable()
export class ContractOverviewRecalculationViewPresenter {

    //#region Fields

    private readonly _quoteService: QuoteService;
    private readonly _acknowledgementService: AcknowledgementService;
    private readonly _slbService: SlbService;
    private readonly _instalmentCalculationService: InstalmentCalculationService;
    private readonly _toastService: ToastService;
    private readonly _eventHub: EventHub;
    private readonly _translationFacade: TranslationFacade;
    private readonly _dataSubject: BehaviorSubject<IContractRecalculationInfo | null>;
    private readonly _contractNumber: BehaviorSubject<string | null>;
    private readonly _disableSave: BehaviorSubject<boolean | null>;
    private readonly _originalInstalment: BehaviorSubject<number | null>;
    private readonly _yearlyInterest: BehaviorSubject<number | null>;
    private readonly _instalment: BehaviorSubject<number | null>;

    private _calculationDataSubscription: Subscription;
    private _eventSubscription: Subscription;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `ContractOverviewRecalculationViewPresenter` class.
     *
     * @public
     */
    public constructor(quoteService: QuoteService, acknowledgementService: AcknowledgementService,
        instalmentCalculationService: InstalmentCalculationService, toastService: ToastService,
        eventHub: EventHub, translationFacade: TranslationFacade, slb: SlbService) {
        this._quoteService = quoteService;
        this._acknowledgementService = acknowledgementService;
        this._slbService = slb;
        this._instalmentCalculationService = instalmentCalculationService;
        this._toastService = toastService;
        this._eventHub = eventHub;
        this._translationFacade = translationFacade;
        this._calculationDataSubscription = Subscription.EMPTY;
        this._eventSubscription = Subscription.EMPTY;
        this._dataSubject = new BehaviorSubject<IContractRecalculationInfo | null>(null);
        this._originalInstalment = new BehaviorSubject<number | null>(null);
        this._yearlyInterest = new BehaviorSubject<number | null>(null);
        this._instalment = new BehaviorSubject<number | null>(null);
        this._contractNumber = new BehaviorSubject<string | null>(null);
        this._disableSave = new BehaviorSubject<boolean | null>(false);
    }

    //#endregion

    //#region Properties
    /**
     * Returns the `data` property.
     *
     * @public
     * @readonly
     */
    public get data(): Observable<IContractRecalculationInfo | null> {
        return this._dataSubject.asObservable();
    }

    public get instalment(): Observable<number | null> {
        return this._instalment.asObservable();
    }

    public get yearlyInterest(): Observable<number | null> {
        return this._yearlyInterest.asObservable();
    }

    public get originalInstalment(): Observable<number | null> {
        return this._originalInstalment.asObservable();
    }

    public get disableSave(): Observable<boolean | null> {
        return this._disableSave.asObservable();
    }
    //#endregion

    //#region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(quoteId: string, contractNumber: string, isSlb: boolean): void {
        this._contractNumber.next(contractNumber);
        const getAcknowledgement$ = isSlb ? of(null) : this._acknowledgementService.readByContractNumber({ contractNumber: contractNumber }).pipe(take(1), catchError(() => of(null)));
        const getSlb$ = isSlb ? this._slbService.getSlb({ contractId: contractNumber }).pipe(take(1), catchError(() => of(null))) : of(null);
        this._calculationDataSubscription = combineLatest([
            this._quoteService.getQuoteCalculation({ leasingQuoteId: quoteId }).pipe(take(1), catchError(() => of(null))),
            this._quoteService.getRefinancingInterest({ quoteId: quoteId }).pipe(take(1), catchError(() => of(null))),
            getAcknowledgement$,
            getSlb$,
            this._instalmentCalculationService.getInstalmentCalculation({ contractId: contractNumber }).pipe(take(1), catchError(() => of(null))),
        ]).subscribe(([calculation, refinancingInterest, acknowledgement, slb, instalmentCalculation]) => {
            this._dataSubject.next({
                calculation,
                refinancingInterest,
                acknowledgement,
                slb,
                instalmentCalculation
            });
            this._instalment.next(instalmentCalculation.instalment);
            this._yearlyInterest.next(instalmentCalculation.yearlyInterest);
            this._originalInstalment.next(instalmentCalculation.instalment);
        });
        this._eventSubscription = this._eventHub.getEvent<IInstalmentCalculationDataDto>(KnownEvents.INSTALMENT_CHANGED_IN_RECALCULATION)
            .subscribe(x => {
                this._instalment.next(x.instalment);
                this._yearlyInterest.next(x.yearlyInterest);
                this._originalInstalment.next(x.instalment);
            });
    }

    public calculate(param: 'yearlyInterest' | 'instalment', value: unknown): void {
        if (!value) {
            return;
        }
        this._instalmentCalculationService.getInstalmentCalculation({ contractId: this._contractNumber.value, [param]: value })
            .subscribe(result => {
                if (param === 'yearlyInterest') {
                    this._instalment.next(result.instalment);
                } else {
                    this._yearlyInterest.next(result.yearlyInterest);
                }
            });
    }

    public updateCalculation(instalment: number, yearlyInterest: number): void {
        this._disableSave.next(true);

        const body = {
            instalment,
            yearlyInterest
        };
        once(this._instalmentCalculationService.createInstalmentCalculation({ contractId: this._contractNumber.value, body }), () => {
            this._eventHub.publish<IInstalmentCalculationDataDto>(KnownEvents.INSTALMENT_CHANGED_IN_RECALCULATION, body);
            this._toastService.show('neue Rate gespeichert', 'success');
        }, error => {
            const info = HttpErrorResponseExtractor.parse(error);
            this._toastService.show(this._translationFacade.translate(`error.${info.error}`), 'danger');
            this._disableSave.next(false);
        });
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
        this._calculationDataSubscription.unsubscribe();
        this._eventSubscription.unsubscribe();
    }

    //#endregion

}
