// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  width: 100%;
  height: 100%;
}
:host .clear-all-container {
  margin: 16px 0;
}
:host .quick-action-container {
  margin: 16px 0;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIkVkaXRSZXRhaWxlclZpZXcuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLGNBQUE7RUFDQSxXQUFBO0VBQ0EsWUFBQTtBQUNKO0FBQ0k7RUFDSSxjQUFBO0FBQ1I7QUFFSTtFQUNJLGNBQUE7QUFBUiIsImZpbGUiOiJFZGl0UmV0YWlsZXJWaWV3LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XG4gICAgZGlzcGxheTogYmxvY2s7XG4gICAgd2lkdGg6IDEwMCU7XG4gICAgaGVpZ2h0OiAxMDAlO1xuXG4gICAgLmNsZWFyLWFsbC1jb250YWluZXIge1xuICAgICAgICBtYXJnaW46IDE2cHggMDtcbiAgICB9XG5cbiAgICAucXVpY2stYWN0aW9uLWNvbnRhaW5lciB7XG4gICAgICAgIG1hcmdpbjogMTZweCAwO1xuICAgIH1cbn1cblxuLy8gOjpuZy1kZWVwIG1hdC10YWItZ3JvdXAge1xuLy8gICAgIG1heC1oZWlnaHQ6IDcwdmg7XG4vLyB9XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/administration/src/Views/EditRetailerView.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,WAAA;EACA,YAAA;AACJ;AACI;EACI,cAAA;AACR;AAEI;EACI,cAAA;AAAR;AACA,oqBAAoqB","sourcesContent":[":host {\n    display: block;\n    width: 100%;\n    height: 100%;\n\n    .clear-all-container {\n        margin: 16px 0;\n    }\n\n    .quick-action-container {\n        margin: 16px 0;\n    }\n}\n\n// ::ng-deep mat-tab-group {\n//     max-height: 70vh;\n// }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
