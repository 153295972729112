//#region Imports

import {
    ICalculationSettingsDto,
    IContactDto,
    IContractTypeDto,
    ICurrencyDto,
    IInhouseCalculationRequestDto,
    IInhouseCalculationResponseDto,
    IInhouseQuoteCreationRequestDto,
    IInhouseQuoteDto, IInsuranceAndHandlingFeeDto,
    IInsuranceAndHandlingFeeRequestDto,
    IInsuranceTypeDto,
    ILeasingQuoteFieldToCalculateDto,
    IObjectConditionDto,
    IObjectGroupDto,
    IQuoteCalculationRequestDto,
    ISaleAndLeaseBackCodeDto,
    QuoteCreationV2Service
} from '@abcfinlab/api/global';
import { ControlsOf, EventHub, once, TranslationFacade } from '@abcfinlab/core';
import { BusyBoxService, ToastService } from '@abcfinlab/ui';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { KnownEvents } from '../../Models/KnowEvents';
import { Validators as QuoteValidators } from '../../Validators/Validators';

//#endregion

export interface IInsuranceAndSlbExtraFields {
    insurance: boolean;
    slb: boolean;
    slbCode: ISaleAndLeaseBackCodeDto;
}

export interface IObjectFields {
    name: string;
    condition: IObjectConditionDto;
}

function addProp<T extends object, K extends PropertyKey, V>(
    obj: T,
    key: K,
    value: V
): asserts obj is T & { [P in K]: V } {
    Object.assign(obj, { [key]: value });
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export type OptionsFlags<IInhouseCalculationRequestDto> = {
    [Property in keyof IInhouseCalculationRequestDto]: { [key: string]: any };
};

/**
 * The presenter of the {@link CalculationView} view.
 *
 * @internal
 */
@Injectable()
export class CalculationViewPresenter {

    //#region Fields
    private readonly _initialReadOnlyValues: IInhouseCalculationResponseDto = {
        // @ts-ignore
        insuranceAndHandlingFee: {
            totalInsuranceInstalmentsVat: 0,
            totalInsuranceInstalments: 0,
            insurancePromille: 0
        },
        refinancingInterestResponse: {
            presentValueProfit: 0,
            presentValueProfitPercentage: 0,
            refinancingInterestDefaultValue: 0,
            refinancingInterestValue: 0,
            riskDiscount: 0
        },
        calculationErrors: null,
        // @ts-ignore
        quoteCalculation: {
            leasingFactorValue: 0,
            totalInstalmentsVat: 0,
            totalInstalments: 0,
            unchangedTerms: 0
        }
    };

    private readonly _activatedRoute: ActivatedRoute;
    private readonly _eventHub: EventHub;
    private readonly _busyBoxService: BusyBoxService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _router: Router;
    private readonly _toastService: ToastService;
    private readonly _quoteCreationService: QuoteCreationV2Service;
    private readonly _quoteId: BehaviorSubject<string> = new BehaviorSubject(null);
    private readonly _objectGroups: BehaviorSubject<Array<IObjectGroupDto>> = new BehaviorSubject(null);
    private readonly _lesseeSubject: BehaviorSubject<IContactDto> = new BehaviorSubject(null);
    private readonly _calculationSettingsSubject: BehaviorSubject<ICalculationSettingsDto> = new BehaviorSubject(null);
    private readonly _quoteSubject: BehaviorSubject<IInhouseQuoteDto> = new BehaviorSubject(null);
    private readonly _slbCodesSubject: BehaviorSubject<Array<ISaleAndLeaseBackCodeDto>> = new BehaviorSubject(null);
    private readonly _smbCodesSubject: BehaviorSubject<Array<ISaleAndLeaseBackCodeDto>> = new BehaviorSubject(null);
    private readonly _selectedObjectGroupSubject: BehaviorSubject<IObjectGroupDto> = new BehaviorSubject<IObjectGroupDto>({
        exploitation_code: 0,
        amortization_period: 0,
        amortization_period_max: 0,
        amortization_period_min: 0,
        contract_types: undefined,
        exploitation_code_factor: 0,
        insurance_value: 0,
        kfz_registration_required: false,
        name: '',
        residual_values: undefined
    });
    private readonly _hasDownPayment: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    private readonly _hasFirstInstalment: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    private readonly _hasLastInstalment: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    private readonly _hasResidualValue: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    private readonly _calculationResponseSubject: BehaviorSubject<IInhouseCalculationResponseDto> = new BehaviorSubject<IInhouseCalculationResponseDto>(this._initialReadOnlyValues);
    private readonly _insuranceAndHandlingFeeFromOriginalQuote: BehaviorSubject<IInsuranceAndHandlingFeeDto> = new BehaviorSubject<IInsuranceAndHandlingFeeDto>(null);
    private _activatedRouteSubscription: Subscription;
    private _form: FormGroup<ControlsOf<IInhouseCalculationRequestDto>>;
    private readonly _insuranceAndSlbForm: FormGroup<ControlsOf<IInsuranceAndSlbExtraFields>> = new FormGroup<ControlsOf<IInsuranceAndSlbExtraFields>>({
        insurance: new FormControl<boolean>({ value: false, disabled: true }),
        slb: new FormControl<boolean>({ value: false, disabled: false }),
        slbCode: new FormControl<ISaleAndLeaseBackCodeDto>({ value: null, disabled: true })
    });

    private readonly _objectForm: FormGroup<ControlsOf<IObjectFields>> = new FormGroup<ControlsOf<IObjectFields>>({
        name: new FormControl<string>({ value: null, disabled: false }, [Validators.required, Validators.maxLength(50)]),
        condition: new FormControl<IObjectConditionDto>({ value: IObjectConditionDto.New, disabled: false })
    });

    public readonly initialFormState: OptionsFlags<IInhouseCalculationRequestDto> = {
        refinancingInterestValue: {},
        contractType: {},
        objectCondition: {},
        objectGroupCode: {},
        valueToCalculate: {},
        quoteCalculationRequest: {},
        insuranceAndHandlingFeeRequest: {}
    };
    //#endregion

    //#region Ctor

    /**
     * Returns the objectGroups property.
     *
     * @public
     * @readonly
     */
    public get objectGroups(): Observable<Array<IObjectGroupDto>> {
        return this._objectGroups.asObservable();
    }

    /**
     * Returns the selectedObjectGroup property.
     *
     * @public
     * @readonly
     */
    public get selectedObjectGroup(): Observable<IObjectGroupDto> {
        return this._selectedObjectGroupSubject.asObservable();
    }

    /**
     * Returns the _lessee property.
     *
     * @public
     * @readonly
     */
    public get lessee(): Observable<IContactDto> {
        return this._lesseeSubject.asObservable();
    }

    /**
     * Returns the calculationSettings property.
     *
     * @public
     * @readonly
     */
    public get calculationSettings(): Observable<ICalculationSettingsDto> {
        return this._calculationSettingsSubject.asObservable();
    }

    /**
     * Returns the calculationResponse property.
     *
     * @public
     * @readonly
     */
    public get calculationResponse(): Observable<IInhouseCalculationResponseDto> {
        return this._calculationResponseSubject.asObservable();
    }

    /**
     * Returns the slbCodes property.
     *
     * @public
     * @readonly
     */
    public get slbCodes(): Observable<Array<ISaleAndLeaseBackCodeDto>> {
        return this._slbCodesSubject.asObservable();
    }

    /**
     * Returns the smbCodes property.
     *
     * @public
     * @readonly
     */
    public get smbCodes(): Observable<Array<ISaleAndLeaseBackCodeDto>> {
        return this._smbCodesSubject.asObservable();
    }

    /**
     * Returns the form property.
     *
     * @public
     * @readonly
     */
    public get form(): FormGroup<ControlsOf<IInhouseCalculationRequestDto>> {
        return this._form;
    }

    /**
     * Returns the insuranceAndSlbForm property.
     *
     * @public
     * @readonly
     */
    public get insuranceAndSlbForm(): FormGroup<ControlsOf<IInsuranceAndSlbExtraFields>> {
        return this._insuranceAndSlbForm;
    }

    /**
     * Returns the objectForm property.
     *
     * @public
     * @readonly
     */
    public get objectForm(): FormGroup<ControlsOf<IObjectFields>> {
        return this._objectForm;
    }

    /**
     * Returns the quoteId property.
     *
     * @public
     * @readonly
     */
    public get quoteId(): Observable<string> {
        return this._quoteId.asObservable();
    }

    /**
     * Returns the insuranceAndHandlingFeeFromOriginalQuote property.
     *
     * @public
     * @readonly
     */
    public get insuranceAndHandlingFeeFromOriginalQuote(): Observable<IInsuranceAndHandlingFeeDto> {
        return this._insuranceAndHandlingFeeFromOriginalQuote.asObservable();
    }

    /**
     * Returns the hasDownPayment property.
     *
     * @public
     * @readonly
     */
    public get hasDownPayment(): Observable<boolean> {
        return this._hasDownPayment.asObservable();
    }

    /**
     * Returns the hasResidualValue property.
     *
     * @public
     * @readonly
     */
    public get hasResidualValue(): Observable<boolean> {
        return this._hasResidualValue.asObservable();
    }

    /**
     * Returns the hasFirstInstalment property.
     *
     * @public
     * @readonly
     */
    public get hasFirstInstalment(): Observable<boolean> {
        return this._hasFirstInstalment.asObservable();
    }

    /**
     * Returns the hasLastInstalment property.
     *
     * @public
     * @readonly
     */
    public get hasLastInstalment(): Observable<boolean> {
        return this._hasLastInstalment.asObservable();
    }

    /**
     * Constructs a new instance of the CalculationViewPresenter class.
     *
     * @public
     */
    public constructor(activatedRoute: ActivatedRoute, eventHub: EventHub, busyBoxService: BusyBoxService,
        quoteCreationService: QuoteCreationV2Service, router: Router, translationFacade: TranslationFacade,
        toastService: ToastService) {
        this._activatedRoute = activatedRoute;
        this._eventHub = eventHub;
        this._busyBoxService = busyBoxService;
        this._quoteCreationService = quoteCreationService;
        this._router = router;
        this._translationFacade = translationFacade;
        this._toastService = toastService;

        this._eventHub.getEvent<{ activeControlName: string; response: IInhouseCalculationResponseDto }>(KnownEvents.CALCULATION)
            .subscribe(x => {
                if (x.activeControlName === 'objectGroupCode' || x.activeControlName === 'contractType' || (x.activeControlName === 'totalLeasingValue' && x.response === null)) {
                    this._calculationResponseSubject.next(this._initialReadOnlyValues);
                    return;
                }
                this._calculationResponseSubject.next(x.response);
                if (!this._form.controls.refinancingInterestValue.hasValidator(Validators.min(0.001))) {
                    this._form.controls.refinancingInterestValue.addValidators(Validators.min(0.001));
                    this._form.controls.refinancingInterestValue.updateValueAndValidity({emitEvent: false});
                }
            });
    }

    //#endregion

    //#region Properties
    //#endregion

    //#region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
        this._activatedRouteSubscription = combineLatest([this._activatedRoute.data, this._activatedRoute.paramMap])
            .subscribe(([data, params]) => {
                if (params.get('quoteId')) {
                    this._quoteId.next(params.get('quoteId'));
                    this._quoteSubject.next(data?.quote);
                }
                if (data) {
                    this._objectGroups.next(data.objectGroups);
                    const lessee = params.get('quoteId') ? data.quote?.lessee : data.lessee;
                    this._lesseeSubject.next(lessee);
                    this._calculationSettingsSubject.next(data.calculationSettings);
                    this._slbCodesSubject.next(data.slbCodes);
                    this._smbCodesSubject.next(data.smbCodes);

                    this._initializeCalculationForm();

                    this._form.controls.objectGroupCode.valueChanges.subscribe(c => {
                        this._selectedObjectGroupSubject.next(this._objectGroups.getValue().find(og => og.code === c));
                    });
                }
            });
    }

    public saveQuote(isNewVersion: boolean): void {
        if (this._form.valid && this._insuranceAndSlbForm.valid && this._objectForm.valid) {
            const saleAndLeaseBack = this._insuranceAndSlbForm.controls.slb.getRawValue() ?
                { code: this._insuranceAndSlbForm.controls.slbCode.getRawValue(), active: true } : null;
            const body: IInhouseQuoteCreationRequestDto = {
                objects: [
                    {
                        name: this._objectForm.controls.name.getRawValue(),
                        condition: this._form.controls.objectCondition.getRawValue(),
                        quantity: 1,
                        currency: ICurrencyDto.Eur,
                        exclusion_of_warranty: this._form.controls.objectCondition.getRawValue() === IObjectConditionDto.Used ? true : false,
                        value: this._form.controls.quoteCalculationRequest.controls.totalLeasingValue.getRawValue(),
                        vendor: null
                    }
                ],
                inhouseCalculationRequest: this._form.getRawValue(),
                quoteName: this._objectForm.controls.name.getRawValue(),
                lessee: this._lesseeSubject.getValue(),
                // @ts-ignore only the two fields are here required
                saleAndLeaseBack
            };
            const saveQuote$ = isNewVersion ?
                this._quoteCreationService.createNewInhouseQuoteVersion({ quoteId: this._quoteId.getValue(), body }) :
                this._quoteCreationService.saveQuoteInhouse({ body });
            once(this._busyBoxService.show(undefined, undefined,
                saveQuote$
            ), res => {
                const route = '/presentation/quotes/list';
                void this._router.navigate([route], {
                    queryParams: { id: res.lesseeId }
                }).then(() => {
                    this._toastService.show(this._translationFacade.translate('calculation.calculation_saved_successful'), 'success');
                });
                return;
            });
        }
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @* @internal
     */
    public dispose(): void {
        this._activatedRouteSubscription.unsubscribe();
    }

    private _initializeCalculationForm(): void {
        const quoteCalculationSettings = this._calculationSettingsSubject.getValue().leasingQuoteCalculationProperties;
        this._form = new FormGroup<ControlsOf<IInhouseCalculationRequestDto>>({
            contractType: new FormControl<IContractTypeDto>({ value: null, disabled: true }, Validators.required),
            objectCondition: new FormControl<IObjectConditionDto>({ value: IObjectConditionDto.New, disabled: false }),
            objectGroupCode: new FormControl<number>(null, Validators.required),
            valueToCalculate: new FormControl<ILeasingQuoteFieldToCalculateDto>(ILeasingQuoteFieldToCalculateDto.Instalment, Validators.required),
            refinancingInterestValue: new FormControl<number>({ value: null, disabled: true }),
            insuranceAndHandlingFeeRequest: new FormGroup<ControlsOf<IInsuranceAndHandlingFeeRequestDto>>({
                insuranceType: new FormControl<IInsuranceTypeDto>({ value: IInsuranceTypeDto.No, disabled: true }, Validators.required),
                insuranceValue: new FormControl<number>({ value: null, disabled: true }),
                handlingFee: new FormControl<boolean>(true),
                handlingFeeValue: new FormControl<number>(200)
            }),
            quoteCalculationRequest: new FormGroup<ControlsOf<IQuoteCalculationRequestDto>>({
                downPaymentPercentage: new FormControl<number>({ value: null, disabled: true }, QuoteValidators.allowedValuesRelatedToLeasingValue(quoteCalculationSettings.downPaymentMax, 0, 'downPaymentValue')),
                downPaymentValue: new FormControl<number>({ value: null, disabled: true }, QuoteValidators.allowedValuesRelatedToLeasingValue(quoteCalculationSettings.downPaymentMax, 0, 'downPaymentValue')),
                firstInstalmentPercentage: new FormControl<number>({ value: null, disabled: true }, QuoteValidators.allowedValuesRelatedToLeasingValue(1, 0, 'firstInstalmentValue')),
                firstInstalmentValue: new FormControl<number>({ value: null, disabled: true }, QuoteValidators.allowedValuesRelatedToLeasingValue(1, 0, 'firstInstalmentValue')),
                instalment: new FormControl<number>({ value: null, disabled: true }, [Validators.required, Validators.min(0.001)]),
                lastInstalmentPercentage: new FormControl<number>({ value: null, disabled: true }, QuoteValidators.allowedValuesRelatedToLeasingValue(1, 0, 'lastInstalmentValue')),
                lastInstalmentValue: new FormControl<number>({ value: null, disabled: true }, QuoteValidators.allowedValuesRelatedToLeasingValue(1, 0, 'lastInstalmentValue')),
                residualValue: new FormControl<number>({ value: null, disabled: true }),
                residualValuePercentage: new FormControl<number>({ value: null, disabled: true }),
                totalLeasingValue: new FormControl<number>({ value: null, disabled: true }, [Validators.required, QuoteValidators.allowedRange(quoteCalculationSettings.inhouse.maxObjectValue, quoteCalculationSettings.inhouse.minObjectValue)]),
                totalTerms: new FormControl<number>({ value: null, disabled: true }, Validators.required),
                yearlyInterestPercentage: new FormControl<number>({ value: 5, disabled: true }, [Validators.required, Validators.min(0.001)]),
            })
        });

        Object.keys(this._form.controls).forEach(key => {
            const control = this._form.controls[key];
            if (control instanceof FormGroup) {
                Object.keys(control.controls).forEach(k => {
                    addProp(this.initialFormState[key], k, { value: control.controls[k].value, disabled: control.controls[k].disabled });
                });
            } else {
                this.initialFormState[key].value = control.value;
                this.initialFormState[key].disabled = control.disabled;
            }
        });

        if (this._quoteId.getValue()) {
            this.patchValuesFromOriginalQuote();
        }
    }

    private patchValuesFromOriginalQuote(): void {
        const quote = this._quoteSubject.getValue();
        this._objectForm.controls.name.patchValue(quote.objects[0].name, { emitEvent: false });
        this._objectForm.controls.condition.patchValue(quote.objects[0].condition, { emitEvent: false });
        this._form.controls.objectCondition.patchValue(quote.objects[0].condition, { emitEvent: false });
        this._form.controls.contractType.enable({ emitEvent: true });
        this._form.controls.objectGroupCode.patchValue(quote.inhouseQuoteCalculation.objectGroupCode);
        this._selectedObjectGroupSubject.next(this._objectGroups.getValue().find(og => og.code === quote.inhouseQuoteCalculation.objectGroupCode));
        setTimeout(() => {
            this._form.controls.contractType.patchValue(quote.inhouseQuoteCalculation.contractType);
            this._form.controls.quoteCalculationRequest.controls.totalTerms.enable({ emitEvent: false });
            this._form.controls.quoteCalculationRequest.controls.totalLeasingValue.enable({ emitEvent: false });
            this._form.controls.quoteCalculationRequest.controls.yearlyInterestPercentage.enable({ emitEvent: false });
            this._calculationResponseSubject.next({ ...quote.inhouseQuoteCalculation, ...{ calculationErrors: [] } } as IInhouseCalculationResponseDto);
            this._form.controls.quoteCalculationRequest.patchValue(quote.inhouseQuoteCalculation.quoteCalculation);
            this._form.controls.refinancingInterestValue.enable({ emitEvent: false });
            this._form.controls.refinancingInterestValue.patchValue(quote.inhouseQuoteCalculation.refinancingInterestResponse.refinancingInterestValue);

            this._form.controls.insuranceAndHandlingFeeRequest.controls.insuranceValue.patchValue(quote.inhouseQuoteCalculation.insuranceAndHandlingFee.insuranceValue, { emitEvent: false });
            this._form.controls.insuranceAndHandlingFeeRequest.controls.handlingFee.patchValue(quote.inhouseQuoteCalculation.insuranceAndHandlingFee.handlingFee, { emitEvent: false });
            this._form.controls.insuranceAndHandlingFeeRequest.controls.handlingFeeValue.patchValue(quote.inhouseQuoteCalculation.insuranceAndHandlingFee.handlingFeeValue, { emitEvent: false });

            switch (quote.inhouseQuoteCalculation.contractType) {
                case IContractTypeDto.Va:
                    this._hasDownPayment.next(!!quote.inhouseQuoteCalculation.quoteCalculation.downPaymentValue);
                    break;
                case IContractTypeDto.Ta:
                case IContractTypeDto.Kfz:
                    this._hasDownPayment.next(!!quote.inhouseQuoteCalculation.quoteCalculation.downPaymentValue);
                    this._hasResidualValue.next(!!quote.inhouseQuoteCalculation.quoteCalculation.residualValue);
                    break;
                case IContractTypeDto.Mietkauf:
                case IContractTypeDto.Mkn:
                    this._hasDownPayment.next(!!quote.inhouseQuoteCalculation.quoteCalculation.downPaymentValue);
                    this._hasFirstInstalment.next(!!quote.inhouseQuoteCalculation.quoteCalculation.firstInstalmentValue);
                    this._hasLastInstalment.next(!!quote.inhouseQuoteCalculation.quoteCalculation.lastInstalmentValue);
                    break;
            }
            this._insuranceAndHandlingFeeFromOriginalQuote.next(quote.inhouseQuoteCalculation.insuranceAndHandlingFee);
        }, 500);
        setTimeout(() => {
            this._insuranceAndSlbForm.controls.slb.patchValue(quote.saleAndLeaseBack?.active, { emitEvent: true });
            if (quote.saleAndLeaseBack?.active) {
                this._insuranceAndSlbForm.controls.slbCode.enable();
                this._insuranceAndSlbForm.controls.slbCode.patchValue(quote.saleAndLeaseBack?.code, { emitEvent: true });
            }
        }, 1000);

    }

    //#endregion

}
