import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ScrollService {
    public constructor() { }
    public scrollToElementById(id: string, behavior: ScrollBehavior = 'smooth'): void {
        const element = document.getElementById(id);
        this.scrollToElement(element, behavior);
    }

    public scrollToElement(element: HTMLElement, behavior: ScrollBehavior = 'smooth'): void {
        element.scrollIntoView({ behavior: behavior });
    }
}
