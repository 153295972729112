//#region Imports

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WizardComponent } from './WizardComponent';
import { WizardStepComponent } from './WizardStepComponent';

//#endregion

/**
 * @public
 */
@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        WizardStepComponent,
        WizardComponent
    ],
    declarations: [
        WizardStepComponent,
        WizardComponent
    ]
})
export class WizardModule {
}
