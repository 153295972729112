//#region Imports

import { DirectusService } from '@abcfinlab/api/global';
import { AuthService, UserService } from '@abcfinlab/auth';
import { SESSION_AVAILABLE_TOKEN, TranslationModule } from '@abcfinlab/core';
import { PresenterService } from '@abcfinlab/layout';
import { SafePipeModule, TileRegistry } from '@abcfinlab/presentation';
import { DialogHeaderModule, MotionModule, Motions, TextBlockModule } from '@abcfinlab/ui';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { Inject, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { Subject, combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';
import { TagColorPipeModule } from '../Pipes/TagColorPipe';
import { NewsManager } from '../Services/NewsManager';
import { WhatsNewView } from '../Views/WhatsNewView';
import { WhatsNewWidgetView } from '../Views/Widgets/WhatsNewWidgetView';

//#endregion

export function newsManagerFactory(dialog: MatDialog, directusService: DirectusService, authService: AuthService, userService: UserService): NewsManager {
    const newsManager = new NewsManager(dialog, directusService, authService);

    userService.userInfo.subscribe(x => {
        newsManager.startPolling();
    });

    return newsManager;
}

/**
 * The `NewsModule` module.
 *
 * @public
 */
@NgModule({
    imports: [
        CommonModule,
        MatChipsModule,
        SafePipeModule,
        TagColorPipeModule,
        FlexLayoutModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatDividerModule,
        MatListModule,
        TranslationModule,
        DialogHeaderModule,
        TextBlockModule,
        MatCardModule,
        CdkScrollableModule,
        MatProgressSpinnerModule,
        MotionModule.forRoot({
            motions: [
                Motions.fadeSlideIn
            ]
        })
    ],
    exports: [
        WhatsNewView,
    ],
    declarations: [
        WhatsNewView,
        WhatsNewWidgetView
    ],
    providers: [
        { provide: NewsManager, useFactory: newsManagerFactory, deps: [MatDialog, DirectusService, AuthService, UserService] }
    ]
})
export class NewsModule {

    //#region Ctor

    /**
     * Constructs a new instance of the `NewsModule` class.
     *
     * @public
     */
    public constructor(presenterService: PresenterService, newsManager: NewsManager, tileRegistry: TileRegistry,
        @Inject(SESSION_AVAILABLE_TOKEN) sessionAvailable: Subject<void>) {
        combineLatest([sessionAvailable])
            .pipe(first())
            .subscribe(() => {
                tileRegistry.register({
                    key: 'NEWS',
                    order: 20,
                    columnSpan: { min: 2, max: 2 },
                    rowSpan: { min: 1, max: 1 },
                    views: {
                        front: WhatsNewWidgetView
                    }
                });

                presenterService.setAction({
                    kind: 'button',
                    order: 100,
                    label: 'Benachrichtigungen',
                    icon: 'Bell',
                    badge: {
                        text: newsManager.unreadCount
                    },
                    action: () => newsManager.show('news')
                });
            });
    }

    //#endregion

}
