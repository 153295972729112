import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GetSlbCodes } from '../../../actions/Slb.actions';
import { ObjectGroupDTO } from '../../../models/ObjectGroupDTO.interface';
import { ObjectgroupService } from '../../services/objectgroup/objectgroup.service';

/**
 * @deprecated - Please use getObjectGroupsResolver instead.
 */
@Injectable({
  providedIn: 'root'
})
export class ObjectGroupsResolver implements Resolve<Array<ObjectGroupDTO>> {

  constructor(
    private readonly _objectGroupService: ObjectgroupService,
    private readonly _store: Store
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<ObjectGroupDTO>> {
    this._store.dispatch(new GetSlbCodes());
    return this._objectGroupService.getObjectGroups().pipe(
      catchError(throwError as any)
    );
  }

}
