/**
 * @internal
 */
export const QUOTE_LIST_ROUTE_PATH: string = 'quotes/list';

/**
 * @internal
 */
export const QUOTE_DETAIL_ROUTE_PATH: string = 'quotes/detail';

/**
 * @internal
 */
export const QUOTE_CALCULATION_ROUTE_PATH: string = 'quote/calculation';

/**
 * @internal
 */
export const QUOTE_CALCULATION_PLAYGROUND_ROUTE_PATH: string = 'quote/calculation/playground';

/**
 * @internal
 */
export const QUOTE_SEARCH_LESSEE_ROUTE_PATH: string = 'quote/create/search-lessee';

/**
 * @internal
 */
export const QUOTE_RETAILER_CREATE_QUOTE_ROUTE_PATH: string = 'quote/retailer/create-quote';

/**
 * @internal
 */
export const QUOTE_RETAILER_EDIT_QUOTE_ROUTE_PATH: string = 'quote/retailer/edit-quote/:id';

/**
 * @internal
 */
export const QUOTE_RETAILER_CREATE_QUOTE_NEW_VERSION_ROUTE_PATH: string = 'quote/retailer/create-quote/new-version';

/**
 * @internal
 */
export const QUOTE_RETAILER_QUOTE_DETAILS_ROUTE_PATH: string = 'quote/retailer/quote-details';

/**
 * @internal
 */
export const QUOTE_RETAILER_LESSEES_ROUTE_PATH: string = 'quote/retailer/lessees';

/**
 * @internal
 */
export const RETAILER_QUOTES_LIST_ROUTE_PATH: string = 'quote/retailer/quotes/list';

/**
 * @internal
 */
export const RETAILER_LESSEE_UPDATE_ROUTE_PATH: string = 'quote/retailer/lessee/update/:lesseeId/:quoteId';
