// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex-flow: row wrap;
  box-sizing: border-box;
  display: flex;
  place-content: stretch flex-start;
  align-items: stretch;
}
:host.horizontal {
  flex-flow: row wrap;
}
:host.vertical {
  flex-flow: column wrap;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIldyYXBDb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLGFBQUE7RUFDQSxXQUFBO0VBQ0EsWUFBQTtFQUNBLGdCQUFBO0VBRUEsbUJBQUE7RUFDQSxzQkFBQTtFQUNBLGFBQUE7RUFDQSxpQ0FBQTtFQUNBLG9CQUFBO0FBQUo7QUFFSTtFQUNJLG1CQUFBO0FBQVI7QUFHSTtFQUNJLHNCQUFBO0FBRFIiLCJmaWxlIjoiV3JhcENvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgd2lkdGg6IDEwMCU7XG4gICAgaGVpZ2h0OiAxMDAlO1xuICAgIG92ZXJmbG93OiBoaWRkZW47XG5cbiAgICBmbGV4LWZsb3c6IHJvdyB3cmFwO1xuICAgIGJveC1zaXppbmc6IGJvcmRlci1ib3g7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBwbGFjZS1jb250ZW50OiBzdHJldGNoIGZsZXgtc3RhcnQ7XG4gICAgYWxpZ24taXRlbXM6IHN0cmV0Y2g7XG5cbiAgICAmLmhvcml6b250YWwge1xuICAgICAgICBmbGV4LWZsb3c6IHJvdyB3cmFwO1xuICAgIH1cblxuICAgICYudmVydGljYWwge1xuICAgICAgICBmbGV4LWZsb3c6IGNvbHVtbiB3cmFwO1xuICAgIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/ui/src/Controls/Components/Wrap/WrapComponent.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EAEA,mBAAA;EACA,sBAAA;EACA,aAAA;EACA,iCAAA;EACA,oBAAA;AAAJ;AAEI;EACI,mBAAA;AAAR;AAGI;EACI,sBAAA;AADR;AACA,o3BAAo3B","sourcesContent":[":host {\n    display: flex;\n    width: 100%;\n    height: 100%;\n    overflow: hidden;\n\n    flex-flow: row wrap;\n    box-sizing: border-box;\n    display: flex;\n    place-content: stretch flex-start;\n    align-items: stretch;\n\n    &.horizontal {\n        flex-flow: row wrap;\n    }\n\n    &.vertical {\n        flex-flow: column wrap;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
