import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CamSearchResultDTO } from '../../../../models/CamSearchResultDTO.interface';
import { LeasingQuoteDTO } from '../../../../models/LeasingQuoteDTO.interface';
import { LesseeListComponent } from '../../../components/lessee-list/lessee-list.component';
import { CalculationService } from '../../../services/calculation/calculation.service';
import { LesseeService } from '../../../services/lessee/lessee.service';
import { IContactDto } from '@abcfinlab/api/contact';

@UntilDestroy()
@Component({
  selector: 'l7-cam-search-result',
  templateUrl: './cam-search-result.component.html',
  styleUrls: ['./cam-search-result.component.scss']
})
export class CamSearchResultComponent implements AfterViewInit {

  public readonly headline: string;
  public readonly subheadline: string;
  public disableButtons: boolean = false;
  public routeData: { searchResult: CamSearchResultDTO };
  @ViewChild(LesseeListComponent, { static: true }) lesseeList: LesseeListComponent;

  constructor(
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _lesseeService: LesseeService,
    private readonly _calculationService: CalculationService
  ) {
    this.headline = 'Leasingnehmersuche';
    this.subheadline = 'Bitte wählen Sie die entsprechende Firma aus.';

    // get resolved data
    this._route.data
      .pipe(untilDestroyed(this))
      .subscribe((resolvedData: { searchResult: CamSearchResultDTO }) => {
        this.routeData = resolvedData;
      });
  }

  ngAfterViewInit(): void {
    if(this.lesseeList.isLoading$){
        this.lesseeList.isLoading$
            .pipe(untilDestroyed(this))
            .subscribe(value => {
                this.disableButtons = value;
            });
    }
  }

  get lessees(): Array<IContactDto> {
    return this.routeData.searchResult.search_results;
  }

  get lessees_present(): boolean {
    return this.routeData.searchResult.search_results.length > 0;
  }

  /**
   * @param quote     The QuoteDTO object
   */
  public onCalculate(quote: LeasingQuoteDTO): Promise<boolean> {
    this._calculationService.setLeasingQuote(quote);
    return this._router.navigateByUrl('private/create-quote/calculation/');
  }

}
