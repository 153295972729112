//#region Imports

import { CommonModule } from '@angular/common';
import { NgModule, type ModuleWithProviders } from '@angular/core';
import { type IMotionModuleConfig } from './IMotionModuleConfig';
import { MotionCache } from './MotionCache';
import { MotionDirective } from './MotionDirective';
import { MotionManager } from './MotionManager';
import { MOTION_MODULE_CONFIG } from './MotionModuleTokens';

//#endregion

/**
 * @internal
 * @factory
 */
export function motionModuleConfigFactory(): IMotionModuleConfig {
    return {
    };
}

/**
 * @internal
 * @factory
 */
export function motionCacheFactory(config: IMotionModuleConfig): MotionCache {
    const cache = new MotionCache();
    config.motions?.forEach(x => cache.set(x.name, x.preset));

    return cache;
}

/**
 * The `MotionModule` module.
 *
 * @public
 */
@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        MotionDirective
    ],
    exports: [
        MotionDirective
    ],
    providers: [
        { provide: MOTION_MODULE_CONFIG, useFactory: motionModuleConfigFactory },
        { provide: MotionCache, useFactory: motionCacheFactory, deps: [MOTION_MODULE_CONFIG] },
        MotionManager,

    ]
})
export class MotionModule {

    //#region Methods

    public static forRoot(config: IMotionModuleConfig): ModuleWithProviders<MotionModule> {
        return {
            ngModule: MotionModule,
            providers: [
                { provide: MOTION_MODULE_CONFIG, useValue: config },
            ]
        };
    }

    //#endregion

}
