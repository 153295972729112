//#region Imports

import { HttpErrorResponse } from '@angular/common/http';

//#endregion

export interface IErrorInfo {
    error: string;
    errorDescription: string;
    errorParams?: Record<string, string>;
    message?: string;
}

/**
 * The `HttpErrorExtractor` class.
 *
 * @public
 */
export class HttpErrorResponseExtractor {

    //#region Methods

    public static parse(respose: HttpErrorResponse): IErrorInfo {
        if (respose instanceof HttpErrorResponse) {
            // console.log(`error status : ${self.status} ${self.statusText}`);
            const error = JSON.parse(respose.error);
            return {
                error: error.error,
                errorDescription: error.error_description,
                errorParams: error?.error_params,
                message: error?.mnessage
            };
        }

        return {
            error: '',
            errorDescription: '',
            errorParams: undefined,
            message: undefined
        };
    }

    //#endregion

}
