// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wizard {
  display: flex;
  flex-direction: column;
}
.wizard .wizard-step {
  display: none;
}
.wizard .wizard-step.active {
  display: block;
}
.wizard .wizard-step .wizard-step-label {
  font-weight: bold;
}
.wizard .wizard-step .wizard-step-content {
  margin-top: 1em;
}
.wizard .wizard-navigation {
  margin-top: 2em;
  display: flex;
  justify-content: space-between;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIldpemFyZENvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksYUFBQTtFQUNBLHNCQUFBO0FBQ0o7QUFDSTtFQUNJLGFBQUE7QUFDUjtBQUFRO0VBQ0ksY0FBQTtBQUVaO0FBQVE7RUFDSSxpQkFBQTtBQUVaO0FBQVE7RUFDSSxlQUFBO0FBRVo7QUFFSTtFQUNJLGVBQUE7RUFDQSxhQUFBO0VBQ0EsOEJBQUE7QUFBUiIsImZpbGUiOiJXaXphcmRDb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi53aXphcmQge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcblxuICAgIC53aXphcmQtc3RlcCB7XG4gICAgICAgIGRpc3BsYXk6IG5vbmU7XG4gICAgICAgICYuYWN0aXZlIHtcbiAgICAgICAgICAgIGRpc3BsYXk6IGJsb2NrO1xuICAgICAgICB9XG4gICAgICAgIC53aXphcmQtc3RlcC1sYWJlbCB7XG4gICAgICAgICAgICBmb250LXdlaWdodDogYm9sZDtcbiAgICAgICAgfVxuICAgICAgICAud2l6YXJkLXN0ZXAtY29udGVudCB7XG4gICAgICAgICAgICBtYXJnaW4tdG9wOiAxZW07XG4gICAgICAgIH1cbiAgICB9XG5cbiAgICAud2l6YXJkLW5hdmlnYXRpb24ge1xuICAgICAgICBtYXJnaW4tdG9wOiAyZW07XG4gICAgICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgICAgIGp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjtcbiAgICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/ui/src/Controls/Components/Wizard/WizardComponent.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;AACJ;AACI;EACI,aAAA;AACR;AAAQ;EACI,cAAA;AAEZ;AAAQ;EACI,iBAAA;AAEZ;AAAQ;EACI,eAAA;AAEZ;AAEI;EACI,eAAA;EACA,aAAA;EACA,8BAAA;AAAR;AACA,4/BAA4/B","sourcesContent":[".wizard {\n    display: flex;\n    flex-direction: column;\n\n    .wizard-step {\n        display: none;\n        &.active {\n            display: block;\n        }\n        .wizard-step-label {\n            font-weight: bold;\n        }\n        .wizard-step-content {\n            margin-top: 1em;\n        }\n    }\n\n    .wizard-navigation {\n        margin-top: 2em;\n        display: flex;\n        justify-content: space-between;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
