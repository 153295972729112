import { Component, Input } from '@angular/core';

@Component({
  selector: 'l7-headline',
  templateUrl: './headline.component.html',
  styleUrls: ['./headline.component.scss']
})
export class HeadlineComponent {

  @Input() prefix: string;
  @Input() headline: string;
  @Input() additional: string;
  @Input() subheadline: string;
  @Input() navisionNumber: string;

  constructor() {
  }
}
