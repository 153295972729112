//#region Imports

import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '../Tokens/WindowToken';

//#endregion

/**
 * The `ProvokeErrorService` class.
 *
 * @public
 */
@Injectable({ providedIn: 'root' })
export class ProvokeErrorService {

    //#region Fields

    private _isEnabled: boolean;
    private _args: unknown;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `ProvokeErrorService` class.
     *
     * @public
     */
    public constructor(@Inject(WINDOW) window: any) {
        this._isEnabled = false;

        Object.assign(window, {
            provokeError: (args: unknown) => {
                this._args = args ?? null;
                this._isEnabled = !this._isEnabled;
                if (this._isEnabled) {
                    console.profile('provoke');
                    console.warn('provoking errors is enabled.');
                } else {
                    console.profileEnd('provoke');
                    console.warn('provoking errors is disabled.');
                }

                return this._isEnabled;
            }
        });
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `isEnabled` property.
     *
     * @public
     * @readonly
     */
    public get isEnabled(): boolean {
        return this._isEnabled;
    }

    /**
     * Returns the `args` property.
     *
     * @public
     * @readonly
     */
    public get args(): unknown {
        return this._args;
    }

    //#endregion

}
