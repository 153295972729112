//#region Imports

import {
    IContractDocumentDto
} from '@abcfinlab/api/contract';
import { ICalculationSettingsDto, SettingsService } from '@abcfinlab/api/global';
import { Validators as CoreValidators, EventHub, subscribeOnce, TranslationFacade } from '@abcfinlab/core';
import { formValuesAreEqual } from '@abcfinlab/presentation';
import {
    percentageValidator,
    ToastService
} from '@abcfinlab/ui';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy, OnInit,
    Output,
    SimpleChanges,
    TrackByFunction
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { KnownEvents } from 'libs/contractManagement/src/Models/KnownEvents';
import {
    ContractOverviewAcknowledgementViewPresenter
} from 'libs/contractManagement/src/Views/ContractOverviewAcknowledgementViewPresenter';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { UploadDocumentType } from '../../../../apps/shell/src/app/models/enums/UploadDocumentType.enum';
import { IContractManagementOverviewInfo } from '../Models/IContractManagementOverviewInfo';

//#endregion

/**
 * @public
 */
@UntilDestroy()
@Component({
    selector: 'l7-contract-overview-takeover-confirmation-view',
    templateUrl: './ContractOverviewAcknowledgementView.html',
    styleUrls: ['./ContractOverviewAcknowledgementView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ContractOverviewAcknowledgementViewPresenter]
})
export class ContractOverviewAcknowledgementView implements OnInit, OnChanges, OnDestroy {

    //#region Fields

    calenderIcon = 'Calendar_Dates';
    public form: UntypedFormGroup;

    private readonly _toastService: ToastService;

    private readonly _presenter: ContractOverviewAcknowledgementViewPresenter;
    private readonly _fb: UntypedFormBuilder;

    private readonly _translationFacade: TranslationFacade;
    private readonly _eventHub: EventHub;
    private readonly _yearTrackByIndex: TrackByFunction<number>;
    private readonly _settingsService: SettingsService;
    private readonly _calculationSettings: BehaviorSubject<ICalculationSettingsDto> = new BehaviorSubject<ICalculationSettingsDto>(null);

    private _objectConditionDisplayName: string | null;
    private _overview: IContractManagementOverviewInfo | null;
    private _ready: boolean;
    private _link: string;

    private readonly _years: BehaviorSubject<Array<number>>;
    private _documents: Array<IContractDocumentDto>;

    private readonly _hasChanges: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _labelSerialNumber: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `ContractOverviewAcknowledgementView` class.
     *
     * @public
     */
    public constructor(toastService: ToastService, translationFacade: TranslationFacade,
        presenter: ContractOverviewAcknowledgementViewPresenter,
        eventHub: EventHub, fb: UntypedFormBuilder, settingsService: SettingsService) {
        this._toastService = toastService;
        this._settingsService = settingsService;
        this._presenter = presenter;
        this._fb = fb;
        this.form = this._fb.group({
            name: [{ value: null, disabled: true }, [Validators.required]],
            serialNumber: [{ value: null, disabled: false }, []],
            isChassisNumber: [{ value: false, disabled: false }],
            acknowledgementDate: [{ value: null, disabled: false }, []],
            condition: [{ value: null, disabled: true }, [Validators.required]],
            yearOfConstruction: [{ value: null, disabled: false }, []],
            purchasePrice: [{ value: null, disabled: false }, []],
            firstInstalment: [{ value: null, disabled: true }, []],
            instalment: [{ value: null, disabled: true }, []],
            lastInstalment: [{ value: null, disabled: true }, []],
            downPayment: [{ value: null, disabled: true }, []],
            residualValue: [{ value: null, disabled: true }, []],
            insuranceValue: [{ value: null, disabled: true }, []]
        });

        this._translationFacade = translationFacade;
        this._eventHub = eventHub;

        this._ready = false;
        this._overview = null;
        this._documents = new Array<IContractDocumentDto>();

        this._objectConditionDisplayName = null;

        // year range
        this._yearTrackByIndex = (index: number): number => index;
        const result = new Array<number>();
        const currentYear = new Date(Date.now()).getFullYear();
        let offset = (currentYear - 1900);
        while (offset--) {
            result.push(currentYear - offset);
        }
        this._years = new BehaviorSubject(result.reverse());
    }

    //#endregion

    /**
     * Gets or sets the `overview` property.
     *
     * @public
     * @readonly
     */
    @Input()
    public get overview(): IContractManagementOverviewInfo {
        return this._overview;
    }
    public set overview(value: IContractManagementOverviewInfo) {
        this._overview = value;
    }

    /**
     * Gets or sets the `ready` property.
     *
     * @public
     */
    @Input()
    public get ready(): boolean {
        return this._ready;
    }
    public set ready(value: boolean) {
        this._ready = value;
    }

    /**
     * Gets or sets the `documents` property.
     *
     * @public
     */
    @Input()
    public get documents(): Array<IContractDocumentDto> {
        return this._documents;
    }
    public set documents(value: Array<IContractDocumentDto>) {
        this._documents = value;
    }

    /**
     * Returns the `objectConditionDisplayName` property.
     *
     * @public
     * @readonly
     */
    public get objectConditionDisplayName(): string | null {
        return this._objectConditionDisplayName;
    }
    public set objectConditionDisplayName(value: string | null) {
        this._objectConditionDisplayName = value;
    }

    /**
     * Returns the `hasChanges` property.
     *
     * @public
     * @readonly
     */
    public get hasChanges(): Observable<boolean> {
        return this._hasChanges.asObservable();
    }

    /**
     * @public
     * @readonly
     */
    public get years(): Array<number> {
        return this._years.value;
    }

    /**
     * Returns the _yearTrackByIndex` property.
     *
     * @public
     * @readonly
     */
    public get yearTrackByIndex(): TrackByFunction<number> {
        return this._yearTrackByIndex;
    }

    /**
     * Returns the link property.
     *
     * @public
     * @readonly
     */
    public get link(): string {
        return this._link;
    }

    /**
     * Returns the labelSerialNumber property.
     *
     * @public
     * @readonly
     */
    public get labelSerialNumber(): Observable<string> {
        return this._labelSerialNumber.asObservable();
    }

    @Output()
    public readonly hasAcknowledgement: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();

    @Output()
    public readonly uploadedPaperSigning: EventEmitter<Array<IContractDocumentDto>> = new EventEmitter<Array<IContractDocumentDto>>();

    //#region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        subscribeOnce(this._settingsService.getCalculationSettingsDto().pipe(
            catchError(err => of([])),
        ), ((calculationSettings: ICalculationSettingsDto) => {
            this._calculationSettings.next(calculationSettings);
        }));
        this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(x => {
            if (this.presenter.originalValues) {
                const areTheyEqual = formValuesAreEqual(x, {
                    acknowledgementDate: this.presenter.originalValues.acknowledgement.acknowledgement_date,
                    purchasePrice: this.presenter.originalValues.acknowledgement.purchase_price ? this.presenter.originalValues.acknowledgement.purchase_price : this.presenter.originalValues.details.object.value,
                    serialNumber: this.presenter.originalValues.acknowledgement.serial_number,
                    isChassisNumber: this.presenter.originalValues.acknowledgement.chassis_number,
                    yearOfConstruction: this.presenter.originalValues.acknowledgement.year_of_construction ? this.presenter.originalValues.acknowledgement.year_of_construction : this.years[0]
                });

                this._hasChanges.next(!areTheyEqual);
            }
        });

        this._eventHub.getEvent<IContractDocumentDto>(KnownEvents.DOCUMENT_DELETED)
            .pipe(
                filter(x => x.type === UploadDocumentType.ACKNOWLEDGEMENT_SIGNED || x.type === UploadDocumentType.ACKNOWLEDGEMENT_SIGNED_PAPER),
                untilDestroyed(this)
            )
            .subscribe(() => {
                this._ready = false;
                this.form?.reset();
                this._presenter.initialize(this._overview);
            });

        this.presenter.data.pipe(untilDestroyed(this))
            .subscribe(_data => {
                if (_data) {
                    const acknowledgementSettings = this._calculationSettings.value.leasingQuoteCalculationProperties.broker.acknowledgement;
                    this.form.controls.name.patchValue(_data.details.object.name);
                    if (_data.acknowledgement.chassis_number) {
                        this._labelSerialNumber.next(this._translationFacade.instant('contract_management.takeover_confirmation.object.chassis_number'));
                        this.form.controls.isChassisNumber.patchValue(_data.acknowledgement.chassis_number);
                        this.form.controls.serialNumber.setValidators([CoreValidators.serialNumber(), Validators.minLength(17), Validators.maxLength(17)]);
                        this.form.controls.serialNumber.updateValueAndValidity();
                    } else {
                        this._labelSerialNumber.next(this._translationFacade.instant('contract_management.takeover_confirmation.object.serial_number'));
                    }
                    this.form.controls.serialNumber.patchValue(_data.acknowledgement.serial_number);
                    this.form.controls.acknowledgementDate.patchValue(_data.acknowledgement.acknowledgement_date);

                    this._translationFacade.translate(`condition.${_data.details.object.condition}`).pipe(untilDestroyed(this)).subscribe((x: string) => {
                        this.form.controls.condition.patchValue(x);
                    });
                    let _yearOfConstruction = _data.acknowledgement.year_of_construction;
                    // id the year_of_construction is null, prefill it with the current year.
                    if (!_data.acknowledgement.year_of_construction && _data.details.object.condition === 'NEW') {
                        _yearOfConstruction = this._years.value[0];
                    }
                    this.form.controls.yearOfConstruction.patchValue(_yearOfConstruction);
                    if (_data.details.object.condition === 'USED') {
                        this.form.controls.yearOfConstruction.setValidators([Validators.required]);
                    }
                    this.form.controls.yearOfConstruction.updateValueAndValidity();
                    this.form.controls.purchasePrice.setValidators([
                        Validators.required,
                        Validators.min(acknowledgementSettings.minObjectValueChange),
                        Validators.max(acknowledgementSettings.maxObjectValueChange),
                        percentageValidator(acknowledgementSettings.maxObjectValueChangePercent, _data.details.object.value, 2)
                    ]);

                    this.form.controls.purchasePrice.patchValue(_data.acknowledgement.purchase_price);
                    this.form.controls.purchasePrice.updateValueAndValidity();
                    this.form.controls.firstInstalment.patchValue(_data.acknowledgement.first_instalment);
                    this.form.controls.instalment.patchValue(_data.acknowledgement.instalment);
                    this.form.controls.lastInstalment.patchValue(_data.acknowledgement.last_instalment);
                    this.form.controls.downPayment.patchValue(_data.acknowledgement.down_payment);
                    this.form.controls.residualValue.patchValue(_data.acknowledgement.residual_value);
                    this.form.controls.insuranceValue.patchValue(_data.expenses.insurance_value);

                    this._link = _data.link.link;

                    if (this.ready) {
                        this.disableFormControls();
                        this._hasChanges.next(false);
                    } else {
                        this.enableFormControls();
                    }
                }
            });
        this.presenter.hasChanges.pipe(untilDestroyed(this))
            .subscribe(x => this._hasChanges.next(x));
        this.presenter.hasAcknowledgement.pipe(untilDestroyed(this))
            .subscribe(x => {
                if (this._overview?.contract_number) {
                    this.hasAcknowledgement.emit([this._overview?.contract_number]);
                }
            });
        this.presenter.uploadedPaperSigning.pipe(untilDestroyed(this))
            .subscribe(x => this.uploadedPaperSigning.emit([x]));

        this.presenter.ready.pipe(untilDestroyed(this))
            .subscribe(x => {
                if (x) {
                    this.disableFormControls();
                    this._hasChanges.next(false);
                }
            });

        this.presenter.calculationValues.pipe(untilDestroyed(this))
            .subscribe(x => {
                if (x) {
                    this.form.controls.firstInstalment.patchValue(x.first_instalment);
                    this.form.controls.instalment.patchValue(x.instalment);
                    this.form.controls.lastInstalment.patchValue(x.last_instalment);
                    this.form.controls.downPayment.patchValue(x.down_payment);
                    this.form.controls.residualValue.patchValue(x.residual_value);
                    this.form.controls.insuranceValue.patchValue(x.insurance_value);
                }
            });
    }

    public changeSerialNumberLabel(evt: MatRadioChange) {
        const label = evt.value ? 'contract_management.takeover_confirmation.object.chassis_number' : 'contract_management.takeover_confirmation.object.serial_number';
        this._labelSerialNumber.next(this._translationFacade.instant(label));
        if (evt.value) {
            this.form.controls.serialNumber.setValidators([CoreValidators.serialNumber(), Validators.minLength(17), Validators.maxLength(17)]);
        } else {
            this.form.controls.serialNumber.setValidators([]);
        }
        this.form.controls.serialNumber.updateValueAndValidity();
    }

    /**
     * @internal
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.overview?.currentValue) {
            this.presenter.initialize(changes.overview.currentValue);
        }
    }

    /**
     * @internal
     */
    public onLinkCopied(): void {
        this._toastService.show(this._translationFacade.translate('contract_management.takeover_confirmation.signing_link.copied'), 'success');
    }

    /**
     * Returns the presenter of the `ContractOverviewAcknowledgementViewPresenter`
     *
     * @public
     * @readonly
     */
    public get presenter(): ContractOverviewAcknowledgementViewPresenter {
        return this._presenter;
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    private disableFormControls(): void {
        this.form.controls.serialNumber.disable();
        this.form.controls.acknowledgementDate.disable();
        this.form.controls.yearOfConstruction.disable();
        this.form.controls.purchasePrice.disable();
        this.form.controls.isChassisNumber.disable();
    }

    private enableFormControls(): void {
        this.form.controls.serialNumber.enable();
        this.form.controls.acknowledgementDate.enable();
        this.form.controls.yearOfConstruction.enable();
        this.form.controls.purchasePrice.enable();
        this.form.controls.isChassisNumber.enable();
    }
    //#endregion
}
