//#region Imports

import { ISerialNumberRequestDto, IWorkflowStepDto, RetailerContractWorkflowService } from '@abcfinlab/api/contract';
import { ControlsOf, FormValidator, once, TranslationFacade, Validators } from '@abcfinlab/core';
import { BusyBoxService, MessageBoxButton, MessageBoxResult, MessageBoxService, ToastService } from '@abcfinlab/ui';
import { Inject, Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators as NgValidators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { UploadDeliveryDocumentDialogView } from './UploadDeliveryDocumentDialogView';

//#endregion

/**
 * @private
 */
type DeleveryAndSerialNumberChoice = 'includeInDelivery' | 'manual' | 'extraDocument';

/**
 * @private
 */
interface IUploadDeliveryDocumentDialogViewPresenterFormData {
    general: FormGroup<{
        acknowledgementDate: FormControl<string>;
        purchasePriceConfirmed: FormControl<boolean>;
        deliveryConfirmed: FormControl<boolean>;
    }>;
    files: FormGroup<{
        serialNumbers: FormArray;
        deliveryNote: FormControl<File>;
        serialNumber: FormControl<File>;
    }>;
    choice: FormControl<DeleveryAndSerialNumberChoice>;
}

/**
 * @private
 */
interface IUploadDeliveryDocumentDialogViewPresenterData {
    step: IWorkflowStepDto;
}

/**
 * The presenter of the {@link UploadDeliveryDocumentDialogView} view.
 *
 * @internal
 */
@Injectable()
export class UploadDeliveryDocumentDialogViewPresenter {

    //#region Fields

    private readonly _dialogRef: MatDialogRef<UploadDeliveryDocumentDialogView>;
    private readonly _dialogData: IUploadDeliveryDocumentDialogViewPresenterData;
    private readonly _retailerContractWorkflowService: RetailerContractWorkflowService;
    private readonly _messageBoxService: MessageBoxService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _formValidator: FormValidator;
    private readonly _toastService: ToastService;
    private readonly _busyBoxService: BusyBoxService;
    private readonly _form: FormGroup<IUploadDeliveryDocumentDialogViewPresenterFormData>;
    private readonly _objectValueSubject: BehaviorSubject<number>;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `UploadDeliveryDocumentDialogViewPresenter` class.
     *
     * @public
     */
    public constructor(dialogRef: MatDialogRef<UploadDeliveryDocumentDialogView>, retailerContractWorkflowService: RetailerContractWorkflowService,
        messageBoxService: MessageBoxService, translationFacade: TranslationFacade, formValidator: FormValidator, toastService: ToastService, busyBoxService: BusyBoxService,
        @Inject(MAT_DIALOG_DATA) dialogData: IUploadDeliveryDocumentDialogViewPresenterData) {
        this._dialogRef = dialogRef;
        this._dialogData = dialogData;
        this._retailerContractWorkflowService = retailerContractWorkflowService;
        this._messageBoxService = messageBoxService;
        this._translationFacade = translationFacade;
        this._formValidator = formValidator;
        this._toastService = toastService;
        this._busyBoxService = busyBoxService;
        this._objectValueSubject = new BehaviorSubject(this._dialogData.step.metaInformation.objectValue as number);
        this._form = new FormGroup<IUploadDeliveryDocumentDialogViewPresenterFormData>({
            general: new FormGroup({
                acknowledgementDate: new FormControl('', [NgValidators.required, Validators.minDate(this._dialogData.step.metaInformation.approvalDate as string)]),
                purchasePriceConfirmed: new FormControl<boolean>(false, [NgValidators.requiredTrue]),
                deliveryConfirmed: new FormControl<boolean>(false, [NgValidators.requiredTrue])
            }),
            files: new FormGroup({
                serialNumbers: new FormArray([]),
                deliveryNote: new FormControl(null, [NgValidators.required]),
                serialNumber: new FormControl(null),
            }),
            choice: new FormControl<DeleveryAndSerialNumberChoice>('manual', [NgValidators.required])
        });
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `form` property.
     *
     * @public
     * @readonly
     */
    public get form(): FormGroup<IUploadDeliveryDocumentDialogViewPresenterFormData> {
        return this._form;
    }

    /**
     * Returns the `serialNumbers` property.
     *
     * @public
     * @readonly
     */
    public get serialNumbers(): FormArray<FormGroup<ControlsOf<ISerialNumberRequestDto>>> {
        return this._form.controls.files.controls.serialNumbers;
    }

    /**
     * Returns the `serialNumbers` property.
     *
     * @public
     * @readonly
     */
    public get choice(): FormControl<DeleveryAndSerialNumberChoice> {
        return this._form.controls.choice;
    }

    /**
     * Returns the `objectValue` property.
     *
     * @public
     * @readonly
     */
    public get objectValue(): Observable<number> {
        return this._objectValueSubject.asObservable();
    }

    /**
     * @public
     * @readonly
     */
    public get years(): Array<number> {
        const result = new Array<number>();
        const currentYear = new Date(Date.now()).getFullYear();
        let offset = (currentYear - 1900);
        while (offset--) {
            result.push(currentYear - offset);
        }
        return result.reverse();
    }

    //#endregion

    //#region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
        this.checkSerialNumberType('manual');
        this._form.controls.choice.valueChanges.subscribe(x => {
            this.checkSerialNumberType(x);
        });
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
    }

    /**
    * @internal
    */
    public onSubmit(cancel: boolean): void {
        if (cancel) {
            this._messageBoxService.show('Vorgang abbrechen', 'Sind Sie sicher, dass Sie den Vorgang abbrechen wollen? Ihre Angaben werden nicht gespeichert.', MessageBoxButton.YesNo, {
                labels: {
                    yes: this._translationFacade.instant('global.yes'),
                    no: this._translationFacade.instant('global.no')
                }
            }).subscribe(result => {
                if (result === MessageBoxResult.Yes) {
                    this._dialogRef.close(false);
                }
            });

            return;
        }
        const errors = this._formValidator
            .validate(this._form)
            .errors(this._form);

        if (errors.length > 0) {
            this._toastService.show('Bitte überprüfen Sie ihre Angaben.', 'danger');
            return;
        }

        const form = this._form.getRawValue();

        let body = {
            deliveryNoteFile: form.files.deliveryNote,
            retailerAcknowledgementRequest: {
                acknowledgementDate: form.general.acknowledgementDate,
                serialNumbers: form.files.serialNumbers
            }
        };

        if (form.files.serialNumber) {
            body = {
                ...body,
                ...{ serialNumberDocument: form.files.serialNumber }
            };
        }

        once(this._busyBoxService.show(undefined, this._translationFacade.instant('global.busy'),
            this._retailerContractWorkflowService.doAcknowledgementDeliveryNote({
                stepId: this._dialogData.step.id,
                body: body
            })
        ), () => {
            this._toastService.show('Angaben erfolgreich gespeichert.', 'success');
            this.onClose(false);
        }, error => {
            this._toastService.show('Beim speichern ist ein Fehler aufgetreten.', 'danger');
            this.onClose(false);
            throwError(error);
        });
    }

    /**
     * @internal
     */
    public onAddSerialNumber(): void {
        const position = this.serialNumbers.length + 1;
        const objectDescription = this._dialogData.step.metaInformation.objectDescription as string ?? '';

        const fg = new FormGroup({
            position: new FormControl(position, [NgValidators.required]),
            objectDescription: new FormControl(objectDescription, [NgValidators.required]),
            yearOfManufacture: new FormControl(null, [NgValidators.required]),
            serialNumber: new FormControl(null, [NgValidators.required, NgValidators.maxLength(30)]),
        });

        this.serialNumbers.push(fg);
    }

    /**
     * @internal
     */
    public onDeleteSerialNumber(sn: any, index: number): void {
        this.serialNumbers.removeAt(index);
    }

    /**
     * @internal
     */
    public onClearSerialNumber(): void {
        while (this.serialNumbers.length !== 0) {
            this.serialNumbers.removeAt(0);
        }
    }

    /**
     * @internal
     */
    public onFilesChanged(kind: 'deliveryNote' | 'serialNumber', files: Array<File>): void {
        if (kind === 'deliveryNote') {
            this._form.controls.files.controls.deliveryNote.patchValue(files[0]);
        }

        if (kind === 'serialNumber') {
            this._form.controls.files.controls.serialNumber.patchValue(files[0]);
        }
    }

    /**
     * @internal
     */
    public onClose(canceled: boolean): void {
        this._dialogRef.close({
            canceled: canceled
        });
    }

    private checkSerialNumberType(selectedType): void {
        this._form.controls.files.controls.serialNumbers.clearValidators();
        this._form.controls.files.controls.serialNumbers.updateValueAndValidity();
        this._form.controls.files.controls.serialNumber.clearValidators();
        this._form.controls.files.controls.serialNumber.updateValueAndValidity();

        if (selectedType === 'manual') {
            this._form.controls.files.controls.serialNumbers.setValidators(NgValidators.required);
            this._form.controls.files.controls.serialNumbers.updateValueAndValidity();
            this.onAddSerialNumber();
        }

        if (selectedType === 'extraDocument') {
            this._form.controls.files.controls.serialNumber.setValidators(NgValidators.required);
            this._form.controls.files.controls.serialNumber.updateValueAndValidity();
            this.onClearSerialNumber();
        }

        if (selectedType === 'includeInDelivery') {
            this.onClearSerialNumber();
        }
    }
    //#endregion

}
