/**
 * The `HostBindingBuilder` class.
 *
 * @public
 */
export class HostBindingBuilder {

    //#region Methods

    /**
     * Returns a record as class string.
     *
     * @public
     */
    public static buildClasses(classes: Record<string, unknown>, additionalClasses?: string): string {
        let allClasses = '';

        Object.entries(classes).forEach(([key, value]) => {
            const isTruthy = (value === false || value === 0 || value === '' || value === null || value === undefined) ? false : true;
            if (isTruthy) {
                allClasses += `${key} `;
            }
        });

        return allClasses.concat(` ${additionalClasses?.trim() ?? ''}`).trim();
    }

    /**
     * Returns a record as style string.
     *
     * @public
     */
    public static buildStyles(styles: Record<string, unknown>, additionalStyles?: string): string {
        let allStyles = '';
        Object.entries(styles).forEach(([key, value]) => {
            const isTruthy = value === false
                || value === ''
                || value === null
                || value === undefined
                ? false
                : true;

            if (isTruthy) {
                allStyles += `${key}:${String(value)}; `;
            }
        });

        return allStyles.concat(` ${(additionalStyles ?? '').trim()}`).trim();
    }

    /**
     * Returns a record as attribite string.
     *
     * @public
     */
    public buildAttributes(attributes: Record<string, unknown>, additionalAttributes?: string): string {
        let allAttributes = '';

        Object.entries(attributes).forEach(([key, value]) => {
            const isTruthy = value === ''
                || value === null
                || value === undefined
                ? false
                : true;

            if (isTruthy) {
                allAttributes += `${key}:${String(value)} `;
            }
        });

        return allAttributes.concat(` ${(additionalAttributes ?? '').trim()}`).trim();
    }

    //#endregion

}
