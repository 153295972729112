//#region Imports

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { SaveOfferWithoutCrefoViewPresenter } from './SaveOfferWithoutCrefoViewPresenter';

// # endregion

export interface ISaveOfferWithoutCrefoViewDialogData {
    companyName: string;
    city: string;
    country: string;
}

export interface ISaveOfferWithoutCrefoViewDialogResult {
    companyName: string;
    street: string;
    houseNumber: string;
    postalCode: string;
    city: string;
    country: string;
}

/**
 * The SaveOfferWithoutCrefoView view.
 *
 * @public
 */
@Component({
    selector: 'l7-save-offer-without-crefo-view',
    templateUrl: './SaveOfferWithoutCrefoView.html',
    styleUrls: ['./SaveOfferWithoutCrefoView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        SaveOfferWithoutCrefoViewPresenter
    ]
})
export class SaveOfferWithoutCrefoView implements OnInit, OnDestroy {

    //#region Fields

    private readonly _presenter: SaveOfferWithoutCrefoViewPresenter;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the saveOfferWithoutCrefo class.
     *
     * @public
     */
    public constructor(presenter: SaveOfferWithoutCrefoViewPresenter) {
        this._presenter = presenter;
    }

    //#endregion

    //#region Properties

    /**
     * Returns the presenter of the saveOfferWithoutCrefo
     *
     * @public
     * @readonly
     */
    public get presenter(): SaveOfferWithoutCrefoViewPresenter {
        return this._presenter;
    }

    //#endregion

    //#region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize();
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    //#endregion

}
