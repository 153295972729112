//#region Imports

import { MessageBoxService } from '@abcfinlab/ui';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker';
import { WINDOW } from '../Tokens/WindowToken';
import { UpdateHandler } from './UpdateHandler';

//#endregion

/**
 * @private
 * @factory
 */
export function updaterFactory(update: SwUpdate, messageBoxService: MessageBoxService, window: any): UpdateHandler {
    const handler = new UpdateHandler(update, messageBoxService, window);

    handler.initialize();

    return handler;
}

/**
 * The `UpdateModule` module.
 *
 * @public
 */
@NgModule({
    imports: [
        CommonModule,
        ServiceWorkerModule
    ],
    providers: [
        { provide: UpdateHandler, useFactory: updaterFactory, deps: [SwUpdate, WINDOW] }
    ],
})
export class UpdateModule { }
