// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `image-cropper {
    height: 10vh;
}
.logo-crop-container {
    width: 30vw;
}
.uploadfilecontainer{
    height: -moz-fit-content;
    height: fit-content;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImxvZ28tdXBsb2FkLmNvbXBvbmVudC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7SUFDSSxZQUFZO0FBQ2hCO0FBQ0E7SUFDSSxXQUFXO0FBQ2Y7QUFDQTtJQUNJLHdCQUFtQjtJQUFuQixtQkFBbUI7QUFDdkIiLCJmaWxlIjoibG9nby11cGxvYWQuY29tcG9uZW50LmNzcyIsInNvdXJjZXNDb250ZW50IjpbImltYWdlLWNyb3BwZXIge1xuICAgIGhlaWdodDogMTB2aDtcbn1cbi5sb2dvLWNyb3AtY29udGFpbmVyIHtcbiAgICB3aWR0aDogMzB2dztcbn1cbi51cGxvYWRmaWxlY29udGFpbmVye1xuICAgIGhlaWdodDogZml0LWNvbnRlbnQ7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/presentation/src/Components/logo-upload/logo-upload.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;IACI,WAAW;AACf;AACA;IACI,wBAAmB;IAAnB,mBAAmB;AACvB;;AAGA,ogBAAogB","sourcesContent":["image-cropper {\n    height: 10vh;\n}\n.logo-crop-container {\n    width: 30vw;\n}\n.uploadfilecontainer{\n    height: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
