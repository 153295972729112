//#region Imports

import { EventEmitter, Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { Observable } from 'rxjs';

//#endregion

/**
 * Facade service for handling translations in the application.
 * This service wraps the ngx-translate's TranslateService and provides
 * a simplified interface for use in components.
 *
 * @public
 */
@Injectable()
export class TranslationFacade {

    //#region Fields

    private readonly _translateService: TranslateService

    //#endregion

    //#region Ctor

    /**
     * Constructor to inject the ngx-translate service.
     *
     * @param translateService The TranslateService provided by ngx-translate.
     */
    public constructor(translateService: TranslateService) {
        this._translateService = translateService;

        this._translateService.setDefaultLang('de');
        this._translateService.use('de');
    }

    //#endregion

    //#region Methods

    /**
     * Gets the current language.
     *
     * @returns The current language code.
     */
    public getCurrentLanguage(): string {
        return this._translateService.currentLang;
    }

    /**
     * Gets the available languages.
     *
     * @returns An array of available language codes.
     */
    public getAvailableLanguages(): string[] {
        return this._translateService.getLangs();
    }

    /**
     * Changes the application language.
     *
     * @param lang The language code to change to.
     */
    public changeLanguage(lang: string): void {
        this._translateService.use(lang);
    }

    /**
     * Translates a key into the current language.
     *
     * @param key The key to translate.
     * @param interpolateParams Optional interpolation parameters.
     * @returns The translated string as an observable.
     */
    public translate(key: string | string[], interpolateParams?: object): Observable<string> {
        const translation = this._translateService.get(key, interpolateParams);
        return translation;
    }

    /**
     * Translates a key into the current language instantly.
     *
     * @param key The key to translate.
     * @param interpolateParams Optional interpolation parameters.
     * @returns The translated string.
     */
    public instant(key: string | string[], interpolateParams?: object): string {
        return this._translateService.instant(key, interpolateParams);
    }

    /**
     * Gets an observable that emits whenever the language is changed.
     *
     * @returns An observable that emits LangChangeEvent.
     */
    public onLanguageChange(): EventEmitter<LangChangeEvent> {
        return this._translateService.onLangChange;
    }

    /**
     * Gets an observable that emits whenever the translation is changed.
     *
     * @returns An observable that emits LangChangeEvent.
     */
    public onTranslationChange(): EventEmitter<TranslationChangeEvent> {
        return this._translateService.onTranslationChange;
    }

    //#endregion

}