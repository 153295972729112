// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep #select_slb_code-panel {
  max-height: 350px;
  border-radius: 0;
}
::ng-deep #select_slb_code-panel .mat-mdc-option {
  padding: 10px 0 5px 15px;
  height: 65px !important;
}
::ng-deep #select_slb_code-panel .mat-mdc-option .mdc-list-item__primary-text p {
  line-height: 1.1em;
}
::ng-deep #select_slb_code-panel .mat-mdc-option .mdc-list-item__primary-text p.code-description {
  font-size: 12px;
  color: #b0b0b0;
}
::ng-deep #select_slb_code-panel .mat-mdc-option:nth-child(odd) {
  background: #f0f2f3;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNsYi1jb2RlLXNlbGVjdGlvbi1mb3JtLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsaUJBQUE7RUFDQSxnQkFBQTtBQUNGO0FBQUU7RUFDRSx3QkFBQTtFQUNBLHVCQUFBO0FBRUo7QUFDTTtFQUNFLGtCQUFBO0FBQ1I7QUFBUTtFQUNFLGVBQUE7RUFDQSxjQUFBO0FBRVY7QUFJRTtFQUNFLG1CQUFBO0FBRkoiLCJmaWxlIjoic2xiLWNvZGUtc2VsZWN0aW9uLWZvcm0uY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6Om5nLWRlZXAgI3NlbGVjdF9zbGJfY29kZS1wYW5lbCB7XG4gIG1heC1oZWlnaHQ6IDM1MHB4O1xuICBib3JkZXItcmFkaXVzOiAwO1xuICAubWF0LW1kYy1vcHRpb24ge1xuICAgIHBhZGRpbmc6IDEwcHggMCA1cHggMTVweDtcbiAgICBoZWlnaHQ6IDY1cHggIWltcG9ydGFudDtcblxuICAgIC5tZGMtbGlzdC1pdGVtX19wcmltYXJ5LXRleHQge1xuICAgICAgcCB7XG4gICAgICAgIGxpbmUtaGVpZ2h0OiAxLjFlbTtcbiAgICAgICAgJi5jb2RlLWRlc2NyaXB0aW9uIHtcbiAgICAgICAgICBmb250LXNpemU6IDEycHg7XG4gICAgICAgICAgY29sb3I6ICNiMGIwYjA7XG4gICAgICAgIH1cbiAgICAgIH1cbiAgICB9XG4gIH1cblxuICAubWF0LW1kYy1vcHRpb246bnRoLWNoaWxkKG9kZCkge1xuICAgIGJhY2tncm91bmQ6ICNmMGYyZjM7XG4gIH1cbn1cblxuXG5cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./apps/shell/src/app/private/components/calculator/slb-code-selection-form/slb-code-selection-form.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,gBAAA;AACF;AAAE;EACE,wBAAA;EACA,uBAAA;AAEJ;AACM;EACE,kBAAA;AACR;AAAQ;EACE,eAAA;EACA,cAAA;AAEV;AAIE;EACE,mBAAA;AAFJ;AACA,ogCAAogC","sourcesContent":["::ng-deep #select_slb_code-panel {\n  max-height: 350px;\n  border-radius: 0;\n  .mat-mdc-option {\n    padding: 10px 0 5px 15px;\n    height: 65px !important;\n\n    .mdc-list-item__primary-text {\n      p {\n        line-height: 1.1em;\n        &.code-description {\n          font-size: 12px;\n          color: #b0b0b0;\n        }\n      }\n    }\n  }\n\n  .mat-mdc-option:nth-child(odd) {\n    background: #f0f2f3;\n  }\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
