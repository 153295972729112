import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ILeasingQuoteOverviewVersionDto } from '@abcfinlab/api/global';

@Component({
    selector: 'l7-quote-versions-cards',
    templateUrl: './quote-versions-cards.component.html',
    styleUrls: ['./quote-versions-cards.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuoteVersionsCardsComponent {
    @Input() versions: Array<ILeasingQuoteOverviewVersionDto>;
    @Output() goToVersionDetailView: EventEmitter<ILeasingQuoteOverviewVersionDto> = new EventEmitter<ILeasingQuoteOverviewVersionDto>();

    @Output() addVendor: EventEmitter<ILeasingQuoteOverviewVersionDto> = new EventEmitter<ILeasingQuoteOverviewVersionDto>();

    public readonly rows: Array<string>;
    constructor() {
        this.rows = ['total_leasing_value', 'total_terms', 'instalment', 'yearly_interest', 'contract_type'];
    }

    public onAddVendor(evt: ILeasingQuoteOverviewVersionDto): void {
        this.addVendor.emit(evt);
    }

    public goToDetailView(evt, version: ILeasingQuoteOverviewVersionDto): void {
        evt.stopPropagation();
        this.goToVersionDetailView.emit(version);
    }

}
