import { Component, OnInit } from '@angular/core';
import {RetailerLesseesOverviewPresenter} from './retailer-lessees-Overview-presenter';

@Component({
    selector: 'l7-retailer-lessees-overview',
    templateUrl: './retailer-lessees-overview.component.html',
    styleUrls: ['./retailer-lessees-overview.component.scss'],
    providers: [RetailerLesseesOverviewPresenter]
})
export class RetailerLesseesOverviewComponent implements OnInit {

    private readonly _presenter: RetailerLesseesOverviewPresenter;

    constructor(presenter: RetailerLesseesOverviewPresenter) {
        this._presenter = presenter;
    }
    /**
     * Returns the presenter of the `RetailerLesseesOverview`
     *
     * @public
     * @readonly
     */
    public get presenter(): RetailerLesseesOverviewPresenter {
        return this._presenter;
    }

    ngOnInit(): void {
        this._presenter.initialize();
    }

}
