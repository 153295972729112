// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
  height: 100%;
  display: flex;
}
:host mat-card {
  padding-bottom: unset;
}
:host mat-card mat-nav-list {
  padding: 0;
  margin: 0;
}
:host .dot {
  background-color: #a72329;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  display: block;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIldoYXRzTmV3V2lkZ2V0Vmlldy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksV0FBQTtFQUNBLFlBQUE7RUFDQSxhQUFBO0FBQ0o7QUFDSTtFQUNJLHFCQUFBO0FBQ1I7QUFDUTtFQUNJLFVBQUE7RUFDQSxTQUFBO0FBQ1o7QUFHSTtFQUNJLHlCQUFBO0VBQ0Esa0JBQUE7RUFDQSxVQUFBO0VBQ0EsV0FBQTtFQUNBLGNBQUE7QUFEUiIsImZpbGUiOiJXaGF0c05ld1dpZGdldFZpZXcuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgICB3aWR0aDogMTAwJTtcbiAgICBoZWlnaHQ6IDEwMCU7XG4gICAgZGlzcGxheTogZmxleDtcblxuICAgIG1hdC1jYXJkIHtcbiAgICAgICAgcGFkZGluZy1ib3R0b206IHVuc2V0O1xuXG4gICAgICAgIG1hdC1uYXYtbGlzdCB7XG4gICAgICAgICAgICBwYWRkaW5nOiAwO1xuICAgICAgICAgICAgbWFyZ2luOiAwO1xuICAgICAgICB9XG4gICAgfVxuXG4gICAgLmRvdCB7XG4gICAgICAgIGJhY2tncm91bmQtY29sb3I6ICNhNzIzMjk7XG4gICAgICAgIGJvcmRlci1yYWRpdXM6IDUwJTtcbiAgICAgICAgd2lkdGg6IDhweDtcbiAgICAgICAgaGVpZ2h0OiA4cHg7XG4gICAgICAgIGRpc3BsYXk6IGJsb2NrO1xuICAgIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/news/src/Views/Widgets/WhatsNewWidgetView.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,aAAA;AACJ;AACI;EACI,qBAAA;AACR;AACQ;EACI,UAAA;EACA,SAAA;AACZ;AAGI;EACI,yBAAA;EACA,kBAAA;EACA,UAAA;EACA,WAAA;EACA,cAAA;AADR;AACA,w5BAAw5B","sourcesContent":[":host {\n    width: 100%;\n    height: 100%;\n    display: flex;\n\n    mat-card {\n        padding-bottom: unset;\n\n        mat-nav-list {\n            padding: 0;\n            margin: 0;\n        }\n    }\n\n    .dot {\n        background-color: #a72329;\n        border-radius: 50%;\n        width: 8px;\n        height: 8px;\n        display: block;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
