//#region Imports

import { TranslationModule } from '@abcfinlab/core';
import { PageModule } from '@abcfinlab/layout';
import {
    BannerModule,
    BreakpointObserverModule, IbanValidatorModule,
    NotAvailablePipeModule,
    SelectOnDblClickDirectiveModule,
    SpacerModule,
    TextBlockModule
} from '@abcfinlab/ui';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { DateAdapter, MAT_DATE_FORMATS, MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { NgxCurrencyDirective } from 'ngx-currency';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { APP_DATE_FORMATS, AppDateAdapter } from '../Adapters/date.adapter';
import { AddressCardModule } from '../Components/address-card/address-card.component';
import { BoniCheckIconComponent } from '../Components/boni-check-icon/boni-check-icon.component';
import { CarouselComponent } from '../Components/carousel/CarouselComponent';
import { ConfigurationOverviewView } from '../Components/configuration-overview-view/ConfigurationOverviewView';
import {
    CustomFormControlModule
} from '../Components/custom-form-control/CustomFormControlComponent';
import { EmailInputWithChipsComponent } from '../Components/email-input-with-chips/email-input-with-chips.component';
import { FileUploaderComponent } from '../Components/file-uploader/file-uploader.component';
import { InsuranceFormComponent } from '../Components/insurance-form/insurance-form.component';
import { LogoUploadComponent } from '../Components/logo-upload/logo-upload.component';
import { ObjectValueChangedComponent } from '../Components/object-value-changed/object-value-changed.component';
import { SectionHeaderModule } from '../Components/section-header/section-header.component';
import { SignaturePadModule } from '../Components/signature-pad/SignaturePadModule';
import { SignatureComponent } from '../Components/signature/signature.component';
import { UploadDocumentsComponent } from '../Components/upload-documents/upload-documents.component';
import { CarouselItemDirective } from '../Directives/CarouselItemDirective';
import { CarouselItemElementDirective } from '../Directives/CarouselItemElementDirective';
import { ValidateIbanDirective } from '../Directives/ValidateIbanDirective';
import { AddClassToFieldDirective } from '../Directives/add-class-to-field.directive';
import { AllowedRegexDirective } from '../Directives/allowed-regex.directive';
import { AutocompleteScrollHandlerDirective } from '../Directives/autocomplete-scroll-handler.directive';
import { DigitsOnlyDirective } from '../Directives/digits-only.directive';
import { DragDropDirective } from '../Directives/drag-drop.directive';
import { MarkAsTouchedDirective } from '../Directives/mark-as-touched.directive';
import { MaskDateDirective } from '../Directives/mask-date.directive';
import { NullDefaultValueDirective } from '../Directives/null-default-value.directive';
import { MailToPipeModule } from '../Pipes/MailToPipe';
import { SafePipeModule } from '../Pipes/SafePipe';
import { NgxMaskDirective } from 'ngx-mask';
import { MatCheckbox } from '@angular/material/checkbox';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

//#endregion

/**
 * The `NameModule` module.
 *
 * @public
 */
@NgModule({
    declarations: [
        DragDropDirective,
        FileUploaderComponent,
        SignatureComponent,
        MaskDateDirective,
        DigitsOnlyDirective,
        AllowedRegexDirective,
        AddClassToFieldDirective,
        NullDefaultValueDirective,
        EmailInputWithChipsComponent,
        UploadDocumentsComponent,
        InsuranceFormComponent,
        MarkAsTouchedDirective,
        ConfigurationOverviewView,
        LogoUploadComponent,
        CarouselComponent,
        CarouselItemDirective,
        CarouselItemElementDirective,
        AutocompleteScrollHandlerDirective,
        ValidateIbanDirective,
        ObjectValueChangedComponent,
        BoniCheckIconComponent
    ],
    exports: [
        FileUploaderComponent,
        SignatureComponent,
        DragDropDirective,
        FileUploaderComponent,
        SectionHeaderModule,
        AddressCardModule,
        CustomFormControlModule,
        MaskDateDirective,
        DigitsOnlyDirective,
        AllowedRegexDirective,
        AddClassToFieldDirective,
        NullDefaultValueDirective,
        EmailInputWithChipsComponent,
        UploadDocumentsComponent,
        InsuranceFormComponent,
        NgxCurrencyDirective,
        MarkAsTouchedDirective,
        ConfigurationOverviewView,
        LogoUploadComponent,
        CarouselComponent,
        CarouselItemDirective,
        CarouselItemElementDirective,
        AutocompleteScrollHandlerDirective,
        ValidateIbanDirective,
        ObjectValueChangedComponent,
        BoniCheckIconComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        BreakpointObserverModule,
        MatFormFieldModule,
        MatButtonModule,
        MatCardModule,
        MatChipsModule,
        MatIconModule,
        MatInputModule,
        MatDialogModule,
        MatProgressBarModule,
        MatSelectModule,
        MatOptionModule,
        MatSlideToggleModule,
        FlexLayoutModule,
        TranslationModule,
        MatIconModule,
        SignaturePadModule,
        BannerModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        SpacerModule,
        TextBlockModule,
        NgxCurrencyDirective,
        SelectOnDblClickDirectiveModule,
        PageModule,
        SafePipeModule,
        ImageCropperComponent,
        MailToPipeModule,
        NotAvailablePipeModule,
        MatBadgeModule,
        IbanValidatorModule,
        MatCheckbox,
        NgxMaskDirective
    ],
    providers: [
        {
            provide: DateAdapter, useClass: AppDateAdapter
        },
        {
            provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
        }
    ]
})
export class PresentationModule { }
