import {Action, Selector, State, StateContext} from '@ngxs/store';
import {SetContactListFromSearchResult} from '../actions/Contact.actions';
import {Injectable} from '@angular/core';
import { IContactDto } from '@abcfinlab/api/contact';
import { ContactDTO } from '../models/ContactDTO.interface';

export class ContactStateModel {
    contactSearchResult: Array<IContactDto> | Array<ContactDTO>;
}

@State<ContactStateModel>({
  name: 'contact',
  defaults: {
    contactSearchResult: [],
  }
})

@Injectable()
export class ContactState {

  constructor() {
  }

  @Selector()
  static getContactListFormSearchResult(state: ContactStateModel) {
    return state.contactSearchResult;
  }

  @Action(SetContactListFromSearchResult)
  setContactListFromSearchResult({patchState}: StateContext<ContactStateModel>, {payload}: SetContactListFromSearchResult) {
    patchState({
      contactSearchResult: payload.search_results
    });
  }
}
