//#region Imports

import { Inject, Injectable } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { IBeneficialOwnerDto } from '@abcfinlab/api/contact';

//#endregion

interface IBeneficialOwnerConfirmDialogViewData {
    persons: Array<IBeneficialOwnerDto>;
}

/**
 * The presenter of the @{link BeneficialOwnerConfirmDialogView} view.
 *
 * @internal
 */
@Injectable()
export class BeneficialOwnerConfirmDialogViewPresenter {

    //#region Fields

    private readonly _dataSubject: BehaviorSubject<IBeneficialOwnerConfirmDialogViewData>;

    //#endregion

    //#region Ctor

    /**
    * Constructs a new instance of the `BeneficialOwnerConfirmDialogViewPresenter` class.
    *
    * @public
    */
    public constructor(@Inject(MAT_DIALOG_DATA) data: IBeneficialOwnerConfirmDialogViewData) {
        this._dataSubject = new BehaviorSubject(data);
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `data` property.
     *
     * @public
     * @readonly
     */
    public get data(): Observable<IBeneficialOwnerConfirmDialogViewData> {
        return this._dataSubject.asObservable();
    }

    //#endregion

    //#region Methods

    /**
    * Called before the view first displays the data-bound properties and sets the view's input properties.
    *
    * @internal
    */
    public initialize(): void {
    }

    /**
    * Called before the view will be destroyed.
    * Unsubscribe Observables and detach event handlers to avoid memory leaks.
    *
    * @internal
    */
    public dispose(): void {
    }

    //#endregion

}
