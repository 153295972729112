// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  width: 100%;
  height: 100%;
  text-align: initial;
}
:host legend {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: #83929b;
}
:host fieldset {
  margin-bottom: 16px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm9iamVjdC12YWx1ZS1jaGFuZ2VkLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksY0FBQTtFQUNBLFdBQUE7RUFDQSxZQUFBO0VBQ0EsbUJBQUE7QUFDSjtBQUNJO0VBQ0ksZUFBQTtFQUNBLGlCQUFBO0VBQ0EsaUJBQUE7RUFDQSxjQUFBO0FBQ1I7QUFFSTtFQUNJLG1CQUFBO0FBQVIiLCJmaWxlIjoib2JqZWN0LXZhbHVlLWNoYW5nZWQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XG4gICAgZGlzcGxheTogYmxvY2s7XG4gICAgd2lkdGg6IDEwMCU7XG4gICAgaGVpZ2h0OiAxMDAlO1xuICAgIHRleHQtYWxpZ246IGluaXRpYWw7XG5cbiAgICBsZWdlbmQge1xuICAgICAgICBmb250LXNpemU6IDE0cHg7XG4gICAgICAgIGxpbmUtaGVpZ2h0OiAyMHB4O1xuICAgICAgICBmb250LXdlaWdodDogYm9sZDtcbiAgICAgICAgY29sb3I6ICM4MzkyOWI7XG4gICAgfVxuXG4gICAgZmllbGRzZXQge1xuICAgICAgICBtYXJnaW4tYm90dG9tOiAxNnB4O1xuICAgIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/presentation/src/Components/object-value-changed/object-value-changed.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;AACJ;AACI;EACI,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,cAAA;AACR;AAEI;EACI,mBAAA;AAAR;AACA,gyBAAgyB","sourcesContent":[":host {\n    display: block;\n    width: 100%;\n    height: 100%;\n    text-align: initial;\n\n    legend {\n        font-size: 14px;\n        line-height: 20px;\n        font-weight: bold;\n        color: #83929b;\n    }\n\n    fieldset {\n        margin-bottom: 16px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
