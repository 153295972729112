import { AfterViewInit, Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[l7MarkAsTouched]'
})
export class MarkAsTouchedDirective implements AfterViewInit{
    @Input() markOnInit: boolean;

    constructor(private readonly ngControl: NgControl) { }

    ngAfterViewInit() {
        if (this.markOnInit) {
            this.ngControl.control.markAsTouched();
        }
    }

    @HostListener('keyup', ['$event'])
    onKeyUp(e: KeyboardEvent): any {
        if (!this.ngControl.control.touched) {
            this.ngControl.control.markAsTouched();
        }
    }
}
