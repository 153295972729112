//#region Imports

import { Route } from '@angular/router';
import { AuthGuard } from '../../../../apps/shell/src/app/shared/guards/auth.guard';
import { CONFIGURATION_OVERVIEW_ROUTE_PATH } from './RoutePaths';
import {
    ConfigurationOverviewView
} from '../Components/configuration-overview-view/ConfigurationOverviewView';
import { UserInfoResolver } from '@abcfinlab/auth';

//#endregion

/**
 * @internal
 */
export const CONFIGURATION_OVERVIEW_ROUTE: Route = {
    path: CONFIGURATION_OVERVIEW_ROUTE_PATH,
    component: ConfigurationOverviewView,
    resolve: {
        userInfo: UserInfoResolver
    },
    canActivate: [AuthGuard]
};
