
import { IContactDto } from '@abcfinlab/api/contact';
import {
    IRetailerQuoteResultDto,
    IRetailerRemoteSignatureRequestDto, RetailerQuoteService,
    VerificationService
} from '@abcfinlab/api/global';
import { ControlsOf, once, TranslationFacade } from '@abcfinlab/core';
import { BusyBoxService, MessageBoxService, ToastService } from '@abcfinlab/ui';
import { Inject, Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { Validators as AdministrationValidators } from '../../../../../administration/src/Validators/Validators';

//#endregion

interface ICreateContractDialogData {
    quoteId: string;
    lesseeId: string;
    lessee: IContactDto;
    quoteDetails: IRetailerQuoteResultDto;
}

/**
 * The presenter of the {@link RetailerCreateContractView} view.
 *
 * @internal
 */
@Injectable()
export class RetailerCreateContractViewPresenter {

    //#region Fields
    private readonly _verificationService: VerificationService;
    private readonly _retailerQuoteService: RetailerQuoteService;
    private readonly _messageBoxService: MessageBoxService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _busyBoxService: BusyBoxService;
    private readonly _toastService: ToastService;
    private readonly _dialogRef: MatDialogRef<any>;
    private readonly _dialogData: BehaviorSubject<ICreateContractDialogData>;
    private readonly _quoteDetails: BehaviorSubject<IRetailerQuoteResultDto> = new BehaviorSubject<IRetailerQuoteResultDto>(null);
    private readonly _lessee: BehaviorSubject<IContactDto> = new BehaviorSubject<IContactDto>(null);
    private readonly _form: FormGroup<ControlsOf<IRetailerRemoteSignatureRequestDto>>;
    private readonly _quoteId: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `RetailerCreateContractViewPresenter` class.
     *
     * @public
     */
    public constructor(messageBoxService: MessageBoxService, translationFacade: TranslationFacade,
        busyBoxService: BusyBoxService, toastService: ToastService, dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) dialogData: ICreateContractDialogData, verificationService: VerificationService,
        retailerQuoteService: RetailerQuoteService) {
        this._verificationService = verificationService;
        this._retailerQuoteService = retailerQuoteService;
        this._messageBoxService = messageBoxService;
        this._translationFacade = translationFacade;
        this._toastService = toastService;
        this._busyBoxService = busyBoxService;
        this._dialogRef = dialogRef;
        this._dialogData = new BehaviorSubject<ICreateContractDialogData>(dialogData);

        this._quoteId.next(dialogData.quoteId);

        this._lessee.next(dialogData.lessee);
        this._quoteDetails.next(dialogData.quoteDetails);

        this._form = new FormGroup<ControlsOf<IRetailerRemoteSignatureRequestDto>>({
            email: new FormControl('', [Validators.required, Validators.email], AdministrationValidators.emailInvalidAsync(verificationService))
        });
    }

    //#endregion

    //#region Properties
    /**
     * Returns the `form` property.
     *
     * @public
     * @readonly
     */
    public get form(): FormGroup<ControlsOf<IRetailerRemoteSignatureRequestDto>> {
        return this._form;
    }

    /**
     * Returns the `quoteDetails` property.
     *
     * @public
     * @readonly
     */
    public get quoteDetails(): Observable<IRetailerQuoteResultDto> {
        return this._quoteDetails.asObservable();
    }

    /**
     * Returns the `lessee` property.
     *
     * @public
     * @readonly
     */
    public get lessee(): Observable<IContactDto> {
        return this._lessee.asObservable();
    }

    /**
     * Returns the `quoteId` property.
     *
     * @public
     * @readonly
     */
    public get quoteId(): Observable<string> {
        return this._quoteId.asObservable();
    }

    //#endregion

    //#region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
        //
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
        //
    }

    /**
     * @internal
     */
    public onSubmit(): void {
        this._form.get('email').clearAsyncValidators();
        this._form.get('email').updateValueAndValidity();
        if (this._form.valid) {
            once(this._busyBoxService.show(undefined, this._translationFacade.translate('global.busy'),
                this._retailerQuoteService.prepareRemoteSignature({
                    leasingQuoteId: this._quoteId.getValue(),
                    body: this._form.getRawValue()
                })), res => this._dialogRef.close('REMOTE'));
        } else {
            this._form.get('email').addAsyncValidators(AdministrationValidators.emailInvalidAsync(this._verificationService));
            this._form.get('email').updateValueAndValidity();
            this._form.markAllAsTouched();
        }
    }


    //#endregion

}
