import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {fromEvent} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
@UntilDestroy()
@Component({
    selector: 'l7-date-picker',
    templateUrl: './DatePickerComponent.html',
    styleUrls: ['./DatePickerComponent.css']
})
export class DatePickerComponent {

    @Input({required: true}) parentFormGroup: FormGroup;
    @Input({required: true}) controlName: string;
    @Input() label: string;
    @Input() formFieldAppearance: string = 'outline';
    @Input() uniqueId: string;
    @Input() formFieldTooltipPosition: string;
    @Input() formFieldTooltipClass: string;
    @Input() formFieldTooltip: string;
    @Input() requiredErrorMessage: string = 'date.errors.required';
    @Input() minDateErrorMessage: string = 'date.errors.minDate';
    @Input() maxDateErrorMessage: string = 'date.errors.maxDate';
    @Input() invalidDateErrorMessage: string = 'date.errors.invalid';
    @Input() maxDate: Date;
    @Input() minDate: Date;
    @Output() dateChanged: EventEmitter<Date> = new EventEmitter<Date>();
    @ViewChild('inputRef') dateInput: ElementRef;
    private dateVal: string;

    constructor() { }

    get formControl() {
        if (!this.parentFormGroup) {
            return null;
        }
        return this.parentFormGroup.get(this.controlName) as FormControl;
    }
    ngAfterViewInit() {
        this.addEventToDatePicker(this.dateInput.nativeElement);
        this.formControl.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
            if (value === null) {
                this.dateChanged.emit(null);
            }
        });
    }

    private addEventToDatePicker(nativeElement): void {
        fromEvent(nativeElement, 'input')
            .pipe(untilDestroyed(this))
            .subscribe(_ => {
                this.dateVal = nativeElement.value;
                // Extract digits and handle both period-separated and non-period-separated formats
                const cleanedDateVal = this.dateVal.replace(/[^\d]/g, ''); // Remove non-digit characters

                if (/^\d{8}$/.test(cleanedDateVal)) {
                    const day = parseInt(cleanedDateVal.substring(0, 2), 10);
                    const month = parseInt(cleanedDateVal.substring(2, 4), 10) - 1; // JS months are 0-based
                    const year = parseInt(cleanedDateVal.substring(4, 8), 10);

                    const date = new Date(year, month, day);

                    if (date.getDate() === day && date.getMonth() === month && date.getFullYear() === year) {
                        this.formControl.setValue(date); // Set valid date in the form control
                        this.formControl.setErrors(null); // Clear previous errors
                    } else {
                        this.formControl.setErrors({ invalid: true }); // Set custom error for invalid date
                    }
                } else if (this.dateVal.match(/^\d{1,2}\.\d{1,2}\.\d{4}$/)) {
                    // Handle dates in the format "D.M.YYYY" or "DD.MM.YYYY"
                    const parts = this.dateVal.split('.').map(part => part.trim());
                    if (parts.length === 3) {
                        const day = parseInt(parts[0], 10);
                        const month = parseInt(parts[1], 10) - 1; // JS months are 0-based
                        const year = parseInt(parts[2], 10);

                        const date = new Date(year, month, day);

                        if (date.getDate() === day && date.getMonth() === month && date.getFullYear() === year) {
                            this.formControl.setValue(date); // Set valid date in the form control
                            this.formControl.setErrors(null); // Clear previous errors
                        } else {
                            this.formControl.setErrors({ invalid: true }); // Set custom error for invalid date
                        }
                    }
                } else {
                    this.formControl.setErrors({ invalid: true }); // Set custom error for invalid format
                }
            });
    }

    public hasError(type: string): boolean {
        const control = this.parentFormGroup.get(this.controlName);
        if (control) {
            switch (type) {
                case 'required':
                    return control.hasError('required');
                case 'minDate':
                    return (control.hasError('minDate') || control.hasError('min')) && control.dirty && !control.hasError('required');
                case 'maxDate':
                    return control.hasError('maxDate') && control.dirty && !control.hasError('required') && !(control.hasError('minDate') || control.hasError('min'));
                case 'invalid':
                    return (!control.valid && !(control.hasError('minDate') || control.hasError('min')) && !control.hasError('maxDate') && control.dirty) || !this.isValidDate(this.dateVal);
                default:
                    return false;
            }
        }
        return false;
    }

    public onDateChange(event: MatDatepickerInputEvent<Date>): void {
        this.dateChanged.emit(event.value);
    }
    private isValidDate(dateString: string): boolean {
        // Check if the date string is in "DDMMYYYY" format
        if (/^\d{8}$/.test(dateString)) {
            const day = parseInt(dateString.substring(0, 2), 10);
            const month = parseInt(dateString.substring(2, 4), 10) - 1; // JS months are 0-based
            const year = parseInt(dateString.substring(4, 8), 10);

            const date = new Date(year, month, day);
            return date.getDate() === day && date.getMonth() === month && date.getFullYear() === year;
        }

        // Use Date.parse for other formats
        return !isNaN(Date.parse(dateString));
    }
}
