/**
 * @internal
 */
export const RETAILER_OVERVIEW_ROUTE_PATH: string = 'retailer/overview';

/**
 * @internal
 */
export const RETAILER_EDIT_ROUTE_PATH: string = 'retailer/edit';

/**
 * @internal
 */
export const RETAILER_USER_EDIT_ROUTE_PATH: string = 'retailer/user/edit';

/**
 * @internal
 */
export const RETAILER_CONFIGURATION_ROUTE_PATH: string = 'retailer/configuration/overview';
