import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfirmationDTO } from '../models/confirmation-dto.class';
import { ConfirmationType } from '../models/confirmation-type.enum';
import { LeasingQuoteIdService } from './leasing-quote-id.service';
import {AppConfig} from '@abcfinlab/core';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationService {
  private readonly _host: string;

  private readonly moneyLaunderingPath: string;

  private readonly confirmationPath: string;

  private readonly identificationConfirmationPath: string;

  constructor(
    private readonly http: HttpClient,
    private readonly leasingQuoteIdService: LeasingQuoteIdService,
    private readonly appConfig: AppConfig
  ) {
      this._host = appConfig.get('host');
      this.moneyLaunderingPath = `${this._host  }/api/v1/gwg`;
      this.confirmationPath = `${this._host  }/api/v1/confirm`;
      this.identificationConfirmationPath = `${this.moneyLaunderingPath  }/signer/confirmation`;
  }

  deleteConfirmations(quoteId: string) {
    return this.http.delete<{}>(`${this.moneyLaunderingPath  }/${  quoteId}`,
      { withCredentials: true }).subscribe();

  }

  postNaturalPersonConfirmation(): Observable<void> {
    const naturalPersonConfirmations: ConfirmationDTO =
      ConfirmationDTO.createNaturalPersonConfirmation(this.leasingQuoteIdService.getLeasingQuoteId());
    return this.http.post<void>(this.identificationConfirmationPath, naturalPersonConfirmations, { withCredentials: true });
  }

  postJuristicPersonConfirmation(transparencyRegisterType: ConfirmationType): Observable<void> {
    const postJuristicPersonConfirmations: ConfirmationDTO =
      ConfirmationDTO.createJuristicPersonConfirmation(this.leasingQuoteIdService.getLeasingQuoteId(), transparencyRegisterType);
    return this.http.post<void>(this.identificationConfirmationPath, postJuristicPersonConfirmations, { withCredentials: true });
  }

  postStandardConfirmations(confirmations: ConfirmationDTO): Observable<void> {
    return this.http.post<void>(this.confirmationPath, confirmations, { withCredentials: true });
  }

  postBonicheckApprovedWithConditionConfirmation(leasingQuoteId: string): Observable<void> {
    const requestBodyConfirmation: ConfirmationDTO = new ConfirmationDTO(leasingQuoteId, [ConfirmationType.APPROVED_WITH_CONDITIONS]);
    return this.http.post<void>(this.confirmationPath, requestBodyConfirmation, { withCredentials: true });
  }

}
