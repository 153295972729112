import {Injectable} from '@angular/core';
import {BeneficialOwnerService} from '@abcfinlab/api/contact';

@Injectable()

export class BeneficialOwnersViewPresenter {

    constructor() {
    }
}

