import { AppConfig } from '@abcfinlab/core';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContractTypeId } from '../../../models/enums/ContractTypeId.enum';
import { SlbCodes } from '../../../models/enums/SlbCodes.enum';

@Injectable({
    providedIn: 'root'
})
export class SlbService {
    private readonly HOST: string;

    constructor(private readonly _http: HttpClient, appConfig: AppConfig) {
        this.HOST = appConfig.get('host');
    }

    /**
   * Fetch the slb codes
   */
    public fetchCodes(): Observable<Array<SlbCodes>> {
        const URL = `${this.HOST  }/api/v1/slb` + '/codes';
        return this._http.get<Array<SlbCodes>>(URL, { withCredentials: true });
    }

    /**
   * Get if contract type can have SLB
   */
    public getCanHaveSlb(contractType: ContractTypeId): Observable<boolean> {
        return this._http.get<boolean>(`${`${this.HOST  }/api/v1/slb`}/contract/${contractType}`, { withCredentials: true });
    }
}
