//#region Imports

import {
    IContractManagementOverviewDto,
    IContractTypeDto,
    ILinkDto,
    ISlbGetResponseDto,
    SlbService
} from '@abcfinlab/api/contract';
import {
    IContactDto,
    IContractManagementDetailsDto,
    ICreditCheckDto,
    IInsuranceTypeDto,
    QuoteService
} from '@abcfinlab/api/global';
import { EventHub, once, TranslationFacade } from '@abcfinlab/core';
import { MessageBoxButton, MessageBoxResult, MessageBoxService, ToastService } from '@abcfinlab/ui';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { KnownEvents } from 'libs/contractManagement/src/Models/KnownEvents';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';

//#endregion

/**
 * The presenter of the {@link ContractOverviewKuevView} view.
 *
 * @internal
 */
@UntilDestroy()
@Injectable()
export class ContractOverviewKuevViewPresenter {

    //#region Fields

    private readonly _data: BehaviorSubject<ISlbGetResponseDto> = new BehaviorSubject<ISlbGetResponseDto>(null);
    private readonly _signingLink: BehaviorSubject<ILinkDto> = new BehaviorSubject<ILinkDto>(null);

    private readonly _slbService: SlbService;
    private readonly _quoteService: QuoteService;

    private readonly _messageBoxService: MessageBoxService;
    private readonly _toastService: ToastService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _eventHub: EventHub;

    private readonly _slbDone: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private readonly _editMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _showSuccessBanner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _showWarningBanner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _showPriceChangedBanner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _lessee: BehaviorSubject<IContactDto> = new BehaviorSubject<IContactDto>(null);
    private readonly _contractId: BehaviorSubject<string> = new BehaviorSubject(null);
    private readonly _originalPurchasePrice: BehaviorSubject<number> = new BehaviorSubject(null);
    private readonly _isMKN: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private readonly _creditCheck: BehaviorSubject<ICreditCheckDto> = new BehaviorSubject<ICreditCheckDto>(null);
    private readonly _insuranceType: BehaviorSubject<IInsuranceTypeDto> = new BehaviorSubject<IInsuranceTypeDto>(null);

    public readonly quoteId: BehaviorSubject<string> = new BehaviorSubject(null);

    private readonly _initializeSubscription: Subscription;

    //#endregion

    //#region Ctor
    /**
     * Constructs a new instance of the `ContractOverviewKuevViewPresenter` class.
     *
     * @public
     */
    public constructor(messageBoxService: MessageBoxService, toastService: ToastService, translationFacade: TranslationFacade,
        slbService: SlbService, eventHub: EventHub, quoteService: QuoteService) {
        this._slbService = slbService;
        this._quoteService = quoteService;
        this._messageBoxService = messageBoxService;
        this._toastService = toastService;
        this._translationFacade = translationFacade;
        this._eventHub = eventHub;

        this._initializeSubscription = this._eventHub.getEvent<IContractManagementOverviewDto>(KnownEvents.CONTRACT_OVERVIEW_ROW_CHANGED)
            .subscribe(row => {
                if (row.slb) {
                    this._isMKN.next(row.contract_type === IContractTypeDto.Mkn);
                    this.initialize(row.quote_id, row.contract_number);
                }
            });
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `data` property.
     *
     * @public
     * @readonly
     */
    public get data(): Observable<ISlbGetResponseDto> {
        return this._data.asObservable();
    }

    /**
     * Returns the `signingLink` property.
     *
     * @public
     * @readonly
     */
    public get signingLink(): Observable<ILinkDto> {
        return this._signingLink.asObservable();
    }

    public get slbDone(): Observable<boolean> {
        return this._slbDone.asObservable();
    }

    public get showSuccessBanner(): Observable<boolean> {
        return this._showSuccessBanner.asObservable();
    }

    public get showWarningBanner(): Observable<boolean> {
        return this._showWarningBanner.asObservable();
    }

    public get showPriceChangedBanner(): Observable<boolean> {
        return this._showPriceChangedBanner.asObservable();
    }

    /**
     * Returns the presenter of the `editMode`
     *
     * @public
     * @readonly
     */
    public get editMode(): Observable<boolean> {
        return this._editMode.asObservable();
    }

    /**
     * Returns the presenter of the `lessee`
     *
     * @public
     * @readonly
     */
    public get lessee(): Observable<IContactDto> {
        return this._lessee.asObservable();
    }

    /**
     * Returns the presenter of the `originalPurchasePrice`
     *
     * @public
     * @readonly
     */
    public get originalPurchasePrice(): Observable<number> {
        return this._originalPurchasePrice.asObservable();
    }

    /**
     * Returns the presenter of the `isMKN`
     *
     * @public
     * @readonly
     */
    public get isMKN(): Observable<boolean> {
        return this._isMKN.asObservable();
    }

    public get creditCheck(): Observable<ICreditCheckDto> {
        return this._creditCheck.asObservable();
    }

    public get insuranceType(): Observable<IInsuranceTypeDto> {
        return this._insuranceType.asObservable();
    }
    //#endregion

    //#region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(quoteId: string, contractId: string): void {
        this.quoteId.next(quoteId);
        this._contractId.next(contractId);
        once(combineLatest([
            this._slbService.getSlb({ contractId: contractId }),
            this._quoteService.getQuoteCalculation({ leasingQuoteId: quoteId })
        ]), ([slbData, quoteCalculation]) => {
            this._originalPurchasePrice.next(quoteCalculation.total_leasing_value);
            const isSlbDone = slbData.activeSlbResponse?.completed || slbData.inactiveSlbResponse?.completed;
            this._slbDone.next(isSlbDone);
            this._insuranceType.next(quoteCalculation.insurance_type);
            if (slbData.inactiveSlbResponse && !slbData.inactiveSlbResponse?.completed) {
                this._editMode.next(true);
            } else if (slbData.activeSlbResponse && slbData.inactiveSlbResponse) {
                this._showWarningBanner.next(true);
                this._editMode.next(false);
            } else if (slbData.activeSlbResponse && !slbData.inactiveSlbResponse) {
                this._showSuccessBanner.next(true);
                this._editMode.next(false);
            } else {
                this._editMode.next(false);
            }
            if (slbData.inactiveSlbResponse && !slbData.activeSlbResponse && slbData.inactiveSlbResponse?.calculation?.totalLeasingValueNet !== quoteCalculation.total_leasing_value) {
                this._showPriceChangedBanner.next(true);
            } else {
                this._showPriceChangedBanner.next(false);
            }
            if (slbData.inactiveSlbResponse) {
                this._signingLink.next(slbData.inactiveSlbResponse?.signingLink);
            } else {
                this._signingLink.next(slbData.activeSlbResponse?.signingLink);
            }

            this._data.next(slbData);
        });
        this._eventHub.getEvent<IContractManagementDetailsDto>(KnownEvents.CONTRACT_QUOTE_DETAILS).subscribe(contractDetails => {
            this._lessee.next(contractDetails.lessee);
        });
        this._eventHub.getEvent<ICreditCheckDto>(KnownEvents.CREDIT_CHECK).subscribe(creditCheck => {
            this._creditCheck.next(creditCheck);
        });
    }

    public onEdit(): void {
        this._editMode.next(true);
        if (this._data.getValue().activeSlbResponse) {
            this._showWarningBanner.next(true);
            this._showSuccessBanner.next(false);
        }
    }

    /**
     * @internal
     */
    public onGenerateLink(): void {
        once(this._slbService.refreshSlbSignatureLink({ contractId: this._contractId.value }), slbLink => {
            this._signingLink.next(slbLink);
        });
    }

    /**
     * @internal
     */
    public onDeactivateLink(): void {
        this._messageBoxService.show('Sind sie sicher?', 'Wenn Sie den Link deaktivieren kann er nicht mehr genutzt werden. Informieren Sie ggf. Ihre Nutzer. Sie können anschließend einen neuen Link erstellen.', MessageBoxButton.OKCancel, {
            id: 'ContractOverviewTakeoverConfirmationDeactivateLinkMessageDialogView',
            width: { maxValue: '600px' },
            labels: {
                ok: 'Deaktivieren',
                cancel: 'Abbrechen'
            }
        }).pipe(untilDestroyed(this)).subscribe(result => {
            if (result === MessageBoxResult.OK) {
                this._slbService.deactivateSlbSignatureLink({ contractId: this._contractId.value })
                    .pipe(untilDestroyed(this)).subscribe(() => {
                        // update the link
                        this._signingLink.next({ ...this._signingLink.getValue(), ...{ invalid_at: new Date(Date.now()).toISOString() } });

                        this._toastService.show(this._translationFacade.translate('contract_management.takeover_confirmation.signing_link.deactivated'), 'success');
                    });
            }
        });
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
        this._initializeSubscription.unsubscribe();
    }

    //#endregion
}
