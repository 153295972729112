// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  flex: 1 1 0%;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: flex-start;
  justify-content: flex-start;
  padding: 16px 0;
  height: 100%;
  width: 100%;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIlBhZ2VDb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLGNBQUE7RUFFQSxZQUFBO0VBQ0Esc0JBQUE7RUFDQSxpQkFBQTtFQUNBLG9CQUFBO0VBQ0EseUJBQUE7RUFDQSwyQkFBQTtFQUVBLGVBQUE7RUFDQSxZQUFBO0VBQ0EsV0FBQTtBQURKIiwiZmlsZSI6IlBhZ2VDb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgICBkaXNwbGF5OiBibG9jaztcblxuICAgIGZsZXg6IDEgMSAwJTtcbiAgICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICAgIGZsZXgtd3JhcDogbm93cmFwO1xuICAgIGFsaWduLWl0ZW1zOiBzdHJldGNoO1xuICAgIGFsaWduLWNvbnRlbnQ6IGZsZXgtc3RhcnQ7XG4gICAganVzdGlmeS1jb250ZW50OiBmbGV4LXN0YXJ0O1xuXG4gICAgcGFkZGluZzogMTZweCAwO1xuICAgIGhlaWdodDogMTAwJTtcbiAgICB3aWR0aDogMTAwJTtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/layout/src/Controls/Components/Page/PageComponent.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EAEA,YAAA;EACA,sBAAA;EACA,iBAAA;EACA,oBAAA;EACA,yBAAA;EACA,2BAAA;EAEA,eAAA;EACA,YAAA;EACA,WAAA;AADJ;AACA,4sBAA4sB","sourcesContent":[":host {\n    display: block;\n\n    flex: 1 1 0%;\n    flex-direction: column;\n    flex-wrap: nowrap;\n    align-items: stretch;\n    align-content: flex-start;\n    justify-content: flex-start;\n\n    padding: 16px 0;\n    height: 100%;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
