export function isNumeric(value: string): boolean {
    return typeof value !== 'string'
        ? false
        : !Number.isNaN(Number.parseFloat(value));
}

export function format(self: string, ...args: Array<any>): string {
    const allArgs = new Array<any>();
    args.forEach(x => {
        if (Array.isArray(x)) {
            x.forEach(y => {
                allArgs.push(y);
            });
        } else {
            allArgs.push(x);
        }
    });

    return self.replace(/{(\d+)}/g, (match, index) => {
        if (allArgs[index] !== undefined) {
            return allArgs[index];
        }

        return match;
    });
}
