import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadFileBacksideComponent } from './upload-file-backside/upload-file-backside.component';
import { UploadFileFrontsideComponent } from './upload-file-frontside/upload-file-frontside.component';
import {IdentificationUploadFileRoutingModule} from './identification-upload-file-routing.module';
import {SharedModule} from '../shared/shared.module';

@NgModule({
  declarations: [
    UploadFileBacksideComponent,
    UploadFileFrontsideComponent
  ],
  imports: [
    CommonModule,
    IdentificationUploadFileRoutingModule,
    SharedModule
  ],
  exports: [
    UploadFileBacksideComponent,
    UploadFileFrontsideComponent
  ]
})
export class IdentificationUploadFileModule { }
