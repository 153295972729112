import {
    ICalculationDto, IContractTypeDto, ICreditCheckDto, IInhouseQuoteDto, IInsuranceTypeDto,
    IRefinancingInterestDto
} from '@abcfinlab/api/global';
import { monthDiff, TranslationModule } from '@abcfinlab/core';
import { AddressCardModule, CreditCheckIconPipeModule, InsuranceTypeIconPipeModule } from '@abcfinlab/presentation';
import { TextBlockModule } from '@abcfinlab/ui';
import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit, TemplateRef } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { BehaviorSubject, Observable } from 'rxjs';
import { mapConditionToText } from '../../../../../apps/shell/src/app/helper/LeasingQuote.helper';
import { QuoteSummaryCardObligationsComponent } from '../quote-summary-card-obligations/quote-summary-card-obligations.component';

@Component({
    selector: 'l7-quote-summary-card',
    templateUrl: './quote-summary-card.component.html',
    styleUrls: ['./quote-summary-card.component.scss']
})
export class QuoteSummaryCardComponent implements OnInit {
    private readonly _isCreditRatingExpiredSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    @Input() leasingQuote: IInhouseQuoteDto;

    @Input() hasSlbCode: boolean;

    @Input() quoteName: string;
    @Input() hasTransparencyRegister: boolean;

    @Input() headerTemplate: TemplateRef<any>;
    @Input() beforeQuoteTemplate: TemplateRef<any>;

    @Input() flat: boolean;

    @Input() outlined: boolean;

    @Input() hasHeader: boolean;

    @Input() calculation: ICalculationDto;

    @Input() refinancingInterest: IRefinancingInterestDto;

    @Input() creditCheck: ICreditCheckDto;

    @Input() maxColumns: number = 2;

    @Input() minColumns: number = 2;

    contractType = IContractTypeDto;

    public get condition_to_text(): string {
        return mapConditionToText(this.leasingQuote.inhouseQuoteCalculation.objectCondition);
    }

    /**
     * Returns the `isCreditRatingExpired` property.
     *
     * @public
     * @readonly
     */
    public get isCreditRatingExpired(): Observable<boolean> {
        return this._isCreditRatingExpiredSubject.asObservable();
    }

    ngOnInit() {
        if (this.creditCheck) {
            this._isCreditRatingExpiredSubject.next(this.calculateIsCreditRatingExpired(new Date(this.creditCheck.timestamp)));
        }
    }

    /**
     * @private
     */
    private calculateIsCreditRatingExpired(date: Date): boolean {
        const indicator = 12; // the indicator is twelve month.
        const now = new Date(Date.now());
        const offset = monthDiff(date, now);

        return offset >= indicator ? true : false;
    }

    protected readonly IInsuranceTypeDto = IInsuranceTypeDto;
}

@NgModule({
    imports: [
        CommonModule,
        AddressCardModule,
        TranslationModule,
        MatCardModule,
        FlexLayoutModule,
        MatIconModule,
        InsuranceTypeIconPipeModule,
        MatTooltipModule,
        CreditCheckIconPipeModule,
        TextBlockModule
    ],
    declarations: [
        QuoteSummaryCardComponent,
        QuoteSummaryCardObligationsComponent,
    ],
    exports: [
        QuoteSummaryCardComponent,
        QuoteSummaryCardObligationsComponent
    ],
})
export class QuoteSummaryCardModule { }
