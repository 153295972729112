import { IRetailerResponseDto, RetailerAdminService } from '@abcfinlab/api/retailer';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RetailerAdminInfoResolver implements Resolve<IRetailerResponseDto> {

    constructor(
        private readonly _retailerAdminService: RetailerAdminService
    ) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IRetailerResponseDto> {
        return this._retailerAdminService.getRetailerById().pipe(
            catchError(throwError as any)
        );
    }

}
