//#region Imports

import { ContactService, IContactDto, IContactSearchResultEntryDto, IIdTypeDto } from '@abcfinlab/api/contact';
import { FormValidator, once, TranslationFacade } from '@abcfinlab/core';
import { objectToSnake, UiStatesPreservationService } from '@abcfinlab/presentation';
import { BusyBoxService, ToastService } from '@abcfinlab/ui';
import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { SetContactListFromSearchResult } from '../../../../apps/shell/src/app/actions/Contact.actions';
import { KnownSettings } from '../Models/KnownSettings';
import { SearchType } from '../Models/SearchType';

//#endregion

/**
 * The presenter of the {@link SearchContactView} view.
 *
 * @internal
 */
@Injectable()
export class SearchContactViewPresenter {

    //#region Fields

    private readonly _formBuilder: UntypedFormBuilder;
    private readonly _formValidator: FormValidator;
    private readonly _uiStatesPreservationService: UiStatesPreservationService;
    private readonly _activatedRoute: ActivatedRoute;
    private readonly _router: Router;
    private readonly _contactService: ContactService;
    private readonly _store: Store;
    private readonly _busyBoxService: BusyBoxService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _toastService: ToastService;
    private readonly _companyForm: UntypedFormGroup;
    private readonly _contactNumberForm: UntypedFormGroup;
    private readonly _selectedTabSubject: BehaviorSubject<number>;
    private readonly _searchTypeSubject: BehaviorSubject<SearchType | null>;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `SearchContactViewPresenter` class.
     *
     * @public
     */
    public constructor(formBuilder: UntypedFormBuilder, uiStatesPreservationService: UiStatesPreservationService, activatedRoute: ActivatedRoute,
        router: Router, contactService: ContactService, store: Store, busyBoxService: BusyBoxService, translationFacade: TranslationFacade,
        formValidator: FormValidator, toastService: ToastService) {
        this._formBuilder = formBuilder;
        this._uiStatesPreservationService = uiStatesPreservationService;
        this._activatedRoute = activatedRoute;
        this._router = router;
        this._contactService = contactService;
        this._store = store;
        this._busyBoxService = busyBoxService;
        this._translationFacade = translationFacade;
        this._formValidator = formValidator;
        this._toastService = toastService;
        this._selectedTabSubject = new BehaviorSubject(0);
        this._searchTypeSubject = new BehaviorSubject(null);
        this._companyForm = this._formBuilder.group({
            companyName: ['', [
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(100)
            ]],
            city: ['', [
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(40)
            ]],
            country: [{ value: 'Deutschland', disabled: true }, [
                Validators.required
            ]],
        });
        this._contactNumberForm = this._formBuilder.group({
            contactNumber: ['', [
                Validators.required,
                Validators.minLength(8)
            ]]
        });
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `form` property.
     *
     * @public
     * @readonly
     */
    public get form(): Observable<UntypedFormGroup> {
        return this._selectedTabSubject.asObservable().pipe(
            map(x => {
                if (x === 0) {
                    return this._companyForm;
                } else if (x === 1) {
                    return this._contactNumberForm;
                }
            })
        );
    }

    /**
     * Returns the `selectedTab` property.
     *
     * @public
     * @readonly
     */
    public get selectedTab(): Observable<number> {
        return this._selectedTabSubject.asObservable();
    }

    /**
     * Returns the `searchType` property.
     *
     * @public
     * @readonly
     */
    public get searchType(): Observable<SearchType> {
        return this._searchTypeSubject.asObservable();
    }

    //#endregion

    //#region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
        once(this._activatedRoute.data, x => {
            if (!x.searchType) {
                throw new Error('data property is required.');
            }

            this._searchTypeSubject.next(x.searchType);

            const setting = x.searchType === SearchType.Lessee ? KnownSettings.SearchLessee : KnownSettings.SearchVendor;
            this._uiStatesPreservationService.get<{ contactType: IIdTypeDto }>(setting);
        });
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
    }

    /**
     * @internal
     */
    public onTabChanged(index: number): void {
        this._selectedTabSubject.next(index);
    }

    /**
     * @internal
     */
    public onSearch(): void {
        const contactType = this._selectedTabSubject.value === 0 ? IIdTypeDto.Crefo : IIdTypeDto.NavContactNumber;
        const form = this._selectedTabSubject.value === 0 ? this._companyForm : this._contactNumberForm;
        const errors = this._formValidator.errors(form);

        if (errors.length > 0) {
            this._toastService.show('Bitte überprüfen Sie ihre Angaben.', 'danger');
            return;
        }

        this._uiStatesPreservationService.set(this._searchTypeSubject.value === SearchType.Lessee ? KnownSettings.SearchLessee : KnownSettings.SearchVendor, { contactType });

        this.search(contactType).subscribe(x => {
            if (this._searchTypeSubject.value === SearchType.Lessee) {
                void this._router.navigate(['../choose-lessee'], {
                    relativeTo: this._activatedRoute,
                    queryParams: {
                        search: x
                    }
                }).then();
            } else {
                void this._router.navigate(['../add-vendor'], {
                    relativeTo: this._activatedRoute,
                    queryParams: {
                        search: x
                    }
                }).then();
            }
        });
    }

    /**
     * @private
     */
    private search(contactType: IIdTypeDto): Observable<string> {
        const subject = new Subject<string>();
        const subjectDoneFn = (query: Record<string, any>, data?: any) => {
            this.storeSearchResults(data);
            subject.next(btoa(JSON.stringify(query)));
            subject.complete();
        };

        if (contactType === IIdTypeDto.Crefo) {
            const formData = this._companyForm.getRawValue();
            this._busyBoxService.show(null, this._translationFacade.translate('global.busy'), this._contactService.searchCamLegacy(formData)).subscribe(result => {
                subjectDoneFn(formData, result.content);
            });
        } else if (contactType === IIdTypeDto.NavContactNumber) {
            const formData = this._contactNumberForm.getRawValue();
            this._busyBoxService.show(null, this._translationFacade.translate('global.busy'), this._contactService.searchContactNumber(formData)).subscribe(result => {
                subjectDoneFn(formData, result);
            });
        }

        return subject.asObservable();
    }

    /**
     * @private
     */
    private storeSearchResults(data?: IContactSearchResultEntryDto | Array<IContactSearchResultEntryDto>): void {
        const arr = objectToSnake(data ? Array.isArray(data) ? data : [data] : []) as unknown as Array<IContactDto>;
        const result = new SetContactListFromSearchResult({
            search_results: arr
        });

        this._store.dispatch(result);
    }

    //#endregion

}
