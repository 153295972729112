//#region Imports

import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    IContractTypeDto,
    IDownPaymentSettingsDto,
    IInhouseCalculationRequestDto,
    ILeasingQuoteFieldToCalculateDto,
    IQuoteCalculationDto
} from '@abcfinlab/api/global';
import { FormGroup } from '@angular/forms';
import { ControlsOf } from '@abcfinlab/core';
import { BehaviorSubject, Observable } from 'rxjs';

//#endregion

/**
 * The CalculationExtraPaymentsComponent view.
 *
 * @public
 */
@Component({
    selector: 'l7-calculation-extra-payments',
    templateUrl: './CalculationExtraPaymentsComponent.html',
    styleUrls: ['./CalculationExtraPaymentsComponent.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalculationExtraPaymentsComponent implements OnInit, OnDestroy {

    //#region Fields
    private readonly _showExtraFieldsSubject: BehaviorSubject<{
        downPayment: boolean; firstInstalment: boolean; lastInstalment: boolean;
    }> = new BehaviorSubject({ downPayment: false, firstInstalment: false, lastInstalment: false });

    private _valueToCalculate: ILeasingQuoteFieldToCalculateDto;

    /**
     * Returns the showExtraFields property.
     *
     * @public
     * @readonly
     */
    public get showExtraFields(): Observable<{downPayment: boolean; firstInstalment: boolean; lastInstalment: boolean}> {
        return this._showExtraFieldsSubject.asObservable();
    }

    public get inactiveFields(): Array<boolean> {
        return Object.values(this._showExtraFieldsSubject.getValue()).filter(v => !v);
    }

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the calculationExtraPaymentsComponent class.
     *
     * @public
     */
    public constructor() {
    }

    //#endregion

    //#region Properties
    @Input() contractType: IContractTypeDto;
    @Input() form: FormGroup<ControlsOf<IInhouseCalculationRequestDto>>;
    @Input() minResidualValue: number;
    @Input() downPaymentSettings: IDownPaymentSettingsDto;
    @Input() quoteCalculationResponse: IQuoteCalculationDto;
    @Input() set valueToCalculate(value: ILeasingQuoteFieldToCalculateDto) {
        this._valueToCalculate = value;

        if (value && value !== ILeasingQuoteFieldToCalculateDto.LastInstalment && this.form.controls.quoteCalculationRequest.controls.totalLeasingValue.valid) {
            this.form.controls.quoteCalculationRequest.controls.lastInstalmentValue.enable({emitEvent: false});
            this.form.controls.quoteCalculationRequest.controls.lastInstalmentValue.markAsTouched();
            this.form.controls.quoteCalculationRequest.controls.lastInstalmentPercentage.enable({emitEvent: false});
            this.form.controls.quoteCalculationRequest.controls.lastInstalmentPercentage.markAsTouched();
        }

        if (value && value !== ILeasingQuoteFieldToCalculateDto.FirstInstalment && this.form.controls.quoteCalculationRequest.controls.totalLeasingValue.valid) {
            this.form.controls.quoteCalculationRequest.controls.firstInstalmentValue.enable({emitEvent: false});
            this.form.controls.quoteCalculationRequest.controls.firstInstalmentValue.markAsTouched();
            this.form.controls.quoteCalculationRequest.controls.firstInstalmentPercentage.enable({emitEvent: false});
            this.form.controls.quoteCalculationRequest.controls.firstInstalmentPercentage.markAsTouched();
        }
    }
    public get valueToCalculate(): ILeasingQuoteFieldToCalculateDto {
        return this._valueToCalculate;
    }
    @Input() set hasDownPayment(value: boolean) {
        this.form.controls.quoteCalculationRequest.controls.downPaymentValue.enable({emitEvent: false});
        this.form.controls.quoteCalculationRequest.controls.downPaymentPercentage.enable({emitEvent: false});
        if (value) {
            this.addExtraField('downPayment');
        }
    }

    @Input() set hasResidualValue(value: boolean) {
        this.form.controls.quoteCalculationRequest.controls.residualValue.enable({emitEvent: false});
        this.form.controls.quoteCalculationRequest.controls.residualValuePercentage.enable({emitEvent: false});
    }

    @Input() set hasFirstInstalment(value: boolean) {
        this.form.controls.quoteCalculationRequest.controls.firstInstalmentValue.enable({emitEvent: false});
        this.form.controls.quoteCalculationRequest.controls.firstInstalmentPercentage.enable({emitEvent: false});
        if (value) {
            this.addExtraField('firstInstalment');
        }
    }

    @Input() set hasLastInstalment(value: boolean) {
        this.form.controls.quoteCalculationRequest.controls.lastInstalmentValue.enable({emitEvent: false});
        this.form.controls.quoteCalculationRequest.controls.lastInstalmentPercentage.enable({emitEvent: false});
        if (value) {
            this.addExtraField('lastInstalment');
        }
    }
    //#endregion

    //#region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {

    }

    public addExtraField(field: 'downPayment' | 'firstInstalment' | 'lastInstalment'): void {
        const extraFields = this._showExtraFieldsSubject.getValue();

        extraFields[field] = true;
        this._showExtraFieldsSubject.next(extraFields);
    }

    public removeExtraField(field: 'downPayment' | 'firstInstalment' | 'lastInstalment'): void {
        const extraFields = this._showExtraFieldsSubject.getValue();

        extraFields[field] = false;
        this._showExtraFieldsSubject.next(extraFields);
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {

    }

    //#endregion

}
