import { TranslationModule } from '@abcfinlab/core';
import { CameraModule, MailToPipeModule } from '@abcfinlab/presentation';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { FlexModule } from '@ngbracket/ngx-layout';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { SpacerModule, TextBlockModule } from '../../../../../libs/ui/src/Index';
import { ImprintComponent } from '../public/pages/imprint/imprint.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { HeadlineComponent } from './components/headline/headline.component';
import { ProfileMenuComponent } from './components/profile-menu/profile-menu.component';
import { TopBarErrorComponent } from './components/top-bar-error/top-bar-error.component';
import { AddErrorDirective } from './directives/add-error.directive';
import { InputRefDirective } from './directives/input-ref.directive';
import { AuthErrorDialogComponent } from './modals/auth-error-dialog/auth-error-dialog.component';
import { CheckCamModalComponent } from './modals/check-cam-modal/check-cam-modal.component';
import { DeleteConfirmationDialogComponent } from './modals/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { GenericDialogComponent } from './modals/generic-dialog/generic-dialog.component';
import { ServerErrorDialogComponent } from './modals/server-error-dialog/server-error-dialog.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PaddingPipe } from './pipes/padding/padding.pipe';

@NgModule({
    declarations: [
        FooterComponent,
        HeaderComponent,
        ImprintComponent,
        ProfileMenuComponent,
        InputRefDirective,
        HeadlineComponent,
        CheckCamModalComponent,
        AuthErrorDialogComponent,
        ServerErrorDialogComponent,
        TopBarErrorComponent,
        NotFoundComponent,
        DeleteConfirmationDialogComponent,
        GenericDialogComponent,
        PaddingPipe,
        AddErrorDirective
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        InputRefDirective,
        HeadlineComponent,
        TopBarErrorComponent,
        DeleteConfirmationDialogComponent,
        PaddingPipe,
        ClipboardModule,
        AddErrorDirective
    ],
    imports: [
        CommonModule,
        RouterModule,
        FlexModule,
        MatIconModule,
        MatButtonModule,
        MatSnackBarModule,
        MatMenuModule,
        MatInputModule,
        CameraModule,
        MatDialogModule,
        OverlayModule,
        MatListModule,
        ClipboardModule,
        MatBadgeModule,
        MatToolbarModule,
        MatChipsModule,
        MatCardModule,
        NgxMaskDirective,
        NgxMaskPipe,
        MailToPipeModule,
        SpacerModule,
        MatProgressSpinnerModule,
        TextBlockModule,
        TranslationModule
    ],
    providers: [
        provideNgxMask(),
        DatePipe,
        PaddingPipe
    ]
})
export class SharedModule { }
