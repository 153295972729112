//#region Imports

import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input } from '@angular/core';
import { ColorSet } from '../../Color';
import { HostBindingBuilder } from '../../HostBindingBuilder';

//#endregion

/**
 * @public
 */
@Component({
    selector: 'l7-text-block, l7-text',
    templateUrl: './TextBlockComponent.html',
    styleUrls: ['./TextBlockComponent.scss'],
    exportAs: 'l7TextBlock',
    host: {
        '[attr.disabled]': 'disabled ? true : null',
        '[attr.aria-disabled]': 'disabled ? true : null',
        '[attr.role]': 'text'
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextBlockComponent {

    //#region Fields

    private readonly _element: ElementRef;

    private _disabled: boolean;
    private _text: string;
    private _truncate: boolean;
    private _wrap: boolean;
    private _breakLine: boolean;
    private _maxLength: number | null;
    private _maxLines: number | null;
    private _readonly: boolean;
    private _block: boolean;
    private _color: ColorSet;
    private _alignment: 'center' | 'justify' | 'left' | 'right';

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `TextBlockComponent` class.
     *
     * @public
     */
    public constructor(element: ElementRef) {
        this._element = element;

        this._disabled = false;
        this._text = '';
        this._truncate = false;
        this._wrap = false;
        this._breakLine = false;
        this._maxLength = null;
        this._maxLines = null;
        this._readonly = false;
        this._block = true;
        this._color = 'neutral';
        this._alignment = 'left';
    }

    //#endregion

    //#region Properties

    /**
     * Gets or sets the color set type value. This will applied as dom class property.
     *
     * @public
     * @type ColorSet | string
     * @default ColorSet.Default
     */
    @Input()
    public get color(): ColorSet | string {
        return this._color;
    }
    public set color(value: ColorSet | string) {
        this._color = value as ColorSet;
    }

    /**
     * Gets or sets a value indicating whether the {@link TextBlockComponent} is disabled in the user interface(UI).
     * Sets the dom class property and the aria attribute as true value, otherwise nothing.
     *
     * @public
     * @type boolean
     * @default false
     */
    @Input()
    @HostBinding('class.disabled')
    public get disabled(): boolean {
        return this._disabled;
    }
    public set disabled(value: boolean) {
        this._disabled = value;
    }

    /**
     * Gets or sets the `text` property.
     *
     * @type string
     * @default ''
     */
    @Input()
    public get text(): string {
        return this._text;
    }
    public set text(value: string) {
        this._text = value;
    }

    /**
     * Gets or sets the `truncate` property.
     * Gets or sets the `truncate` property.
     * @type boolean
     * @default false
     */
    @Input()
    public get truncate(): boolean {
        return this._truncate;
    }
    public set truncate(value: boolean) {
        this._truncate = value;
    }

    /**
     * Gets or sets the `wrap` property.
     *
     * @type boolean
     * @default false
     */
    @Input()
    public get wrap(): boolean {
        return this._wrap;
    }
    public set wrap(value: boolean) {
        this._wrap = value;
    }

    /**
     * Gets or sets the `breakLine` property.
     *
     * @type boolean
     * @default false
     */
    @Input()
    public get breakLine(): boolean {
        return this._breakLine;
    }
    public set breakLine(value: boolean) {
        this._breakLine = value;
    }

    /**
     * Gets or sets the `maxLength` property.
     *
     * @type number
     * @default null
     */
    @Input()
    public get maxLength(): number | null {
        return this._maxLength;
    }
    public set maxLength(value: number | null) {
        this._maxLength = value;
    }

    /**
     * Gets or sets the `maxLines` property.
     *
     * @public
     * @default null
     */
    @Input()
    public get maxLines(): number | null {
        return this._maxLines;
    }
    public set maxLines(value: number | null) {
        this._maxLines = value;
    }

    /**
     * Gets or sets the `readonly` property.
     *
     * @public
     * @default false
     */
    @Input()
    @HostBinding('class.readonly')
    public get readonly(): boolean {
        return this._readonly;
    }
    public set readonly(value: boolean) {
        this._readonly = value;
    }

    /**
     * Gets or sets the `block` property.
     *
     * @public
     */
    @Input()
    @HostBinding('class.block')
    public get block(): boolean {
        return this._block;
    }
    public set block(value: boolean) {
        this._block = value;
    }

    /**
     * Gets or sets the `alignment` property.
     *
     * @public
     */
    @Input()
    public get alignment(): 'center' | 'justify' | 'left' | 'right' {
        return this._alignment;
    }
    public set alignment(value: 'center' | 'justify' | 'left' | 'right') {
        this._alignment = value;
    }

    /**
     * Returns a boolean that indecates whether the `TextBlockComponent` has a overflow or not.
     *
     * @public
     * @readonly
     */
    public get hasOverflow(): boolean {
        return this._element.nativeElement.offsetWidth < this._element.nativeElement.scrollWidth;
    }

    public get isTruncated(): boolean {
        return this._element.nativeElement.scrollWidth > this._element.nativeElement.clientWidth;
    }

    /**
     * Returns the `classes` property.
     *
     * @public
     * @readonly
     */
    @HostBinding('class')
    public get classes(): string | undefined {
        return HostBindingBuilder.buildClasses({
            ['truncate']: this._truncate,
            ['wrap']: this._wrap,
            ['disabled']: this._disabled,
            [`${this._color}`]: true
        });
    }

    /**
     * Returns the `classes` property.
     *
     * @public
     * @readonly
     */
    @HostBinding('style')
    public get styles(): string | undefined {
        const s = HostBindingBuilder.buildStyles({
            ['-webkit-line-clamp']: this._maxLines ?? '',
            ['text-align']: this._alignment,
        });

        return s;
    }

    //#endregion

    //#region Methods

    /**
     * Trim the `text` by the given `maxLength`.
     *
     * @internal
     */
    public trim(): string {
        return this._maxLength === undefined || this._maxLength === null
            ? this._text
            : this._text.length <= this._maxLength
                ? this._text
                : this._text.substr(0, this._maxLength).trim();
    }

    //#endregion

}
