//#region Imports

import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { IPresenterService } from '../../../Services/Interfaces/IPresenterService';
import { PresenterService } from '../../../Services/PresenterService';
import { IPageModuleConfig } from './IPageModuleConfig';
import { PAGE_MODULE_CONFIG } from './PageModuleTokens';

//#endregion

/**
 * The `PageHeaderComponent` component.
 *
 * @public
 */
@Component({
    selector: 'l7-page-header',
    templateUrl: 'PageHeaderComponent.html',
    styleUrls: ['PageHeaderComponent.scss'],
    exportAs: 'l7PageHeader',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageHeaderComponent {

    //#region Fields

    private readonly _location: Location;
    private readonly _presenterService: IPresenterService;
    private readonly _navigated: EventEmitter<{ cancel: boolean }>;

    private _title: string;
    private _subTitle: string;
    private _supportiveTitle: string;
    private _hasBackNavigation: boolean;
    private _cancelNavigation: boolean;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `PageHeaderComponent` class.
     *
     * @public
     */
    public constructor(location: Location, presenterService: PresenterService, @Inject(PAGE_MODULE_CONFIG) moduleConfig: IPageModuleConfig) {
        this._location = location;
        this._presenterService = presenterService;

        this._title = '';
        this._subTitle = '';
        this._supportiveTitle = '';
        this._hasBackNavigation = moduleConfig.hasBackNavigation ?? true;
        this._navigated = new EventEmitter();
    }

    //#endregion

    //#region Properties

    /**
     * Gets or sets the `title` property.
     *
     * @public
     */
    @Input()
    public get title(): string {
        return this._title;
    }
    public set title(value: string) {
        this._title = value;
        this._presenterService.setTitle(this._title);
    }

    /**
     * Gets or sets the `subTitle` property.
     *
     * @public
     */
    @Input()
    public get subTitle(): string {
        return this._subTitle;
    }
    public set subTitle(value: string) {
        this._subTitle = value;
    }

    /**
     * Gets or sets the `supportiveTitle` property.
     *
     * @public
     */
    @Input()
    public get supportiveTitle(): string {
        return this._supportiveTitle;
    }
    public set supportiveTitle(value: string) {
        this._supportiveTitle = value;
    }

    /**
     * Gets or sets the `hasBackNavigation` property.
     *
     * @public
     */
    @Input()
    public get hasBackNavigation(): boolean {
        return this._hasBackNavigation;
    }
    public set hasBackNavigation(value: boolean) {
        this._hasBackNavigation = value;
    }

    /**
     * Gets or sets the `cancelNavigation` property.
     *
     * @public
     */
    @Input()
    public get cancelNavigation(): boolean {
        return this._cancelNavigation;
    }
    public set cancelNavigation(value: boolean) {
        this._cancelNavigation = value;
    }

    /**
     * Called when <ACTION>.
     * Provides reference to `{cancel: boolean}` as event argument.
     *
     * @public
     * @readonly
     * @eventProperty
     * @type EventEmitter<{cancel: boolean}>
     */
    @Output()
    public get navigated(): EventEmitter<{ cancel: boolean }> {
        return this._navigated;
    }

    //#endregion

    //#region Methods

    /**
     * @internal
     */
    public onNavigate(): void {
        const args = { cancel: this._cancelNavigation };
        this._navigated.emit(args);

        if (!args.cancel) {
            this._location.historyGo(-1);
        }
    }

    //#endregion

}
