//#region Imports

import { Injectable } from '@angular/core';
import { AppConfig } from '../Configuration/AppConfig/AppConfig';

//#endregion

interface IFeature {
    name: string;
    active: boolean;
    options: [key: string];
}

/**
 * The `FeatureManager` class.
 *
 * @public
 */
@Injectable({ providedIn: 'root' })
export class FeatureManager {

    //#region Fields

    private readonly _features: Array<IFeature>;
    private readonly _stage: 'local' | 'test' | 'acc' | 'demo' | 'prod';

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `FeatureManager` class.
     *
     * @public
     */
    public constructor(appConfig: AppConfig) {
        this._features = [
            ...Object.entries(appConfig.get<Array<IFeature>>('features')).map(([k, v]) => ({
                name: k,
                active: v.active ?? null,
                options: v.options ?? null
            })),
            ...JSON.parse(localStorage.getItem('features')) ?? [] as Array<IFeature>
        ];

        this._stage = appConfig.get('stage');
    }

    //#endregion

    //#region Methods

    public stage(): 'local' | 'test' | 'acc' | 'demo' | 'prod' {
        return this._stage;
    }

    public active(featureName: string, isFeatureOptional: boolean = true): boolean {
        const feature = this._features.find(x => x.name === featureName);
        if (feature) {
            return feature.active || false;
        }

        if (!isFeatureOptional) {
            throw new Error(`Invalid feature name '${featureName}'.`);
        }

        return false;
    }

    public option(featureName: string, featureOption: string, isFeatureOptional: boolean = false): any {
        const feature = this._features.find(x => x.name === featureName);
        if (feature) {
            return feature.options[featureOption];
        }

        if (!isFeatureOptional) {
            throw new Error(`Invalid feature name '${featureName}'.`);
        }
    }

    //#endregion

}
