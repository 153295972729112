import { Route } from '@angular/router';
import {
    CONTRACT_MANAGEMENT_RETAILER_CONTRACT_DETAILS_ROUTE_PATH
} from './RoutePaths';
import {
    RetailerContractDetailsView
} from '../Views/retailer-contract-managment/retailer-contract-details-view/RetailerContractDetailsView';
import { RetailerContractDetailsResolver } from '../Resolvers/RetailerContractDetailsResolver';
import { AuthGuard } from '../../../../apps/shell/src/app/shared/guards/auth.guard';

/**
 * @internal
 */
export const CONTRACT_MANAGEMENT_RETAILER_CONTRACT_DETAILS_ROUTE: Route = {
    path: `${CONTRACT_MANAGEMENT_RETAILER_CONTRACT_DETAILS_ROUTE_PATH}/:id`,
    component: RetailerContractDetailsView,
    resolve: {
        contractDetails: RetailerContractDetailsResolver
    },
    canActivate: [AuthGuard]
};
