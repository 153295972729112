// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  margin-top: 32px;
}
:host legend {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: #83929b;
}
:host fieldset {
  margin-bottom: 16px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIkNvbnRyYWN0T3ZlcnZpZXdEZXRhaWxzVmlldy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksZ0JBQUE7QUFDSjtBQUNJO0VBQ0ksZUFBQTtFQUNBLGlCQUFBO0VBQ0EsaUJBQUE7RUFDQSxjQUFBO0FBQ1I7QUFFSTtFQUNJLG1CQUFBO0FBQVIiLCJmaWxlIjoiQ29udHJhY3RPdmVydmlld0RldGFpbHNWaWV3LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XG4gICAgbWFyZ2luLXRvcDogMzJweDtcblxuICAgIGxlZ2VuZCB7XG4gICAgICAgIGZvbnQtc2l6ZTogMTRweDtcbiAgICAgICAgbGluZS1oZWlnaHQ6IDIwcHg7XG4gICAgICAgIGZvbnQtd2VpZ2h0OiBib2xkO1xuICAgICAgICBjb2xvcjogIzgzOTI5YjtcbiAgICB9XG5cbiAgICBmaWVsZHNldCB7XG4gICAgICAgIG1hcmdpbi1ib3R0b206IDE2cHg7XG4gICAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/contractManagement/src/Views/ContractOverviewDetailsView.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AACI;EACI,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,cAAA;AACR;AAEI;EACI,mBAAA;AAAR;AACA,4pBAA4pB","sourcesContent":[":host {\n    margin-top: 32px;\n\n    legend {\n        font-size: 14px;\n        line-height: 20px;\n        font-weight: bold;\n        color: #83929b;\n    }\n\n    fieldset {\n        margin-bottom: 16px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
