// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .mat-mdc-checkbox {
  margin-left: 15px;
  margin-bottom: 10px;
  height: 35px;
}
:host form {
  padding-right: 16px;
  overflow: hidden;
  overflow-y: scroll;
  height: 70vh !important;
}
:host form .button-blue {
  margin-bottom: 15px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFja25vd2xlZGdlbWVudC1mb3JtLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNJO0VBQ0ksaUJBQUE7RUFDQSxtQkFBQTtFQUNBLFlBQUE7QUFBUjtBQUdJO0VBQ0ksbUJBQUE7RUFDQSxnQkFBQTtFQUNBLGtCQUFBO0VBQ0EsdUJBQUE7QUFEUjtBQUVRO0VBQ0ksbUJBQUE7QUFBWiIsImZpbGUiOiJhY2tub3dsZWRnZW1lbnQtZm9ybS5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgICAubWF0LW1kYy1jaGVja2JveCB7XG4gICAgICAgIG1hcmdpbi1sZWZ0OiAxNXB4O1xuICAgICAgICBtYXJnaW4tYm90dG9tOiAxMHB4O1xuICAgICAgICBoZWlnaHQ6IDM1cHg7XG4gICAgfVxuXG4gICAgZm9ybSB7XG4gICAgICAgIHBhZGRpbmctcmlnaHQ6IDE2cHg7XG4gICAgICAgIG92ZXJmbG93OiBoaWRkZW47XG4gICAgICAgIG92ZXJmbG93LXk6IHNjcm9sbDtcbiAgICAgICAgaGVpZ2h0OiA3MHZoICFpbXBvcnRhbnQ7XG4gICAgICAgIC5idXR0b24tYmx1ZSB7XG4gICAgICAgICAgICBtYXJnaW4tYm90dG9tOiAxNXB4O1xuICAgICAgICB9XG4gICAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/backoffice/src/Views/acknowledgement-form.component.scss"],"names":[],"mappings":"AACI;EACI,iBAAA;EACA,mBAAA;EACA,YAAA;AAAR;AAGI;EACI,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,uBAAA;AADR;AAEQ;EACI,mBAAA;AAAZ;AACA,41BAA41B","sourcesContent":[":host {\n    .mat-mdc-checkbox {\n        margin-left: 15px;\n        margin-bottom: 10px;\n        height: 35px;\n    }\n\n    form {\n        padding-right: 16px;\n        overflow: hidden;\n        overflow-y: scroll;\n        height: 70vh !important;\n        .button-blue {\n            margin-bottom: 15px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
