// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-list-item {
  background: #ffffff;
}
.mat-mdc-list-item .lesseeInfo {
  color: #cccccc;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInJldGFpbGVyLWxlc3NlZXMtb3ZlcnZpZXcuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxtQkFBQTtBQUNKO0FBQ0E7RUFDSSxjQUFBO0FBQ0oiLCJmaWxlIjoicmV0YWlsZXItbGVzc2Vlcy1vdmVydmlldy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5tYXQtbWRjLWxpc3QtaXRlbSB7XG4gICAgYmFja2dyb3VuZDogI2ZmZmZmZjtcblxuLmxlc3NlZUluZm8ge1xuICAgIGNvbG9yOiAjY2NjY2NjO1xufVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/quote/src/Views/retailer-lessees-overview/retailer-lessees-overview.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;AACA;EACI,cAAA;AACJ;AACA,gcAAgc","sourcesContent":[".mat-mdc-list-item {\n    background: #ffffff;\n\n.lesseeInfo {\n    color: #cccccc;\n}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
