//#region Imports

import { BeneficialOwnerService, IContactDto } from '@abcfinlab/api/contact';
import { ILegalPersonalityDto } from '@abcfinlab/api/global';
import { FeatureManager, subscribeOnce } from '@abcfinlab/core';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { BeneficialOwnerConfirmDialogView } from '../Views/Dialogs/BeneficialOwnerConfirmDialogView';

//#endregion

/**
 * The `BeneficialOwnerManager` class.
 *
 * @public
 */
@Injectable({ providedIn: 'root' })
export class BeneficialOwnerManager {

    //#region Fields

    private readonly _dialog: MatDialog;
    private readonly _featureManager: FeatureManager;
    private readonly _beneficialOwnerService: BeneficialOwnerService;

    private _dialogRef: MatDialogRef<BeneficialOwnerConfirmDialogView>;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `BeneficialOwnerManager` class.
     *
     * @public
     */
    public constructor(dialog: MatDialog, featureManager: FeatureManager, beneficialOwnerService: BeneficialOwnerService) {
        this._dialog = dialog;
        this._featureManager = featureManager;
        this._beneficialOwnerService = beneficialOwnerService;
    }

    //#endregion

    //#region Properties
    //#endregion

    //#region Methods

    /**
     * @public
     */
    public confirm(contact: IContactDto): Observable<boolean> {
        const subject = new Subject<boolean>();

        (combineLatest([
            of(contact.legal_personality === ILegalPersonalityDto.J),
            of(this._featureManager.active('beneficialOwner'))
        ]).pipe(
            map(x => (x as Array<boolean>).every(y => y))
        )).subscribe(fulfilld => {
            if (fulfilld) {
                subscribeOnce(this._beneficialOwnerService.search({ crefoId: contact.crefo_id }), beneficialOwners => {
                    if (!this._dialogRef) {
                        this._dialogRef = this._dialog.open(BeneficialOwnerConfirmDialogView, {
                            id: 'BeneficialOwnerConfirmDialogView',
                            disableClose: true,
                            closeOnNavigation: true,
                            height: '320px',
                            width: '680px',
                            data: {
                                persons: beneficialOwners,
                            }
                        });
                        subscribeOnce(this._dialogRef.afterClosed(), x => {
                            this._dialogRef = null;
                            subject.next(x);
                            subject.complete();
                        });
                    }
                });
            } else {
                subscribeOnce(of(true).pipe(delay(0)), _ => {
                    subject.next(true);
                    subject.complete();
                });
            }
        });

        return subject.asObservable();
    }

    //#endregion

}
