// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}
:host.sticky {
  position: sticky;
  top: 0;
  z-index: 2;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIlBhZ2VQcmVDb250ZW50Q29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxjQUFBO0FBQ0o7QUFDSTtFQUNJLGdCQUFBO0VBQ0EsTUFBQTtFQUNBLFVBQUE7QUFDUiIsImZpbGUiOiJQYWdlUHJlQ29udGVudENvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICAgIGRpc3BsYXk6IGJsb2NrO1xuXG4gICAgJi5zdGlja3kge1xuICAgICAgICBwb3NpdGlvbjogc3RpY2t5O1xuICAgICAgICB0b3A6IDA7XG4gICAgICAgIHotaW5kZXg6IDI7XG4gICAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/layout/src/Controls/Components/Page/PagePreContentComponent.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;AACI;EACI,gBAAA;EACA,MAAA;EACA,UAAA;AACR;AACA,oeAAoe","sourcesContent":[":host {\n    display: block;\n\n    &.sticky {\n        position: sticky;\n        top: 0;\n        z-index: 2;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
