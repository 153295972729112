// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host h2 {
  color: #5e6f79;
  text-align: left;
  font-size: 18px;
}
:host p {
  margin-top: 10px;
}
:host .mat-mdc-dialog-content {
  text-align: justify;
}
:host .mat-mdc-dialog-actions {
  justify-content: flex-end;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInB1cmNoYXNlLWVudHJ5LW9iamVjdC12YWx1ZS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFSTtFQUNJLGNBSEs7RUFJTCxnQkFBQTtFQUNBLGVBQUE7QUFEUjtBQUlJO0VBQ0ksZ0JBQUE7QUFGUjtBQUtJO0VBQ0ksbUJBQUE7QUFIUjtBQU1JO0VBQ0kseUJBQUE7QUFKUiIsImZpbGUiOiJwdXJjaGFzZS1lbnRyeS1vYmplY3QtdmFsdWUuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIkc2xhdGUtZ3JleTogIzVlNmY3OTtcbjpob3N0IHtcbiAgICBoMiB7XG4gICAgICAgIGNvbG9yOiAkc2xhdGUtZ3JleTtcbiAgICAgICAgdGV4dC1hbGlnbjogbGVmdDtcbiAgICAgICAgZm9udC1zaXplOiAxOHB4O1xuICAgIH1cblxuICAgIHAge1xuICAgICAgICBtYXJnaW4tdG9wOiAxMHB4O1xuICAgIH1cblxuICAgIC5tYXQtbWRjLWRpYWxvZy1jb250ZW50IHtcbiAgICAgICAgdGV4dC1hbGlnbjoganVzdGlmeTtcbiAgICB9XG5cbiAgICAubWF0LW1kYy1kaWFsb2ctYWN0aW9ucyB7XG4gICAgICAgIGp1c3RpZnktY29udGVudDogZmxleC1lbmQ7XG4gICAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/contractManagement/src/Views/Dialogs/purchase-entry-object-value/purchase-entry-object-value.component.scss"],"names":[],"mappings":"AAEI;EACI,cAHK;EAIL,gBAAA;EACA,eAAA;AADR;AAII;EACI,gBAAA;AAFR;AAKI;EACI,mBAAA;AAHR;AAMI;EACI,yBAAA;AAJR;AACA,g1BAAg1B","sourcesContent":["$slate-grey: #5e6f79;\n:host {\n    h2 {\n        color: $slate-grey;\n        text-align: left;\n        font-size: 18px;\n    }\n\n    p {\n        margin-top: 10px;\n    }\n\n    .mat-mdc-dialog-content {\n        text-align: justify;\n    }\n\n    .mat-mdc-dialog-actions {\n        justify-content: flex-end;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
