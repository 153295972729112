// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-card-header .mat-mdc-card-title {
  display: flex;
}
.mat-mdc-card-header .mat-mdc-card-title .mat-icon {
  margin-left: 16px;
}

.more-button {
  font-size: 16px;
  color: #2b7e95;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImJlbmVmaWNpYWwtb3duZXJzLXZpZXcuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0k7RUFDSSxhQUFBO0FBQVI7QUFFUTtFQUNJLGlCQUFBO0FBQVo7O0FBSUE7RUFDSSxlQUFBO0VBQ0EsY0FBQTtBQURKIiwiZmlsZSI6ImJlbmVmaWNpYWwtb3duZXJzLXZpZXcuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIubWF0LW1kYy1jYXJkLWhlYWRlciB7XG4gICAgLm1hdC1tZGMtY2FyZC10aXRsZSB7XG4gICAgICAgIGRpc3BsYXk6IGZsZXg7XG5cbiAgICAgICAgLm1hdC1pY29uIHtcbiAgICAgICAgICAgIG1hcmdpbi1sZWZ0OiAxNnB4O1xuICAgICAgICB9XG4gICAgfVxufVxuLm1vcmUtYnV0dG9uIHtcbiAgICBmb250LXNpemU6IDE2cHg7XG4gICAgY29sb3I6ICMyYjdlOTU7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/contacts/src/Views/beneficial-owners-view/beneficial-owners-view.component.scss"],"names":[],"mappings":"AACI;EACI,aAAA;AAAR;AAEQ;EACI,iBAAA;AAAZ;;AAIA;EACI,eAAA;EACA,cAAA;AADJ;AACA,ooBAAooB","sourcesContent":[".mat-mdc-card-header {\n    .mat-mdc-card-title {\n        display: flex;\n\n        .mat-icon {\n            margin-left: 16px;\n        }\n    }\n}\n.more-button {\n    font-size: 16px;\n    color: #2b7e95;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
