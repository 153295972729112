// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: relative;
  height: 100%;
  width: 100%;
  top: 0;
}
:host .login-error-container {
  text-align: center;
}
:host .svg-container {
  width: 100%;
  height: 100%;
  position: relative;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIkF1dGhvcml6ZUNhbGxiYWNrVmlldy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksa0JBQUE7RUFDQSxZQUFBO0VBQ0EsV0FBQTtFQUNBLE1BQUE7QUFDSjtBQUNJO0VBQ0ksa0JBQUE7QUFDUjtBQUVJO0VBQ0ksV0FBQTtFQUNBLFlBQUE7RUFDQSxrQkFBQTtBQUFSIiwiZmlsZSI6IkF1dGhvcml6ZUNhbGxiYWNrVmlldy5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICAgIHBvc2l0aW9uOiByZWxhdGl2ZTtcbiAgICBoZWlnaHQ6IDEwMCU7XG4gICAgd2lkdGg6IDEwMCU7XG4gICAgdG9wOiAwO1xuXG4gICAgLmxvZ2luLWVycm9yLWNvbnRhaW5lciB7XG4gICAgICAgIHRleHQtYWxpZ246IGNlbnRlcjtcbiAgICB9XG5cbiAgICAuc3ZnLWNvbnRhaW5lciB7XG4gICAgICAgIHdpZHRoOiAxMDAlO1xuICAgICAgICBoZWlnaHQ6IDEwMCU7XG4gICAgICAgIHBvc2l0aW9uOiByZWxhdGl2ZTtcbiAgICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/auth/src/Views/AuthorizeCallbackView.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,YAAA;EACA,WAAA;EACA,MAAA;AACJ;AACI;EACI,kBAAA;AACR;AAEI;EACI,WAAA;EACA,YAAA;EACA,kBAAA;AAAR;AACA,4tBAA4tB","sourcesContent":[":host {\n    position: relative;\n    height: 100%;\n    width: 100%;\n    top: 0;\n\n    .login-error-container {\n        text-align: center;\n    }\n\n    .svg-container {\n        width: 100%;\n        height: 100%;\n        position: relative;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
