//#region Imports

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { toDecimal } from '../../../../apps/shell/src/app/helper/calculation.helper';

//#endregion

export namespace Validators {

    export function isNumberInRange(minValue: number, maxValue: number, factor?: number): ValidatorFn {
        const fn = (control: AbstractControl): ValidationErrors | null => {
            if (!control) {
                return null;
            }

            if (control.value === null || control.value === undefined || control.value === '') {
                return null;
            }

            const value = control.value / (factor || 1);

            if (value < minValue || value > maxValue) {
                return {
                    isNumberInRange: true,
                    min: minValue,
                    max: maxValue
                };
            }

            return null;
        };

        return fn;
    }

    export function allowedValuesRelatedToLeasingValue(maxValue: number, minValue: number, controlName: string): ValidatorFn {
        const fn = (control: AbstractControl): ValidationErrors | null => {
            if (!control || !control.parent) {
                return null;
            }

            const totalValue = control.parent.get('totalLeasingValue')?.value;
            const controlValue = control.parent.get(controlName)?.value;
            const maxAllowedValue = toDecimal(((maxValue) * totalValue), 2);
            const minAllowedValue = toDecimal(((minValue) * totalValue), 2);

            if (!totalValue) {
                return null;
            }

            if (controlValue < minAllowedValue || controlValue > maxAllowedValue) {
                return {
                    allowedValuesRelatedToLeasingValue: {
                        minAllowedValue: minAllowedValue,
                        maxAllowedValue: maxAllowedValue
                    },

                };
            }

            return null;
        };

        return fn;
    }

    export function allowedRange(maxValue: number, minValue: number): ValidatorFn {
        const fn = (control: AbstractControl): ValidationErrors | null => {
            if (!control || !control.parent) {
                return null;
            }

            const value = control?.value;

            if (value < minValue || value > maxValue) {
                return { allowedRange: true };
            }

            return null;
        };

        return fn;
    }

}
