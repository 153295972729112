// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .mat-mdc-tooltip.allow-cr {
  font-size: 12px;
  white-space: pre-wrap;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIlNldHRsZW1lbnRTdGF0ZXNWaWV3LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0k7RUFDSSxlQUFBO0VBQ0EscUJBQUE7QUFBUiIsImZpbGUiOiJTZXR0bGVtZW50U3RhdGVzVmlldy5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOjpuZy1kZWVwIC5tYXQtbWRjLXRvb2x0aXAge1xuICAgICYuYWxsb3ctY3Ige1xuICAgICAgICBmb250LXNpemU6IDEycHg7XG4gICAgICAgIHdoaXRlLXNwYWNlOiBwcmUtd3JhcDtcbiAgICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/backoffice/src/Views/SettlementStatesView.scss"],"names":[],"mappings":"AACI;EACI,eAAA;EACA,qBAAA;AAAR;AACA,4aAA4a","sourcesContent":["::ng-deep .mat-mdc-tooltip {\n    &.allow-cr {\n        font-size: 12px;\n        white-space: pre-wrap;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
