//#region Imports

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../Services/AuthService';

//#endregion

/**
 * @public
 */
@Injectable()
export class AuthGuard2 implements CanActivate {

    //#region Fields

    private _authService: AuthService;
    private _router: Router;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `AuthGuard` class.
     *
     * @public
     */
    public constructor(authService: AuthService, router: Router) {
        this._authService = authService;
        this._router = router;
    }

    //#endregion

    //#region Methods

    /**
     * Protects the 'private'-Route from being access by a non authorized user
     *
     * @public
     */
    public canActivate(_: ActivatedRouteSnapshot, __: RouterStateSnapshot): Observable<boolean> {
        return this._authService.hasSession.pipe(
            map(x => {
                if (x) {
                    return x;
                } else {
                    this._authService.getSession().pipe(
                        map(() => {
                            return of(true);
                        }),
                        catchError(() => {
                            this._authService.removeSession();

                            // this._router.navigate([''], { queryParams: { returnUrl: state.url } }).then();
                            return of(false);
                        })
                    );
                }
            })
        );
    }

    //#endregion
}
