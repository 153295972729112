/**
 * @internal
 */
export namespace KnownEvents {
    export const CONTRACT_OVERVIEW_ROW_CHANGED = 'ContractOverviewRowChanged';
    export const INSTALMENT_CHANGED_IN_RECALCULATION = 'InstalmentChangedInRecalculation';
    export const DOCUMENT_DELETED = 'DocumentDeleted';
    export const CONTRACT_QUOTE_DETAILS = 'ContractQuoteDetails';
    export const CREDIT_CHECK = 'CreditCheck';
}
