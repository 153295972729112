import { CameraService } from '@abcfinlab/presentation';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { RoutingstateService } from '../../../../../../../apps/shell/src/app/shared/services/routingstate/routingstate.service';
import { environment } from '../../../../../../../apps/shell/src/environments/environment';

@UntilDestroy()
@Component({
  selector: 'gwg-identification-container',
  templateUrl: './identification-container.component.html',
  styleUrls: ['./identification-container.component.scss']
})
export class IdentificationContainerComponent implements OnInit {
  isFeatureUploadFileActive: boolean = environment.features.upload_file.active;
  cameraEnabled: boolean = false;
  text: string = 'Vorderseite';
  textEmitter$ = new BehaviorSubject<string>(this.text);
  selectedTab: number;

  constructor(
    private readonly routingState: RoutingstateService,
    private readonly route: ActivatedRoute,
    private readonly cameraService: CameraService,
    private readonly cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.cameraService.cameraCanBeUsed$
      .pipe(untilDestroyed(this))
      .subscribe(camActive => {
        this.cameraEnabled = camActive;
        this.cdr.detectChanges();
      });

    const previousRoute: string = this.routingState.getPreviousUrl();
    const isCameraFlow: boolean = previousRoute.includes('(tab2:verification)');
    this.selectedTab = isCameraFlow ? 1 : 0;

    this.route.data
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        if (data.title) {
          this.textEmitter$.next(data.title);
          this.cdr.detectChanges();
        }
      });
  }
}
