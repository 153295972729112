import {
    Injector, NgModule,
    Pipe,
    PipeTransform
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

@Pipe({
    name: 'arrayFilter'
})
export class ArrayFilterPipe implements PipeTransform {

    public constructor() {
    }

    transform(value: Array<any>, prop: string, criteria: string, term: any): any {
        const _value = value.filter(x => x !== null);
        if (Array.isArray(term)) {
            return _value.filter(_x => term.includes(_x[prop]));
        }
        switch (criteria) {
            case 'eq':
                return _value.filter(_x => _x[prop] === term);
            case 'neq':
                return _value.filter(_x => _x[prop] !== term);
            case 'lt':
                return _value.filter(_x => _x[prop] < term);
            case 'gt':
                return _value.filter(_x => _x[prop] > term);
            case 'lte':
                return _value.filter(_x => _x[prop] <= term);
            case 'gte':
                return _value.filter(_x => _x[prop] >= term);
        }
    }
}

/**
 * The `ArrayFilterPipeModule` provides the {@link ArrayFilterPipe}.
 * @public
 */
@NgModule({
    declarations: [
        ArrayFilterPipe
    ],
    imports: [
        BrowserModule
    ],
    exports: [
        ArrayFilterPipe
    ]
})
export class ArrayFilterPipeModule {
}
