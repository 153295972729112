//#region Imports

import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

//#endregion

/**
 * The `nameComponent` .
 *
 * @public
 */
@Component({
    selector: 'l7-virtualized, [l7-virtualized]',
    templateUrl: './VirtualizedContainerComponent.html',
    exportAs: 'virtualized',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VirtualizedContainerComponent implements AfterViewInit {

    //#region Fields

    private readonly _isConnected: Subject<boolean>;

    //#endregion

    //#region Ctor

    public constructor() {
        this._isConnected = new BehaviorSubject(false);
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `isConnected` property.
     *
     * @public
     * @readonly
     */
    public get isConnected(): Observable<boolean> {
        return this._isConnected;
    }

    //#endregion

    //#region Mehtods

    public ngAfterViewInit(): void {
        this._isConnected.next(true);
    }

    //#endregion

}
