//#region Imports

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { VirtualizedContainerComponent } from './VirtualizedContainerComponent';

//#endregion

/**
 * The `VirtualizedContainer` module.
 *
 * @public
 */
@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        VirtualizedContainerComponent
    ],
    exports: [
        VirtualizedContainerComponent
    ],
    // providers: [],
})
export class VirtualizedContainerModule { }
