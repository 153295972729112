//#region Imports

import {ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { EditRetailerViewPresenter } from './EditRetailerViewPresenter';

//#endregion

/**
 * The `EditRetailerView` view.
 *
 * @public
 */
@Component({
    selector: 'edit-retailer-view',
    templateUrl: './EditRetailerView.html',
    styleUrls: ['./EditRetailerView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        EditRetailerViewPresenter
    ]
})
export class EditRetailerView implements OnInit, OnDestroy {

    //#region Fields

    private readonly _presenter: EditRetailerViewPresenter;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `EditRetailerView` class.
     *
     * @public
     */
    public constructor(presenter: EditRetailerViewPresenter) {
        this._presenter = presenter;
    }

    //#endregion

    //#region Proeprties

    /**
     * Returns the presenter of the `EditRetailerView`
     *
     * @public
     * @readonly
     */
    public get presenter(): EditRetailerViewPresenter {
        return this._presenter;
    }

    //#endregion

    //#region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize();
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    //#endregion

}
