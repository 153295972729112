//#region Imports

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { DashboardViewPresenter } from './DashboardViewPresenter';

//#endregion

/**
 * The `DashboardView` view.
 *
 * @public
 */
@Component({
    selector: 'l7-dashboard',
    templateUrl: './DashboardView.html',
    styleUrls: ['./DashboardView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        DashboardViewPresenter
    ]
})
export class DashboardView implements OnInit, OnDestroy {

    //#region Fields

    private readonly _presenter: DashboardViewPresenter;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `DashboardView` class.
     *
     * @public
     */
    public constructor(presenter: DashboardViewPresenter) {
        this._presenter = presenter;
    }

    //#endregion

    //#region Proeprties

    /**
     * Returns the presenter of the `DashboardView`
     *
     * @public
     * @readonly
     */
    public get presenter(): DashboardViewPresenter {
        return this._presenter;
    }

    //#endregion

    //#region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize();
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    //#endregion

}
