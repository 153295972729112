import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { ResetRemoteState } from '../../../../../../../apps/shell/src/app/actions/Remote.actions';
import { SuccessIds } from '../success/success.component';

@UntilDestroy()
@Component({
  selector: 'gwg-success-remote-container',
  template: `
    <div class="page-container">
        <gwg-success [id]="remoteId" [bodyText]="successText"></gwg-success>
    </div>
  `,
  styles: [
    `
                                                                              `
  ]
})
export class SuccessRemoteContainerComponent implements OnInit {
  remoteId = SuccessIds.REMOTE;
  successText: string = 'gwg.success.body_remote';

  constructor(private readonly _store: Store, private readonly _route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this._store.dispatch(new ResetRemoteState());
    this._route.queryParams
      .pipe(untilDestroyed(this))
      .subscribe(params => {
        if (params?.type && params?.type === 'acknowledgement') {
          this.successText = 'remote.acknowledgement.success';
        }
      });
  }
}
