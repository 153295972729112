import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PrivacyNoticeComponent} from './pages/privacy-notice/privacy-notice.component';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import {LandingPageComponent} from './pages/landing-page/landing-page.component';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  declarations: [PrivacyNoticeComponent, LandingPageComponent],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatButtonModule,
    MatIconModule
  ]
})
export class PublicModule {
}
