//#region Imports

import { Injectable } from '@angular/core';
import { Role } from './Role';

//#endregion

/**
 * The `RoleManager` class.
 *
 * @public
 */
@Injectable()
export class RoleManager {

    //#region Fields

    private readonly _roles: Array<string>;

    private _sealed: boolean;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `RoleManager` class.
     *
     * @public
     */
    public constructor() {
        this._roles = [];
        this._sealed = false;
    }

    //#endregion

    //#region Methods

    /**
     * Seals the 'RoleManager' to prevent new regestations.
     *
     * @public
     */
    public seal(): void {
        this._sealed = true;
    }

    /**
     * Registers a new role.
     *
     * @public
     * @param role - The role to register.
     */
    public register(role: Role | Array<Role>): RoleManager {
        if (this._sealed) {
            throw new Error('The RoleManager is already sealed.');
        }

        const p = Array.isArray(role)
            ? role
            : [role];

        for (const x of p) {
            if (this._roles.includes(x)) {
                throw new Error(`The role ${x} already exist.`);
            }

            this._roles.push(x);
        }

        return this;
    }

    /**
     * Invalidates whether a role is grranted.
     *
     * @public
     * @param role - The role to invalidate.
     */
    public can(role: Role | Array<Role>): boolean {
        const roleResults: Array<boolean> = [];

        for (const x of Array.isArray(role) ? role : [role]) {
            roleResults.push(this._roles.includes(x));

            if (x === '*') {
                roleResults.push(true);
            }
        }

        return roleResults.includes(true);
    }

    //#endregion

}
