//#region Imports

import { InjectionToken } from '@angular/core';
import { ISpacerModuleConfig } from './ISpacerModuleConfig';

//#endregion

/**
 * The Spacer module configuration injection token.
 *
 * @public
 */
export const SPACER_MODULE_CONFIG = new InjectionToken<ISpacerModuleConfig>('SPACER_MODULE_CONFIG');
