import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { combineLatest, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
    IRetailerQuoteSettingsResponseDto, RetailerLeasingService
} from '@abcfinlab/api/global';
import { IRetailerResponseDto, RetailerAdminService } from '@abcfinlab/api/retailer';

@Injectable({
    providedIn: 'root'
})
export class RetailerConfigurationResolver implements Resolve<[IRetailerQuoteSettingsResponseDto, IRetailerResponseDto]> {

    constructor(
        private _retailerCalculationService: RetailerLeasingService, private _retailerAdminService: RetailerAdminService
    ) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<[IRetailerQuoteSettingsResponseDto, IRetailerResponseDto]> {
        return combineLatest([this._retailerCalculationService.getRetailerQuoteSettings(), this._retailerAdminService.getRetailerById()])
            .pipe(
                catchError(err => {
                    return throwError(err);
                })
            );
    }

}
