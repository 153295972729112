import { Component } from '@angular/core';

@Component({
  selector: 'l7-container-sign-contract',
  templateUrl: './container-sign-contract.component.html',
  styleUrls: ['./container-sign-contract.component.scss']
})
export class ContainerSignContractComponent {

  constructor() {
  }

}
