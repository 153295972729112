//#region Imports

import { ChangeDetectionStrategy, Component } from '@angular/core';

//#endregion

/**
 * The `nameComponent` .
 *
 * @public
 */
@Component({
    selector: 'l7-persona-group',
    templateUrl: './PersonaGroupComponent.html',
    styleUrls: ['./PersonaGroupComponent.scss'],
    exportAs: 'l7PersonaGroup',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonaGroupComponent {

    //#region Ctor

    public constructor() {
    }

    //#endregion

}
