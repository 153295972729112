// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .icon-alert {
  margin: 8px;
  color: #ff7600;
}
:host .legalform-info-text {
  margin-bottom: 5px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInBhZ2UtY2hvb3NlLWxlc3NlZS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDSTtFQUNJLFdBQUE7RUFDQSxjQUFBO0FBQVI7QUFHSTtFQUNJLGtCQUFBO0FBRFIiLCJmaWxlIjoicGFnZS1jaG9vc2UtbGVzc2VlLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICAgIC5pY29uLWFsZXJ0IHtcbiAgICAgICAgbWFyZ2luOiA4cHg7XG4gICAgICAgIGNvbG9yOiAjZmY3NjAwO1xuICAgIH1cblxuICAgIC5sZWdhbGZvcm0taW5mby10ZXh0IHtcbiAgICAgICAgbWFyZ2luLWJvdHRvbTogNXB4O1xuICAgIH1cbn1cblxuIl19 */`, "",{"version":3,"sources":["webpack://./apps/shell/src/app/private/pages/route-container-create-quote/page-choose-lessee/page-choose-lessee.component.scss"],"names":[],"mappings":"AACI;EACI,WAAA;EACA,cAAA;AAAR;AAGI;EACI,kBAAA;AADR;AACA,4gBAA4gB","sourcesContent":[":host {\n    .icon-alert {\n        margin: 8px;\n        color: #ff7600;\n    }\n\n    .legalform-info-text {\n        margin-bottom: 5px;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
