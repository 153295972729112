import { ContactService } from '@abcfinlab/api/contact';
import { CreditCheckService, IContactDto, QuoteService } from '@abcfinlab/api/global';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { BankAccountDTO } from '../../../models/BankAccountDTO.interface';
import { LeasingQuoteDTO } from '../../../models/LeasingQuoteDTO.interface';
import { QuoteState } from '../../../state/Quote.state';
import { QuoteRepositoryService } from '../../repository/quote-repository.service';
import { ObjectgroupService } from '../objectgroup/objectgroup.service';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root'
})
export class QuoteDetailService {
    private getQuote: LeasingQuoteDTO;

  constructor(
    private readonly _quoteService: QuoteService,
    private readonly _quoteRepoService: QuoteRepositoryService,
    private readonly _contactService: ContactService,
    private readonly _objectGroupRepoService: ObjectgroupService,
    private readonly _creditCheckService: CreditCheckService,
    private readonly _store: Store
  ) {
       this._store.select(QuoteState.getQuote).subscribe(q => {
           this.getQuote = q;
      });
  }

  /**
 * If the user has not entered a bank account yet, the bank account object on the Quote is not present but needed in the view.
 * There for it must be patched before rendering. In addition the lessee is shown in the bank account component as the implicit pre-filled owner.
 * @param bankAccount
 * @param lessee
 * @private
 */
  private static _patchBankAccountForDetailView(bankAccount: BankAccountDTO, lessee: IContactDto) {
    if (bankAccount) {
      return { ...bankAccount, ...{ bank_account_name: lessee.name } };
    } else {
      return { bank_account_name: lessee.name } as BankAccountDTO;
    }
  }

  /**
 * Setup the necessary quote detail page data.
 * Mandatory: The selected quote, the corresponding lessee to the quote, the state of the signing link, the cam credit check result for the given lessee of the quote.
 */
  public setupPage(): Observable<any> {
    const selectedQuoteID = this.getQuote.quote_id;
    const selectedQuoteLesseeID = this.getQuote.lessee_id;
    const selectedQuoteObjectGroup = this.getQuote.object_group_id;

    const request$ = forkJoin([
        this._quoteRepoService.getQuoteByUUID(this.getQuote.quote_id, this.getQuote.quote_calculation.contract_type),
        this._quoteService.getQuoteByIdV({ quoteId: selectedQuoteID }),
        this._objectGroupRepoService.getObjectGroupById(selectedQuoteObjectGroup),
        this._quoteRepoService.fetchSigningLink(selectedQuoteID),
        this._creditCheckService.getCreditCheckDetail({ quoteId: selectedQuoteID }),
        this._quoteRepoService.fetchNavInfo(this.getQuote.quote_number),
        this._contactService.identificationRequired({ lesseeId: selectedQuoteLesseeID })
    ]);

    return request$.pipe(
      map(([quote, newGetQuote, objectGroup, signingLink, camResult, navQuoteInfo, lesseeNeedsIdentification]) => {
        const bankAccount = QuoteDetailService._patchBankAccountForDetailView(newGetQuote.bankAccount, newGetQuote.lessee);
        return {
          newGetQuote,
          objectGroup,
          signingLink,
          camResult,
          navQuoteInfo,
          lesseeNeedsIdentification,
          quote: { ...quote, ...bankAccount }
        };
      })
    );
  }
}

