//#region Imports

import { ContactService, IContactDto } from '@abcfinlab/api/contact';
import { IContractDocumentDto } from '@abcfinlab/api/contract';
import { DocumentsService, RetailerQuoteService } from '@abcfinlab/api/global';
import { once, TranslationFacade } from '@abcfinlab/core';
import { BusyBoxService, ToastService } from '@abcfinlab/ui';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    QUOTE_RETAILER_CREATE_QUOTE_NEW_VERSION_ROUTE_PATH,
    RETAILER_QUOTES_LIST_ROUTE_PATH
} from '../../Routing/RoutePaths';
import { RetailerQuoteDocumentActionView } from '../Dialogs/retailer-quote-document-action/RetailerQuoteDocumentAction';
import {
    RetailerQuoteDetailsViewPresenter
} from './RetailerQuoteDetailsViewPresenter';

export enum QuoteDocumentTableAction {
    PREVIEW = 'preview',
    SEND = 'send',
    PRINT = 'print'
}
//#endregion

/**
 * The `RetailerQuoteDetailsView` view.
 *
 * @public
 */
@UntilDestroy()
@Component({
    selector: 'l7-retailer-quote-details-view',
    templateUrl: './RetailerQuoteDetailsView.html',
    styleUrls: ['./RetailerQuoteDetailsView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        RetailerQuoteDetailsViewPresenter
    ]
})
export class RetailerQuoteDetailsView implements OnInit, OnDestroy {

    //#region Fields
    private readonly _presenter: RetailerQuoteDetailsViewPresenter;

    private readonly _cdr: ChangeDetectorRef;

    private readonly _documentsService: DocumentsService;

    private readonly _retailerQuoteService: RetailerQuoteService;

    private readonly _toastService: ToastService;

    private readonly _busyBoxService: BusyBoxService;

    private readonly _translationFacade: TranslationFacade;

    private readonly _contactService: ContactService;

    private readonly _router: Router;

    private readonly _columns: Array<string>;

    private readonly _dialogService: MatDialog;

    private _lesseeContact: IContactDto;

    private _isDraftLessee: boolean = false;

    private _quoteId: string;
    private _lesseeId: string;
    protected readonly quoteDocumentTableAction = QuoteDocumentTableAction;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `RetailerQuoteDetailsView` class.
     *
     * @public
     */
    public constructor(presenter: RetailerQuoteDetailsViewPresenter, documentsService: DocumentsService,
        toastService: ToastService, translationFacade: TranslationFacade, cdr: ChangeDetectorRef, router: Router, contactService: ContactService, dialogService: MatDialog, retailerQuoteService: RetailerQuoteService, busyBoxService: BusyBoxService) {
        this._presenter = presenter;
        this._busyBoxService = busyBoxService;
        this._documentsService = documentsService;
        this._retailerQuoteService = retailerQuoteService;
        this._toastService = toastService;
        this._translationFacade = translationFacade;
        this._columns = ['name', 'creation_date', 'created_by', 'type', 'actions'];
        this._dialogService = dialogService;
        this._cdr = cdr;
        this._router = router;
        this._contactService = contactService;
    }

    //#endregion

    //#region Properties

    /**
     * Returns the presenter of the `RetailerQuoteDetailsView`
     *
     * @public
     * @readonly
     */
    public get presenter(): RetailerQuoteDetailsViewPresenter {
        return this._presenter;
    }

    /**
     * Returns the property `columns`
     *
     * @public
     * @readonly
     */
    public get columns(): Array<string> {
        return this._columns;
    }

    public get isDraftLessee(): boolean {
        return this._isDraftLessee;
    }

    //#endregion

    //#region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize();
        this.presenter.quoteTasks.pipe(untilDestroyed(this))
            .subscribe(_ => this._cdr.detectChanges());
        this.presenter.quoteId.pipe(untilDestroyed(this))
            .subscribe(id => this._quoteId = id);
        this.presenter.isDraftLessee.pipe(untilDestroyed(this))
            .subscribe(isDraft => this._isDraftLessee = isDraft);
        this.presenter.quoteDetails.pipe(untilDestroyed(this))
            .subscribe(quoteDetails => this._lesseeId = quoteDetails?.lesseeId);
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }


    public navigateTo(where: 'NEW_VERSION' | 'QUOTES'): void {
        if (where === 'NEW_VERSION') {
            this._router.navigateByUrl(`presentation/${QUOTE_RETAILER_CREATE_QUOTE_NEW_VERSION_ROUTE_PATH}/${this._quoteId}`);
        }
        if (where === 'QUOTES') {
            this._contactService.getById({ id: this._lesseeId }).subscribe(
                contact => {
                    this._lesseeContact = contact;
                    this._router.navigateByUrl(`presentation/${RETAILER_QUOTES_LIST_ROUTE_PATH}/${this._lesseeId}?lesseName=${this._lesseeContact.name}`);
                }
            );
        }

    }

    public callAction(row: IContractDocumentDto, action: string, event: Event): void {
        event.stopPropagation();
        if (this._isDraftLessee) {
            this._contactService.getById({ id: this._lesseeId }).subscribe(
                contact => {
                    this._lesseeContact = contact;
                    this._dialogService.open(RetailerQuoteDocumentActionView, { data: { actionType: action, isDraft: this._isDraftLessee, lesseeName: this._lesseeContact.name } }).afterClosed().subscribe(result => {
                        if (result) {
                            this.doAction(action, row, result);
                        }
                    });
                }
            );
        } else {
            this.doAction(action, row, null);
        }
    }
    private doAction(action: string, row: IContractDocumentDto, result: any): void {
        switch (action) {
            case QuoteDocumentTableAction.PREVIEW:
                this.fileDownload(row.id, false);
                break;
            case QuoteDocumentTableAction.SEND:
                this._retailerQuoteService.get({ leasingQuoteId: this._quoteId }).subscribe(
                    contact => {
                        this.sendDocument(this._quoteId, result.email, contact.quote.name);
                    }
                );
                break;
            case QuoteDocumentTableAction.PRINT:
                this.fileDownload(row.id, true);
                break;
        }
    }
    private fileDownload(documentId: string, isPrint: boolean): void {
        this._documentsService.downloadDocument({ documentId: documentId }).pipe(untilDestroyed(this)).subscribe(draftPdf => {
            const draftPdfUrl = URL.createObjectURL(draftPdf);
            const printWindow = window.open(draftPdfUrl, '_blank');
            if (isPrint) {
                printWindow.onload = () => {
                    printWindow.print();
                };
            }
        }, error => {
            this._toastService.show(this._translationFacade.translate('error.generic_error'), 'danger');
        });
    }

    private sendDocument(leasingQuoteId: string, email: string, quoteName: string): void {
        once(this._busyBoxService.show(undefined, this._translationFacade.translate('global.busy'),
            this._retailerQuoteService.sendDocumentToLessee({ leasingQuoteId: leasingQuoteId, email: email })
        ), () => {
            this._toastService.show(this._translationFacade.instant('dialogs.quoteDocumentTableAction.send.success', { email: email, quoteID: quoteName }), 'success');
        }, error => {
            this._toastService.show(this._translationFacade.translate('error.generic_error'), 'danger');
        });
    }
    //#endregion
}
