// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host hr {
  border-top: 1px solid #eee;
}
:host form div {
  margin: 10px 0;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImp1cmlzdGljLXBlcnNvbi1jb25maXJtYXRpb24uY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0k7RUFDSSwwQkFBQTtBQUFSO0FBR0k7RUFDSSxjQUFBO0FBRFIiLCJmaWxlIjoianVyaXN0aWMtcGVyc29uLWNvbmZpcm1hdGlvbi5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgICBociB7XG4gICAgICAgIGJvcmRlci10b3A6IDFweCBzb2xpZCAjZWVlO1xuICAgIH1cblxuICAgIGZvcm0gZGl2IHtcbiAgICAgICAgbWFyZ2luOiAxMHB4IDA7XG4gICAgfVxufVxuXG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/legacy/gwg/src/lib/confirmation/juristic-person-confirmation/juristic-person-confirmation.component.scss"],"names":[],"mappings":"AACI;EACI,0BAAA;AAAR;AAGI;EACI,cAAA;AADR;AACA,4eAA4e","sourcesContent":[":host {\n    hr {\n        border-top: 1px solid #eee;\n    }\n\n    form div {\n        margin: 10px 0;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
