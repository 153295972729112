import { VerificationService } from '@abcfinlab/api/global';
import { AppConfig, TranslationFacade } from '@abcfinlab/core';
import { BusyBoxService } from '@abcfinlab/ui';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { ErrorHandlerService } from '../../../../../../apps/shell/src/app/shared/services/errorHandler/error-handler.service';
import { SignerDTO } from '../models/signer-dto.interface';
import { LeasingQuoteIdService } from '../services/leasing-quote-id.service';
import { SignerService } from '../services/signer.service';
import { SignerDataProcessingModalComponent } from '../shared/signer-data-processing-modal/signer-data-processing-modal.component';

@UntilDestroy()
@Component({
    selector: 'gwg-signer-data',
    templateUrl: './signer-data.component.html',
    styleUrls: ['./signer-data.component.scss']
})
export class SignerDataComponent implements OnInit {
    private readonly hostUrl: string;
    private dialogRef: MatDialogRef<SignerDataProcessingModalComponent>;
    private readonly _isIdentificationRequired: BehaviorSubject<boolean> = new BehaviorSubject(null);

    privacyPolicyPdfPath: string;

    file: string;

    disabled = true;

    signer: SignerDTO = {
        first_name: '',
        last_name: '',
        mail_address: '',
        quote_id: ''
    };

    public get isIdentificationRequired(): Observable<boolean> {
        return this._isIdentificationRequired.asObservable();
    }

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly signerService: SignerService,
        private readonly leasingQuoteIdService: LeasingQuoteIdService,
        private readonly errorHandler: ErrorHandlerService,
        private readonly emailVerification: VerificationService,
        private readonly _translationFacade: TranslationFacade,
        private readonly _busyBoxService: BusyBoxService,
        public dialog: MatDialog,
        private readonly appConfig: AppConfig
    ) {
        this.hostUrl = this.appConfig.get('host');
        this.privacyPolicyPdfPath = `${this.hostUrl}/api/v1/gwg` + '/privacypolicy';
        this.file = btoa(`${this.hostUrl}/api/v1/gwg` + '/privacypolicy');
        this.leasingQuoteIdService.isIdentificationRequired.pipe(untilDestroyed(this))
            .subscribe(x => this._isIdentificationRequired.next(x));
    }

    ngOnInit() {
        this.signer.quote_id = this.leasingQuoteIdService.getLeasingQuoteId();
    }

    submitSigner(): void {
        this._busyBoxService.show(null, null, this.emailVerification.verifyEmail({ emailAddress: this.signer.mail_address }))
            .pipe(untilDestroyed(this))
            .subscribe(_ => {
                this.saveSigner();
            },
                _error => {
                    this.dialog.closeAll();
                    const dialogMessage = this._translationFacade.instant(`error.${_error.error.error}`,
                        _error.error.error_params);
                    this.errorHandler.openDialogWithIdAndBody('error.email_not_valid', _error, dialogMessage);
                    this.errorHandler.errorDialogEmailVerification$
                        .pipe(untilDestroyed(this))
                        .subscribe(value => {
                            if (value) {
                                this.saveSigner();
                            }
                        });
                });
    }

    private saveSigner() {
        this.signerService.updateSigner(this.signer);
        this.openDialog();
        this.signerService.postSignerToBackend()
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.dialog.closeAll();
                if (this._isIdentificationRequired.getValue()) {
                    void this.router.navigate(['../identification'], { relativeTo: this.route });
                } else {
                    void this.router.navigate(['../flow-decision'], { relativeTo: this.route });
                }
            });
    }

    private openDialog() {
        this.dialogRef = this.dialog.open(SignerDataProcessingModalComponent, {
            width: '500px',
            disableClose: true
        });
    }
}
