import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Store} from '@ngxs/store';
import {SetLoadingState} from '@abcfinlab/core';
import {
    CreditCheckService, ICreditCheckDetailDto, IQuoteDto,
    ISaleAndLeaseBackDetailDto,
    QuoteService,
    SaleAndLeaseBackService
} from '@abcfinlab/api/global';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';
import { ContactService, IContactDto } from '@abcfinlab/api/contact';

@Injectable({
    providedIn: 'root'
})
export class SlbDetailsResolver implements Resolve<[ISaleAndLeaseBackDetailDto, IContactDto, ICreditCheckDetailDto, IQuoteDto]> {
    constructor(private readonly store: Store, private readonly _saleAndLeaseBackService: SaleAndLeaseBackService,
        private readonly _contactService: ContactService, private readonly _quoteService: QuoteService,
        private readonly _creditCheckService: CreditCheckService){}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<[ISaleAndLeaseBackDetailDto, IContactDto, ICreditCheckDetailDto, IQuoteDto]> {
        this.store.dispatch(new SetLoadingState(true));

        return combineLatest([
            this._saleAndLeaseBackService.getSaleAndLeaseBackDetails({leasingQuoteId: route.params.quote_id}),
            this._quoteService.getQuoteById({quoteId: route.params.quote_id}),
            this._creditCheckService.getCreditCheckDetail({quoteId: route.params.quote_id})
        ])
            .pipe(
                concatMap( ([slbDetails, quote, creditCheck]) => {
                    return combineLatest([
                        of(slbDetails),
                        this._contactService.getById({id: quote.lessee_id}),
                        of(creditCheck),
                        of(quote)
                    ]);
                }),
                catchError(err => {
                    return throwError(err);
                })
            );
    }
}
