//#region Imports

import { TranslationModule } from '@abcfinlab/core';
import { PageModule } from '@abcfinlab/layout';
import {
    CheckSignaturesDialogModule,
    CreditCheckIconPipeModule,
    InsuranceTypeIconPipeModule,
    PresentationModule,
    SepaMandateStatusIconPipeModule
} from '@abcfinlab/presentation';
import {
    BannerModule,
    BreakpointObserverModule,
    DatePickerModule,
    DetailRowModule,
    DialogHeaderModule,
    FilterModule,
    MemberValuePipeModule,
    NotAvailablePipeModule,
    PersonaModule,
    SelectOnDblClickDirectiveModule,
    SpacerModule,
    SummaryModule,
    TextBlockModule
} from '@abcfinlab/ui';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { DateAdapter, MAT_DATE_FORMATS, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { APP_DATE_FORMATS, AppDateAdapter } from '../../../presentation/src/Adapters/date.adapter';
import { AppPaginatorIntlAdapter } from '../../../presentation/src/Adapters/paginator.adapter';
import { ManualProcessingErrorDialog } from '../Views/Dialogs/ManualProcessingErrorDialog';
import { SettlementNavErrorDialogView } from '../Views/Dialogs/SettlementNavErrorDialogView';
import { DocumentDialogComponent } from '../Views/Dialogs/document-dialog.component';
import { ManualProcessingDialogComponent } from '../Views/Dialogs/manual-processing-dialog.component';
import { InvoiceContractInformationView } from '../Views/InvoiceContractInformationView';
import { SettlementDocumentsView } from '../Views/SettlementDocumentsView';
import { SettlementOverviewView } from '../Views/SettlementOverviewView';
import { SettlementStatesView } from '../Views/SettlementStatesView';
import { AcknowledgementFormComponent } from '../Views/acknowledgement-form.component';
import { DocumentConfirmationFormComponent } from '../Views/document-confirmation-form.component';
import { InvoiceFormComponent } from '../Views/invoice-form.component';

//#endregion

/**
 * The `ContractManagementModule` module.
 *
 * @public
 */
@NgModule({
    imports: [
        CommonModule,
        TranslationModule,
        FlexLayoutModule,
        PresentationModule,
        PdfViewerModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatIconModule,
        MatTableModule,
        MatInputModule,
        DetailRowModule,
        MatPaginatorModule,
        TextBlockModule,
        MatExpansionModule,
        MatChipsModule,
        MatSortModule,
        MemberValuePipeModule,
        MatButtonModule,
        MatDividerModule,
        MatSelectModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatButtonModule,
        MatDialogModule,
        NotAvailablePipeModule,
        MatCardModule,
        MatTooltipModule,
        MatRippleModule,
        NgxMaskDirective,
        NgxMaskPipe,
        TextFieldModule,
        PersonaModule,
        SelectOnDblClickDirectiveModule,
        CreditCheckIconPipeModule,
        SepaMandateStatusIconPipeModule,
        InsuranceTypeIconPipeModule,
        SummaryModule,
        PageModule,
        DialogHeaderModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        SpacerModule,
        FilterModule,
        BreakpointObserverModule,
        DatePickerModule,
        CheckSignaturesDialogModule,
        BannerModule
    ],
    declarations: [
        SettlementOverviewView,
        InvoiceContractInformationView,
        SettlementDocumentsView,
        SettlementStatesView,
        SettlementNavErrorDialogView,
        DocumentDialogComponent,
        AcknowledgementFormComponent,
        InvoiceFormComponent,
        DocumentConfirmationFormComponent,
        ManualProcessingDialogComponent,
        ManualProcessingErrorDialog
    ],
    providers: [
        provideNgxMask(),
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
        { provide: MatPaginatorIntl, useClass: AppPaginatorIntlAdapter },
        { provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: { formFieldAppearance: 'fill' } }
    ]
})
export class SettlementModule {

    //#region Ctor

    /**
     * Constructs a new instance of the `SettlementModule` class.
     *
     * @public
     */
    public constructor() {

    }

    //#endregion

}
