//#region Imports

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

//#endregion

/**
 * The `BrandComponent` component.
 *
 * @public
 */
@Component({
    selector: 'l7-brand',
    templateUrl: './BrandComponent.html',
    styleUrls: ['BrandComponent.scss'],
    exportAs: 'l7Brand',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BrandComponent {
    @Input('showText') public showText: boolean = true; // hide or show the abc lettering
    @Input('compact') public compact: boolean = false;
}
