import {LeasingQuoteDTO} from '../models/LeasingQuoteDTO.interface';
import {VendorDTO} from '../models/VendorDTO.interface';
import {ContractTypeId} from '../models/enums/ContractTypeId.enum';
import {CreditCheckDTO} from '../models/CreditCheckDTO.interface';
import {InsuranceCalculationRequestDTO} from '../models/InsuranceCalculationRequestDTO';
import {ContactDTO} from '../models/ContactDTO.interface';
import { IContractTypeDto, IContactDto as IContactDtoGlobal } from '@abcfinlab/api/global';
import { IContactDto } from '@abcfinlab/api/contact';

export class SetQuoteID {
  static readonly type = '[QUOTE] Set UUID';

  constructor(public payload: string) {
  }
}

export class ResetSelectedQuoteID {
  static readonly type = '[QUOTE] Reset selected UUID';

  constructor() {
  }
}

export class CalculateInsuranceForQuote {
  static readonly type = '[Quote] Updating insurance';

  constructor(public request: InsuranceCalculationRequestDTO) {
  }
}

export class SetQuote {
  static readonly type = '[QUOTE] Set quote';

  constructor(public payload: LeasingQuoteDTO) {
  }
}

/**
 *
 */
export class FetchQuoteByUUID {
  static readonly type = '[QUOTE] Fetch quote by UUID';

  constructor(public UUID: string, public contractType: ContractTypeId | IContractTypeDto) {
  }
}

/**
 *
 */
export class FetchQuotesListByLesseeUUID {
  static readonly type = '[QUOTE] Fetch quotes by lessee UUID';

  constructor(public lesseeUUID) {
  }
}

/**
 *
 */
export class FetchSigningLinkByUUID {
  static readonly type = '[QUOTE] Fetch link by UUID';

  constructor(public UUID: string) {
  }
}

/**
 *
 */
export class SetVendor {
  static readonly type = '[QUOTE] Set vendor';

  constructor(public vendor: VendorDTO) {
  }
}

export class UpdateVendor {
  static readonly type = '[QUOTE] Update vendor';

  constructor(public vendor: ContactDTO | IContactDto, public UUID: string) {
  }
}

export class UpdateLessee {
  static readonly type = '[QUOTE] Update lessee';

    constructor(public lessee: ContactDTO | IContactDto | IContactDtoGlobal, public UUID: string,) {
  }
}

/**
 *
 */
export class SetCreditRating {
  static readonly type = '[QUOTE] Set credit rating for selected quote';

  constructor(public creditRating: CreditCheckDTO) {
  }
}

/**
 *
 */
export class SetLesseeForQuote {
    static readonly type = '[QUOTE] Set lessee for selected quote';

    constructor(public lessee: ContactDTO) {
    }
}

/**
 *
 */
export class GetCreditRating {
  static readonly type = '[QUOTE] Get credit rating for quote';

  constructor(public quoteId: string, public lesseeId: string, public dialogRef?) {
  }
}

/**
 *
 */
export class UpdateQuoteBankAccount {
  static readonly type = '[QUOTE] update quote bank account';

  constructor(public quote: LeasingQuoteDTO) {
  }
}

/**
 *
 */
export class SetNavInfoForQuotes {
  static readonly type = '[QUOTE] Set nav info for quotes';

  constructor() {
  }
}
