//#region Imports

import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UploadTakeoverDocumentDialogViewPresenter } from './UploadTakeoverDocumentDialogViewPresenter';

//#endregion

/**
 * The `UploadTakeoverDocumentDialogView` view.
 *
 * @public
 */
@Component({
    selector: 'l7-upload-takeover-document-dialog-view',
    templateUrl: './UploadTakeoverDocumentDialogView.html',
    styleUrls: ['./UploadTakeoverDocumentDialogView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        UploadTakeoverDocumentDialogViewPresenter,
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { showError: true, displayDefaultIndicatorType: false },
        }
    ]
})
export class UploadTakeoverDocumentDialogView implements OnInit, OnDestroy {

    //#region Fields

    private readonly _presenter: UploadTakeoverDocumentDialogViewPresenter;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `UploadTakeoverDocumentDialogView` class.
     *
     * @public
     */
    public constructor(presenter: UploadTakeoverDocumentDialogViewPresenter) {
        this._presenter = presenter;
    }

    //#endregion

    //#region Proeprties

    /**
     * Returns the presenter of the `UploadTakeoverDocumentDialogView`
     *
     * @public
     * @readonly
     */
    public get presenter(): UploadTakeoverDocumentDialogViewPresenter {
        return this._presenter;
    }

    //#endregion

    //#region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize();
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    //#endregion

}
