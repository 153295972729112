import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {ISearchRetailerQuoteResultDto} from '@abcfinlab/api/global';
import { MatTableDataSource } from '@angular/material/table';
import {
    IRetailerQuoteVersionListMenu,
    IRetailerQuoteVersionListMenuEvent
} from '../retailer-quote-versions-cards/retailer-quote-versions-cards.component';

@Component({
    selector: 'l7-retailer-quote-versions-table',
    templateUrl: './retailer-quote-versions-table.component.html',
    styleUrls: ['./retailer-quote-versions-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RetailerQuoteVersionsTableComponent implements OnInit {
    @Input() versions: Array<ISearchRetailerQuoteResultDto>;
    @Input() menuActions: Array<IRetailerQuoteVersionListMenu>;

    @Output() goToVersionDetailView: EventEmitter<ISearchRetailerQuoteResultDto> = new EventEmitter<ISearchRetailerQuoteResultDto>();

    @Output() menuItemClicked: EventEmitter<IRetailerQuoteVersionListMenuEvent> = new EventEmitter<IRetailerQuoteVersionListMenuEvent>();

    private readonly _columns: Array<string>;
    private _dataSource: MatTableDataSource<ISearchRetailerQuoteResultDto>;
    public disableRipple: boolean = false;

    constructor() {
        this._columns = ['versionNumber', 'creationDate', 'objectName', 'objectValue', 'term', 'instalment', 'contractType', 'progress', 'menu'];
    }

    public get columns(): Array<string> {
        return this._columns;
    }

    public get dataSource(): MatTableDataSource<ISearchRetailerQuoteResultDto> {
        return this._dataSource;
    }

    ngOnInit(): void {
        this._dataSource = new MatTableDataSource<ISearchRetailerQuoteResultDto>(this.versions);
    }

    public goToDetailView(evt, version: ISearchRetailerQuoteResultDto, column: string): void {
        if (column === 'menu') {
            return;
        }
        evt.stopPropagation();
        this.goToVersionDetailView.emit(version);
    }

    public onMenuItemClick(evt: IRetailerQuoteVersionListMenu, quoteId: string): void {
        this.menuItemClicked.emit({action: evt.action, quoteId: quoteId});
    }

}
