import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { calculateInsuranceValueRange, getInsuranceFactorFromObjectGroup } from '../../../helper/calculation.helper';
import { CalculationRequestDTO } from '../../../models/CalculationRequestDTO.interface';
import { ContractTypeId } from '../../../models/enums/ContractTypeId.enum';
import {
    InsuranceCalculationRequestDTO,
    InsuranceCalculationResponseDTO
} from '../../../models/InsuranceCalculationRequestDTO';
import {
    HirePurchaseCalculationDTO,
    LeasingQuoteCalculationDTO
} from '../../../models/LeasingQuoteCalculationDTO.interface';
import { LeasingQuoteDTO } from '../../../models/LeasingQuoteDTO.interface';
import { AppConfig, IAppConfigData } from '@abcfinlab/core';
import { UsedCondition } from '../../../models/enums/UsedCondition.enum';
import { Currency } from '../../../models/enums/Currency.enum';

@Injectable({
  providedIn: 'root'
})
export class CalculationService {

  private readonly HOST: string;
  public readonly OBJECT_VALUE_MAX: number = 750000;
  public readonly OBJECT_VALUE_MIN: number = 500;

  private _leasingQuote: LeasingQuoteDTO;

  constructor(
    private readonly _http: HttpClient,
    appConfig: AppConfig<IAppConfigData>
  ) {
      this.HOST = appConfig.get<string>('host');
  }

  /**
   * Calculation request to the backend
   */
  public calculate(payload: CalculationRequestDTO): Observable<LeasingQuoteCalculationDTO | HirePurchaseCalculationDTO> {
    const calculationLeasingPath: string = '/api/v1/calculate/leasing';
    const calculationHirePurchasePath: string = '/api/v1/calculate/hirepurchase';
    const endPoint: string = payload?.quote_calculation?.contract_type === ContractTypeId.MIETKAUF
      ? calculationHirePurchasePath : calculationLeasingPath;
    return this._http.post<LeasingQuoteCalculationDTO | HirePurchaseCalculationDTO>(`${this.HOST + endPoint}`, payload, { withCredentials: true });
  }

  /**
   * Calculation Insurance request to the backend
   */
  public calculateInsurance(payload: InsuranceCalculationRequestDTO): Observable<InsuranceCalculationResponseDTO> {
      const calculationInsurancePath: string = '/api/v1/calculate/insurance';

      return this._http.post<InsuranceCalculationResponseDTO>(
      `${this.HOST + calculationInsurancePath}`,
      payload, { withCredentials: true }
    );
  }

  /**
   * Find the amortization range for a given object-group
   * Do calculation for allowed range in months
   * min: 0,4 * AfA ← aufrunden zu nächsten ganzen Zahl
   * max  0,9 * AfA ← abrunden zu nächsten ganzen Zahl
   * @param start   The number to start with
   * @param end     The number to end with
   */
  public getAmortizationRange(start: number, end: number): Array<number> {
    let amortizationRange: Array<number> | null;
    if (start === end) {
      amortizationRange = [start];
    } else {
      amortizationRange = [start, ...this.getAmortizationRange(start + 1, end)];
    }
    return amortizationRange;
  }

  public prepareCalculationRequest(formValues: LeasingQuoteCalculationDTO | HirePurchaseCalculationDTO, isInsuranceStandardAndActive = false, usedCondition: UsedCondition) {
    if (isInsuranceStandardAndActive) {
      formValues.insurance_fee = calculateInsuranceValueRange(
        formValues.total_leasing_value, getInsuranceFactorFromObjectGroup(formValues.object_group)
      ).maxValue;
    }
    const payload: CalculationRequestDTO = {
      quote_calculation: formValues,
      object_group: formValues.object_group,
      objects: [{
          currency: Currency.EURO,
          name: formValues.name,
          quantity: 1,
          value: formValues.object_value,
          condition: usedCondition
      }]
    };

    // clean up after structural change on backend
    delete payload.quote_calculation.object_group;
    return payload;
  }

  public setLeasingQuote(leasingQuote): void {
    this._leasingQuote = leasingQuote;
  }
}
