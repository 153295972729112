import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TextBlockModule } from '../TextBlock/TextBlockModule';
import { BannerComponent } from './BannerComponent';
import {FlexLayoutModule} from '@ngbracket/ngx-layout';
import {MatButtonModule} from '@angular/material/button';

/**
 * @public
 */
@NgModule({
    imports: [
        CommonModule,
        TextBlockModule,
        MatIconModule,
        FlexLayoutModule,
        MatButtonModule
    ],
    declarations: [
        BannerComponent
    ],
    exports: [
        BannerComponent
    ]
})
export class BannerModule {
}
