import { TranslationModule } from '@abcfinlab/core';
import { SpacerModule, TextBlockModule } from '@abcfinlab/ui';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { ConfirmationRoutingModule } from './confirmation-routing.module';
import { ConfirmationSummaryComponent } from './confirmation-summary/confirmation-summary.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { JuristicPersonConfirmationComponent } from './juristic-person-confirmation/juristic-person-confirmation.component';
import { NaturalPersonConfirmationComponent } from './natural-person-confirmation/natural-person-confirmation.component';
import { StandardConfirmationComponent } from './standard-confirmation/standard-confirmation.component';

@NgModule({
    declarations: [
        JuristicPersonConfirmationComponent,
        NaturalPersonConfirmationComponent,
        StandardConfirmationComponent,
        ConfirmationComponent,
        ConfirmationSummaryComponent
    ],
    imports: [
        CommonModule,
        ConfirmationRoutingModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatRadioModule,
        TranslationModule,
        MatButtonModule,
        MatIconModule,
        SpacerModule,
        TextBlockModule,
        FlexLayoutModule
    ],
    exports: [
        JuristicPersonConfirmationComponent,
        NaturalPersonConfirmationComponent,
        StandardConfirmationComponent,
        ConfirmationComponent,
        ConfirmationSummaryComponent
    ]
})
export class ConfirmationModule { }
