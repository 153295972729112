import { Component } from '@angular/core';

@Component({
  selector: 'l7-create-contract',
  templateUrl: './create-contract.component.html',
  styleUrls: ['./create-contract.component.scss']
})
export class CreateContractComponent {

  constructor() {
  }

}
