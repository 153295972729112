import { AdministrationModule, AdministrationNavigationModule, AdministrationRoutingModule } from '@abcfinlab/administration';
import { AnalyticsService } from '@abcfinlab/analytics';
import { authInitializer, AuthModule } from '@abcfinlab/auth';
import { SettlementModule, SettlementNavigationModule, SettlementRoutingModule } from '@abcfinlab/backoffice';
import { ContactsNavigationModule } from '@abcfinlab/contacts';
import { ContractManagementModule, ContractManagementNavigationModule, ContractManagementRoutingModule } from '@abcfinlab/contract-management';
import { AppConfig, CoreModule, faroInitializer, FeatureManager, GlobalErrorHandler, TranslationModule, UpdateModule, USER_INFO_TOKEN } from '@abcfinlab/core';
import { ExplorerModule, ExplorerNavigationModule, ExplorerRoutingModule } from '@abcfinlab/explorer';
import { FeedbackModule } from '@abcfinlab/feedback';
import { LayoutModule } from '@abcfinlab/layout';
import { NewsModule } from '@abcfinlab/news';
import { ConfigurationNavigationModule, PresentationRoutingModule } from '@abcfinlab/presentation';
import { QuoteModule, QuoteNavigationModule, QuotesRoutingModule } from '@abcfinlab/quote';
import { IbanValidatorModule, SpacerModule } from '@abcfinlab/ui';
import { userGuidanceInitializer } from '@abcfinlab/userguiding';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FlexModule } from '@ngbracket/ngx-layout';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import * as Sentry from '@sentry/angular';
import { NgxCurrencyInputMode, provideEnvironmentNgxCurrency } from "ngx-currency";
import { withNgxsResetPlugin } from 'ngxs-reset-plugin';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LegacyModule } from './private/modules/legacy.module';
import { PublicModule } from './public/public.module';
import { HttpErrorInterceptor } from './shared/interceptors/http-error-interceptor';
import { SharedModule } from './shared/shared.module';
import { CalculationState } from './state/Calculation.state';
import { ContactState } from './state/Contact.state';
import { LesseeState } from './state/Leesee.state';
import { QuoteState } from './state/Quote.state';
import { RemoteState } from './state/Remote.state';
import { SlbState } from './state/Slb.state';

// set locale to DE
registerLocaleData(localeDe, 'de-DE', localeDeExtra);

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        FeedbackModule,
        LayoutModule.forRoot({
            titlePrefix: 'Lease Seven'
        }),
        NgxsModule.forRoot([LesseeState, QuoteState, ContactState, CalculationState, RemoteState, SlbState],
            { developmentMode: !environment.production }),
        CoreModule.forRoot({
            baseUrl: environment.host
        }),
        AuthModule.forRoot({
            callbackUrl: '/login-service/api/oauth2/callback',
            logoutRetryAttempts: 3,
            logoutRetryTimeout: 1500
        }),
        AdministrationModule,
        AdministrationNavigationModule,
        AdministrationRoutingModule,
        ExplorerModule,
        ExplorerNavigationModule,
        ExplorerRoutingModule,
        ContractManagementModule,
        ContractManagementNavigationModule,
        ContractManagementRoutingModule,
        SettlementModule,
        SettlementRoutingModule,
        SettlementNavigationModule,
        ContactsNavigationModule,
        ConfigurationNavigationModule,
        PresentationRoutingModule,
        QuoteModule,
        QuotesRoutingModule,
        QuoteNavigationModule,
        NewsModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        SharedModule,
        PublicModule,
        ReactiveFormsModule,
        MatProgressBarModule,
        MatDialogModule,
        MatIconModule,
        MatButtonModule,
        FlexModule,
        UpdateModule,
        SpacerModule,
        NgxsReduxDevtoolsPluginModule.forRoot({
            name: 'L7-Sales-UI',
            disabled: environment.production
        }),
        NgxsStoragePluginModule.forRoot({
            keys: ['quote', 'lessee', 'user', 'remote']
        }),
        NgxsRouterPluginModule.forRoot(),
        NgxsFormPluginModule.forRoot(),
        ServiceWorkerModule.register('./ngsw-worker.js', {
            enabled: environment.production,
            registrationStrategy: 'registerImmediately',
        }),
        LegacyModule,
        IbanValidatorModule,
        TranslationModule
    ],
    providers: [
        withNgxsResetPlugin(),
        provideEnvironmentNgxCurrency({
            align: '',
            allowZero: false,
            allowNegative: false,
            decimal: ',',
            precision: 2,
            suffix: ' €',
            prefix: '',
            thousands: '.',
            nullable: true,
            inputMode: NgxCurrencyInputMode.Natural
        }),
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            })
        },
        {
            provide: LOCALE_ID,
            useValue: 'de-DE'
        },
        {
            provide: DEFAULT_CURRENCY_CODE,
            useValue: 'EUR'
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: authInitializer,
            deps: [USER_INFO_TOKEN],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (analyticsService: AnalyticsService) => () => analyticsService.load(),
            deps: [AnalyticsService],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: userGuidanceInitializer,
            deps: [AppConfig, USER_INFO_TOKEN],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: faroInitializer,
            deps: [FeatureManager],
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
            deps: [FeatureManager]
        }

    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
