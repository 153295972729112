import { ILoginsInfoDto, IRetailerInfoDto } from '@abcfinlab/api/login';
import {
    AuthService,
    isUserInfoLogins,
    IUserGroupLoginsDto,
    IUserGroupRetailerDto,
    UserService
} from '@abcfinlab/auth';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationService } from '../../services/notification/notification.service';

@UntilDestroy()
@Component({
  selector: 'l7-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss']
})
export class ProfileMenuComponent implements OnInit {

  public isLoggedIn: boolean;

  constructor(
    private readonly _authService: AuthService,
    private readonly _userService: UserService,
    private readonly _router: Router,
    private readonly _notify: NotificationService) {
  }

  get user(): Observable<ILoginsInfoDto | IRetailerInfoDto> {
    return this._userService.userInfo.pipe(
        map(x => {
            if (isUserInfoLogins(x)) {
                return x.loginsInfo;
            }
            return x.retailerInfo;
        })
    );
  }

  get userStrippedName(): Observable<string> {
    return this._userService.userInfo.pipe(
      map(x => {
          if (isUserInfoLogins(x)) {
              return `${x.loginsInfo.givenName?.charAt(0) + x.loginsInfo.familyName?.charAt(0)}`;
          } else {
              return `${x.retailerInfo.givenName?.charAt(0) + x.retailerInfo.familyName?.charAt(0)}`;
          }
      })
    );
  }

  get leadStrippedName(): Observable<string> {
    return this._userService.userInfo.pipe(
      map(x => {
        if (isUserInfoLogins(x)) {
          return `${x.loginsInfo.leadName?.charAt(0) + x.loginsInfo.leadFamilyName?.charAt(0)}`;
        } else {
          // TODO: AW retailer
          return '';
        }
      })
    );
  }

  get userGroups(): Observable<Array<IUserGroupLoginsDto> | Array<IUserGroupRetailerDto>> {
        return this._userService.userGroups.pipe(
            map(x => x)
        );
  }

  ngOnInit(): void {
    this._authService.hasSession
      .pipe(untilDestroyed(this))
      .subscribe(loggedIn => {
        loggedIn ? this.isLoggedIn = loggedIn : this.isLoggedIn = false;
      }, () => this.isLoggedIn = false);
  }

  /**
   * Trigger logout procedure
   */
  public onLogout(): void {
    this._authService.logout()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this._notify.showSuccess('Logout erfolgreich! Auf Wiedersehen!');
        this._router.navigateByUrl('logout').then();
      });
  }

  /**
   * Open the external KC account page
   */
  public onOpenAccount(): void {
    this._userService.openAccountPage()
      .pipe(untilDestroyed(this))
      .subscribe(url => {
        window.open(url.account_url);
      });
  }

  public onMenuOpened(): void {
    this._userService.getUserInfo()
      .pipe(untilDestroyed(this))
      .subscribe();
  }
}
