//#region Imports

import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef, ViewChild } from '@angular/core';
import { SummaryItemIconDirective } from './SummaryItemIconDirective';

//#endregion

/**
 * The `SummaryItemComponent` component.
 *
 * @public
 */
@Component({
    selector: 'l7-summary-item',
    templateUrl: './SummaryItemComponent.html',
    styleUrls: ['./SummaryItemComponent.scss'],
    exportAs: 'l7summaryItem',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SummaryItemComponent {

    //#region Fields

    @ContentChild(SummaryItemIconDirective)
    private readonly _icon: SummaryItemIconDirective;
    @ViewChild('contentTemplate', { static: true })
    private readonly _contentTemplate!: TemplateRef<unknown>;

    private _title: string;
    private _content: string;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `SummaryItemComponent` class.
     *
     * @public
     */
    public constructor() {
        this._title = '';
        this._content = '';
    }

    //#endregion

    //#region Properties

    /**
     * Gets or sets the `title` property.
     *
     * @public
     */
    @Input()
    public get title(): string {
        return this._title;
    }
    public set title(value: string) {
        this._title = value;
    }

    /**
     * Gets or sets the `content` property.
     *
     * @public
     */
    @Input()
    public get content(): string {
        return this._content;
    }
    public set content(value: string) {
        this._content = value;
    }

    /**
     * Returns the `icon` property.
     *
     * @public
     * @readonly
     */
    public get icon(): SummaryItemIconDirective {
        return this._icon;
    }

    /**
     * Returns the `contentTemplate` property.
     *
     * @public
     * @readonly
     */
    public get contentTemplate(): TemplateRef<unknown> {
        return this._contentTemplate;
    }

    //#endregion

}
