// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host l7-page {
  padding: 16px;
}
:host l7-page .margin-top {
  margin-top: 16px;
}
:host l7-page l7-page-header ::ng-deep l7-text-block.title {
  font-size: 18px;
}
:host l7-page l7-page-header ::ng-deep l7-text-block.title.sub {
  font-size: 24px;
}
:host l7-page l7-page-header ::ng-deep l7-text-block.title.supportive {
  margin-top: 8px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIkNhbGN1bGF0aW9uVmlldy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNJO0VBQ0ksYUFBQTtBQUFSO0FBQ1E7RUFDSSxnQkFBQTtBQUNaO0FBR2dCO0VBQ0ksZUFBQTtBQURwQjtBQUVvQjtFQUNJLGVBQUE7QUFBeEI7QUFFb0I7RUFDSSxlQUFBO0FBQXhCIiwiZmlsZSI6IkNhbGN1bGF0aW9uVmlldy5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICAgIGw3LXBhZ2Uge1xuICAgICAgICBwYWRkaW5nOiAxNnB4O1xuICAgICAgICAubWFyZ2luLXRvcCB7XG4gICAgICAgICAgICBtYXJnaW4tdG9wOiAxNnB4O1xuICAgICAgICB9XG4gICAgICAgIGw3LXBhZ2UtaGVhZGVyIDo6bmctZGVlcCB7XG4gICAgICAgICAgICBsNy10ZXh0LWJsb2NrIHtcbiAgICAgICAgICAgICAgICAmLnRpdGxlIHtcbiAgICAgICAgICAgICAgICAgICAgZm9udC1zaXplOiAxOHB4O1xuICAgICAgICAgICAgICAgICAgICAmLnN1YiB7XG4gICAgICAgICAgICAgICAgICAgICAgICBmb250LXNpemU6IDI0cHg7XG4gICAgICAgICAgICAgICAgICAgIH1cbiAgICAgICAgICAgICAgICAgICAgJi5zdXBwb3J0aXZlIHtcbiAgICAgICAgICAgICAgICAgICAgICAgIG1hcmdpbi10b3A6IDhweDtcbiAgICAgICAgICAgICAgICAgICAgfVxuICAgICAgICAgICAgICAgIH1cbiAgICAgICAgICAgIH1cbiAgICAgICAgfVxuICAgIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/quote/src/Views/calculation/CalculationView.scss"],"names":[],"mappings":"AACI;EACI,aAAA;AAAR;AACQ;EACI,gBAAA;AACZ;AAGgB;EACI,eAAA;AADpB;AAEoB;EACI,eAAA;AAAxB;AAEoB;EACI,eAAA;AAAxB;AACA,4/BAA4/B","sourcesContent":[":host {\n    l7-page {\n        padding: 16px;\n        .margin-top {\n            margin-top: 16px;\n        }\n        l7-page-header ::ng-deep {\n            l7-text-block {\n                &.title {\n                    font-size: 18px;\n                    &.sub {\n                        font-size: 24px;\n                    }\n                    &.supportive {\n                        margin-top: 8px;\n                    }\n                }\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
