import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { ContactDTO } from '../../../models/ContactDTO.interface';
import { LesseeListComponent } from '../lessee-list/lessee-list.component';
import { IContactDto } from '@abcfinlab/api/contact';

@Component({
  selector: 'l7-lessee-list-item',
  templateUrl: './lessee-list-item.component.html',
  styleUrls: ['./lessee-list-item.component.scss']
})
export class LesseeListItemComponent {

  @Input() lesseeList: LesseeListComponent;
  private _lessee: ContactDTO | IContactDto;
  private _checked: boolean = false;
  private _disabled: boolean = false;
  @Output() createQuote: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  set lessee(listEntity: ContactDTO | IContactDto) {
    this._lessee = listEntity;
  }

  set checked(value: boolean) {
    this._checked = value;
  }

  set disabled(value: boolean) {
    this._disabled = value;
  }

  get lessee_name(): string {
    return this._lessee.name;
  }

  get lessee_address(): string {
    return `${this._lessee.street}, ${this._lessee.house_number}, ${this._lessee.postcode}, ${this._lessee.city}`;
  }

  get lessee_crefo_no(): string {
    return this._lessee.crefo_id;
  }

  get is_checked(): boolean {
    return this._checked;
  }

  get list_performing_action(): Observable<boolean> {
    return this.lesseeList.isLoading$.asObservable();
  }

  get classes(): any {
    return {
      checked: this._checked,
      disabled: this._disabled
    };
  }

  /**
   * Start the calculation process for the selected quote
   */
  onCalculateQuote(): void {
    this.createQuote.emit(this._lessee);
  }

}
