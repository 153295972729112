import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';
import { ContractTypeId } from '../../../../models/enums/ContractTypeId.enum';
import { SlbCodes } from '../../../../models/enums/SlbCodes.enum';
import { ObjectGroupDTO } from '../../../../models/ObjectGroupDTO.interface';
import { CalculationState, CalculationUIState } from '../../../../state/Calculation.state';
import { SlbState } from '../../../../state/Slb.state';

@UntilDestroy()
@Component({
  selector: 'l7-slb-code-form',
  templateUrl: './slb-code-selection-form.component.html',
  styleUrls: ['./slb-code-selection-form.component.scss']
})
export class SlbCodeSelectionFormComponent {
  public form: UntypedFormGroup;
  public code: UntypedFormControl = new UntypedFormControl({ value: null, disabled: true }, Validators.required);
  public calculationUiState = CalculationUIState;

  public slbCodes$: Observable<Array<SlbCodes>>;
  public canHaveSlb$: Observable<boolean>;

  public objectGroup$: Observable<ObjectGroupDTO>;
  public contractType$: Observable<ContractTypeId>;
  public calculationType$: Observable<CalculationUIState>;

  constructor(private readonly _fb: UntypedFormBuilder, private readonly _store: Store) {
    this.slbCodes$ = this._store.select(SlbState.getCodes);
    this.canHaveSlb$ = this._store.select(SlbState.getCanHaveSlb);
    this.objectGroup$ = this._store.select(CalculationState.GetObjectGroup);
    this.contractType$ = this._store.select(CalculationState.GetContractType);
    this.calculationType$ = this._store.select(CalculationState.UIMode);
    this.form = this._fb.group({
      active: [{ value: false, disabled: true }],
      code: this.code,
    });

    combineLatest([this.objectGroup$, this.contractType$])
      .pipe(untilDestroyed(this))
      .subscribe(([_objGroup, _contractType]) => {
        if (_objGroup && !_contractType) {
          this.disableControls();
        }

        if (_contractType) {
          this.form.get('active').enable({ emitEvent: false });
          if (this.form.getRawValue().active) {
            this.form.get('code').enable({ emitEvent: false });
          }
        }
      });
  }

  handleToggleChanged(evt: boolean) {
    if (evt) {
      this.form.get('code').enable({ emitEvent: false });
    } else {
      this.form.get('code').patchValue(null);
      this.form.get('code').disable({ emitEvent: false });
    }
  }

  private disableControls() {
    this.form.get('active').disable({ emitEvent: false });
    this.form.get('code').disable({ emitEvent: false });
  }
}
