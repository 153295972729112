//#region Imports

import { IObjectGroupDto, ObjectgroupService } from '@abcfinlab/api/global';
import { ClientService, FinanceAdminService, IClientDto, IContractTypeDto, IHandlingFeeOptionDto, IRetailerResponseDto, IRetailerUserResponseDto, IUpdateRetailerRequestDto } from '@abcfinlab/api/retailer';
import { ControlsOf, Validators as CoreValidators, CountryCode, DeepRequired, FormChangesObserver, FormValidator, Globals, once, TranslationFacade } from '@abcfinlab/core';
import { BusyBoxService, MessageBoxButton, MessageBoxResult, MessageBoxService, ToastService } from '@abcfinlab/ui';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, throwError } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { IRetailerUsersSearchCondition } from '../Models/Interfaces/IRetailerUsersSearchCondition';
import { RETAILER_OVERVIEW_ROUTE_PATH, RETAILER_USER_EDIT_ROUTE_PATH } from '../Routing/RoutePaths';
import { Validators as AdministrationValidators } from '../Validators/Validators';
import { CreateRetailerUserView } from './Dialogs/CreateRetailerUserView';
//#endregion

/**
 * @private
 */
const EMPTY_SEARCH_CONDITION: IRetailerUsersSearchCondition = {
    term: '',
};

/**
 * @private
 */
const isEmptySearchCondition = (other: IRetailerUsersSearchCondition): boolean => EMPTY_SEARCH_CONDITION.term.toLowerCase() === other.term.toLowerCase();

/**
 * The presenter of the {@link EditRetailerView} view.
 *
 * @internal
 */
@Injectable()
export class EditRetailerViewPresenter {

    //#region Fields

    private readonly _financeAdminService: FinanceAdminService;
    private readonly _route: ActivatedRoute;
    private readonly _busyBoxService: BusyBoxService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _formValidator: FormValidator;
    private readonly _formChangesObserver: FormChangesObserver;
    private readonly _toastService: ToastService;
    private readonly _clientService: ClientService;
    private readonly _messageBoxService: MessageBoxService;
    private readonly _objectgroupService: ObjectgroupService;
    private readonly _router: Router;
    private readonly _dialog: MatDialog;
    private readonly _retailerIdSubject: BehaviorSubject<string>;
    private readonly _factorsSubject: BehaviorSubject<Array<string>>;
    private readonly _clientsSubject: BehaviorSubject<Array<IClientDto>>;
    private readonly _objectGroupsSubject: BehaviorSubject<Array<IObjectGroupDto>>;
    private readonly _contractTypesSubject: BehaviorSubject<Array<IContractTypeDto>>;
    private readonly _clientHandlingFeeSubject: BehaviorSubject<number | null>;
    private readonly _allowedTopLevelDomainsSubject: BehaviorSubject<Array<string>>;
    private readonly _retailerSubject: BehaviorSubject<IRetailerResponseDto | null>;
    private readonly _form: FormGroup<ControlsOf<DeepRequired<IUpdateRetailerRequestDto>>>;
    private readonly _conditionSubject: BehaviorSubject<IRetailerUsersSearchCondition>;
    private readonly _isDefaultSearchConditionSubject: BehaviorSubject<boolean>;
    private readonly _dataSource: MatTableDataSource<IRetailerUserResponseDto>;
    private readonly _dataSourceTotalSubject: BehaviorSubject<number>;
    private readonly _pageSizeSubject: BehaviorSubject<number>;
    private readonly _pageSizesSubject: BehaviorSubject<Array<number>>;
    private readonly _selectedTabIndexSubject: BehaviorSubject<number>;
    private readonly _objectGroupsTerm: BehaviorSubject<string>;
    private readonly _isNavigating: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _columns: Array<string>;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `EditRetailerViewPresenter` class.
     *
     * @public
     */
    public constructor(financeAdminService: FinanceAdminService, route: ActivatedRoute, clientService: ClientService, toastService: ToastService,
        busyBoxService: BusyBoxService, translationFacade: TranslationFacade, formValidator: FormValidator, formChangesObserver: FormChangesObserver,
        objectgroupService: ObjectgroupService, messageBoxService: MessageBoxService, router: Router, dialog: MatDialog) {
        this._financeAdminService = financeAdminService;
        this._route = route;
        this._clientService = clientService;
        this._busyBoxService = busyBoxService;
        this._translationFacade = translationFacade;
        this._formValidator = formValidator;
        this._formChangesObserver = formChangesObserver;
        this._toastService = toastService;
        this._messageBoxService = messageBoxService;
        this._objectgroupService = objectgroupService;
        this._router = router;
        this._dialog = dialog;
        this._conditionSubject = new BehaviorSubject(EMPTY_SEARCH_CONDITION);
        this._isDefaultSearchConditionSubject = new BehaviorSubject(true);
        this._retailerIdSubject = new BehaviorSubject(null);
        this._retailerSubject = new BehaviorSubject(null);
        this._objectGroupsSubject = new BehaviorSubject([]);
        this._factorsSubject = new BehaviorSubject([]);
        this._clientsSubject = new BehaviorSubject([IClientDto.Pag, IClientDto.Fhl]);
        this._contractTypesSubject = new BehaviorSubject([IContractTypeDto.Ta, IContractTypeDto.ItFlexSmart, IContractTypeDto.Va]);
        this._clientHandlingFeeSubject = new BehaviorSubject(null);
        this._allowedTopLevelDomainsSubject = new BehaviorSubject(new Array<string>());
        this._dataSourceTotalSubject = new BehaviorSubject(0);
        this._pageSizeSubject = new BehaviorSubject(Globals.Page.DEFAULT_PAGE_SIZE);
        this._pageSizesSubject = new BehaviorSubject(Globals.Page.DEFAULT_PAGE_SIZES);
        this._selectedTabIndexSubject = new BehaviorSubject(0);
        this._dataSource = new MatTableDataSource();
        this._objectGroupsTerm = new BehaviorSubject('');
        this._columns = [
            'givenName',
            'familyName',
            'email',
            'phoneNumber',
            'groups',
            'active'
        ];
        this._form = new FormGroup<ControlsOf<DeepRequired<IUpdateRetailerRequestDto>>>({
            retailer: new FormGroup({
                contactId: new FormControl(''),
                id: new FormControl('', Validators.required),
                city: new FormControl('', Validators.required),
                client: new FormControl<IClientDto>({ value: null, disabled: true }, Validators.required),
                contactNumber: new FormControl('', Validators.required, AdministrationValidators.contactNumberExistsAsync(financeAdminService, () => [this._retailerSubject.value.retailer.contactNumber])),
                factor: new FormControl<string>({ value: null, disabled: false }, Validators.required),
                name: new FormControl('', Validators.required),
                partnerNumber: new FormControl('', [Validators.required, Validators.maxLength(10), Validators.minLength(10)], AdministrationValidators.partnerNumberExistsAsync(financeAdminService, () => [this._retailerSubject.value.retailer.partnerNumber])),
                postalCode: new FormControl('', [Validators.required, CoreValidators.postalCode(CountryCode.DE)]),
                streetAndHousenumber: new FormControl('', Validators.required),
            }),
            retailerConfig: new FormGroup({
                active: new FormControl<boolean>(true),
                adjustableProvision: new FormControl<boolean>(false),
                contractTypes: new FormControl<any>([''], Validators.required),
                handlingFeeOption: new FormControl<IHandlingFeeOptionDto>(IHandlingFeeOptionDto.AlwaysCharge, Validators.required),
                objectGroupCodes: new FormControl(new Array<string>(), Validators.required),
                preRent: new FormControl<boolean>(true),
                provision: new FormControl<number>(null, [Validators.required, Validators.min(0), Validators.max(5)]),
            }),
            contact: new FormGroup({
                officeService: new FormGroup({
                    familyName: new FormControl('', Validators.required),
                    givenName: new FormControl('', Validators.required),
                    email: new FormControl('', [Validators.required, Validators.email], CoreValidators.emailEndsWithDomainAsync(this._financeAdminService.getAllowedDomains())),
                    phoneNumber: new FormControl('', [Validators.required, CoreValidators.phoneNumber()]),
                }),
                fieldService: new FormGroup({
                    familyName: new FormControl('', Validators.required),
                    givenName: new FormControl('', Validators.required),
                    email: new FormControl('', [Validators.required, Validators.email], CoreValidators.emailEndsWithDomainAsync(this._financeAdminService.getAllowedDomains())),
                    phoneNumber: new FormControl('', [Validators.required, CoreValidators.phoneNumber()]),
                })
            })
        });
        this._formValidator.validate(this._form);
        this._router.events.subscribe(evt => {
            if (evt instanceof NavigationStart) {
                this._isNavigating.next(true);
            } else if (evt instanceof NavigationEnd) {
                this._isNavigating.next(false);
            }
        })
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `form` property.
     *
     * @public
     * @readonly
     */
    public get form(): FormGroup<ControlsOf<DeepRequired<IUpdateRetailerRequestDto>>> {
        return this._form;
    }

    /**
     * Returns an array of error fields on the input form
     * @public
     */
    public getInputErrors(path: string): Array<string> {
        const errors = this._form?.get(path)?.errors ?? {};
        return Object.keys(errors);
    }

    /**
     * Returns the translation for a given input field error based on its context
     * @public
     */
    public getErrorMessage(context: string, error: string): string {
        return this._translationFacade.instant(`input.${context}.error.${error}`);
    }

    /**
     * Returns the `retailerId` property.
     *
     * @public
     * @readonly
     */
    public get retailerId(): Observable<string> {
        return this._retailerIdSubject.asObservable();
    }

    /**
     * Returns the `retailer` property.
     *
     * @public
     * @readonly
     */
    public get retailer(): Observable<IRetailerResponseDto | null> {
        return this._retailerSubject.asObservable();
    }

    /**
     * Returns the `clients` property.
     *
     * @public
     * @readonly
     */
    public get clients(): Observable<Array<IClientDto>> {
        return this._clientsSubject.asObservable();
    }

    /**
     * Returns the `factors` property.
     *
     * @public
     * @readonly
     */
    public get factors(): Observable<Array<string>> {
        return this._factorsSubject.asObservable();
    }

    /**
     * Returns the `objectGroups` property.
     *
     * @public
     * @readonly
     */
    public get objectGroups(): Observable<Array<IObjectGroupDto>> {
        return this._objectGroupsSubject.asObservable().pipe(
            withLatestFrom(this._objectGroupsTerm.asObservable()),
            // eslint-disable-next-line arrow-body-style
            map(([values, term]) => {
                return values.filter(x => {
                    let hasResult = false;
                    // filter out the selected ones...
                    hasResult = !this._form.controls.retailerConfig.controls.objectGroupCodes.value.includes(x.code.toString());

                    if (hasResult && term) {
                        // filter by term in input
                        const key = `${x.code}: ${x.name}`;
                        hasResult = key.toLowerCase().includes(term.toLowerCase());
                    }

                    return hasResult;
                });
            })
        );
    }

    /**
     * Returns the `contractTypes` property.
     *
     * @public
     * @readonly
     */
    public get contractTypes(): Observable<Array<IContractTypeDto>> {
        return this._contractTypesSubject.asObservable();
    }

    /**
     * Returns the `clientHandlingFee` property.
     *
     * @public
     * @readonly
     */
    public get clientHandlingFee(): Observable<number | null> {
        return this._clientHandlingFeeSubject.asObservable();
    }

    /**
     * Returns the `allowedTopLevelDomains` property.
     *
     * @public
     * @readonly
     */
    public get allowedTopLevelDomains(): Observable<Array<string>> {
        return this._allowedTopLevelDomainsSubject.asObservable();
    }

    /**
     * Returns the `columns` property.
     *
     * @public
     * @readonly
     */
    public get columns(): Array<string> {
        return this._columns;
    }

    /**
     * Returns the `dataSource` property.
     *
     * @public
     * @readonly
     */
    public get dataSource(): MatTableDataSource<IRetailerUserResponseDto> {
        return this._dataSource;
    }

    /**
     * Returns the `dataSourceTotal` property.
     *
     * @public
     * @readonly
     */
    public get dataSourceTotal(): Observable<number> {
        return this._dataSourceTotalSubject.asObservable();
    }

    /**
     * Returns the `pageSize` property.
     *
     * @public
     * @readonly
     */
    public get pageSize(): Observable<number> {
        return this._pageSizeSubject.asObservable();
    }

    /**
     * Returns the `pageSizes` property.
     *
     * @public
     * @readonly
     */
    public get pageSizes(): Observable<Array<number>> {
        return this._pageSizesSubject.asObservable();
    }

    /**
     * Returns the `condition` property.
     *
     * @public
     * @readonly
     */
    public get condition(): Observable<IRetailerUsersSearchCondition> {
        return this._conditionSubject.asObservable();
    }

    /**
     * Returns the `isDefaultSearchCondition` property.
     *
     * @public
     * @readonly
     */
    public get isDefaultSearchCondition(): Observable<boolean> {
        return this._isDefaultSearchConditionSubject.asObservable();
    }

    /**
     * Returns the `selectedTabIndex` property.
     *
     * @public
     * @readonly
     */
    public get selectedTabIndex(): Observable<number> {
        return this._selectedTabIndexSubject.asObservable();
    }

    //#endregion

    //#region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
        this.initializeQueryParams();
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
        // nothing
    }

    /**
     * @internal
     */
    public onFilterObjectGroups(value: string): void {
        this._objectGroupsTerm.next(value);
    }

    /**
     * @internal
     */
    public getObjectGroupFromCode(code: string): IObjectGroupDto | null {
        return this._objectGroupsSubject.value.find(y => y.code === parseInt(code)) ?? null;
    }

    /**
     * @internal
     */
    public translateRoles(roles: Array<string>): Array<string> {
        return roles.map(x => this._translationFacade.instant(`roles.${x}`));
    }

    /**
     * @internal
     */
    public onObjectGroupSelected(value: IObjectGroupDto, autocompleteTrigger: MatAutocompleteTrigger, input: HTMLInputElement): void {
        this._form.controls.retailerConfig.controls.objectGroupCodes.value.push(value.code.toString());
        this._form.controls.retailerConfig.controls.objectGroupCodes.updateValueAndValidity();

        // hack to reopen the auto complete popup
        setTimeout(() => {
            autocompleteTrigger.openPanel();
        }, 100);

        // reset the input element
        input.value = '';
        this.onFilterObjectGroups('');
    }

    /**
     * @internal
     */
    public onObjectGroupRemove(value: IObjectGroupDto | null): void {
        if (value) {
            const index = this._form.controls.retailerConfig.controls.objectGroupCodes.value.indexOf(value.code.toString());
            this._form.controls.retailerConfig.controls.objectGroupCodes.value.splice(index, 1);
            this._form.controls.retailerConfig.controls.objectGroupCodes.updateValueAndValidity();
        }
    }

    /**
     * @internal
     */
    public onTermChanged(value: string): void {
        const condition = {
            ...this._conditionSubject.value,
            term: value.trim().toLowerCase()
        };

        this._conditionSubject.next(condition);
        this._isDefaultSearchConditionSubject.next(isEmptySearchCondition(this._conditionSubject.value));
        this.initializeRetailerUsers(this._pageSizeSubject.value, 0);
    }

    /**
     * @internal
     */
    public onResetConditions(): void {
        this._conditionSubject.next(EMPTY_SEARCH_CONDITION);
        this._isDefaultSearchConditionSubject.next(true);
        this.initializeRetailerUsers(this._pageSizeSubject.value, 0);
    }

    /**
     * @internal
     */
    public onPageChanged(page: PageEvent): void {
        this._pageSizeSubject.next(page.pageSize);
        this.initializeRetailerUsers(page.pageSize, page.pageIndex);
    }

    /**
     * @internal
     */
    public onRowSelected(row: IRetailerUserResponseDto): void {
        void this._router.navigate([`../../${RETAILER_USER_EDIT_ROUTE_PATH}`], {
            relativeTo: this._route,
            queryParams: {
                retailerId: this._retailerIdSubject.value,
                retailerUserId: row.id
            }
        });
    }

    /**
     * @internal
     */
    public onCreateRetailerUser(): void {
        once(this._dialog.open(CreateRetailerUserView, {
            closeOnNavigation: true,
            disableClose: true,
            autoFocus: true,
            maxWidth: '100vw',
            maxHeight: '100vh',
            data: { retailerId: this._retailerIdSubject.value }
        }).afterClosed(), result => {
            if (result) {
                this.initialize();
            }
        });
    }

    /**
     * @internal
     */
    public onClientChanged(newValue?: string): void {
        const client = this._form.controls.retailer.controls.client.value;

        once(this._clientService.getClientInformation({ clientId: client }), (x => {
            this._clientHandlingFeeSubject.next(x.handlingFee);
            this._factorsSubject.next(x.factors);

            if (newValue) {
                this._form.controls.retailer.controls.factor.setValue(newValue);
            } else {
                this._form.controls.retailer.controls.factor.reset();
            }
            if (!x.factors) {
                this._form.controls.retailer.controls.factor.disable();
                this._form.updateValueAndValidity();
            }
        }));
    }

    /**
     * @internal
     */
    public onSubmit(cancel: boolean = false): void {
        if (cancel) {
            this._messageBoxService.show('Vorgang abbrechen', 'Sind Sie sicher, dass Sie den Vorgang abbrechen wollen? Ihre Änderungen werden nicht gespeichert.', MessageBoxButton.YesNo, {
                labels: {
                    yes: this._translationFacade.translate('global.yes'),
                    no: this._translationFacade.translate('global.no')
                }
            }).subscribe(result => {
                if (result === MessageBoxResult.Yes) {
                    void this._router.navigate([`../../${RETAILER_OVERVIEW_ROUTE_PATH}`], {
                        relativeTo: this._route
                    });
                }
            });

            return;
        }

        this._formValidator.validate(this._form);

        if (this._formValidator.errors(this._form).length > 0) {
            this._toastService.show(this._translationFacade.instant('administration.retailers.edit.toast.form.error'), 'danger');
        } else {
            once(this._financeAdminService.updateRetailer({
                body: this._form.getRawValue(),
            })
                , () => {
                    this._toastService.show(this._translationFacade.instant('administration.retailers.edit.toast.success'), 'success');
                    void this._router.navigate([`../../${RETAILER_OVERVIEW_ROUTE_PATH}`], {
                        relativeTo: this._route
                    });
                }, error => {
                    this._toastService.show(this._translationFacade.instant('administration.retailers.edit.toast.error'), 'danger');
                    throwError(error);
                });
        }
    }

    /**
     * @internal
     */
    public onTabIndexChanged(index: number): void {
        once(this._router.routerState.root.queryParams, x => {
            if (this._isNavigating.getValue()) {
                return;
            }
            void this._router.navigate([], {
                relativeTo: this._route,
                queryParamsHandling: 'merge',
                replaceUrl: true,
                queryParams: {
                    ...x,
                    tabIndex: index
                }
            });
        });
    }

    /**
     * @private
     */
    private initializeQueryParams(): void {
        once(this._route.queryParams, x => {
            if (!x.id) {
                throw new Error('The \'id\' is required.');
            }
            this._retailerIdSubject.next(x.id);
            this._selectedTabIndexSubject.next(x?.tabIndex ?? 0);

            combineLatest([
                this._financeAdminService.getRetailerById({ id: x.id }),
                this._financeAdminService.searchUser({
                    retailerId: this._retailerIdSubject.getValue(),
                    page: 0,
                    pageSize: this._pageSizeSubject.value,
                    searchString: this._conditionSubject.getValue().term
                }),
                this._objectgroupService.getObjectgroups(),
                this._financeAdminService.getAllowedDomains()
            ]).subscribe(([x, y, z, w]) => {
                this._dataSource.data = y.content;
                this._dataSourceTotalSubject.next(y.totalElements);
                this._retailerSubject.next(x);
                this._form.controls.retailer.controls.client.patchValue(x.retailer.client)
                this.onClientChanged(x.retailer.factor);
                this._form.patchValue(x);
                this._objectGroupsSubject.next(z);
                this._allowedTopLevelDomainsSubject.next(w);
            }, error => {
                this._messageBoxService.show('Nicht gefunden', error.error.error_description, MessageBoxButton.OK, {
                    labels: {
                        ok: this._translationFacade.translate('global.ok')
                    }
                });
            });
        });
    }

    /**
     * @private
     */
    private initializeRetailerUsers(size: number, index: number): void {
        if (this._retailerIdSubject.getValue() !== undefined && this._retailerIdSubject.getValue() !== null) {
            once(this._busyBoxService.show(null, this._translationFacade.translate('global.busy'), this._financeAdminService.searchUser({
                retailerId: this._retailerIdSubject.getValue(),
                page: index,
                pageSize: size,
                searchString: this._conditionSubject.getValue().term
            }), { id: 'getRetailersUsers' }), x => {
                this._dataSource.data = x.content;
                this._dataSourceTotalSubject.next(x.totalElements);
            });
        }
    }

    //#endregion

}
