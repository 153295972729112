//#region Imports

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProvokeErrorService } from '../Services/ProvokeErrorService';

//#endregion

@Injectable({ providedIn: 'root' })
export class ProvokeErrorHttpInterceptor implements HttpInterceptor {

    //#region Fields

    private readonly _provokeErrorService: ProvokeErrorService;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `ProvokeErrorHttpInterceptor` class.
     *
     * @public
     */
    public constructor(provokeErrorService: ProvokeErrorService) {
        this._provokeErrorService = provokeErrorService;
    }

    //#endregion

    //#region Methods

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this._provokeErrorService.isEnabled) {
            req = req.clone({
                headers: req.headers.set('X-Provoke-Errors', JSON.stringify(this._provokeErrorService.args) ?? '')
            });
        }

        return next.handle(req);
    }

    //#endregion

}
