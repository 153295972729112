// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .mat-mdc-card {
  padding: 0;
}
:host .text {
  padding-left: 5px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNsYi1xdW90ZS1kZXRhaWwtY2FyZC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFSTtFQUNFLFVBQUE7QUFETjtBQUtJO0VBQ0ksaUJBQUE7QUFIUiIsImZpbGUiOiJzbGItcXVvdGUtZGV0YWlsLWNhcmQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XG4gIDo6bmctZGVlcCB7XG4gICAgLm1hdC1tZGMtY2FyZCB7XG4gICAgICBwYWRkaW5nOiAwO1xuICAgIH1cbiAgfVxuXG4gICAgLnRleHQge1xuICAgICAgICBwYWRkaW5nLWxlZnQ6IDVweDtcbiAgICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./apps/shell/src/app/private/components/slb-quote-detail-card/slb-quote-detail-card.component.scss"],"names":[],"mappings":"AAEI;EACE,UAAA;AADN;AAKI;EACI,iBAAA;AAHR;AACA,weAAwe","sourcesContent":[":host {\n  ::ng-deep {\n    .mat-mdc-card {\n      padding: 0;\n    }\n  }\n\n    .text {\n        padding-left: 5px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
