//#region Imports

import { ContactService, IContactDto, IIdTypeDto } from '@abcfinlab/api/contact';
import {
    IContractDocumentDto, IDocumentTypeDto,
    IRetailerContractDetailInformationResultDto, IRetailerContractResultDto, IWorkflowInfoDto, RetailerContractService,
    RetailerContractWorkflowService
} from '@abcfinlab/api/contract';
import {
    ICalculationSettingsDto,
    IWorkflowStepDto,
    IWorkflowStepStatusDto,
    SettingsService
} from '@abcfinlab/api/global';
import { RetailerAdminService } from '@abcfinlab/api/retailer';
import { once, TranslationFacade } from '@abcfinlab/core';
import { BusyBoxService, MessageBoxButton, MessageBoxResult, MessageBoxService, ToastService } from '@abcfinlab/ui';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DigitalSignDialogView } from '../../Dialogs/DigitalSignDialog/DigitalSignDialogView';
import { ITakeoverDocumentationDialogData, ITakeoverDocumentationDialogResult, TakeoverDocumentationDialogView } from '../../Dialogs/takeover-documentation/takeoverDocumentationDialogView';
import { UploadDeliveryDocumentDialogView } from '../../Dialogs/UploadDeliveryDocumentDialog/UploadDeliveryDocumentDialogView';
import { UploadTakeoverDocumentDialogView } from '../../Dialogs/UploadTakeoverDocumentDialog/UploadTakeoverDocumentDialogView';

//#endregion

/**
 * The presenter of the {@link RetailerContractDetailsView} view.
 *
 * @internal
 */

export enum AcknowledgementsActionsTypes {
    Delete = 'ACTION_RETAILER_CONTRACT_ACKNOWLEDGEMENT_DELETE',
    GetLinK = 'ACTION_RETAILER_CONTRACT_ACKNOWLEDGEMENT_GET_REMOTE_LINK',
    Retry = 'ACTION_RETAILER_CONTRACT_ACKNOWLEDGEMENT_RETRY',
}

@UntilDestroy()
@Injectable()
export class RetailerContractDetailsViewPresenter {

    //#region Fields

    private readonly _busyBoxService: BusyBoxService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _toastService: ToastService;
    private readonly _retailerContractService: RetailerContractService;
    private readonly _messageBoxService: MessageBoxService;
    private readonly _retailerContractWorkflowService: RetailerContractWorkflowService;
    private readonly _contactService: ContactService;
    private readonly _settingsService: SettingsService;
    private readonly _activatedRoute: ActivatedRoute;
    private readonly _contractDetails: BehaviorSubject<IRetailerContractDetailInformationResultDto> = new BehaviorSubject<IRetailerContractDetailInformationResultDto>(null);
    private readonly _contractTasks: BehaviorSubject<Array<IWorkflowStepDto>> = new BehaviorSubject<Array<IWorkflowStepDto>>(null);
    private readonly _openTasks: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    private readonly _workflowInfo: BehaviorSubject<IWorkflowInfoDto> = new BehaviorSubject<IWorkflowInfoDto>(null);
    private readonly _pendingTasks: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    private readonly _invoiceUploadDate: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private readonly _lessee: BehaviorSubject<IContactDto> = new BehaviorSubject<IContactDto>(null);
    private readonly _contractDocuments: BehaviorSubject<MatTableDataSource<IContractDocumentDto>> = new BehaviorSubject<MatTableDataSource<IContractDocumentDto>>(null);
    private readonly _calculationSettings: BehaviorSubject<ICalculationSettingsDto> = new BehaviorSubject<ICalculationSettingsDto>(null);
    private readonly _dialog: MatDialog;
    private _activatedRouteSubscription: Subscription;
    private _documentsDataSource: MatTableDataSource<IContractDocumentDto>;
    private readonly _retailerAdminService: RetailerAdminService;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `RetailerContractDetailsViewPresenter` class.
     *
     * @public
     */
    public constructor(toastService: ToastService, busyBoxService: BusyBoxService, translationFacade: TranslationFacade,
        messageBoxService: MessageBoxService, activatedRoute: ActivatedRoute, contactService: ContactService, dialogService: MatDialog,
        retailerContractWorkflowService: RetailerContractWorkflowService, retailerContractService: RetailerContractService,
        retailerAdminService: RetailerAdminService, settingsService: SettingsService) {
        this._activatedRoute = activatedRoute;
        this._contactService = contactService;
        this._settingsService = settingsService;
        this._messageBoxService = messageBoxService;
        this._retailerContractWorkflowService = retailerContractWorkflowService;
        this._translationFacade = translationFacade;
        this._dialog = dialogService;
        this._busyBoxService = busyBoxService;
        this._toastService = toastService;
        this._retailerContractService = retailerContractService;
        this._retailerAdminService = retailerAdminService;
    }

    //#endregion

    //#region Properties
    /**
     * Returns the `contractDetails` property.
     *
     * @public
     * @readonly
     */
    public get contractDetails(): Observable<IRetailerContractDetailInformationResultDto> {
        return this._contractDetails.asObservable();
    }

    /**
     * Returns the `contractTasks` property.
     *
     * @public
     * @readonly
     */
    public get contractTasks(): Observable<Array<IWorkflowStepDto>> {
        return this._contractTasks.asObservable();
    }

    /**
     * Returns the `contractDocuments` property.
     *
     * @public
     * @readonly
     */
    public get contractDocuments(): Observable<MatTableDataSource<IContractDocumentDto>> {
        return this._contractDocuments.asObservable();
    }

    /**
     * Returns the `openTasks` property.
     *
     * @public
     * @readonly
     */
    public get openTasks(): Observable<number> {
        return this._openTasks.asObservable();
    }

    /**
     * Returns the `completedTasks` property.
     *
     * @public
     * @readonly
     */
    public get workFlowInfo(): Observable<IWorkflowInfoDto> {
        return this._workflowInfo.asObservable();
    }

    public get invoiceUploadDate(): Observable<string> {
        return this._invoiceUploadDate.asObservable();
    }

    /**
     * Returns the `completedTasks` property.
     *
     * @public
     * @readonly
     */
    public get lessee(): Observable<IContactDto> {
        return this._lessee.asObservable();
    }

    //#endregion

    //#region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
        this._activatedRouteSubscription = this._activatedRoute.data
            .subscribe(res => {
                if (res.contractDetails) {
                    this.updateContractDetailsInfo(res.contractDetails);
                    once(combineLatest([
                        this._contactService.getById({ id: res.contractDetails.detail.lesseeId, idType: IIdTypeDto.Id }),
                        this._settingsService.getCalculationSettingsDto()
                    ]), ([c, s]) => {
                        this._lessee.next(c);
                        this._calculationSettings.next(s);
                    });
                }
            });
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
        this._activatedRouteSubscription.unsubscribe();
    }
    public uploadInvoice(files: Array<Blob>, task: IWorkflowStepDto): void {
        Array.from(files).forEach(file => {
            this._busyBoxService.show(null, null, this._retailerContractWorkflowService.uploadDocument({ stepId: task.id, documentType: IDocumentTypeDto.Invoice, body: { file: file } }))
                .pipe(untilDestroyed(this))
                .subscribe(x => {
                    this._busyBoxService.show(null, null, this._retailerContractService.get({ contractNo: this._contractDetails.value.contractNo }))
                        .pipe(untilDestroyed(this))
                        .subscribe(res => {
                            this._toastService.show('Die Rechnung wurde erfolgreich hochgeladen', 'success');
                            this.updateContractDetailsInfo(res);
                        });
                });
        });
    }

    public deleteInvoice(task: IWorkflowStepDto): void {
        this._messageBoxService.show(this._translationFacade.instant('contract_management.retailers.contractDetails.dialogs.deleteDocument.title'), this._translationFacade.instant('contract_management.retailers.contractDetails.dialogs.deleteDocument.subTitle'), MessageBoxButton.YesNo, {
            labels: {
                yes: this._translationFacade.instant('global.yes'),
                no: this._translationFacade.instant('global.no')
            }
        }).subscribe(result => {
            if (result === MessageBoxResult.Yes) {
                this._busyBoxService.show(null, null, this._retailerContractWorkflowService.triggerContextAction({ stepId: task.id, stepContextAction: task.actions[0], body: {} }))
                    .pipe(untilDestroyed(this))
                    .subscribe(x => {
                        this.getWorkflowStatus(true);
                    });
            }
        });
    }
    public deleteAcknowledgment(task: IWorkflowStepDto): void {
        const deleteAcknowledgmentAction = task.actions.filter(x => x === AcknowledgementsActionsTypes.Delete || x === AcknowledgementsActionsTypes.Retry);
        if (deleteAcknowledgmentAction.length === 1) {
            this._messageBoxService.show(this._translationFacade.instant('contract_management.retailers.contractDetails.dialogs.deleteDocument.title'),
                this._translationFacade.instant('contract_management.retailers.contractDetails.dialogs.deleteDocument.subTitle'), MessageBoxButton.YesNo, {
                labels: {
                    yes: this._translationFacade.instant('global.yes'),
                    no: this._translationFacade.instant('global.no')
                }
            }).subscribe(result => {
                if (result === MessageBoxResult.Yes) {
                    this._busyBoxService.show(null, null, this._retailerContractWorkflowService.triggerContextAction({ stepId: task.id, stepContextAction: deleteAcknowledgmentAction[0], body: {} }))
                        .pipe(untilDestroyed(this))
                        .subscribe(x => {
                            this.getWorkflowStatus(true);
                        });
                }
            });
        }
    }
    public onLinkCopy(payload: boolean): void {
        this._toastService.show('In Zwischenablage gespeichert!', 'success');
    }

    public openTakeoverDocumentationUploadDialog(step: IWorkflowStepDto): void {
        once(this._dialog.open<TakeoverDocumentationDialogView, ITakeoverDocumentationDialogData, ITakeoverDocumentationDialogResult>(TakeoverDocumentationDialogView, {
            width: '80vw',
            disableClose: true,
            data: {
                companyName: this._lessee.value.name
            }
        }).afterClosed().pipe(
            filter(x => !x.canceled)
        ), x => {
            switch (x.kind) {
                case 'digitalSign':
                    once(this._dialog.open(DigitalSignDialogView, {
                        width: '80vw',
                        disableClose: true,
                        data: {
                            contractDetails: this._contractDetails.value,
                            contactInfo: this._lessee.value,
                            step: step,
                            calculationSettings: this._calculationSettings.getValue()
                        }
                    }).afterClosed(), x => {
                        if (x.canceled) {
                            this.openTakeoverDocumentationUploadDialog(step);
                        } else {
                            this.getWorkflowStatus();
                        }
                    });
                    break;
                case 'uploadDeliveryDocument':
                    once(this._dialog.open(UploadDeliveryDocumentDialogView, {
                        width: '80vw',
                        disableClose: true,
                        data: {
                            step: step
                        }
                    }).afterClosed(), x => {
                        if (x.canceled) {
                            this.openTakeoverDocumentationUploadDialog(step);
                        } else {
                            this.getWorkflowStatus();
                        }
                    });
                    break;
                case 'uploadTakeoverDocument':
                    once(this._dialog.open(UploadTakeoverDocumentDialogView, {
                        width: '80vw',
                        disableClose: true,
                        data: {
                            step: step,
                            leasingQuoteId: this._contractDetails.value.leasingQuoteId,
                            contractNumber: this._contractDetails.value.contractNo,
                            calculationSettings: this._calculationSettings.getValue()
                        }
                    }).afterClosed(), x => {
                        if (x.canceled) {
                            this.openTakeoverDocumentationUploadDialog(step);
                        } else {
                            this.getWorkflowStatus();
                        }
                    });
                    break;
            }
        });
    }
    public triggerRequestPayment(task: IWorkflowStepDto): void {

        once(this._busyBoxService.show(null, null, this._retailerAdminService.getRetailerAdminConfig()), retailerConfig => {
            if (!retailerConfig.retailerBankAccount) {
                this._messageBoxService.show(this._translationFacade.instant('contract_management.retailers.contractDetails.dialogs.NoBankData.title'), this._translationFacade.instant('contract_management.retailers.contractDetails.dialogs.NoBankData.subTitle', { contactPerson: 'Ihren Administrator' }), MessageBoxButton.OK, {
                    labels: {
                        ok: 'OK'
                    }
                });
            } else if (task.startable || (task.status === IWorkflowStepStatusDto.Error && task.actions.length > 0)) {
                this._busyBoxService.show(null, null, this._retailerContractWorkflowService.triggerMainAction({ stepId: task.id, body: {} }))
                    .pipe(untilDestroyed(this))
                    .subscribe(res => {
                        this.getWorkflowStatus(true);
                    });
            }
        });

    }
    private updateContractDetailsInfo(contractDetails: IRetailerContractResultDto): void {
        this._contractDetails.next(contractDetails.detail);
        this._contractTasks.next(contractDetails.workflow.workflowSteps);
        this._documentsDataSource = new MatTableDataSource<IContractDocumentDto>(contractDetails.documents);
        this._contractDocuments.next(this._documentsDataSource);
        this._workflowInfo.next(contractDetails.workflow.workflowInfo);
        this._openTasks.next(contractDetails.workflow.workflowSteps.filter(step => step.status === IWorkflowStepStatusDto.Open).length);
        this._invoiceUploadDate.next(contractDetails.documents.filter(targetDocument => targetDocument.type === IDocumentTypeDto.Invoice)[0]?.creationDate);
        this._pendingTasks.next(contractDetails.workflow.workflowSteps.filter(step => step.status === IWorkflowStepStatusDto.Pending).length);
    }

    private getWorkflowStatus(showSuccess: boolean = false): void {
        once(this._busyBoxService.show(null, null, this._retailerContractService.get({ contractNo: this._contractDetails.value.contractNo }), { id: 'updateDetails' }), res => {
            this.updateContractDetailsInfo(res);
            if (showSuccess) {
                this._toastService.show('Aktion erfolgreich!', 'success');
            }
        });
    }
    //#endregion

}
