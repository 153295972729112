
import { Injectable } from '@angular/core';
import { DetailRowDirective } from './DetailRowDirective';

@Injectable()
export class DetailRowService {

    //#region Fields

    private readonly _cache: Array<DetailRowDirective>;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `DetailRowService` class.
     *
     * @public
     */
    public constructor() {
        this._cache = [];
    }

    //#endregion

    //#region Methods

    public register(detail: DetailRowDirective): void {
        this._cache.push(detail);
    }

    public unregister(detail: DetailRowDirective): void {
        const index = this._cache.indexOf(detail);
        this._cache.splice(index, 1);
    }

    public expand(detail: DetailRowDirective): void {
        this._cache.filter(x => x !== detail).forEach(x => x.collapse());
        detail.expand();
    }

    //#endregion

}
