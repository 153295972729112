import { Directive, NgModule } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import  * as IBAN  from 'iban';

@Directive({
  selector: '[l7IbanValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: IbanValidatorDirective, multi: true }],
})
export class IbanValidatorDirective implements Validator {
  validate(c: AbstractControl): { [key: string]: any } {
    if (c.value && !IBAN.isValid(c.value)) {
        return { iban: { value: c.value } };
      }
      return null as any;
}
}

@NgModule({
    declarations: [
        IbanValidatorDirective
    ],
    exports: [
        IbanValidatorDirective
    ]
})
export class IbanValidatorModule { }

