import { ErrorHandler, Injectable } from '@angular/core';
import { faro } from '@grafana/faro-web-sdk';
import { FeatureManager } from './FeatureManager';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    private readonly _featureManager: FeatureManager;
    public constructor(featureManager: FeatureManager) {
        this._featureManager = featureManager;
    }
    public handleError(error: any): void {
        if (error instanceof Error && this._featureManager.active('grafanaFaro')) {
            faro.api.pushError(error);
        }
    }
}
