//#region Imports

import { IRealmDto, IUserInfoDto } from '@abcfinlab/api/login';
import { RetailerAdminService } from '@abcfinlab/api/retailer';
import { SESSION_AVAILABLE_TOKEN, TranslationModule, USER_INFO_TOKEN, once } from '@abcfinlab/core';
import { AppbarAction, BrandModule, PresenterService } from '@abcfinlab/layout';
import { SizeDirectiveModule, TextBlockModule } from '@abcfinlab/ui';
import { CommonModule } from '@angular/common';
import { Inject, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { Router, RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { ReplaySubject, Subject, combineLatest, of } from 'rxjs';
import { concatMap, first } from 'rxjs/operators';
import { isUserInfoLogins, isUserInfoRetailer } from '../Extensions/UserInfoDtoExtensions';
import { AuthGuard2 } from '../Guards/AuthGuard';
import { LoginGuard } from '../Guards/LoginGuard';
import { IUserGroupRetailerDto } from '../Models/IUserGroupRetailerDto';
import { AuthService } from '../Services/AuthService';
import { SessionService } from '../Services/SessionService';
import { UserService } from '../Services/UserService';
import { AuthorizeCallbackView } from '../Views/AuthorizeCallbackView';
import { AuthorizeView } from '../Views/AuthorizeView';
import { AUTH_MODULE_CONFIG } from './AuthModuleTokens';
import { IAuthModuleConfig } from './IAuthModuleConfig';

//#endregion

/**
 * @internal
 */
export function authModuleConfigFactory(): IAuthModuleConfig {
    return {
        callbackUrl: '',
        logoutRetryAttempts: 3,
        logoutRetryTimeout: 1500
    };
}

/**
 * The `AuthModule` module.
 *
 * @public
 */
@NgModule({
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatCardModule,
        FlexLayoutModule,
        BrandModule,
        MatButtonModule,
        MatInputModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        TranslationModule,
        TextBlockModule,
        MatListModule,
        MatIconModule,
        SizeDirectiveModule
    ],
    declarations: [
        AuthorizeView,
        AuthorizeCallbackView
    ],
    exports: [
        AuthorizeView,
        AuthorizeCallbackView
    ],
    providers: [
        AuthService,
        UserService,
        AuthGuard2,
        LoginGuard,
        SessionService,
        { provide: AUTH_MODULE_CONFIG, useFactory: authModuleConfigFactory }
    ]
})
export class AuthModule {

    //#region Ctor

    /**
     * Constructs a new instance of the `AuthModule` class.
     *
     * @public
     */
    public constructor(presenterService: PresenterService, router: Router, userService: UserService, authService: AuthService, retailerAdminService: RetailerAdminService,
        @Inject(SESSION_AVAILABLE_TOKEN) sessionAvailable: Subject<void>, @Inject(USER_INFO_TOKEN) userInfo: ReplaySubject<IUserInfoDto>) {
        combineLatest([sessionAvailable, userInfo,])
            .pipe(
                first(),
                concatMap(x => combineLatest([of(x[1])]))
            ).subscribe(([user]) => {
                const action: AppbarAction = {
                    kind: 'menu',
                    order: 300,
                    label: 'Profileinstellungen',
                    icon: 'User',
                    testId: 'appbarBtnProfile',
                    groups: [
                        {
                            order: 1,
                            kind: 'persona',
                            label: 'DU',
                            priority: 'secondary',
                            persona: {
                                name: user.realm === IRealmDto.Logins ? `${user.loginsInfo.givenName} ${user.loginsInfo.familyName}` : `${user.retailerInfo.givenName} ${user.retailerInfo.familyName}`,
                                email: user.realm === IRealmDto.Logins ? user.loginsInfo.email : user.retailerInfo.email
                            },
                            divided: true,
                        },
                        {
                            order: 3,
                            kind: 'tags',
                            label: 'ROLLEN',
                            items: user.groups.map(x => ({ label: x.toString() })),
                            divided: true,
                        },
                        {
                            order: 4,
                            kind: 'list',
                            label: 'AKTIONEN',
                            divided: true,
                            items: [
                                {
                                    label: 'Benutzerkonto',
                                    icon: 'User_Circle',
                                    action: () => {
                                        once(userService.openAccountPage(), x => {
                                            window.open(x.account_url);
                                        });
                                    },
                                    isHidden: user.realm === IRealmDto.Retailer
                                },
                                {
                                    label: 'Einstellungen',
                                    icon: 'Gear',
                                    action: () => router.navigateByUrl('presentation/explorer/user-settings')
                                },
                                {
                                    label: 'Impressum',
                                    icon: 'Info',
                                    action: () => router.navigateByUrl('imprint')
                                },
                                {
                                    label: 'Datenschutz',
                                    icon: 'Lock_2',
                                    action: () => router.navigateByUrl('privacy-notice')
                                },
                                {
                                    label: 'Abmelden',
                                    testId: 'appbarBtnLogout',
                                    icon: 'Sign_Out',
                                    action: () => {
                                        once(authService.logout(), () => {
                                            void router.navigateByUrl('logout');
                                        });
                                    }
                                }
                            ]
                        }
                    ]
                };

                if (isUserInfoLogins(user) && user.loginsInfo.leadName) {
                    action.groups.push({
                        order: 0,
                        kind: 'persona',
                        label: 'LEAD',
                        priority: 'primary',
                        persona: {
                            name: `${user.loginsInfo.leadName} ${user.loginsInfo.leadFamilyName}`,
                            email: user.loginsInfo.leadEmail
                        },
                        divided: true,
                    });

                }

                presenterService.setAction(action);

                // the support button is only visible for NOT finance admins & retailers
                if (!user.groups.includes(IUserGroupRetailerDto.FinanceAdmin) && isUserInfoRetailer(user)) {
                    once(retailerAdminService.getRetailerById(), info => {
                        presenterService.setAction({
                            kind: 'menu',
                            order: 100,
                            label: 'Fragen? Kontaktieren Sie uns!',
                            icon: 'Support',
                            testId: 'appbarBtnSupport',
                            groups: [{
                                order: 0,
                                kind: 'text',
                                label: '',
                                text: 'Fragen? Kontaktieren Sie uns!',
                                divided: false,
                            }, {
                                order: 1,
                                kind: 'persona',
                                label: '',
                                priority: 'primary',
                                persona: {
                                    name: `${info.contact.officeService.givenName} ${info.contact.officeService.familyName}`,
                                    email: info.contact.officeService.email,
                                    phone: info.contact.officeService.phoneNumber,
                                    role: 'Innendienst'
                                },
                                divided: true,
                            }, {
                                order: 2,
                                kind: 'persona',
                                label: '',
                                priority: 'primary',
                                persona: {
                                    name: `${info.contact.fieldService.givenName} ${info.contact.fieldService.familyName}`,
                                    email: info.contact.fieldService.email,
                                    phone: info.contact.fieldService.phoneNumber,
                                    role: 'Außendienst'
                                },
                                divided: false,
                            }]
                        });
                    });
                }

            });
    }

    //#endregion

    //#region Methods

    public static forRoot(config: IAuthModuleConfig): ModuleWithProviders<AuthModule> {
        return {
            ngModule: AuthModule,
            providers: [
                { provide: AUTH_MODULE_CONFIG, useValue: config }
            ]
        };
    }

    //#endregion

}

