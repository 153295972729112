import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
    IRetailerQuoteResultDto, RetailerQuoteService
} from '@abcfinlab/api/global';

@Injectable({
    providedIn: 'root'
})
export class RetailerQuoteDetailsResolver implements Resolve<IRetailerQuoteResultDto> {
    private readonly _retailerQuoteService: RetailerQuoteService;
    public constructor(
        retailerQuoteService: RetailerQuoteService
    ) {
        this._retailerQuoteService = retailerQuoteService;
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IRetailerQuoteResultDto> {
        return this._retailerQuoteService.get({leasingQuoteId: route.params.id})
            .pipe(
                catchError(err => {
                    return throwError(err);
                })
            );
    }

}
