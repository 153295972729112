// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box {
  margin-bottom: 16px;
}

.headline {
  margin-bottom: 24px;
}

h4 {
  margin: 0 0 8px 0;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImltcHJpbnQuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxtQkFBQTtBQUNKOztBQUNBO0VBQ0ksbUJBQUE7QUFFSjs7QUFBQTtFQUNJLGlCQUFBO0FBR0oiLCJmaWxlIjoiaW1wcmludC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5ib3h7XG4gICAgbWFyZ2luLWJvdHRvbTogMTZweDtcbn1cbi5oZWFkbGluZXtcbiAgICBtYXJnaW4tYm90dG9tOiAyNHB4O1xufVxuaDR7XG4gICAgbWFyZ2luOiAwIDAgOHB4IDA7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./apps/shell/src/app/public/pages/imprint/imprint.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;;AACA;EACI,mBAAA;AAEJ;;AAAA;EACI,iBAAA;AAGJ;AACA,wcAAwc","sourcesContent":[".box{\n    margin-bottom: 16px;\n}\n.headline{\n    margin-bottom: 24px;\n}\nh4{\n    margin: 0 0 8px 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
