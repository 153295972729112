//#region Imports

import { TranslationModule } from '@abcfinlab/core';
import { LayoutModule } from '@abcfinlab/layout';
import { BannerModule, BreakpointObserverModule, DatePickerModule, DialogHeaderModule, MandatoryCheckboxWithErrorDirectiveModule, NotAvailablePipeModule, PersonaModule, SpacerModule, TextBlockModule } from '@abcfinlab/ui';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { NgxsModule } from '@ngxs/store';
import { NgxCurrencyDirective } from 'ngx-currency';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { LesseeFilterByPipe } from '../Pipes/LesseeFilterPipe';
import { BeneficialOwnersView } from '../Views/beneficial-owners-view/beneficial-owners-view.component';
import { BeneficialOwnerConfirmDialogView } from '../Views/Dialogs/BeneficialOwnerConfirmDialogView';
import { BeneficialOwnerEditDialogView } from '../Views/Dialogs/BeneficialOwnerEditDialogView';
import { SaveOfferWithoutCrefoView } from '../Views/Dialogs/SaveOfferWithoutCrefoView';
import { SearchContactView } from '../Views/SearchContactView';
import { SelectLesseeView } from '../Views/SelectLesseeView';

//#endregion

/**
 * The `ContractManagementModule` module.
 *
 * @public
 */
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslationModule,
        FlexLayoutModule,
        MatButtonModule,
        MatListModule,
        MatIconModule,
        TextBlockModule,
        PersonaModule,
        MatChipsModule,
        MatDialogModule,
        DialogHeaderModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        MatCardModule,
        MatTabsModule,
        BannerModule,
        NotAvailablePipeModule,
        LayoutModule,
        BreakpointObserverModule,
        NgxsModule,
        SpacerModule,
        DatePickerModule,
        MatSelectModule,
        MatTooltipModule,
        MatCheckboxModule,
        NgxMatSelectSearchModule,
        MandatoryCheckboxWithErrorDirectiveModule,
        NgxCurrencyDirective
    ],
    exports: [
        BeneficialOwnerEditDialogView,
        BeneficialOwnerConfirmDialogView,
        SelectLesseeView,
        SearchContactView,
        BeneficialOwnersView,
        SaveOfferWithoutCrefoView
    ],
    declarations: [
        BeneficialOwnerEditDialogView,
        BeneficialOwnerConfirmDialogView,
        SelectLesseeView,
        SearchContactView,
        LesseeFilterByPipe,
        BeneficialOwnersView,
        SaveOfferWithoutCrefoView
    ]
})
export class ContactsModule {

    //#region Ctor

    /**
     * Constructs a new instance of the `ContactsModule` class.
     *
     * @public
     */
    public constructor() {
    }

    //#endregion

}
