import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import {ContactService, IContactDto, IIdTypeDto, IPageContactSearchResultEntryDto} from '@abcfinlab/api/contact';
import { inject } from '@angular/core';
import {
    ICalculationSettingsDto, IInhouseQuoteDto,
    ILeasingQuoteOverviewDto,
    IObjectGroupDto, IPageSearchRetailerQuoteLesseeResultDto,
    ISaleAndLeaseBackCodeDto,
    ObjectgroupService,
    QuoteService, RetailerQuoteService,
    SaleAndLeaseBackService,
    SettingsService
} from '@abcfinlab/api/global';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import {Globals} from "@abcfinlab/core";

export const getLesseeResolver: ResolveFn<IContactDto> = (
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot
) => {
    let type: IIdTypeDto = IIdTypeDto.Crefo;
    let id: string = route.paramMap.get('lesseeId');
    if (route.queryParams.id) {
        type = IIdTypeDto.Id;
        id = route.queryParams.id;
    }
    return inject(ContactService).getById({id, idType: type}).pipe(
        catchError(err => {
            return throwError(err);
        })
    );
};

export const getInhouseQuoteResolver: ResolveFn<IInhouseQuoteDto> = (
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot
) => {
    return inject(QuoteService).getQuoteByIdV({quoteId: route.paramMap.get('quoteId')}).pipe(
        catchError(err => {
            return throwError(err);
        })
    );
};

export const getObjectGroupsResolver: ResolveFn<Array<IObjectGroupDto>> = (
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot
) => {
    return inject(ObjectgroupService).getObjectgroups().pipe(
        catchError(err => {
            return throwError(err);
        })
    );
};

export const getCalculationSettingsResolver: ResolveFn<ICalculationSettingsDto> = (
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot
) => {
    return inject(SettingsService).getCalculationSettingsDto().pipe(
        catchError(err => {
            return throwError(err);
        })
    );
};

export const getSlbCodesResolver: ResolveFn<Array<ISaleAndLeaseBackCodeDto>> = (
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot
) => {
    return inject(SaleAndLeaseBackService).getSaleAndLeaseBackCodes().pipe(
        catchError(err => {
            return throwError(err);
        })
    );
};

export const getSmbCodesResolver: ResolveFn<Array<ISaleAndLeaseBackCodeDto>> = (
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot
) => {
    return inject(SaleAndLeaseBackService).getSmbCodes().pipe(
        catchError(err => {
            return throwError(err);
        })
    );
};

export const getQuotesForLesseeResolver: ResolveFn<ILeasingQuoteOverviewDto> = (
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot
) => {
    return inject(QuoteService).getQuotesForLesse({lesseeId: route.queryParams.id}).pipe(
        catchError(err => {
            return throwError(err);
        })
    );
};

export const getLesseesForQuoteResolver: ResolveFn<IContactDto> = (
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot
) => {
    return inject(ContactService).getById({id:route.paramMap.get('lesseeId'),idType: IIdTypeDto.Id}).pipe(
        catchError(err => {
            return throwError(err);
        })
    );
};
