import { ICreditCheckDetailDto } from '@abcfinlab/api/global';
import { TranslationFacade } from '@abcfinlab/core';
import { Component, Input } from '@angular/core';
import { CreditCheckDTO } from 'apps/shell/src/app/models/CreditCheckDTO.interface';

@Component({
    selector: 'l7-gwg-dependency-bar',
    templateUrl: './gwg-dependency-bar.component.html',
    styleUrls: ['./gwg-dependency-bar.component.scss']
})
export class GwgDependencyBarComponent {

    @Input() creditState: CreditCheckDTO | ICreditCheckDetailDto;

    constructor(private readonly _translationFacade: TranslationFacade) { }

    get credit_state_tooltip() {
        let creditState = this._translationFacade.instant(`states.credit_check.${this.creditState?.credit_rating}`);
        if (this.creditState?.code) {
            creditState += `\n${this.creditState.code}`;
        }

        if (this.creditState?.description) {
            creditState += `\n${this.creditState.description}`;
        }
        return creditState;
    }
}
