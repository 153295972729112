// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .mat-icon {
  height: 24px;
  width: 24px;
  font-size: 24px;
}
:host .mat-mdc-card {
  min-height: 75px;
  height: 172px;
}
:host .block-header {
  height: 16px;
}
:host .block-header .mat-icon {
  height: 24px;
  width: 24px;
  font-size: 24px;
  color: #71879c;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRha2VvdmVyRG9jdW1lbnRhdGlvbkRpYWxvZ1ZpZXcuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDSTtFQUNJLFlBQUE7RUFDQSxXQUFBO0VBQ0EsZUFBQTtBQUFSO0FBR0k7RUFDSSxnQkFBQTtFQUNBLGFBQUE7QUFEUjtBQUlJO0VBUUksWUFBQTtBQVRSO0FBRVE7RUFDSSxZQUFBO0VBQ0EsV0FBQTtFQUNBLGVBQUE7RUFDQSxjQUFBO0FBQVoiLCJmaWxlIjoidGFrZW92ZXJEb2N1bWVudGF0aW9uRGlhbG9nVmlldy5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICAgIC5tYXQtaWNvbiB7XG4gICAgICAgIGhlaWdodDogMjRweDtcbiAgICAgICAgd2lkdGg6IDI0cHg7XG4gICAgICAgIGZvbnQtc2l6ZTogMjRweDtcbiAgICB9XG5cbiAgICAubWF0LW1kYy1jYXJkIHtcbiAgICAgICAgbWluLWhlaWdodDogNzVweDtcbiAgICAgICAgaGVpZ2h0OiAxNzJweDtcbiAgICB9XG5cbiAgICAuYmxvY2staGVhZGVyIHtcbiAgICAgICAgLm1hdC1pY29uIHtcbiAgICAgICAgICAgIGhlaWdodDogMjRweDtcbiAgICAgICAgICAgIHdpZHRoOiAyNHB4O1xuICAgICAgICAgICAgZm9udC1zaXplOiAyNHB4O1xuICAgICAgICAgICAgY29sb3I6ICM3MTg3OWM7XG4gICAgICAgIH1cblxuICAgICAgICBoZWlnaHQ6IDE2cHg7XG4gICAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/contractManagement/src/Views/Dialogs/takeover-documentation/takeoverDocumentationDialogView.scss"],"names":[],"mappings":"AACI;EACI,YAAA;EACA,WAAA;EACA,eAAA;AAAR;AAGI;EACI,gBAAA;EACA,aAAA;AADR;AAII;EAQI,YAAA;AATR;AAEQ;EACI,YAAA;EACA,WAAA;EACA,eAAA;EACA,cAAA;AAAZ;AACA,o8BAAo8B","sourcesContent":[":host {\n    .mat-icon {\n        height: 24px;\n        width: 24px;\n        font-size: 24px;\n    }\n\n    .mat-mdc-card {\n        min-height: 75px;\n        height: 172px;\n    }\n\n    .block-header {\n        .mat-icon {\n            height: 24px;\n            width: 24px;\n            font-size: 24px;\n            color: #71879c;\n        }\n\n        height: 16px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
