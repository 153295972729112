//#region Imports

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CONFIGURATION_OVERVIEW_ROUTE } from '../Routing/Routes';

//#endregion

/**
 * @public
 */
export const PRESENTATION_ROUTES: Routes = [
    CONFIGURATION_OVERVIEW_ROUTE
];

/**
 * @public
 */
@NgModule({
    imports: [
        RouterModule.forChild(PRESENTATION_ROUTES)
    ],
    exports: [RouterModule]
})
export class PresentationRoutingModule {
}
