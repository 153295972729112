export namespace Globals {

    /**
     * Global calculation constants.
     */
    export const calculation = {
        standardHandlingFeeAmount: 200,
        standard100proInsuranceAmount: 0,
        minimum100proValue: 0,
        maximum100proValue: 35,
        maximumTotalLeasingValue: 750000,
        minimumTotalLeasingValue: 500,
        maximumTermsForHirePurchase: 96,
        minimumTermsForHirePurchase: 12,
        minimumTermsForUsedObject: 15,
        maximumDownPayment: 35,
        defaultResidualValue: 10
    };
    export const defaultLogoConfig = {
        height: 100,
        width: 300,
        aspectRatio: 3
    };
    export const contractManagementEmails = {
        defaultEmail: 'test_no-reply@lease-seven.de',
        prodEmail: 'l7-vertragspruefung@abcfinance.de',
        retailerProdEmail: 'l7-fh-vertragspruefung@abcfinance.de'
    };

    export namespace Page {
        export const DEFAULT_PAGE_SIZE = 20;
        export const DEFAULT_PAGE_SIZES = [5, 10, 20, 50, 100];
    }

    export namespace Widget {
        export namespace Frame {
            export const MIN_COLUMNS = 1;
            export const MAX_COLUMNS = 2;
        }

        export namespace Item {
            // export const MA = 100;
        }
    }

    export namespace Input {
        export const DELAY = 400;
    }

}
