import {ContactDTO} from '../ContactDTO.interface';
import {Currency} from '../enums/Currency.enum';
import {Serializable, Serialize, SerializeProperty} from 'ts-serializer';
import {UsedCondition} from '../enums/UsedCondition.enum';

@Serialize({})
export class LeasingQuoteObject extends Serializable {
  @SerializeProperty({
    map: 'vendor'
  })
  private _vendor: ContactDTO;
  @SerializeProperty({
    map: 'name'
  })
  private readonly _name: string;
  @SerializeProperty({
    map: 'quantity'
  })
  private readonly _quantity: number;
  @SerializeProperty({
    map: 'value'
  })
  private readonly _value: number;
  @SerializeProperty({
    map: 'currency'
  })
  private readonly _currency: Currency;

  @SerializeProperty({
    map: 'condition'
  })
  private readonly _condition: UsedCondition;

  constructor(name: string, quantity: number = 1, value: number | null = null, currency = Currency.EURO, condition: UsedCondition) {
    super();
    this._name = name;
    this._quantity = quantity;
    this._value = value;
    this._currency = currency;
    this._condition = condition;
  }

  set vendor(vendor: ContactDTO) {
    this._vendor = vendor;
  }

  get vendor(): ContactDTO {
    return this._vendor;
  }

  get serialized_values(): any {
    return this.serialize();
  }
}
