import { MatDialogRef } from '@angular/material/dialog';
import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'gwg-contract-document-creation-modal',
  templateUrl: './contract-document-creation.modal.html',
  styleUrls: ['./contract-document-creation.modal.scss']
})
export class ContractDocumentCreationModalComponent {

  constructor(
    public dialogRef: MatDialogRef<ContractDocumentCreationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  onConfirm(value: string) {
    this.dialogRef.close(value);
  }
}
