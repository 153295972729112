//#region Imports

import { Inject, Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { BeneficialOwnerService, IBeneficialOwnerDto, IContactDto, ILegalPersonalityDto } from '@abcfinlab/api/contact';
import {
    IbanCheckService,
    IBankAccountDto,
    ILeasingQuoteConfirmationTypeDto, IRetailerSignerDto,
    RetailerQuoteWorkflowService,
    VerificationService
} from '@abcfinlab/api/global';
import { ControlsOf, Validators as CoreValidators, once, TranslationFacade } from '@abcfinlab/core';
import { BusyBoxService, MessageBoxService, ToastService } from '@abcfinlab/ui';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatRadioChange } from '@angular/material/radio';
import * as blobUtil from 'blob-util';
import { Validators as AdministrationValidators } from '../../../../../administration/src/Validators/Validators';

//#endregion

interface IIdentificationDialogData {
    quoteId: string;
    identificationRequired: boolean;
    beneficialOwners: Array<IBeneficialOwnerDto>;
    lessee: IContactDto;
}

export interface IBankAccountFormDto {
    bankAccountName: string;
    bankAccountType: 'DIRECT_DEBIT';
    bankName: string;
    bic: string;
    iban: string;
}

interface IIdentificationFormDto {
    signer: FormGroup<ControlsOf<IRetailerSignerDto>>;
    emailAddress: FormControl<string>;
    lastName: FormControl<string>;
    firstName: FormControl<string>;
    identifyBeneficialOwners: FormControl<boolean>;
    leasingQuoteConfirmationTypes: FormControl<Array<ILeasingQuoteConfirmationTypeDto>>;
    bankAccount: FormGroup<ControlsOf<IBankAccountFormDto>>;
}

/**
 * The presenter of the {@link RetailerIdentificationView} view.
 *
 * @internal
 */
@Injectable()
export class RetailerIdentificationViewPresenter {

    //#region Fields
    private readonly _ibanCheckService: IbanCheckService;
    private readonly _retailerQuoteWorkFlowService: RetailerQuoteWorkflowService;
    private readonly _beneficialOwnersService: BeneficialOwnerService;
    private readonly _messageBoxService: MessageBoxService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _busyBoxService: BusyBoxService;
    private readonly _toastService: ToastService;
    private readonly _dialogRef: MatDialogRef<any>;
    private readonly _dialogData: BehaviorSubject<IIdentificationDialogData> = new BehaviorSubject<IIdentificationDialogData>(null);
    private readonly _form: FormGroup<ControlsOf<IIdentificationFormDto>>;
    private readonly _bankAccount: BehaviorSubject<IBankAccountDto> = new BehaviorSubject<IBankAccountDto>(null);
    private readonly _showBankAccountFields: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _showUploadForAddressFile: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _frontImage: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private readonly _backImage: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private readonly _isFrontImagePdf: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _isBackImagePdf: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _legalPersonality: BehaviorSubject<ILegalPersonalityDto> = new BehaviorSubject<ILegalPersonalityDto>(null);
    private readonly _identificationCompleted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _isShortCutFlow: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _lessee: BehaviorSubject<IContactDto> = new BehaviorSubject<IContactDto>(null);
    private readonly _lesseeCrefoId: string;
    private readonly _addressFile: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private readonly _quoteId: string;
    private readonly _beneficialOwners: IBeneficialOwnerDto[];

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `RetailerIdentificationViewPresenter` class.
     *
     * @public
     */
    public constructor(messageBoxService: MessageBoxService, translationFacade: TranslationFacade,
        busyBoxService: BusyBoxService, toastService: ToastService, dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) dialogData: IIdentificationDialogData, verificationService: VerificationService,
        ibanCheckService: IbanCheckService, beneficialOwnersService: BeneficialOwnerService,
        retailerQuoteWorkFlowService: RetailerQuoteWorkflowService) {
        this._ibanCheckService = ibanCheckService;
        this._beneficialOwnersService = beneficialOwnersService;
        this._retailerQuoteWorkFlowService = retailerQuoteWorkFlowService;
        this._messageBoxService = messageBoxService;
        this._translationFacade = translationFacade;
        this._toastService = toastService;
        this._busyBoxService = busyBoxService;
        this._dialogRef = dialogRef;
        this._dialogData.next(dialogData);
        this._form = new FormGroup<ControlsOf<IIdentificationFormDto>>({
            signer: new FormGroup<ControlsOf<IRetailerSignerDto>>({
                emailAddress: new FormControl('', [Validators.required, Validators.email], AdministrationValidators.emailInvalidAsync(verificationService)),
                lastName: new FormControl('', Validators.required),
                firstName: new FormControl('', Validators.required)
            }),
            emailAddress: new FormControl(null),
            lastName: new FormControl(null),
            firstName: new FormControl(null),
            identifyBeneficialOwners: new FormControl(false),
            leasingQuoteConfirmationTypes: new FormControl<Array<ILeasingQuoteConfirmationTypeDto>>([], Validators.required),
            bankAccount: new FormGroup<ControlsOf<IBankAccountFormDto>>({
                bankAccountName: new FormControl(null),
                bankAccountType: new FormControl('DIRECT_DEBIT'),
                bankName: new FormControl(''),
                bic: new FormControl(''),
                iban: new FormControl(null, [], CoreValidators.validateIban(this._ibanCheckService))
            })
        });
        this._quoteId = dialogData.quoteId;
        this._lesseeCrefoId = dialogData.lessee.crefo_id;
        this._beneficialOwners = dialogData.beneficialOwners;

        this._lessee.next(dialogData.lessee);
        this._legalPersonality.next(dialogData.lessee.legal_personality);
        if (dialogData.lessee.legal_personality === ILegalPersonalityDto.J) {
            this._form.get('leasingQuoteConfirmationTypes').patchValue([ILeasingQuoteConfirmationTypeDto.TransparencyRegisterNotificationMissing]);
        } else {
            this._form.get('leasingQuoteConfirmationTypes').patchValue([ILeasingQuoteConfirmationTypeDto.RegistrationAddressOnId, ILeasingQuoteConfirmationTypeDto.PersonOnOwnBehalf]);
        }

        if (!dialogData.identificationRequired) {
            this._isShortCutFlow.next(true);
        }
    }

    //#endregion
    public get lesseeCrefoId(): string {
        return this._lesseeCrefoId;
    }

    //#region Properties
    public get frontImage(): Observable<string> {
        return this._frontImage.asObservable();
    }

    public get beneficialOwners(): Array<IBeneficialOwnerDto> {
        return this._beneficialOwners;
    }

    public get backImage(): Observable<string> {
        return this._backImage.asObservable();
    }

    public get isFrontImagePdf(): Observable<boolean> {
        return this._isFrontImagePdf.asObservable();
    }

    public get isBackImagePdf(): Observable<boolean> {
        return this._isBackImagePdf.asObservable();
    }

    /**
     * Returns the property `showBankAccountFields`
     *
     * @public
     * @readonly
     */
    public get showBankAccountFields(): Observable<boolean> {
        return this._showBankAccountFields.asObservable();
    }

    /**
     * Returns the `form` property.
     *
     * @public
     * @readonly
     */
    public get form(): FormGroup<ControlsOf<IIdentificationFormDto>> {
        return this._form;
    }

    /**
     * Returns the `bankAccount` property.
     *
     * @public
     * @readonly
     */
    public get bankAccount(): Observable<IBankAccountDto> {
        return this._bankAccount.asObservable();
    }

    /**
     * Returns the `legalPersonality` property.
     *
     * @public
     * @readonly
     */
    public get legalPersonality(): Observable<ILegalPersonalityDto> {
        return this._legalPersonality.asObservable();
    }

    /**
     * Returns the `showUploadForAddressFile` property.
     *
     * @public
     * @readonly
     */
    public get showUploadForAddressFile(): Observable<boolean> {
        return this._showUploadForAddressFile.asObservable();
    }

    /**
     * Returns the `identificationCompleted` property.
     *
     * @public
     * @readonly
     */
    public get identificationCompleted(): Observable<boolean> {
        return this._identificationCompleted.asObservable();
    }

    /**
     * Returns the `isShortCutFlow` property.
     *
     * @public
     * @readonly
     */
    public get isShortCutFlow(): Observable<boolean> {
        return this._isShortCutFlow.asObservable();
    }

    public get lessee(): Observable<IContactDto> {
        return this._lessee.asObservable();
    }

    //#endregion

    //#region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
        //
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
        //
    }

    public setBankAccountIsShowed(value: boolean): void {
        this._showBankAccountFields.next(value);
    }

    public setBankAccountFields(evt: MatCheckboxChange): void {
        this._showBankAccountFields.next(evt.checked);
        if (evt.checked) {
            this._form.get('bankAccount.bankAccountName').addValidators(Validators.required);
            this._form.get('bankAccount.bankAccountName').updateValueAndValidity();
            this._form.get('bankAccount.iban').addValidators(Validators.required);
            this._form.get('bankAccount.iban').updateValueAndValidity();
        } else {
            this._form.get('bankAccount.bankAccountName').removeValidators([Validators.required]);
            this._form.get('bankAccount.bankAccountName').updateValueAndValidity();
            this._form.get('bankAccount.iban').removeValidators([Validators.required]);
            this._form.get('bankAccount.iban').updateValueAndValidity();
        }
    }

    public saveBulk(bulk: Array<IBeneficialOwnerDto>): Observable<string> {
        const subject = new Subject<string>();
        const subjectDoneFn = (query: Record<string, any>, data?: any) => {
            subject.next(btoa(JSON.stringify(query)));
            subject.complete();
        };

        this._busyBoxService.show(null, this._translationFacade.translate('global.busy'), this._beneficialOwnersService.bulkSave({ body: bulk }))
            .subscribe(result => {
                subjectDoneFn(bulk, result);
            },
                error => {
                    this._toastService.show(this._translationFacade.translate('error.generic_error'), 'danger');
                });

        return subject.asObservable();
    }

    public setIdImage(side: 'FRONT' | 'BACK' | 'ADDRESS', file: string): void {
        if (side === 'FRONT') {
            this._frontImage.next(file);
            this._isFrontImagePdf.next(file.includes('data:application/pdf'));
        } else if (side === 'BACK') {
            this._backImage.next(file);
            this._isBackImagePdf.next(file.includes('data:application/pdf'));
        } else {
            this._addressFile.next(file);
        }
    }

    public confirmationsChanged(evt: MatCheckboxChange | MatRadioChange): void {
        const value = evt instanceof MatCheckboxChange ? evt.source.name : evt.value;
        if (evt instanceof MatCheckboxChange) {
            this.setConfirmationsForCheckbox(evt.source.name, evt.checked);
        } else {
            this.setConfirmationsForRadio(evt.value);
        }
    }

    public submitIdentificationShortCutFlow(): void {
        if (this.isFormValid()) {
            let identificationRequest = this._form.getRawValue();
            if (!this._showBankAccountFields.getValue()) {
                identificationRequest = {
                    ...this._form.getRawValue(),
                    ...{ bankAccount: null }
                };
            }
            once(this._busyBoxService.show(undefined, this._translationFacade.translate('global.busy'),
                this._retailerQuoteWorkFlowService.triggerIdentification({
                    leasingQuoteId: this._quoteId,
                    body: {
                        identificationRequest,
                        idFront: null,
                        idBack: null,
                        idAddress: null
                    }
                })), res => this._identificationCompleted.next(true));
        } else {
            this._form.markAllAsTouched();
        }
    }

    public submitIdentification(): void {
        if (!this.areConfirmationsAndFilesValid()) {
            this._form.controls.leasingQuoteConfirmationTypes.setErrors({ 'invalid': true });
            this._form.controls.leasingQuoteConfirmationTypes.markAsTouched();
        } else {
            this._form.setErrors(null);
        }
        if (this.isFormValid() && this.areConfirmationsAndFilesValid()) {
            if (this._legalPersonality.getValue() === ILegalPersonalityDto.J) {
                const confirmations = this._form.get('leasingQuoteConfirmationTypes').getRawValue();
                const result = confirmations.filter(c => c !== 'TRANSPARENCY_REGISTER');
                this._form.get('leasingQuoteConfirmationTypes').patchValue(result);
            }
            // save
            let identificationRequest = this._form.getRawValue();
            if (!this._showBankAccountFields.getValue()) {
                identificationRequest = {
                    ...this._form.getRawValue(),
                    ...{ bankAccount: null }
                };
            }
            const addressFile = this._addressFile.getValue() ? blobUtil.dataURLToBlob(this._addressFile.getValue()) : null;
            once(this._busyBoxService.show(undefined, this._translationFacade.translate('global.busy'),
                this._retailerQuoteWorkFlowService.triggerIdentification({
                    leasingQuoteId: this._quoteId,
                    body: {
                        identificationRequest,
                        idFront: blobUtil.dataURLToBlob(this._frontImage.getValue()),
                        idBack: blobUtil.dataURLToBlob(this._backImage.getValue()),
                        idAddress: addressFile
                    }
                })), res => this._identificationCompleted.next(true));
        } else {
            let msg: string = '';
            const confirmations = this._form.get('leasingQuoteConfirmationTypes').getRawValue();
            if (this._legalPersonality.getValue() === ILegalPersonalityDto.J) {
                msg += 'Bitte bestätigen Sie Ihre Aussagen zu den Geldwäschepunkten.';
            } else {
                if (confirmations.includes(ILeasingQuoteConfirmationTypeDto.RegistrationAddressNotOnId) && this._addressFile.getValue() === null) {
                    msg += 'Bitte laden Sie ein Dokument für die Meldeadresse hoch. ';
                }
                if (!confirmations.includes(ILeasingQuoteConfirmationTypeDto.IdentificationNaturalPerson)) {
                    msg += 'Bitte bestätigen Sie Ihre Aussagen zu den Geldwäschepunkten.';
                }
            }
            this._toastService.show(msg, 'danger', 'short');
        }
    }

    private setConfirmationsForCheckbox(value: string, checked: boolean): void {
        const confirmations = this._form.get('leasingQuoteConfirmationTypes').getRawValue();
        let result;
        if (checked) {
            result = confirmations.push(value);
            result = confirmations;
        } else {
            result = confirmations.filter(c => c !== value);
        }

        this._form.get('leasingQuoteConfirmationTypes').patchValue(result);
    }

    private setConfirmationsForRadio(value: string): void {
        const confirmations = this._form.get('leasingQuoteConfirmationTypes').getRawValue();
        let result;
        switch (value) {
            case ILeasingQuoteConfirmationTypeDto.TransparencyRegisterNotificationMissing:
                result = confirmations.filter(c => c !== ILeasingQuoteConfirmationTypeDto.TransparencyRegisterNotificationEntry);
                break;
            case ILeasingQuoteConfirmationTypeDto.TransparencyRegisterNotificationEntry:
                result = confirmations.filter(c => c !== ILeasingQuoteConfirmationTypeDto.TransparencyRegisterNotificationMissing);
                break;
            case ILeasingQuoteConfirmationTypeDto.RegistrationAddressOnId:
                this._showUploadForAddressFile.next(false);
                result = confirmations.filter(c => c !== ILeasingQuoteConfirmationTypeDto.RegistrationAddressNotOnId);
                break;
            case ILeasingQuoteConfirmationTypeDto.RegistrationAddressNotOnId:
                this._showUploadForAddressFile.next(true);
                result = confirmations.filter(c => c !== ILeasingQuoteConfirmationTypeDto.RegistrationAddressOnId);
                break;

        }
        result.push(value);
        this._form.get('leasingQuoteConfirmationTypes').patchValue(result);
    }

    private areConfirmationsAndFilesValid(): boolean {
        const defaultConfirmationsForJ = [ILeasingQuoteConfirmationTypeDto.IdentificationJuristicPerson];
        const defaultConfirmationsForN = [ILeasingQuoteConfirmationTypeDto.IdentificationNaturalPerson, ILeasingQuoteConfirmationTypeDto.PersonOnOwnBehalf];
        const confirmations = this._form.get('leasingQuoteConfirmationTypes').getRawValue();
        let includesDefault;
        let isAddressFilePresent = true;
        if (this._legalPersonality.getValue() === ILegalPersonalityDto.J) {
            includesDefault = confirmations.includes(ILeasingQuoteConfirmationTypeDto.IdentificationJuristicPerson) &&
                confirmations.includes('TRANSPARENCY_REGISTER');
        } else {
            includesDefault = confirmations.includes(ILeasingQuoteConfirmationTypeDto.IdentificationNaturalPerson);
            if (confirmations.includes(ILeasingQuoteConfirmationTypeDto.RegistrationAddressNotOnId)) {
                isAddressFilePresent = this._addressFile.getValue() !== null;
            }
        }

        return includesDefault && isAddressFilePresent && this._frontImage.getValue() !== null && this._backImage.getValue() !== null;
    }

    private isFormValid(): boolean {
        if (!this._showBankAccountFields.getValue()) {
            this._form.get('bankAccount.iban').removeAsyncValidators([CoreValidators.validateIban(this._ibanCheckService)]);
            this._form.get('bankAccount.iban').updateValueAndValidity();
        }
        this._form.get('emailAddress').clearAsyncValidators();
        this._form.get('emailAddress').updateValueAndValidity();
        return this._form.valid;
    }

    //#endregion

}
