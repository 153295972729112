/**
 * A config file to provide the PorjectID for the Marker.io service
 * - ensure that the project ID can only be used on a specific domain (on the platform settings!)
 */

/**
 * The project ID`s provided by the service according to the desired stage in base64-encoded strings
 */
export const projectID = new Map<string, string>();
projectID.set('local', null);
projectID.set('mr', null);
projectID.set('dev', null);
projectID.set('test', 'NjRmODFhM2Y3MWFiYzgyZmFjNjg0ZThi');
projectID.set('acc', 'NjRmNzQzNjU1ZThlM2ZjZDg3NTdkNzk1');
projectID.set('demo', 'NjUwMTVhODY0MmY0ZTcwYmQ5OTAwOTc3');
projectID.set('prod', 'NjY0MjAxYWU1MWVkYWVkNjJjZmJhYTdk'); // projectId: '664201ae51edaed62cfbaa7d'

/**
 * The supported realms based on the provided shape of the reponse object given by the BE on application bootstrap
 * (/auth/userInfo) -> no userinfo in general, the shape of the object depends on the realm ...
 */
export const supportedRealms = new Map<string, string>();
supportedRealms.set('RETAILER', 'retailerInfo');
supportedRealms.set('LOGINS', 'loginsInfo');
