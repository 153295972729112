// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}
div img {
  max-height: 22vh;
}
div .image-preview {
  position: relative;
  width: 240px;
  height: 300px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImlkZW50aWZpY2F0aW9uLXBpY3R1cmVzLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsYUFBQTtFQUNBLDhCQUFBO0VBQ0EsY0FBQTtBQUNGO0FBQ0U7RUFDRSxnQkFBQTtBQUNKO0FBRUU7RUFDRSxrQkFBQTtFQUNBLFlBQUE7RUFDQSxhQUFBO0FBQUoiLCJmaWxlIjoiaWRlbnRpZmljYXRpb24tcGljdHVyZXMuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJkaXYge1xuICBkaXNwbGF5OiBmbGV4O1xuICBqdXN0aWZ5LWNvbnRlbnQ6IHNwYWNlLWJldHdlZW47XG4gIG1hcmdpbjogMjBweCAwO1xuXG4gIGltZyB7XG4gICAgbWF4LWhlaWdodDogMjJ2aDtcbiAgfVxuXG4gIC5pbWFnZS1wcmV2aWV3IHtcbiAgICBwb3NpdGlvbjogcmVsYXRpdmU7XG4gICAgd2lkdGg6IDI0MHB4O1xuICAgIGhlaWdodDogMzAwcHg7XG4gIH1cblxuICAvLyBwZGYtdmlld2VyIHtcbiAgLy8gICBtYXgtd2lkdGg6IDEwdnc7XG4gIC8vIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/legacy/gwg/src/lib/shared/identification-pictures/identification-pictures.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,cAAA;AACF;AACE;EACE,gBAAA;AACJ;AAEE;EACE,kBAAA;EACA,YAAA;EACA,aAAA;AAAJ;AACA,wvBAAwvB","sourcesContent":["div {\n  display: flex;\n  justify-content: space-between;\n  margin: 20px 0;\n\n  img {\n    max-height: 22vh;\n  }\n\n  .image-preview {\n    position: relative;\n    width: 240px;\n    height: 300px;\n  }\n\n  // pdf-viewer {\n  //   max-width: 10vw;\n  // }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
