//#region Imports

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

//#endregion

/**
 * Defines an interface to get instances of an event.
 *
 * @public
 */
export interface IEventHub {

    //#region Methods

    getEvent<TPayload>(event: string): Observable<TPayload>;

    publish<TPayload>(event: string, payload: TPayload): void;

    dispose(): void;

    //#endregion

}

/**
 * Reactive event aggregator implementation for events.
 *
 * @public
 */
@Injectable({ providedIn: 'root' })
export class EventHub implements IEventHub {

    //#region Fields

    private readonly _subject: Subject<[string, any]>;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `ReactiveEventAggregator` class.
     *
     * @public
     */
    public constructor() {
        this._subject = new Subject();
    }

    //#endregion

    //#region Methods

    /**
     * @public
     */
    public getEvent<TPayload = unknown>(event: string): Observable<TPayload> {
        return this._subject.pipe(
            filter(([e]) => e === event),
            map(([_, p]) => p)
        );
    }

    /**
     * @public
     */
    public publish<TPayload = unknown>(event: string, payload: TPayload): void {
        this._subject.next([event, payload]);
    }

    /**
     * @public
     */
    public dispose(): void {
        if (!this._subject.closed) {
            this._subject.unsubscribe();
        }
    }

    //#endregion

}
