import { Pipe, PipeTransform } from '@angular/core';
import { ContactDTO } from '../../../models/ContactDTO.interface';
import { LesseeListEntityDTO } from '../../../models/LesseeListEntityDTO.interface';

@Pipe({
  name: 'ContactFilter'
})
export class ContactFilterPipe implements PipeTransform {

  transform(lessees: Array<ContactDTO> | Array<LesseeListEntityDTO>, term: string): Array<any> {
    if (!lessees) {
      return [];
    }
    if (!term) {
      return lessees;
    }

    term = term.toLowerCase();

    const result = lessees.filter(item => item.name.toLowerCase().includes(term) || item.street.toLowerCase().includes(term) || item.city.toLowerCase().includes(term) || item.postcode.toLowerCase().includes(term));

    return result;
  }

}
