//#region Imports

import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { Role } from './Role';
import { RoleManager } from './RoleManager';

//#endregion

/**
 * Structural directive for manipulating content of the template.
 *
 * @public
 */
@Directive({
    selector: '[l7HasRole]'
})
export class HasRoleDirective implements OnChanges {

    //#region Fields

    private readonly _viewContainerRef: ViewContainerRef;
    private readonly _templateRef: TemplateRef<unknown>;
    private readonly _roleManager: RoleManager;

    private _role: Array<string>;
    private _hasView: boolean;

    //#endregion

    //#region Ctor

    public constructor(viewContainerRef: ViewContainerRef, templateRef: TemplateRef<unknown>, roleManager: RoleManager) {
        this._viewContainerRef = viewContainerRef;
        this._templateRef = templateRef;
        this._roleManager = roleManager;

        this._role = [];
        this._hasView = false;
    }

    //#endregion

    //#region Properties

    /**
     * Gets or sets the `role` property.
     *
     * @public
     */
    @Input('l7HasRole')
    public get role(): Array<Role> | Role {
        return this._role;
    }
    public set role(value: Array<Role> | Role) {
        if (this._role !== value) {
            this._role = Array.isArray(value) ? value : [value];
        }
    }

    //#endregion

    //#region Methods

    /**
     * @public
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.role) {
            this.invalidate();
        }
    }

    /**
     * @private
     */
    private invalidate(): void {
        const can = this._roleManager.can(this._role);

        if (can && !this._hasView) {
            this._hasView = true;
            this._viewContainerRef.createEmbeddedView(this._templateRef);
        }

        if (!can && this._hasView) {
            this._hasView = false;
            this._viewContainerRef.clear();
        }
    }

    //#endregion

}
