import { PresentationModule, SignaturePadModule } from '@abcfinlab/presentation';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from '../../../../../../apps/shell/src/app/shared/shared.module';
import { SharedModule as SharedModuleLb } from '../shared/shared.module';
import { SignatureRemoteContainerComponent } from './signature-remote-container/signature-remote-container.component';
import { SignatureRoutingModule } from './signature-routing.module';
import { SignatureComponent } from './signature/signature.component';

@NgModule({
  declarations: [
    SignatureComponent,
    SignatureRemoteContainerComponent
  ],
  imports: [
    CommonModule,
    SignatureRoutingModule,
    SignaturePadModule,
    FlexLayoutModule,
    FormsModule,
    PresentationModule,
    SharedModuleLb,
    SharedModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule
  ],
  exports: [
    SignatureComponent,
    SignatureRemoteContainerComponent
  ]
})
export class SignatureModule { }
