//#region Imports

import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../../../apps/shell/src/app/shared/guards/auth.guard';
import { DashboardView } from '../Views/DashboardView';
import { UserSettingsView } from '../Views/user-settings-view/UserSettingsView';

//#endregion

/**
 * @internal
 */
export const DASHBOARD_ROUTE_PATH: string = 'explorer/dashboard';

export const USER_SETTINGS_ROUTE_PATH: string = 'explorer/user-settings';

/**
 * @internal
 */
export const DASHBOARD_OVERVIEW_ROUTE: Route = {
    path: DASHBOARD_ROUTE_PATH,
    component: DashboardView,
    canActivate: [AuthGuard]
};

/**
 * @internal
 */
export const USER_SETTINGS_ROUTE_ROUTE: Route = {
    path: USER_SETTINGS_ROUTE_PATH,
    component: UserSettingsView,
    canActivate: [AuthGuard]
};

/**
 * @public
 */
export const EXPLORER_ROUTES: Routes = [
    DASHBOARD_OVERVIEW_ROUTE,
    USER_SETTINGS_ROUTE_ROUTE,
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
    },
];

/**
 * @public
 */
@NgModule({
    imports: [
        RouterModule.forChild(EXPLORER_ROUTES)
    ],
    exports: [RouterModule]
})
export class ExplorerRoutingModule {
}
