//#region Imports

import { ContractsDocumentService } from '@abcfinlab/api/contract';
import { IbanCheckService, RemoteIbanCheckService } from '@abcfinlab/api/global';
import { AbstractControl, AsyncValidatorFn, Validators as NgValidators, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Observable, Subject, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, finalize, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { isDateAfterOtherDate, isDateBeforeOrEqualOtherDate } from '../Extensions/DateExtensions';
import { CountryCode } from '../Intl/CountryCode';
import { PostalCode } from '../Intl/PostalCode';
import { Pattern } from './Pattern';

//#endregion

export namespace Validators {

    /**
     * Validate the value of a control against a range of values.
     *
     * @param minValue The minimum value allowed.
     * @param maxValue The maximum value allowed.
     * @param factor The factor to divide the control value by before comparing it to the range.
     */
    export function isNumberInRange(minValue: number, maxValue: number, factor?: number): ValidatorFn {
        const fn = (control: AbstractControl): ValidationErrors | null => {
            if (!control) {
                return null;
            }

            if (control.value === null || control.value === undefined || control.value === '') {
                return null;
            }

            const value = control.value / (factor || 1);

            if (value < minValue || value > maxValue) {
                return {
                    isNumberInRange: true,
                    min: minValue,
                    max: maxValue
                };
            }

            return null;
        };

        return fn;
    }

    /**
     * Custom validator to validate phone numbers, allowing only digits and an optional leading plus sign.
     * Enforces a maximum length constraint and checks for valid national and international formats.
     *
     * @param maxLength The maximum allowed length of the phone number (default is 17).
     * @returns A validator function that checks the validity of the input value.
     */
    export function phoneNumber(maxLength?: number): ValidatorFn {
        maxLength = maxLength ?? 17;

        return (control: AbstractControl): ValidationErrors | null => {
            const phoneNumber = control.value;
            const pattern = Pattern.phoneNumber

            if (phoneNumber.length > maxLength) {
                return { maxLengthExceeded: true };
            }

            const isValid = pattern.test(phoneNumber);

            return isValid ? null : { invalidPhoneNumber: true };
        };
    }

    /**
     * Validate a postal code.
     */
    export function postalCode(countryCode: CountryCode): ValidatorFn {
        const fn = (control: AbstractControl): ValidationErrors | null => {
            if (control.pristine || !control.value || PostalCode.isValid(control.value, countryCode)) {
                return null;
            }

            control.markAllAsTouched();

            return {
                postCode: true,
            };
        };

        return fn;
    }

    /**
     * Validate emails ending with a spesific domain(s).
     */
    export function emailEndsWithDomainAsync(domains: Observable<Array<string>>): AsyncValidatorFn {
        const fn = (control: AbstractControl): Observable<ValidationErrors | null> => {
            const result = NgValidators.email(control);
            if (!result) {
                return domains.pipe(
                    map(x => {
                        const value = (control.value as string).split('@')[1];
                        if (x.includes(value)) {
                            return null;
                        }

                        return {
                            emailEndsWithDomain: {
                                requiredDomains: x,
                                actualValue: control.value
                            }
                        };
                    })
                );
            }

            return of(null);
        };

        return fn;
    }

    export function minDate(value: Date | string): ValidatorFn {
        const minDate = value instanceof Date ? value : new Date(value);

        const fn = (control: AbstractControl): ValidationErrors | null => {

            if (!control.value) {
                return null;
            }

            const controlDate = control.value instanceof Date ? control.value : new Date(control.value);

            return isDateBeforeOrEqualOtherDate(minDate, controlDate)
                ? null
                : { minDate: true };
        };

        return fn;
    }

    export function maxDate(value: Date | string): ValidatorFn {
        const maxDate = value instanceof Date ? value : new Date(value);

        const fn = (control: AbstractControl): ValidationErrors | null => {

            if (!control.value) {
                return null;
            }

            const controlDate = control.value instanceof Date ? control.value : new Date(control.value);

            return isDateAfterOtherDate(controlDate, maxDate) ? { maxDate: true } : null;
        };

        return fn;
    }

    export function futureDateValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any | null } => {
            const currentDate = new Date();
            const inputDate = control.value instanceof Date ? control.value : new Date(control.value);

            if (!control.value) {
                return null;
            }

            if (inputDate.getTime() > currentDate.getTime()) {
                return { maxDate: true };
            }

            return null;
        };
    }

    export function noEmptyString(): ValidatorFn {
        const fn = (control: AbstractControl): ValidationErrors | null => (control.value || '').trim().length ? null : { required: true };

        return fn;
    }

    export function serialNumber(): ValidatorFn {
        const fn = (control: AbstractControl): ValidationErrors | null => {

            if (!control.value) {
                return null;
            }

            const pattern = new RegExp(/^[^ioqIOQ]+$/);
            const isValid = pattern.test(control.value);
            return isValid
                ? null
                : { serialNumber: true };
        };

        return fn;
    }

    /**
     * A conditional validator generator. Assigns a validator to the form control if the predicate function returns true on the moment of validation
     * @example
     * Here if the myCheckbox is set to true, the myEmailField will be required and also the text will have to have the word 'mason' in the end.
     * If it doesn't satisfy these requirements, the errors will placed to the dedicated `illuminatiError` namespace.
     * Also the myEmailField will always have `maxLength`, `minLength` and `pattern` validators.
     * ngOnInit() {
     *   this.myForm = this.fb.group({
     *    myCheckbox: [''],
     *    myEmailField: ['', [
     *       Validators.maxLength(250),
     *       Validators.minLength(5),
     *       Validators.pattern(/.+@.+\..+/),
     *       conditionalValidator(() => this.myForm.get('myCheckbox').value,
     *                            Validators.compose([
     *                            Validators.required,
     *                            Validators.pattern(/.*mason/)
     *         ]),
     *        'illuminatiError')
     *        ]]
     *     })
     * }
     * @param predicate
     * @param validator
     * @param errorNamespace optional argument that creates own namespace for the validation error
     */
    export function conditionalValidator(predicate: () => boolean, validator: ValidatorFn, errorNamespace?: string): ValidatorFn {
        return (formControl => {
            if (!formControl.parent) {
                return null;
            }
            let error = null;
            if (predicate()) {
                error = validator(formControl);
            }
            if (errorNamespace && error) {
                const customError = {};
                customError[errorNamespace] = error;
                error = customError;
            }
            return error;
        });
    }

    /**
     * Async Validation of IBAN.
     */
    export function validateIban(service: IbanCheckService | RemoteIbanCheckService): AsyncValidatorFn {
        const fn = (control: AbstractControl): Observable<ValidationErrors | null> => {
            if (control.value === null || control.value === '') {
                return of(null);
            }
            const iban: string = control.value.toLowerCase().replace(/\s/g, '');

            const isGermanIban = iban?.charAt(0) === 'd' && iban?.charAt(1) === 'e';
            const containsOnlyNumbers = iban.substring(2).match(/^[0-9]+$/);

            if (iban.length >= 2 && !isGermanIban) {
                return createError('iban_structure_not_found');
            } else if (iban.length === 1 && iban !== 'd') {
                return createError('iban_structure_not_found');
            } else if (iban.length <= 21 && isGermanIban && !containsOnlyNumbers && iban.length > 2) {
                return createError('letters_not_allowed');
            } else if ((iban.length <= 21 && isGermanIban && containsOnlyNumbers) || (iban.length === 2 && isGermanIban) || ((iban.length === 1 && iban === 'd'))) {
                return createError('invalid_iban_length');
            }

            const checkIban$ = service instanceof IbanCheckService ? service.getBankAccountbyIban({ iban }) : service.getBankAccountRemoteByIban({ iban });
            return checkIban$.pipe(
                distinctUntilChanged(),
                debounceTime(1000),
                tap(_ => {
                    control.markAllAsTouched();
                }),
                switchMap(res => {
                    if (!res) {
                        return of({
                            invalidIban: false,
                            bankAccount: res
                        });
                    }
                    return of(null);
                }),
                catchError(res => {
                    control.markAllAsTouched();
                    return of({
                        invalidIban: true,
                        error: res.error.error
                    });
                }),
                take(1),
                finalize(() => of(true))
            );
        };
        return fn;
    }

    export function validateVatNumber(service: ContractsDocumentService): AsyncValidatorFn {
        const fn = (control: AbstractControl): Observable<ValidationErrors | null> => {
            if (control.value === null || control.value === '') {
                return of(null);
            }
            const vatNumber: string = control.value.replace(/\s/g, '');

            return service.isVatNumberValid({ vatNumber }).pipe(
                distinctUntilChanged(),
                debounceTime(1000),
                tap(_ => {
                    control.markAllAsTouched();
                }),
                switchMap(res => {
                    if (res) {
                        return of(null);
                    }
                    return of({ isInvalidVatNumber: true });
                }),
                catchError(_ => {
                    control.markAllAsTouched();
                    return of(null);
                }),
                take(1),
                finalize(() => of(true))
            );
        };
        return fn;
    }

    export function isIbanValid(service: ContractsDocumentService, contractNumber: string): AsyncValidatorFn {
        const fn = (control: AbstractControl): Observable<ValidationErrors | null> => {
            if (control.value === null || control.value === '') {
                return of(null);
            }
            const iban: string = control.value.replace(/\s/g, '');

            return service.isIbanValid({ contractId: contractNumber, iban }).pipe(
                distinctUntilChanged(),
                debounceTime(1000),
                tap(_ => {
                    control.markAllAsTouched();
                }),
                switchMap(res => {
                    if (res) {
                        return of(null);
                    }
                    return of({ isInvalidIban: true });
                }),
                catchError(_ => {
                    control.markAllAsTouched();
                    return of(null);
                }),
                take(1),
                finalize(() => of(true))
            );
        };
        return fn;
    }

    function createError(errorCode: string): Observable<{ invalidIban: boolean; error: string }> {
        return of({
            invalidIban: true,
            error: errorCode
        });
    }

    let cancelSubject = new Subject();
    export function validateInvoiceNumberUniqueAsync(service: ContractsDocumentService, docId: string, originalValue?: string): AsyncValidatorFn {
        cancelSubject.next(true); // Cancel previous requests
        cancelSubject.complete(); // Complete the subject
        cancelSubject = new Subject(); // Create a new subject
        const fn = (control: AbstractControl): Observable<ValidationErrors | null> => service.validateInvoiceNumberUnique({ documentId: docId, body: { invoiceNumber: control.value } }).pipe(
            distinctUntilChanged(),
            debounceTime(1000),
            tap(_ => {
                control.markAllAsTouched();
            }),
            switchMap(res => {
                if (!res.invoiceNumberVendorUnique && originalValue !== control.value) {
                    return of({ invoiceNumberVendorUnique: !res.invoiceNumberVendorUnique });
                }
                return of(null);
            }),
            takeUntil(cancelSubject)
        );

        return fn;
    }

}
