import {CalculationUIState} from '../state/Calculation.state';
import {LeasingQuoteDTO} from '../models/LeasingQuoteDTO.interface';
import {ContactDTO} from '../models/ContactDTO.interface';
import {CalculationRequestDTO} from '../models/CalculationRequestDTO.interface';
import {ValueToCalculate} from '../models/enums/ValueToCalculate.enum';
import {ContractTypeId} from '../models/enums/ContractTypeId.enum';
import {InsuranceCalculationRequestDTO} from '../models/InsuranceCalculationRequestDTO';
import {ObjectGroupDTO} from '../models/ObjectGroupDTO.interface';
import { UsedCondition } from 'apps/shell/src/app/models/enums/UsedCondition.enum';

export class UpdateCalculation {
    static readonly type = '[CALCULATION] Updating calculation';
    constructor(public request: CalculationRequestDTO) {
    }
}

export class CalculateInsurance {
    static readonly type = '[CALCULATION] Updating insurance';
    constructor(public request: InsuranceCalculationRequestDTO) {
    }
}

export class UpdateCalculationUIState {
    static readonly type = '[CALCULATION] Update calculation UI state';
    constructor(public state: CalculationUIState) {
    }
}

export class SetCalculationForScreen {
    static readonly type = '[CALCULATION] Set calculation for calculation view';
    constructor(public leasingQuoteCalculation: LeasingQuoteDTO) {
    }
}

export class CreateQuote {
    static readonly type = '[CALCULATION] Create quote for calculation view';
    constructor(public contact: ContactDTO) {
    }
}

export class SetContractType {
    static readonly type = '[CALCULATION] Set contract_type_id for calculation view';
    constructor(public value: ContractTypeId) {
    }
}

export class SetTotalLeasingValue {
    static readonly type = '[CALCULATION] Set total_leasing_value for calculation view';
    constructor(public value: number) {
    }
}

export class SetValueToCalculate {
    static readonly type = '[CALCULATION] Set value_to_calculate for calculation view';
    constructor(public value: ValueToCalculate) {
    }
}

export class SetLesseeByUUID {
    static readonly type = '[CALCULATION] Set lessee for calculation view';
    constructor(public lesseeUUID: string, public quoteID: string) {
    }
}

export class ChangeObjectGroupOrContractType {
    static readonly type = '[CALCULATION] Change object group or contract type for calculation view [create, transient]';
    constructor(public objectGroup: ObjectGroupDTO, public contractType: ContractTypeId, public objectCondition: UsedCondition) {}
}

export class ResetCalculationState {
    static readonly type = '[CALCULATION] Reset calculation state';
    constructor() {}
}
