import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { FetchLesseeByUUID, ResetLesseeState, SetLessee, SetLesseeID } from '../actions/Lessee.actions';
import { ContactDTO } from '../models/ContactDTO.interface';
import { LesseeRepositoryService } from '../private/repository/lessee-repository.service';

export class LesseeStateModel {
  selectedLesseeID: string;
  selectedLessee: ContactDTO;
}

const defaults = {
  selectedLesseeID: null,
  selectedLessee: {} as ContactDTO
};

@State<LesseeStateModel>({
  name: 'lessee',
  defaults
})
@Injectable()
export class LesseeState {

  constructor(
    private readonly _lesseeRepository: LesseeRepositoryService
  ) {
  }

  @Selector()
  static getSelectedLessee(state: LesseeStateModel): ContactDTO {
    return state.selectedLessee;
  }

  @Selector()
  static selectedLesseeID(state: LesseeStateModel) {
    return state.selectedLesseeID;
  }

  @Selector()
  static getLessee(state: LesseeStateModel): ContactDTO {
    return state.selectedLessee;
  }

  @Action(SetLesseeID)
  setSelectedLesseeID({ patchState }: StateContext<LesseeStateModel>, { payload }: SetLesseeID) {
    patchState({
      selectedLesseeID: payload
    });
  }

  @Action(SetLessee)
  setLessee({ getState, patchState }: StateContext<LesseeStateModel>, { payload }: SetLessee) {
    patchState({
      selectedLessee: payload
    });
  }

  @Action(ResetLesseeState)
  resetLesseeState({ patchState }: StateContext<LesseeStateModel>, { }: ResetLesseeState) {
    patchState(defaults);
  }

  @Action(FetchLesseeByUUID)
  fetchLesseeByUUID({ patchState }: StateContext<LesseeStateModel>, { lesseeUUID }: FetchLesseeByUUID) {
    return this._lesseeRepository.getLessee(lesseeUUID).pipe(
      tap((lessee: ContactDTO) => {
        patchState({
          selectedLesseeID: lessee.id,
          selectedLessee: lessee
        });
      })
    );
  }
}

