import { TranslationModule } from '@abcfinlab/core';
import { PresentationModule } from '@abcfinlab/presentation';
import {
    BusyBoxModule,
    DatePickerModule,
    MandatoryCheckboxWithErrorDirectiveModule, ScrollToErrorModule,
    SpacerModule,
    TextBlockModule
} from '@abcfinlab/ui';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { RetailerAcknowledgmentSignatureComponent } from './retailer-acknowledgment-signature.component';

@NgModule({
    declarations: [RetailerAcknowledgmentSignatureComponent],
    imports: [
        CommonModule,
        PresentationModule,
        TranslationModule,
        BusyBoxModule,
        SpacerModule,
        MatSlideToggleModule,
        TextBlockModule,
        MatCardModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatButtonModule,
        FlexLayoutModule,
        DatePickerModule,
        MandatoryCheckboxWithErrorDirectiveModule,
        ScrollToErrorModule
    ],
    exports: [RetailerAcknowledgmentSignatureComponent]
})
export class RetailerAcknowledgmentDigitalSignModule { }
