//#region Imports

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import {
    ManualProcessingErrorDialogPresenter
} from './ManualProcessingErrorDialogPresenter';

//#endregion

/**
 * The `SettlementNavErrorDialogView` view.
 *
 * @public
 */
@Component({
    selector: 'l7-manual-processing-error-dialog',
    templateUrl: './ManualProcessingErrorDialog.html',
    styleUrls: ['./ManualProcessingErrorDialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        ManualProcessingErrorDialogPresenter
    ]
})
export class ManualProcessingErrorDialog implements OnInit, OnDestroy {

    //#region Fields

    private readonly _presenter: ManualProcessingErrorDialogPresenter;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `SettlementNavErrorDialogView` class.
     *
     * @public
     */
    public constructor(presenter: ManualProcessingErrorDialogPresenter) {
        this._presenter = presenter;
    }

    //#endregion

    //#region Proeprties

    /**
     * Returns the presenter of the `SettlementNavErrorDialogView`
     *
     * @public
     * @readonly
     */
    public get presenter(): ManualProcessingErrorDialogPresenter {
        return this._presenter;
    }

    //#endregion

    //#region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize();
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    //#endregion

}
