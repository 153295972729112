import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ILeasingQuoteCreditRatingDto, ILeasingQuoteOverviewVersionDto } from '@abcfinlab/api/global';

@Component({
  selector: 'l7-delete-quote',
  templateUrl: './delete-quote.component.html',
  styleUrls: ['./delete-quote.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteQuoteComponent implements OnInit {
    @Input() versions: Array<ILeasingQuoteOverviewVersionDto>;

    @Output() delete: EventEmitter<string> = new EventEmitter<string>();

    quoteVersionWithValidCreditCheck: ILeasingQuoteOverviewVersionDto;

    ngOnInit() {
        this.quoteVersionWithValidCreditCheck = this.versions.find((version) => {
            return version.states.lessee.credit_rating === ILeasingQuoteCreditRatingDto.Positive
                || version.states.lessee.credit_rating === ILeasingQuoteCreditRatingDto.Pending
                || version.states.lessee.credit_rating === ILeasingQuoteCreditRatingDto.ApprovedWithConditions
                || version.states.lessee.credit_rating === ILeasingQuoteCreditRatingDto.ApprovedWithConditionsFulfilled;
        });
    }

    onAllDelete(evt: MouseEvent) {
        evt.stopPropagation();
        if (this.quoteVersionWithValidCreditCheck) {
            return;
        }
        this.delete.emit(this.versions[0].id);
    }

}
