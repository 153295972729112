// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host canvas {
  background: rgba(0, 0, 0, 0.04);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIlNpZ25hdHVyZVBhZENvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNJO0VBQ0ksK0JBQUE7QUFBUiIsImZpbGUiOiJTaWduYXR1cmVQYWRDb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgICBjYW52YXMge1xuICAgICAgICBiYWNrZ3JvdW5kOiByZ2JhKDAsIDAsIDAsIDAuMDQpO1xuICAgIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/presentation/src/Components/signature-pad/SignaturePadComponent.scss"],"names":[],"mappings":"AACI;EACI,+BAAA;AAAR;AACA,4WAA4W","sourcesContent":[":host {\n    canvas {\n        background: rgba(0, 0, 0, 0.04);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
