// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host legend {
  font-size: 12px;
  font-weight: unset;
  margin-bottom: 5px;
}
:host fieldset {
  margin-bottom: 8px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInJldGFpbGVyLWFja25vd2xlZGdtZW50LXNpZ25hdHVyZS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDSTtFQUNJLGVBQUE7RUFDQSxrQkFBQTtFQUNBLGtCQUFBO0FBQVI7QUFHSTtFQUNJLGtCQUFBO0FBRFIiLCJmaWxlIjoicmV0YWlsZXItYWNrbm93bGVkZ21lbnQtc2lnbmF0dXJlLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICAgIGxlZ2VuZCB7XG4gICAgICAgIGZvbnQtc2l6ZTogMTJweDtcbiAgICAgICAgZm9udC13ZWlnaHQ6IHVuc2V0O1xuICAgICAgICBtYXJnaW4tYm90dG9tOiA1cHg7XG4gICAgfVxuXG4gICAgZmllbGRzZXQge1xuICAgICAgICBtYXJnaW4tYm90dG9tOiA4cHg7XG4gICAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/contractManagement/src/Views/retailer-acknowledgment-signature/retailer-acknowledgment-signature.component.scss"],"names":[],"mappings":"AACI;EACI,eAAA;EACA,kBAAA;EACA,kBAAA;AAAR;AAGI;EACI,kBAAA;AADR;AACA,4lBAA4lB","sourcesContent":[":host {\n    legend {\n        font-size: 12px;\n        font-weight: unset;\n        margin-bottom: 5px;\n    }\n\n    fieldset {\n        margin-bottom: 8px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
