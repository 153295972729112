//#region Imports

import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ILeasingQuoteDto, IRetailerQuoteDetailInformationResultDto, IRetailerQuoteDto} from '@abcfinlab/api/global';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject, Observable} from 'rxjs';
import {QUOTE_RETAILER_CREATE_QUOTE_NEW_VERSION_ROUTE_PATH} from "../../../Routing/RoutePaths";

//#endregion

export interface ILesseeUpdateComparisonInfo {
    oldCalculation: IRetailerQuoteDetailInformationResultDto;
    newCalculation: IRetailerQuoteDto;
}
export enum RetailerRecalculationConfirmationState {
    NewCalculation = 'NewCalculation',
    ConfirmChanges = 'ConfirmChanges',
    Cancel = 'Cancel'
}

/**
 * The RetailerRecalculationConfirmationView view.
 *
 * @public
 */
@Component({
    selector: 'l7-retailer-recalculation-confirmation-view',
    templateUrl: './RetailerRecalculationConfirmationView.html',
    styleUrls: ['./RetailerRecalculationConfirmationView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetailerRecalculationConfirmationView implements OnInit, OnDestroy {

    //#region Fields
    private readonly _comparisonData: BehaviorSubject<ILesseeUpdateComparisonInfo>;
    protected readonly RetailerRecalculationConfirmationState = RetailerRecalculationConfirmationState;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the retailerRecalculationConfirmation class.
     *
     * @public
     */
    public constructor(@Inject(MAT_DIALOG_DATA) public dialogData: ILesseeUpdateComparisonInfo, private readonly _dialogRef: MatDialogRef<RetailerRecalculationConfirmationView>) {
        this._comparisonData = new BehaviorSubject(dialogData);
    }

    //#endregion

    //#region Properties

    /**
     * Returns the presenter of the retailerRecalculationConfirmation
     *
     * @public
     * @readonly
     */

    //#endregion

    //#region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
    }

    public get comparisonData(): Observable<ILesseeUpdateComparisonInfo> {
        return this._comparisonData.asObservable();
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
    }

    public submitDialog( state: RetailerRecalculationConfirmationState ): void {
        this._dialogRef.close(state);
    }

    //#endregion

}
