//#region Imports

import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnDestroy, OnInit } from '@angular/core';
import { RetailersOverviewViewPresenter } from './RetailersOverviewViewPresenter';

//#endregion

/**
 * The `RetailersOverviewView` view.
 *
 * @public
 */
@Component({
    selector: 'l7-retailers-overview-view',
    templateUrl: './RetailersOverviewView.html',
    styleUrls: ['./RetailersOverviewView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        RetailersOverviewViewPresenter
    ]
})
export class RetailersOverviewView implements OnInit, OnDestroy {

    //#region Fields

    private readonly _presenter: RetailersOverviewViewPresenter;

    private readonly _destroyRef = inject(DestroyRef);

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `RetailersOverviewView` class.
     *
     * @public
     */
    public constructor(presenter: RetailersOverviewViewPresenter) {
        this._presenter = presenter;
    }

    //#endregion

    //#region Proeprties

    /**
     * Returns the presenter of the `RetailersOverviewView`
     *
     * @public
     * @readonly
     */
    public get presenter(): RetailersOverviewViewPresenter {
        return this._presenter;
    }

    //#endregion

    //#region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize(this._destroyRef);
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    //#endregion

}
