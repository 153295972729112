//#region Imports

import { Injectable } from '@angular/core';
import { TranslateCompiler, TranslateStore } from '@ngx-translate/core';

//#endregion

/**
 * Translation compiler that resolves key references in the form of $ref:key.
 * It replaces placeholders with the corresponding translation values.
 *
 * @public
 */
@Injectable()
export class DefaultTranslationCompiler extends TranslateCompiler {

    //#region Fields

    private readonly _store: TranslateStore

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `DefaultTranslationCompiler` class.
     *
     * @public
     * @param store The translation store.
     */
    public constructor(store: TranslateStore) {
        super();

        this._store = store;
    }

    //#endregion

    //#region Methods

    /**
     * Compiles a single translation value.
     *
     * @public
     * @param value The translation value (string or function).
     * @param lang The language code.
     * @returns The compiled translation or the original value.
     */
    public compile(value: string, lang: string): string | Function {
        return this.resolveReferences(value, lang);
    }

    /**
     * Compiles a set of translations.
     *
     * @public
     * @param translations The set of translations.
     * @param lang The language code.
     * @returns The compiled translations.
     */
    public compileTranslations(translations: any, lang: string): any {
        return this.resolveReferencesInTranslations(translations, lang);
    }

    /**
     * Resolves references within a single translation string.
     * Example: If a translation contains '$ref:global.further', it will replace it with the actual value of 'global.further'.
     *
     * @private
     * @param value The translation string.
     * @param lang The language code.
     * @returns The translation string with resolved references.
     */
    private resolveReferences(value: string, lang: string): string {
        const referenceRegex = /^\$ref:([a-zA-Z0-9._-]+)$/;
        const match = value.match(referenceRegex);
        if (match) {
            const key = match[1];
            const translation = this.getTranslationForKey(key, lang);
            return translation || value;
        }
        return value;
    }

    /**
     * Recursively resolves references in an entire translation object.
     *
     * @private
     * @param translations The translation object.
     * @param lang The language code.
     * @returns The translation object with all references resolved.
     */
    private resolveReferencesInTranslations(translations: any, lang: string): any {
        if (typeof translations === 'string') {
            return this.resolveReferences(translations, lang);
        }

        if (typeof translations === 'object') {
            const resolvedTranslations: any = {};
            for (const key in translations) {
                if (translations.hasOwnProperty(key)) {
                    resolvedTranslations[key] = this.resolveReferencesInTranslations(translations[key], lang);
                }
            }
            return resolvedTranslations;
        }

        return translations;
    }

    /**
     * Helper method to fetch a translation for a given key.
     *
     * @private
     * @param key The translation key (e.g., 'global.further').
     * @param lang The language code.
     * @returns The translation value or null if not found.
     */
    private getTranslationForKey(key: string, lang: string): string | null {
        const dict = this._store.translations[lang];

        if (dict !== undefined) {
            const translation = dict[key];
            return translation !== key ? translation : null;
        }

        return null;
    }

    //#endregion

}
