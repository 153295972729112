import { Component } from '@angular/core';
import {SuccessIds} from '../success/success.component';

@Component({
  selector: 'gwg-success-container',
  template: `
    <gwg-success [id]="localId" [bodyText]="'gwg.success.body_local'"></gwg-success>
  `,
  styles: [
      `

                                                                                                                      `
  ]
})
export class SuccessContainerComponent {
  localId = SuccessIds.LOCAL;
}
