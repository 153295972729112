import { UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function notEqualValidator(value: number): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
        if (control.value === null) {
            return null;
        }
        return control.value === value ? null : {
            notEqual: true
        };
    };
}
