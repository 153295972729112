export enum InsuranceType {
    NO = 'NO',
    STANDARD = 'STANDARD',
    HUNDRED_PRO = 'PRO100',
}

export enum InsuranceViewTypes {
    QUOTE_DETAIL = 'QUOTE_DETAIL',
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    TRANSIENT = 'TRANSIENT'
}
