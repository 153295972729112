//#region Imports

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

//#endregion

export type SelectionType = 'digitalSign' | 'uploadDeliveryDocument' | 'uploadTakeoverDocument';

export interface ITakeoverDocumentationDialogData {
    companyName: string;
}

export interface ITakeoverDocumentationDialogResult {
    kind: SelectionType;
    canceled: boolean;
}

/**
 * @public
 */
@Component({
    selector: 'l7-takeover-documentation-dialog-view',
    templateUrl: './takeoverDocumentationDialogView.html',
    styleUrls: ['./takeoverDocumentationDialogView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TakeoverDocumentationDialogView {

    //#region Fields

    private readonly _dialogRef: MatDialogRef<TakeoverDocumentationDialogView>;
    private readonly _data: ITakeoverDocumentationDialogData;

    private _kind: SelectionType | null;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `TakeoverDocumentationDialogView` class.
     *
     * @public
     */
    public constructor(dialogRef: MatDialogRef<TakeoverDocumentationDialogView>, @Inject(MAT_DIALOG_DATA) data: ITakeoverDocumentationDialogData) {
        this._dialogRef = dialogRef;
        this._data = data;
        this._kind = null;
    }

    //#endregion

    //#region Properties

    /**
     * @internal
     * @readonly
     */
    public get data(): ITakeoverDocumentationDialogData {
        return this._data;
    }

    //#endregion

    //#region Methods

    /**
     * @internal
     */
    public onSelect(kind: 'digitalSign' | 'uploadDeliveryDocument' | 'uploadTakeoverDocument'): void {
        this._kind = kind;
        this.onClose(false);
    }

    /**
     * @internal
     */
    public onClose(canceled: boolean): void {
        this._dialogRef.close({
            kind: this._kind,
            canceled: canceled
        });
    }

    //#endregion

}
