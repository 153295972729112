//#region Imports

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { CalendarWidgetViewPresenter } from './CalendarWidgetViewPresenter';

//#endregion

/**
 * The `CalendarWidgetView` view.
 *
 * @public
 */
@Component({
    selector: 'l7-calendar-widget-view',
    templateUrl: './CalendarWidgetView.html',
    styleUrls: ['./CalendarWidgetView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        CalendarWidgetViewPresenter
    ]
})
export class CalendarWidgetView implements OnInit, OnDestroy {

    //#region Fields

    private readonly _presenter: CalendarWidgetViewPresenter;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `CalendarWidgetView` class.
     *
     * @public
     */
    public constructor(presenter: CalendarWidgetViewPresenter) {
        this._presenter = presenter;
    }

    //#endregion

    //#region Proeprties

    /**
     * Returns the presenter of the `CalendarWidgetView`
     *
     * @public
     * @readonly
     */
    public get presenter(): CalendarWidgetViewPresenter {
        return this._presenter;
    }

    //#endregion

    //#region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize();
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    //#endregion

}
