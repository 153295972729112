// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .align-center {
  display: flex;
  justify-content: center;
  text-align: center;
}
:host p {
  line-height: 1.8em;
}
:host h2 {
  text-align: center;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNvbnRyYWN0LWRvY3VtZW50LWNyZWF0aW9uLm1vZGFsLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0k7RUFDRSxhQUFBO0VBQ0EsdUJBQUE7RUFDQSxrQkFBQTtBQUFOO0FBRUk7RUFDSSxrQkFBQTtBQUFSO0FBR0k7RUFDSSxrQkFBQTtBQURSIiwiZmlsZSI6ImNvbnRyYWN0LWRvY3VtZW50LWNyZWF0aW9uLm1vZGFsLnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XG4gICAgLmFsaWduLWNlbnRlcntcbiAgICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgICAgIHRleHQtYWxpZ246IGNlbnRlcjtcbiAgICB9XG4gICAgcCB7XG4gICAgICAgIGxpbmUtaGVpZ2h0OiAxLjhlbTtcbiAgICB9XG5cbiAgICBoMiB7XG4gICAgICAgIHRleHQtYWxpZ246IGNlbnRlcjtcbiAgICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/legacy/gwg/src/lib/shared/contract-document-creation-modal/contract-document-creation.modal.scss"],"names":[],"mappings":"AACI;EACE,aAAA;EACA,uBAAA;EACA,kBAAA;AAAN;AAEI;EACI,kBAAA;AAAR;AAGI;EACI,kBAAA;AADR;AACA,opBAAopB","sourcesContent":[":host {\n    .align-center{\n      display: flex;\n      justify-content: center;\n      text-align: center;\n    }\n    p {\n        line-height: 1.8em;\n    }\n\n    h2 {\n        text-align: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
