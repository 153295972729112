/**
 * @internal
 */
export namespace KnownEvents {
    export const SETTLEMENT_CHANGED = 'SettlementChanged';
    export const SETTLEMENT_DETAIL_LOADED = 'SettlementDetailLoaded';
    export const SETTLEMENT_DOCUMENTS = 'SettlementDocuments';
    export const SETTLEMENT_SEND_TO_ACCOUNTANT_SUCCEED = 'SettlementSendToAccountantSucceed';
    export const SETTLEMENT_BILLABLE_READY = 'SettlementBillableReady';
    export const SETTLEMENT_DIGITAL_PROCESSING_BILLABLE_READY = 'SettlementDigitalProcessingBillableReady';
}
