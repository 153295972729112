// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .logout-container {
  margin-top: 40px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIkxvZ291dFZpZXcuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRTtFQUNFLGdCQUFBO0FBQUoiLCJmaWxlIjoiTG9nb3V0Vmlldy5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICAubG9nb3V0LWNvbnRhaW5lciB7XG4gICAgbWFyZ2luLXRvcDogNDBweDtcbiAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/auth/src/Views/LogoutView.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ;AACA,4TAA4T","sourcesContent":[":host {\n  .logout-container {\n    margin-top: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
