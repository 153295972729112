import {
    ContractCrudService,
    ContractFunctionService,
    IContractsBillableReadyDto,
    IContractStateDto,
    IDigitalProcessingDto,
    IProcessingStatusDto,
    ISalesChannelDto,
    IStepResultDto, IStepResultStatusDto
} from '@abcfinlab/api/contract';
import { EventHub, TranslationFacade } from '@abcfinlab/core';
import { BusyBoxService, MessageBoxButton, MessageBoxResult, MessageBoxService, ToastService } from '@abcfinlab/ui';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ISettlementSendToAccount } from 'libs/backoffice/src/Models/ISettlementSendToAccount';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { DialogService } from '../../../../apps/shell/src/app/private/services/dialog/dialog.service';
import { GenericDialogComponent, GenericDialogData } from '../../../../apps/shell/src/app/shared/modals/generic-dialog/generic-dialog.component';
import { ISettlementContractInformationInfo } from '../Models/ISettlementContractInformationInfo';
import { ISettlementNavErrorDialogData, ISettlementNavErrorValue } from '../Models/ISettlementNavErrorDialogData';
import { KnownEvents } from '../Models/KnownEvents';
import { ManualProcessingDialogComponent } from './Dialogs/manual-processing-dialog.component';
import { SettlementNavErrorDialogView } from './Dialogs/SettlementNavErrorDialogView';

@UntilDestroy()
@Component({
    selector: 'l7-settlement-states-view',
    templateUrl: './SettlementStatesView.html',
    styleUrls: ['./SettlementStatesView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettlementStatesView implements OnInit {

    private readonly _contractsService: ContractCrudService;
    private readonly _contractFunctionService: ContractFunctionService;
    private readonly _dialogService: DialogService;
    private readonly _eventHub: EventHub;
    private readonly _dialog: MatDialog;
    private readonly _toastService: ToastService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _messageBoxService: MessageBoxService;
    private readonly _busyBoxService: BusyBoxService;

    private _data: ISettlementContractInformationInfo;

    public constructor(contractsService: ContractCrudService, contractFunctionService: ContractFunctionService, eventHub: EventHub, dialog: MatDialog,
        toastService: ToastService, translationFacade: TranslationFacade, messageBoxService: MessageBoxService, busyBoxService: BusyBoxService, dialogService: DialogService) {
        this._contractsService = contractsService;
        this._contractFunctionService = contractFunctionService;
        this._eventHub = eventHub;
        this._dialog = dialog;
        this._toastService = toastService;
        this._translationFacade = translationFacade;
        this._messageBoxService = messageBoxService;
        this._busyBoxService = busyBoxService;
        this._dialogService = dialogService;
    }

    @Input()
    public salesChanel: ISalesChannelDto;
    @Input() public isReadOnlyUser: boolean;
    public readonly sendToNavState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public readonly clarificationState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public readonly manualProcessingState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public readonly discardState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public readonly hasReportsState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public sendToNavAndManualProcessingTooltip: string;

    public ngOnInit(): void {
        this._eventHub.getEvent<ISettlementContractInformationInfo>(KnownEvents.SETTLEMENT_DETAIL_LOADED)
            .pipe(untilDestroyed(this))
            .subscribe(x => {
                this._data = x;
                if (this._data?.row.nav_contract_number) {
                    combineLatest([
                        this._contractFunctionService.getContractsBillableReady({ contractNo: this._data.row.nav_contract_number }),
                        this._contractFunctionService.getDigitalProcessingBillableReady({ contractNo: this._data.row.nav_contract_number })
                    ]).pipe(untilDestroyed(this))
                        .subscribe(([data, digitalProcessingReady]) => {
                            const isReadyForManualProcessing = this.shouldManualProcessingBeActive(data.manual_processing, data.manual_processing_ready_errors);

                            this._eventHub.publish<IContractsBillableReadyDto>(KnownEvents.SETTLEMENT_BILLABLE_READY, data);

                            this.sendToNavState.next(digitalProcessingReady.ready);
                            this.discardState.next(x.contract.state !== 'MANUALLY_DISCARDED');
                            this.manualProcessingState.next(isReadyForManualProcessing);
                            this.clarificationState.next(data.clarification);
                            this.hasReportsState.next(data.report_available);

                            this.sendToNavAndManualProcessingTooltip = this.translateTooltipForSendToNavAndManualProcessing(data.manual_processing_ready_errors);
                        });
                }
            });
        this._eventHub.getEvent<IDigitalProcessingDto>(KnownEvents.SETTLEMENT_DIGITAL_PROCESSING_BILLABLE_READY)
            .pipe(untilDestroyed(this))
            .subscribe(x => this.sendToNavState.next(x.ready));
    }

    public showManualProcessingModal(): void {
        this._dialog.open(ManualProcessingDialogComponent, {
            data: this._data,
            width: '720px'
        }).afterClosed().pipe(untilDestroyed(this)).subscribe(res => {
            if (res) {
                this.setStatusSendToNav(IProcessingStatusDto.Manual, false);
            }
        });
    }
    public showReportsModal(): void {
        this._busyBoxService.show(null, null, this._contractFunctionService.getGirmResults({ contractId: this._data.row.nav_contract_number }))
            .pipe(untilDestroyed(this))
            .subscribe(x => {
                this.prepareGirmModal(x);
            }, (error: HttpErrorResponse) => {
                this._toastService.show(this._translationFacade.instant(`error.${error.error.error_description}`), 'danger', 'long');
            });
    }
    public showManualDiscardedModal(): void {
        const data: GenericDialogData = {
            id: 'dialog_contact_alert_changed_address',
            image: 'assets/images/icon-create-contract.svg',
            title: this._translationFacade.instant('settlement.manually_discarded.title'),
            body: this._translationFacade.instant('settlement.manually_discarded.text'),
            negativeText: 'global.cancel',
            positiveText: this._translationFacade.instant('settlement.manually_discarded.button_discard')
        };
        this._dialogService
            .openDialog(GenericDialogComponent, {}, data).afterClosed().subscribe(confirmed => {
                if (confirmed) {
                    const body = {
                        status: IContractStateDto.ManuallyDiscarded
                    };
                    this._busyBoxService.show(null, null, this._contractsService.setContractStatus({ contractNumber: this._data.row.nav_contract_number, body }))
                        .pipe(untilDestroyed(this))
                        .subscribe(() => {
                            this._toastService.show(this._translationFacade.instant('settlement.manually_discarded.success'), 'success');
                            this.setStatusSendToNav(IProcessingStatusDto.ManuallyDiscarded, false);
                        });
                }
            });
    }

    public setToClarificationState(): void {
        this._contractsService.setContractStatus({
            contractNumber: this._data.row.nav_contract_number,
            body: {
                status: IContractStateDto.Clarification
            }
        }).pipe(untilDestroyed(this))
            .subscribe(x => {
                this._toastService.show(this._translationFacade.translate('settlement.state.messages.clarification_state'), 'success');
                this.clarificationState.next(false);
            });
    }

    public onSendToNavision(): void {
        this._messageBoxService.show(this._translationFacade.translate('settlement.state.messages.navision.title'),
            this._translationFacade.translate('settlement.state.messages.navision.content'), MessageBoxButton.YesNo, {
            labels: {
                yes: this._translationFacade.translate('global.yes'),
                no: this._translationFacade.translate('global.no')
            }
        }).pipe(untilDestroyed(this))
            .subscribe(result => {
                if (result === MessageBoxResult.Yes) {
                    this._busyBoxService.show(null, null, this._contractFunctionService.sendToNavision({ contractId: this._data.row.nav_contract_number }))
                        .pipe(untilDestroyed(this))
                        .subscribe(x => {
                            this.prepareGirmModal(x);
                        }, (error: HttpErrorResponse) => {
                            this._toastService.show(this._translationFacade.instant(`error.${error.error.error_description}`), 'danger', 'long');
                        });
                }
            });
    }

    private setStatusSendToNav(status: IProcessingStatusDto, response: boolean): void {
        this.clarificationState.next(response);
        this.sendToNavState.next(response);
        this.manualProcessingState.next(response);
        this.discardState.next(response);
        this._eventHub
            .publish<ISettlementSendToAccount>(KnownEvents.SETTLEMENT_SEND_TO_ACCOUNTANT_SUCCEED, {
                contractNumber: this._data.row.nav_contract_number, status
            });
    }

    private shouldManualProcessingBeActive(isReadyForManualProcessing: boolean, manualProcessingReadyErrors: Array<'NO_ACKNOWLEDGEMENT_DOCUMENT' | 'NO_INVOICE_DOCUMENT' | 'NO_KUEV_DOCUMENT' | 'NO_PROOF_OF_PAYMENT_DOCUMENT'>): boolean {
        if (!isReadyForManualProcessing) {
            return false;
        } else if (isReadyForManualProcessing && (this._data.row.status === IProcessingStatusDto.ToBeDiscussed &&
            (manualProcessingReadyErrors.includes('NO_ACKNOWLEDGEMENT_DOCUMENT') || manualProcessingReadyErrors.includes('NO_INVOICE_DOCUMENT') || manualProcessingReadyErrors.includes('NO_KUEV_DOCUMENT')))) {
            return false;
        }
        return true;
    }

    private translateTooltipForSendToNavAndManualProcessing(manualProcessingReadyErrors: Array<'NO_ACKNOWLEDGEMENT_DOCUMENT' | 'NO_INVOICE_DOCUMENT' | 'NO_KUEV_DOCUMENT' | 'NO_PROOF_OF_PAYMENT_DOCUMENT'>): string {
        if (this._data.row.status !== IProcessingStatusDto.ToBeDiscussed) {
            return null;
        }

        if (manualProcessingReadyErrors.includes('NO_ACKNOWLEDGEMENT_DOCUMENT') || manualProcessingReadyErrors.includes('NO_INVOICE_DOCUMENT') || manualProcessingReadyErrors.includes('NO_KUEV_DOCUMENT')) {
            let translatedReasons = '';
            manualProcessingReadyErrors.forEach(reason => {
                translatedReasons += `\n${this._translationFacade.instant(`settlement.state.messages.to_be_discussed.${reason.toLowerCase()}`)}`;
            });
            return translatedReasons;
        }

        return null;
    }

    private prepareGirmModal(x: { [p: string]: IStepResultDto }): void {
        // Check for timestamp
        const hasTimeStamp = x.timestamp;
        const sortedArray = Object.entries(x).sort(([aKey, aValue]: [string, ISettlementNavErrorValue], [bKey, bValue]: [string, ISettlementNavErrorValue]) => {
            if ((aValue.status === IStepResultStatusDto.Warning || aValue.status === IStepResultStatusDto.Ok) && bValue.status === IStepResultStatusDto.Error) {
                return 1;
            } else if (aValue.status === IStepResultStatusDto.Error) {
                return -1;
            } else if (aValue.status === IStepResultStatusDto.Ok && bValue.status === IStepResultStatusDto.Warning) {
                return 1;
            } else if (aValue.status === IStepResultStatusDto.Warning && bValue.status === IStepResultStatusDto.Ok) {
                return -1;
            }
            return 0;
        });
        this._dialog.open(SettlementNavErrorDialogView, {
            id: 'SettlementNavErrorDialogView',
            disableClose: true,
            closeOnNavigation: true,
            height: '520px',
            width: '620px',
            data: {
                contractNo: this._data.row.nav_contract_number,
                submitDate: hasTimeStamp ? hasTimeStamp.message : new Date(Date.now()),
                errors: sortedArray.map(([key, value]) => ({
                    key: 'settlement.nav_errors.'.concat(key),
                    value: value
                }))
            } as ISettlementNavErrorDialogData
        });
        const arrayContractActivated = Object.entries(x).filter(([key, value]: [string, ISettlementNavErrorValue]) => value.name === 'contract_activate');
        const isContractActivated = arrayContractActivated[0] as unknown as ISettlementNavErrorValue;
        if (isContractActivated?.[1].success) {
            this.setStatusSendToNav(IProcessingStatusDto.Transferred, false);
        }
    }

}
