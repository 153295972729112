//#region Imports

import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

//#endregion

/**
 * The `PageSectionComponent` component.
 *
 * @public
 */
@Component({
    selector: 'l7-page-section',
    templateUrl: 'PageSectionComponent.html',
    styleUrls: ['PageSectionComponent.scss'],
    exportAs: 'l7PageSection',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageSectionComponent {

    //#region Fields

    private _inline: boolean;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `PageHeaderComponent` class.
     *
     * @public
     */
    public constructor() {
        this._inline = false;
    }

    //#endregion

    //#region Properties

    /**
     * Gets or sets the `inline` property.
     *
     * @public
     */
    @Input()
    @HostBinding('class.inline')
    public get inline(): boolean {
        return this._inline;
    }
    public set inline(value: boolean) {
        this._inline = value;
    }

    //#endregion

    //#region Methods

    //#endregion

}
