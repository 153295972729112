//#region Imports

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import {
    CONTRACT_MANAGEMENT_RETAILER_CONTRACT_DETAILS_ROUTE_PATH
} from '../../../../contractManagement/src/Routing/RoutePaths';

//#endregion

/**
 * The `RetailerContractSuccessView` view.
 *
 * @public
 */
@UntilDestroy()
@Component({
    selector: 'l7-retailer-contract-success-view',
    templateUrl: './RetailerContractSuccessView.html',
    styleUrls: ['./RetailerContractSuccessView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RetailerContractSuccessView {

    //#region Fields

    private readonly _router: Router;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `RetailerContractSuccessView` class.
     *
     * @public
     */
    public constructor(router: Router) {
        this._router = router;
    }

    @Input() contractNo: string;

    @Input() showButton: boolean;

    @Input() customText: string;

    public goToContract(): void {
        this._router.navigateByUrl(`presentation/${CONTRACT_MANAGEMENT_RETAILER_CONTRACT_DETAILS_ROUTE_PATH}/${this.contractNo}`);
    }
}
