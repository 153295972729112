//#region Imports

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IMessageBoxComponentData } from './IMessageBoxComponentData';
import { MessageBoxResult } from './MessageBoxResult';

//#endregion

@Component({
    selector: 'l7-message-box',
    templateUrl: './MessageBoxComponent.html',
    styleUrls: ['./MessageBoxComponent.scss']
})
export class MessageBoxComponent {

    //#region Fields

    private readonly _data: any;

    //#endregion

    //#region Ctor

    public constructor(public dialogRef: MatDialogRef<MessageBoxComponent, MessageBoxResult>, @Inject(MAT_DIALOG_DATA) data: IMessageBoxComponentData) {
        this._data = data;
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `data` property.
     *
     * @public
     * @readonly
     */
    public get data(): IMessageBoxComponentData {
        return this._data;
    }

    //#endregion

    //#region Methods

    public onClose(result: MessageBoxResult): void {
        this.dialogRef.close(result);
    }

    //#endregion

}
