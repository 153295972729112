//#region Imports

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TextBlockModule } from '../TextBlock/TextBlockModule';
import { DialogHeaderComponent } from './DialogHeaderComponent';
import {MatFormFieldModule} from '@angular/material/form-field';

//#endregion

/**
 * The `DialogHeaderModule` module.
 *
 * @public
 */
@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        FlexLayoutModule,
        TextBlockModule,
        MatFormFieldModule
    ],
    declarations: [
        DialogHeaderComponent,
    ],
    exports: [
        DialogHeaderComponent
    ]
})
export class DialogHeaderModule {
}
