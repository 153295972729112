import {Injectable} from '@angular/core';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private readonly _isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private readonly _router: Router) {
    this._router.events.subscribe((event: any) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this._isLoading$.next(true);
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this._isLoading$.next(false);
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  /**
   * Provide loading application wide loading information
   */
  public getLoadingStatus(): Observable<boolean> {
    return this._isLoading$.asObservable();
  }

  /**
   * Set the global loading status of the application
   * @param status    The loading state as boolean
   */
  public setLoadingStatus(status: boolean): void {
    this._isLoading$.next(status);
  }

}
