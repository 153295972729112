//#region Imports

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ColorSet } from '../../Color';

//#endregion

/**
 * The `personaCardComponent` component.
 *
 * @public
 */
@Component({
    selector: 'l7-persona-card',
    templateUrl: './PersonaCardComponent.html',
    styleUrls: ['./PersonaCardComponent.scss'],
    exportAs: 'personaCard ',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PersonaCardComponent {

    //#region Properties

    private _color: ColorSet;
    private _name: string;
    private _role: string;
    private _phone: string;
    private _email: string;

    //#endregion

    //#region Ctor

    public constructor() {

        this._color = 'neutral';
        this._name = '';
        this._role = '';
        this._phone = '';
        this._email = '';
    }

    //#endregion

    //#region Properties

    /**
     * Gets or sets the color set type value. This will applied as dom class property.
     *
     * @public
     * @type ColorSet | string
     * @default ColorSet.Default
     */
    @Input()
    public get color(): ColorSet | string {
        return this._color;
    }
    public set color(value: ColorSet | string) {
        this._color = value as ColorSet;
    }

    /**
     * Gets or sets the `name` property.
     *
     * @public
     */
    @Input()
    public get name(): string {
        return this._name;
    }
    public set name(value: string) {
        this._name = value;
    }

    /**
     * Gets or sets the `role` property.
     *
     * @public
     */
    @Input()
    public get role(): string {
        return this._role;
    }
    public set role(value: string) {
        this._role = value;
    }

    /**
     * Gets or sets the `email` property.
     *
     * @public
     */
    @Input()
    public get email(): string {
        return this._email;
    }
    public set email(value: string) {
        this._email = value;
    }

    /**
     * Gets or sets the `phone` property.
     *
     * @public
     */
    @Input()
    public get phone(): string {
        return this._phone;
    }
    public set phone(value: string) {
        this._phone = value;
    }

    //#endregion

}
