// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  width: 100%;
  height: 100%;
}
:host .item {
  margin: 8px;
  max-width: calc(33.3% - 16px);
}
:host .item.lt-lg {
  max-width: calc(50% - 16px);
}
:host .item.lt-md {
  max-width: 100%;
}
:host mat-card-content {
  padding-bottom: 16px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInF1b3RlLXZlcnNpb25zLWNhcmRzLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksY0FBQTtFQUNBLFdBQUE7RUFDQSxZQUFBO0FBQ0o7QUFDSTtFQUNJLFdBQUE7RUFDQSw2QkFBQTtBQUNSO0FBQ1E7RUFDSSwyQkFBQTtBQUNaO0FBRVE7RUFDSSxlQUFBO0FBQVo7QUFJSTtFQUNJLG9CQUFBO0FBRlIiLCJmaWxlIjoicXVvdGUtdmVyc2lvbnMtY2FyZHMuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XG4gICAgZGlzcGxheTogYmxvY2s7XG4gICAgd2lkdGg6IDEwMCU7XG4gICAgaGVpZ2h0OiAxMDAlO1xuXG4gICAgLml0ZW0ge1xuICAgICAgICBtYXJnaW46IDhweDtcbiAgICAgICAgbWF4LXdpZHRoOiBjYWxjKDMzLjMlIC0gMTZweCk7XG5cbiAgICAgICAgJi5sdC1sZyB7XG4gICAgICAgICAgICBtYXgtd2lkdGg6IGNhbGMoNTAlIC0gMTZweCk7XG4gICAgICAgIH1cblxuICAgICAgICAmLmx0LW1kIHtcbiAgICAgICAgICAgIG1heC13aWR0aDogMTAwJTtcbiAgICAgICAgfVxuICAgIH1cblxuICAgIG1hdC1jYXJkLWNvbnRlbnQge1xuICAgICAgICBwYWRkaW5nLWJvdHRvbTogMTZweDtcbiAgICB9XG5cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/quote/src/Components/quote-versions-cards/quote-versions-cards.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,WAAA;EACA,YAAA;AACJ;AACI;EACI,WAAA;EACA,6BAAA;AACR;AACQ;EACI,2BAAA;AACZ;AAEQ;EACI,eAAA;AAAZ;AAII;EACI,oBAAA;AAFR;AACA,g5BAAg5B","sourcesContent":[":host {\n    display: block;\n    width: 100%;\n    height: 100%;\n\n    .item {\n        margin: 8px;\n        max-width: calc(33.3% - 16px);\n\n        &.lt-lg {\n            max-width: calc(50% - 16px);\n        }\n\n        &.lt-md {\n            max-width: 100%;\n        }\n    }\n\n    mat-card-content {\n        padding-bottom: 16px;\n    }\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
