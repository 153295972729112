//#region Imports

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    RetailerCreateContractViewPresenter
} from './RetailerCreateContractViewPresenter';
import { BehaviorSubject, Observable } from 'rxjs';

//#endregion

/**
 * The `RetailerCreateContractView` view.
 *
 * @public
 */
@UntilDestroy()
@Component({
    selector: 'l7-retailer-create-contract-view',
    templateUrl: './RetailerCreateContractView.html',
    styleUrls: ['./RetailerCreateContractView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        RetailerCreateContractViewPresenter
    ]
})
export class RetailerCreateContractView implements OnInit, OnDestroy {

    //#region Fields

    private readonly _presenter: RetailerCreateContractViewPresenter;

    private readonly _dialogRef: MatDialogRef<RetailerCreateContractView>;

    private readonly _supportiveTitle: BehaviorSubject<string> = new BehaviorSubject<string>('Bitte wählen Sie eine der beiden Optionen');
    private readonly _title: BehaviorSubject<string> = new BehaviorSubject<string>('Vertragsabschluss');

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `RetailerCreateContractView` class.
     *
     * @public
     */
    public constructor(presenter: RetailerCreateContractViewPresenter, dialogRef: MatDialogRef<RetailerCreateContractView>) {
        this._presenter = presenter;
        this._dialogRef = dialogRef;
    }

    //#endregion

    //#region Properties
    @ViewChild('stepper') public stepperCreateContract: MatStepper;

    /**
     * Returns the presenter of the `RetailerCreateContractViewPresenter`
     *
     * @public
     * @readonly
     */
    public get presenter(): RetailerCreateContractViewPresenter {
        return this._presenter;
    }

    /**
     * Returns the property `supportiveTitle`
     *
     * @public
     * @readonly
     */
    public get supportiveTitle(): Observable<string> {
        return this._supportiveTitle.asObservable();
    }

    /**
     * Returns the property `title`
     *
     * @public
     * @readonly
     */
    public get title(): Observable<string> {
        return this._title.asObservable();
    }

    //#endregion

    //#region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize();
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    /**
     * @internal
     */
    public closeOnSuccess(evt: 'LOCAL'): void {
        if (evt) {
            this._dialogRef.close(evt);
        }
    }

    /**
     * @internal
     */
    public onClose(): void {
        this._dialogRef.close();
    }

    public chooseSigningType(type: 'LOCAL' | 'REMOTE'): void {
        if (type === 'LOCAL') {
            this._supportiveTitle.next('Bitte lassen Sie die Angaben durch den Unterzeichner prüfen und bestätigen.');
            this._dialogRef.removePanelClass('dialog-with-width');
            this._dialogRef.addPanelClass('fit-to-content');
            this.stepperCreateContract.next();
        } else {
            this.stepperCreateContract.selectedIndex = 2;
            this._title.next('Vertrag per E-Mail senden');
            this._supportiveTitle.next('Bitte kontrollieren und korrigieren Sie ggf. die E-Mail Adresse des Unterzeichners');
        }
    }


//#endregion
}
