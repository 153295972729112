//#region Imports

import { NgModule, Pipe, PipeTransform } from '@angular/core';

//#endregion

/**
 * Transforms a `string` to a formatted `string` where one or more format items in a string with the string representation of a specified object.
 * @public
 */
@Pipe({
    name: 'format',
})
export class FormatPipe implements PipeTransform {

    //#region Methods

    public transform(value: string, ...args: Array<any>): string {
        return this.format(value, args);
    }

    private format(self: string, ...args: Array<any>): string {
        const allArgs = new Array<any>();
        args.forEach(x => {
            if (Array.isArray(x)) {
                x.forEach(y => {
                    allArgs.push(y);
                });
            } else {
                allArgs.push(x);
            }
        });

        return self.replace(/{(\d+)}/g, (match, index) => {
            if (allArgs[index] !== undefined) {
                return allArgs[index];
            }

            return match;
        });
    }

    //#endregion

}

@NgModule({
    declarations: [FormatPipe],
    exports: [FormatPipe]
})
export class FormatPipeModule {
}
