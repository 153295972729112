import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'l7-retailer-quote-step-chip',
    templateUrl: './retailer-quote-step-chip.component.html',
    styleUrls: ['./retailer-quote-step-chip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RetailerQuoteStepChipComponent {
    @Input('label') label: string;
    @Input('tooltip') tooltip: string;
    @Input('icon') icon: string;
    @Input('variant') variant: string;
}
