// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.align-center > div {
  display: flex;
  justify-content: center;
}

.body-success {
  text-align: center;
  padding: 0 8%;
}

h4, h1 {
  margin: 10px 0;
  width: auto;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInN1Y2Nlc3MuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxhQUFBO0VBQ0EsdUJBQUE7QUFDRjs7QUFFQTtFQUNFLGtCQUFBO0VBQ0EsYUFBQTtBQUNGOztBQUVBO0VBQ0UsY0FBQTtFQUNBLFdBQUE7QUFDRiIsImZpbGUiOiJzdWNjZXNzLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmFsaWduLWNlbnRlciA+IGRpdiB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGp1c3RpZnktY29udGVudDogY2VudGVyO1xufTtcblxuLmJvZHktc3VjY2VzcyB7XG4gIHRleHQtYWxpZ246IGNlbnRlcjtcbiAgcGFkZGluZzogMCA4JTtcbn1cblxuaDQsIGgxIHtcbiAgbWFyZ2luOiAxMHB4IDA7XG4gIHdpZHRoOiBhdXRvO1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/legacy/gwg/src/lib/success/success/success.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,kBAAA;EACA,aAAA;AACF;;AAEA;EACE,cAAA;EACA,WAAA;AACF;AACA,olBAAolB","sourcesContent":[".align-center > div {\n  display: flex;\n  justify-content: center;\n};\n\n.body-success {\n  text-align: center;\n  padding: 0 8%;\n}\n\nh4, h1 {\n  margin: 10px 0;\n  width: auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
