import { TranslationFacade } from '@abcfinlab/core';
import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class AppPaginatorIntlAdapter extends MatPaginatorIntl {
    public constructor(private readonly translate: TranslationFacade) {
        super();
        this.itemsPerPageLabel = this.translate.instant('global.paginator_items_per_page');
    }
}
