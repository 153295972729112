import { Subject, Observable } from 'rxjs';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { PictureType } from '../../models/picture-type.enum';
import {PicturesService} from '../../services/pictures.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { WebcamComponent, WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';

@UntilDestroy()
@Component({
  selector: 'gwg-identification-photo',
  templateUrl: './identification-photo.component.html',
  styleUrls: ['./identification-photo.component.scss']
})
export class IdentificationPhotoComponent implements OnInit {

  @ViewChild('webcamComponent') webcamElement: WebcamComponent;

  @Input() text: string = '';

  @Input() pictureType: PictureType;

  @Output() navigationStarted: EventEmitter<void> = new EventEmitter<void>();

  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {
    width: { ideal: 800 },
    height: { ideal: 500 }
  };
  public errors: Array<WebcamInitError> = [];

  // latest snapshot
  public webcamImage: WebcamImage = null;

  // webcam snapshot trigger
  private readonly trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private readonly nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

  constructor(
    private readonly _pictureService: PicturesService
  ) {}

  public ngOnInit(): void {
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: Array<MediaDeviceInfo>) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });
  }

  public handleInitError(error: WebcamInitError): void {
    console.error(error);
    this.errors.push(error);
  }

  public handleImage(webcamImage: WebcamImage): void {
    this._pictureService.storeIdentificationDocument(this.pictureType, webcamImage.imageAsDataUrl)
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.webcamImage = webcamImage;
      });
  }

  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    this.webcamElement?.nativeVideoElement?.play();
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  public recordSubmitted(): void {
    this.trigger.next();
    this.navigationStarted.emit();
  }

}
