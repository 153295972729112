//#region Imports

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

//#endregion

/**
 * The presenter of the {@link CalendarWidgetView} view.
 *
 * @internal
 */
@Injectable()
export class CalendarWidgetViewPresenter {

    //#region Fields

    private readonly _dateSubject: BehaviorSubject<Date>;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `CalendarWidgetViewPresenter` class.
     *
     * @public
     */
    public constructor() {
        this._dateSubject = new BehaviorSubject<Date>(new Date());
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `date` property.
     *
     * @public
     * @readonly
     */
    public get date(): Observable<Date> {
        return this._dateSubject.asObservable();
    }

    //#endregion

    //#region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
    }

    //#endregion

}
