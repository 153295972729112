//#region Imports

import { Routes } from '@angular/router';
import { AuthGuard } from '../../../../apps/shell/src/app/shared/guards/auth.guard';
import { SettlementOverviewView } from '../../../backoffice/src/Views/SettlementOverviewView';
import { ContractOverviewView } from '../../../contractManagement/src/Views/ContractOverviewView';
import { PresenterView } from '../Views/PresenterView';

//#endregion

export const LAYOUT_ROUTES: Routes = [
    {
        path: '',
        component: PresenterView,
        children: [
            {
                path: 'private',
                loadChildren: () => import('../../../../apps/shell/src/app/private/private.module').then(mod => mod.PrivateModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'contracts/overview',
                component: ContractOverviewView,
                canActivate: [AuthGuard]
            },
            {
                path: 'settlement/overview',
                component: SettlementOverviewView,
                canActivate: [AuthGuard]
            },
        ]
    },
];