// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep signature-pad canvas {
  border-style: dashed;
  border-width: 1px;
  width: 100%;
  min-height: 300px;
  background-color: #CDD3D7;
}

.background {
  min-height: 300px;
}

.align-center {
  margin-top: 20px;
  text-align: center;
}
.align-center a {
  color: #19485A;
  font-size: 16px;
  font-weight: 300;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNpZ25hdHVyZS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNJLG9CQUFBO0VBQ0EsaUJBQUE7RUFDQSxXQUFBO0VBQ0EsaUJBQUE7RUFDQSx5QkFBQTtBQURKOztBQUlBO0VBQ0ksaUJBQUE7QUFESjs7QUFJQTtFQUNJLGdCQUFBO0VBQ0Esa0JBQUE7QUFESjtBQUVJO0VBQ0ksY0FsQlM7RUFtQlQsZUFBQTtFQUNBLGdCQUFBO0FBQVIiLCJmaWxlIjoic2lnbmF0dXJlLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiJHNlY29uZGFyeS1ibHVlOiAjMTk0ODVBO1xuXG46Om5nLWRlZXAgc2lnbmF0dXJlLXBhZCBjYW52YXMge1xuICAgIGJvcmRlci1zdHlsZTogZGFzaGVkO1xuICAgIGJvcmRlci13aWR0aDogMXB4O1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIG1pbi1oZWlnaHQ6IDMwMHB4O1xuICAgIGJhY2tncm91bmQtY29sb3I6ICNDREQzRDc7XG59XG5cbi5iYWNrZ3JvdW5kIHtcbiAgICBtaW4taGVpZ2h0OiAzMDBweDtcbn1cblxuLmFsaWduLWNlbnRlciB7XG4gICAgbWFyZ2luLXRvcDogMjBweDtcbiAgICB0ZXh0LWFsaWduOiBjZW50ZXI7XG4gICAgYSB7XG4gICAgICAgIGNvbG9yOiAkc2Vjb25kYXJ5LWJsdWU7XG4gICAgICAgIGZvbnQtc2l6ZTogMTZweDtcbiAgICAgICAgZm9udC13ZWlnaHQ6IDMwMDtcbiAgICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/presentation/src/Components/signature/signature.component.scss"],"names":[],"mappings":"AAEA;EACI,oBAAA;EACA,iBAAA;EACA,WAAA;EACA,iBAAA;EACA,yBAAA;AADJ;;AAIA;EACI,iBAAA;AADJ;;AAIA;EACI,gBAAA;EACA,kBAAA;AADJ;AAEI;EACI,cAlBS;EAmBT,eAAA;EACA,gBAAA;AAAR;AACA,49BAA49B","sourcesContent":["$secondary-blue: #19485A;\n\n::ng-deep signature-pad canvas {\n    border-style: dashed;\n    border-width: 1px;\n    width: 100%;\n    min-height: 300px;\n    background-color: #CDD3D7;\n}\n\n.background {\n    min-height: 300px;\n}\n\n.align-center {\n    margin-top: 20px;\n    text-align: center;\n    a {\n        color: $secondary-blue;\n        font-size: 16px;\n        font-weight: 300;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
