//#region Imports

import { Directionality } from '@angular/cdk/bidi';
import { CdkStepper, StepperSelectionEvent } from '@angular/cdk/stepper';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, QueryList } from '@angular/core';
import { WizardStepComponent } from './WizardStepComponent';

//#endregion

export type WizardSelectionChangeEvent = StepperSelectionEvent & { cancel: boolean };

/**
 * The `WizardComponent` component.
 *
 * @public
 */
@Component({
    selector: 'l7-wizard',
    templateUrl: './WizardComponent.html',
    styleUrls: ['./WizardComponent.scss'],
    providers: [{ provide: CdkStepper, useExisting: WizardComponent }]
})
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export class WizardComponent extends CdkStepper {

    //#region Fields

    //  private readonly _selectionChange: EventEmitter<WizardSelectionChangeEvent>;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `WizardComponent` class.
     *
     * @public
     */
    public constructor(_dir: Directionality, _changeDetectorRef: ChangeDetectorRef, _elementRef: ElementRef<HTMLElement>) {
        super(_dir, _changeDetectorRef, _elementRef);

        Object.assign(this.selectionChange, new EventEmitter<WizardSelectionChangeEvent>());
        // this._selectionChange = new EventEmitter<WizardSelectionChangeEvent>();
    }

    //#endregion

    //#region Properties

    public declare selectionChange: EventEmitter<WizardSelectionChangeEvent>;

    /**
     * @public
     * @override
     */
    public override readonly steps: QueryList<WizardStepComponent> = new QueryList<WizardStepComponent>();

    //#endregion

    //#region Methods

    /**
     * Navigate to the next step in the wizard
     *
     * @public
     */
    public override next(): void {
        super.next();
    }

    /**
     * Navigate to the previous step in the wizard
     *
     * @public
     */
    public override previous(): void {
        super.previous();
    }

    /**
     * Navigate to a specific step in the wizard
     *
     * @public
     * @param index - The index of the step to navigate to
     */
    public goTo(index: number): void {
        this.selectedIndex = index;
    }

    /**
     * Emitts the {@link selectionChanged} event.
     *
     * @protected
     */
    protected onSelectionChanged(args: WizardSelectionChangeEvent): void {
        this.selectionChange.emit(args);
    }

    /**
     * This method is originally defined in the `CdkStepper` class.
     * We need to override on a dirty way to fire oure selectionChange event.
     *
     * @private
     */
    private _updateSelectedItemIndex(newIndex: number): void {
        const stepsArray = this.steps.toArray();

        const args = {
            selectedIndex: newIndex,
            previouslySelectedIndex: this.selectedIndex,
            selectedStep: stepsArray[newIndex],
            previouslySelectedStep: stepsArray[this.selectedIndex],
            cancel: false
        };

        this.selectionChange.emit(args);

        if (args.cancel) {
            return;
        }

        // If focus is inside the stepper, move it to the next header, otherwise it may become
        // lost when the active step content is hidden. We can't be more granular with the check
        // (e.g. checking whether focus is inside the active step), because we don't have a
        // reference to the elements that are rendering out the content.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this._containsFocus()
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ? this._keyManager.setActiveItem(newIndex)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            : this._keyManager.updateActiveItem(newIndex);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this._selectedIndex = newIndex;
        this._stateChanged();
    }

    //#endregion

}
