//#region Imports

import { AcknowledgementService, ISerialNumberRequestDto, IWorkflowStepDto, RetailerContractWorkflowService } from '@abcfinlab/api/contract';
import { ICalculationSettingsDto, RetailerLeasingService } from '@abcfinlab/api/global';
import { ControlsOf, FormValidator, once, TranslationFacade, Validators } from '@abcfinlab/core';
import { BusyBoxService, MessageBoxButton, MessageBoxResult, MessageBoxService, percentageValidator, ToastService } from '@abcfinlab/ui';
import { CurrencyPipe } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators as NgValidators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { UploadTakeoverDocumentDialogView } from './UploadTakeoverDocumentDialogView';

//#endregion

/**
 * @private
 */
type DeleveryAndSerialNumberChoice = 'includeInDelivery' | 'manual' | 'extraDocument';

/**
 * @private
 */
interface IUploadTakeoverDocumentDialogViewPresenterFormData {
    general: FormGroup<{
        acknowledgementDate: FormControl<string>;
        purchasePrice: FormControl<number>;
        purchasePriceChanged: FormControl<boolean>;
        acknowledgementConfirmed: FormControl<boolean>;
    }>;
    files: FormGroup<{
        serialNumbers: FormArray;
        acknowledgement: FormControl<File>;
        serialNumber: FormControl<File>;
    }>;
    choice: FormControl<DeleveryAndSerialNumberChoice>;
}

/**
 * @private
 */
interface IUploadTakeoverDocumentDialogViewPresenterData {
    step: IWorkflowStepDto;
    contractNumber: string;
    leasingQuoteId: string;
    calculationSettings: ICalculationSettingsDto;
}

/**
 * The presenter of the {@link UploadTakeoverDocumentDialogView} view.
 *
 * @internal
 */
@UntilDestroy()
@Injectable()
export class UploadTakeoverDocumentDialogViewPresenter {

    //#region Fields

    private readonly _dialogRef: MatDialogRef<UploadTakeoverDocumentDialogView>;
    private readonly _dialogData: IUploadTakeoverDocumentDialogViewPresenterData;
    private readonly _retailerContractWorkflowService: RetailerContractWorkflowService;
    private readonly _retailerLeasingService: RetailerLeasingService;
    private readonly _messageBoxService: MessageBoxService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _formValidator: FormValidator;
    private readonly _toastService: ToastService;
    private readonly _busyBoxService: BusyBoxService;
    private readonly _acknowledgementService: AcknowledgementService;
    private readonly _currencyPipe: CurrencyPipe;
    private readonly _form: FormGroup<IUploadTakeoverDocumentDialogViewPresenterFormData>;
    private readonly _isPurchasePriceChangedSubject: BehaviorSubject<boolean>;
    private readonly _isBusyCalculating: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _calculationHasError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _purchasePriceValueChangesSubscription: Subscription;
    private readonly _newInstalmentSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private readonly _newDownPaymentSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    private readonly _newResidualValueSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    private readonly _warningMessageIfPriceChanged: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private readonly _calculationSettings: BehaviorSubject<ICalculationSettingsDto> = new BehaviorSubject<ICalculationSettingsDto>(null);
    public purchasePriceSubject: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
    public readonly terms: number;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `UploadTakeoverDocumentDialogViewPresenter` class.
     *
     * @public
     */
    public constructor(dialogRef: MatDialogRef<UploadTakeoverDocumentDialogView>, retailerContractWorkflowService: RetailerContractWorkflowService,
        messageBoxService: MessageBoxService, translationFacade: TranslationFacade, formValidator: FormValidator, toastService: ToastService, busyBoxService: BusyBoxService,
        acknowledgementService: AcknowledgementService, @Inject(MAT_DIALOG_DATA) dialogData: IUploadTakeoverDocumentDialogViewPresenterData,
        retailerLeasingService: RetailerLeasingService, currencyPipe: CurrencyPipe) {
        this._dialogRef = dialogRef;
        this._dialogData = dialogData;
        this._retailerContractWorkflowService = retailerContractWorkflowService;
        this._messageBoxService = messageBoxService;
        this._translationFacade = translationFacade;
        this._retailerLeasingService = retailerLeasingService;
        this._formValidator = formValidator;
        this._toastService = toastService;
        this._busyBoxService = busyBoxService;
        this._acknowledgementService = acknowledgementService;
        this._currencyPipe = currencyPipe;
        this._calculationSettings.next(this._dialogData.calculationSettings);
        this._isPurchasePriceChangedSubject = new BehaviorSubject(false);
        this._purchasePriceValueChangesSubscription = Subscription.EMPTY;
        this.terms = this._dialogData?.step.metaInformation.term as number;
        this._form = new FormGroup<IUploadTakeoverDocumentDialogViewPresenterFormData>({
            general: new FormGroup({
                acknowledgementDate: new FormControl('', [NgValidators.required, Validators.minDate(this._dialogData.step.metaInformation.approvalDate as string)]),
                purchasePrice: new FormControl<number>({ value: this._dialogData?.step.metaInformation.objectValue as number, disabled: true },
                    [
                        NgValidators.required,
                        percentageValidator(this._dialogData.calculationSettings.leasingQuoteCalculationProperties.retailer.acknowledgement.maxObjectValueChangePercent, this._dialogData.step.metaInformation.objectValue as number, 2),
                        NgValidators.min(this._dialogData.calculationSettings.leasingQuoteCalculationProperties.retailer.acknowledgement.minObjectValueChange),
                        NgValidators.max(this._dialogData.calculationSettings.leasingQuoteCalculationProperties.retailer.acknowledgement.maxObjectValueChange)
                    ]),
                purchasePriceChanged: new FormControl<boolean>(false),
                acknowledgementConfirmed: new FormControl<boolean>(false, [NgValidators.requiredTrue])
            }),
            files: new FormGroup({
                serialNumbers: new FormArray([]),
                acknowledgement: new FormControl(null, [NgValidators.required]),
                serialNumber: new FormControl(null),
            }),
            choice: new FormControl<DeleveryAndSerialNumberChoice>('manual', [NgValidators.required])
        });
        this.purchasePriceSubject.pipe(
            debounceTime(1000),
            distinctUntilChanged(),
            untilDestroyed(this),
            mergeMap(search => of(search).pipe(
                delay(500)
            )),
        ).subscribe(x => {
            const ignoreKeysList = ['ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown', ',', '.'];
            if (ignoreKeysList.indexOf(x.key) < 0) {
                this.onPurchasePriceChanged();
            }
        });
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `form` property.
     *
     * @public
     * @readonly
     */
    public get form(): FormGroup<IUploadTakeoverDocumentDialogViewPresenterFormData> {
        return this._form;
    }

    /**
     * Returns the `serialNumbers` property.
     *
     * @public
     * @readonly
     */
    public get serialNumbers(): FormArray<FormGroup<ControlsOf<ISerialNumberRequestDto>>> {
        return this._form.controls.files.controls.serialNumbers;
    }

    /**
     * Returns the `serialNumbers` property.
     *
     * @public
     * @readonly
     */
    public get choice(): FormControl<DeleveryAndSerialNumberChoice> {
        return this._form.controls.choice;
    }

    /**
     * @public
     * @readonly
     */
    public get isBusyCalculating(): Observable<boolean> {
        return this._isBusyCalculating.asObservable();
    }

    public get calculationHasError(): Observable<boolean> {
        return this._calculationHasError.asObservable();
    }

    public get newInstalment(): Observable<number> {
        return this._newInstalmentSubject.asObservable();
    }

    public get newDownPayment(): Observable<number> {
        return this._newDownPaymentSubject.asObservable();
    }

    public get newResidualValue(): Observable<number> {
        return this._newResidualValueSubject.asObservable();
    }

    public get calculationSettings(): Observable<ICalculationSettingsDto> {
        return this._calculationSettings.asObservable();
    }

    public get warningMessageIfPriceChanged(): Observable<string> {
        return this._warningMessageIfPriceChanged.asObservable();
    }

    public get years(): Array<number> {
        const result = new Array<number>();
        const currentYear = new Date(Date.now()).getFullYear();
        let offset = (currentYear - 1900);
        while (offset--) {
            result.push(currentYear - offset);
        }
        return result.reverse();
    }

    /**
     * Returns the `isPurchasePriceChanged` property.
     *
     * @public
     * @readonly
     */
    public get isPurchasePriceChanged(): Observable<boolean> {
        return this._isPurchasePriceChangedSubject.asObservable();
    }

    public onPurchasePriceChanged(): void {
        const purchasePrice = this.form.get('general.purchasePrice').value;
        if (this.form.get('general.purchasePrice').invalid) {
            return;
        }

        this._isPurchasePriceChangedSubject.next(this.form.get('general.purchasePrice').value !== null && this.form.get('general.purchasePrice').value !== this._dialogData?.step.metaInformation.objectValue);

        if (purchasePrice) {
            this._isBusyCalculating.next(true);
            this._retailerLeasingService.objectValueChange({
                leasingQuoteId: this._dialogData.leasingQuoteId,
                body: { totalLeasingValue: purchasePrice }
            }).pipe(untilDestroyed(this)).subscribe(x => {
                this._newInstalmentSubject.next(x.instalment);
                this._newDownPaymentSubject.next(x.downPayment);
                this._newResidualValueSubject.next(x.residualValue);
                this._setWarningMessageIfPriceChanged();
                this._isBusyCalculating.next(false);
                this._calculationHasError.next(false);
            }, error => {
                this._isBusyCalculating.next(false);
                this._calculationHasError.next(true);
                this._toastService.show(this._translationFacade.instant('error.generic_error'), 'danger');
            });
        }
    }

    public onPurchasePriceChangeToggle(evt: MatSlideToggleChange): void {
        if (!evt.checked) {
            this._isPurchasePriceChangedSubject.next(evt.checked);
            this.form.controls.general.controls.purchasePrice.disable();
            this.form.controls.general.controls.purchasePrice.setValue(this._dialogData?.step.metaInformation.objectValue as number);
        } else {
            this.form.controls.general.controls.purchasePrice.enable();
        }
    }

    //#endregion

    //#region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
        this.checkSerialNumberType('manual');
        this._form.controls.choice.valueChanges.subscribe(x => {
            this.checkSerialNumberType(x);
        });
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
        this._purchasePriceValueChangesSubscription.unsubscribe();
    }

    /**
    * @internal
    */
    public onSubmit(cancel: boolean): void {
        if (cancel) {
            this._messageBoxService.show('Vorgang abbrechen', 'Sind Sie sicher, dass Sie den Vorgang abbrechen wollen? Ihre Angaben werden nicht gespeichert.', MessageBoxButton.YesNo, {
                labels: {
                    yes: this._translationFacade.instant('global.yes'),
                    no: this._translationFacade.instant('global.no')
                }
            }).subscribe(result => {
                if (result === MessageBoxResult.Yes) {
                    this._dialogRef.close(false);
                }
            });

            return;
        }

        const errors = this._formValidator
            .validate(this._form)
            .errors(this._form);

        if (errors.length > 0) {
            this._toastService.show('Bitte überprüfen Sie ihre Angaben.', 'danger');
            return;
        }

        const form = this._form.getRawValue();

        let body = {
            acknowledgementFile: form.files.acknowledgement,
            retailerAcknowledgementRequest: {
                purchasePrice: form.general.purchasePrice,
                purchasePriceChanged: form.general.purchasePriceChanged,
                serialNumbers: form.files.serialNumbers,
                acknowledgementDate: form.general.acknowledgementDate,
                signedByLastName: '',
                signedByFirstName: '',
            }
        };

        if (form.files.serialNumber) {
            body = {
                ...body,
                ...{ serialNumberDocument: form.files.serialNumber }
            };
        }

        once(this._busyBoxService.show(undefined, this._translationFacade.instant('global.busy'),
            this._retailerContractWorkflowService.doAcknowledgementUpload({
                stepId: this._dialogData.step.id,
                body: body
            })
        ), () => {
            this._toastService.show('Angaben erfolgreich gespeichert.', 'success');
            this.onClose(false);
        }, error => {
            this._toastService.show('Beim speichern ist ein Fehler aufgetreten.', 'danger');
            this.onClose(false);
            throwError(error);
        });
    }

    /**
     * @internal
     */
    public onAddSerialNumber(): void {
        const position = this.serialNumbers.length + 1;
        const objectDescription = this._dialogData.step.metaInformation.objectDescription as string ?? '';

        const fg = new FormGroup({
            position: new FormControl(position, [NgValidators.required]),
            objectDescription: new FormControl(objectDescription, [NgValidators.required]),
            yearOfManufacture: new FormControl(null, [NgValidators.required]),
            serialNumber: new FormControl(null, [NgValidators.required, NgValidators.maxLength(30)]),
        });

        this.serialNumbers.push(fg);
    }

    /**
     * @internal
     */
    public onDeleteSerialNumber(sn: any, index: number): void {
        this.serialNumbers.removeAt(index);
    }

    /**
     * @internal
     */
    public onClearSerialNumber(): void {
        while (this.serialNumbers.length !== 0) {
            this.serialNumbers.removeAt(0);
        }
    }

    /**
     * @internal
     */
    public onFilesChanged(kind: 'acknowledgement' | 'serialNumber', files: Array<File>): void {
        if (kind === 'acknowledgement') {
            this._form.controls.files.controls.acknowledgement.patchValue(files[0]);
        }

        if (kind === 'serialNumber') {
            this._form.controls.files.controls.serialNumber.patchValue(files[0]);
        }
    }

    /**
     * @internal
     */
    public onClose(canceled: boolean): void {
        this._dialogRef.close({
            canceled: canceled
        });
    }

    private _setWarningMessageIfPriceChanged(): void {
        let message = null;
        if (this._newDownPaymentSubject.getValue() === 0 && this._newResidualValueSubject.getValue() === 0) {
            message = 'purchasePriceChanged';
        } else if (this._newDownPaymentSubject.getValue() !== 0 && this._newResidualValueSubject.getValue() !== 0) {
            message = 'purchasePriceChangedWithDownPaymentAndResidualValue';
        } else if (this._newDownPaymentSubject.getValue() !== 0 && this._newResidualValueSubject.getValue() === 0) {
            message = 'purchasePriceChangedWithDownPayment';
        } else if (this._newDownPaymentSubject.getValue() === 0 && this._newResidualValueSubject.getValue() !== 0) {
            message = 'purchasePriceChangedWithResidualValue';
        }
        const translatedMessage = this._translationFacade.instant(`contract_management.retailers.dialogs.digitalSigning.step1.${message}`, {
            instalment: this._currencyPipe.transform(this._newInstalmentSubject.getValue()),
            duration: this.terms,
            downPayment: this._currencyPipe.transform(this._newDownPaymentSubject.getValue()),
            residualValue: this._currencyPipe.transform(this._newResidualValueSubject.getValue())
        });

        this._warningMessageIfPriceChanged.next(translatedMessage);
    }

    private checkSerialNumberType(selectedType): void {
        this._form.controls.files.controls.serialNumbers.clearValidators();
        this._form.controls.files.controls.serialNumber.clearValidators();

        if (selectedType === 'manual') {
            this._form.controls.files.controls.serialNumber.removeValidators(NgValidators.required);
            this._form.controls.files.controls.serialNumber.updateValueAndValidity();
            this._form.controls.files.controls.serialNumbers.setValidators(NgValidators.required);
            this._form.controls.files.controls.serialNumbers.updateValueAndValidity();
            this.onAddSerialNumber();
        }

        if (selectedType === 'extraDocument') {
            this._form.controls.files.controls.serialNumber.setValidators(NgValidators.required);
            this._form.controls.files.controls.serialNumber.updateValueAndValidity();
            this.onClearSerialNumber();
        }

        if (selectedType === 'includeInDelivery') {
            this._form.controls.files.controls.serialNumber.removeValidators(NgValidators.required);
            this._form.controls.files.controls.serialNumber.updateValueAndValidity();
            this.onClearSerialNumber();
        }
    }
    //#endregion

}
