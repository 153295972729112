//#region Imports

import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, Renderer2 } from '@angular/core';
import { HostBindingBuilder } from '../../HostBindingBuilder';

//#endregion

/**
 * The `WrapComponent` component.
 *
 * @public
 */
@Component({
    selector: 'l7-wrap',
    templateUrl: './WrapComponent.html',
    styleUrls: ['./WrapComponent.scss'],
    exportAs: 'wrap',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WrapComponent {

    //#region Fields

    private readonly _renderer: Renderer2;
    private readonly _element: ElementRef;

    private _mutationObserver: MutationObserver;
    private _gap: number;
    private _columns: number;
    private _orientation: string;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `WrapComponent` class.
     *
     * @public
     */
    public constructor(renderer: Renderer2, element: ElementRef) {
        this._renderer = renderer;
        this._element = element;

        this._gap = 0;
        this._columns = 1;
        this._orientation = 'row';
    }

    //#endregion

    //#region Properties

    /**
     * The `gap` property represents the space between the child elements.
     *
     * @public
     */
    @Input()
    public get gap(): number {
        return this._gap;
    }
    public set gap(value: number) {
        this._gap = value;
        (Array.from((this._element.nativeElement as HTMLElement).children) as Array<HTMLElement>).forEach(x => this.renderChild(x));
    }

    /**
     * The `columns` property represents the number of columns before wrapping.
     *
     * @public
     */
    @Input()
    public get columns(): number {
        return this._columns;
    }
    public set columns(value: number) {
        this._columns = value;
        (Array.from((this._element.nativeElement as HTMLElement).children) as Array<HTMLElement>).forEach(x => this.renderChild(x));
    }

    /**
     * Gets or sets the `orientation ` property.
     *
     * @public
     */
    @Input()
    public get orientation(): string {
        return this._orientation;
    }
    public set orientation(value: string) {
        this._orientation = value;
    }

    /**
     * Returns all classes that applied to the host.
     *
     * @public
     * @override
     * @readonly
     */
    @HostBinding('class')
    public get classes(): string {
        return HostBindingBuilder.buildClasses({
            [`${this._orientation}`]: true,
        });
    }

    //#endregion

    //#region Methods

    /**
     * @public
     */
    public ngOnInit(): void {
        this._mutationObserver = new MutationObserver(x => {
            const children = x.flatMap<HTMLElement>(y => [].slice.call(y.addedNodes));
            const childrenWithoutRoot = children.filter(x => x !== this._element.nativeElement); // check if a child not the root himself
            this.onChildrenChanged(childrenWithoutRoot);
        });

        const options = { childList: true, subtree: true };
        this._mutationObserver.observe(this._element.nativeElement, options);
    }

    /**
     * @public
     */
    public ngOnDestroy(): void {
        this._mutationObserver?.disconnect();
    }

    /**
     * @private
     */
    private onChildrenChanged(children: Array<HTMLElement>): void {
        children.forEach(x => this.renderChild(x));
    }

    /**
     * @private
     */
    private renderChild(element: HTMLElement): void {
        const size = 100 / this._columns;
        const gaps = this._columns - 1;
        const calc = `calc(${size}% - calc(calc(${this._gap}px * ${gaps}) / ${this._columns}))`;

        this._renderer.setStyle(element, 'flex', '1');
        this._renderer.setStyle(element, 'flex-basis', calc);
        this._renderer.setStyle(element, 'max-width', calc);
    }

    //#endregion

}
