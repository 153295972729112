import { IDownPaymentSettingsDto } from '@abcfinlab/api/global';
import { ConfigState, FormValidator, SetLoadingState, TranslationFacade } from '@abcfinlab/core';
import { BusyBoxComponent, BusyBoxService, ToastService } from '@abcfinlab/ui';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, ReplaySubject, zip } from 'rxjs';
import { delay } from 'rxjs/operators';
import { BreakpointService } from '../../../../../../../libs/layout/src/Services/BreakpointService';
import {
    ChangeObjectGroupOrContractType,
    ResetCalculationState,
    SetCalculationForScreen,
    SetContractType,
    SetTotalLeasingValue,
    SetValueToCalculate,
    UpdateCalculation
} from '../../../actions/Calculation.actions';
import { GetCanHaveSlb } from '../../../actions/Slb.actions';
import {
    calculateInsuranceValueRange,
    contractTypeHasResidualValue,
    filterObjectGroupsForCalculation,
    findObjectGroupById,
    getInsuranceFactorFromObjectGroup,
    getPercent,
    getPercentageValue,
    toDecimal
} from '../../../helper/calculation.helper';
import { InsuranceFormDTO, SlbCodeFormDTO } from '../../../models/CalculationFormDTO';
import { ContactDTO } from '../../../models/ContactDTO.interface';
import { LeasingQuoteCalculationDTO } from '../../../models/LeasingQuoteCalculationDTO.interface';
import { LeasingQuoteDTO } from '../../../models/LeasingQuoteDTO.interface';
import { ObjectGroupDTO } from '../../../models/ObjectGroupDTO.interface';
import { LeasingQuoteCalculationMapper } from '../../../models/classes/LeasingQuoteCalculationMapper.class';
import { LeasingQuoteMapper } from '../../../models/classes/LeasingQuoteMapper.class';
import { LeasingQuoteObject } from '../../../models/classes/LeasingQuoteObject.class';
import { ContractTypeId } from '../../../models/enums/ContractTypeId.enum';
import { Currency } from '../../../models/enums/Currency.enum';
import { InsuranceType } from '../../../models/enums/InsuranceType.enum';
import { UsedCondition } from '../../../models/enums/UsedCondition.enum';
import { ValueToCalculate } from '../../../models/enums/ValueToCalculate.enum';
import { CalculationState, CalculationStateModel, CalculationUIState } from '../../../state/Calculation.state';
import { LesseeState } from '../../../state/Leesee.state';
import { QuoteState } from '../../../state/Quote.state';
import {
    CalculatorFeeFormComponent
} from '../../components/calculator/calculator-fee-form/calculator-fee-form.component';
import {
    CalculatorUsedConditionFormComponent
} from '../../components/calculator/calculator-used-condition-form/calculator-used-condition-form.component';
import { QuoteRepositoryService } from '../../repository/quote-repository.service';
import { CalculationService } from '../../services/calculation/calculation.service';
import { DialogService } from '../../services/dialog/dialog.service';
import { CalculationValuesValidator } from '../../validators/calculation-values.validator';

@UntilDestroy()
@Component({
    selector: 'l7-calculation',
    templateUrl: './calculation.component.html',
    styleUrls: ['./calculation.component.scss']
})
export class CalculationComponent implements OnInit, AfterViewInit {
    public isMobile: boolean;
    /** control for the MatSelect filter keyword for object groups */
    public objectGroupFilterCtrl: UntypedFormControl = new UntypedFormControl();
    /** list of object groups filtered by the search keyword */
    public filteredObjectGroups$: ReplaySubject<Array<ObjectGroupDTO>> = new ReplaySubject<Array<ObjectGroupDTO>>(1);
    public showAdditionalFields: boolean = true;
    public disableFuncParamFirstRate: boolean = true;
    public disableFuncParamLastRate: boolean = true;
    public objectGroups: Array<ObjectGroupDTO> = [];
    public contractTypes: Array<ContractTypeId>;
    public contractTypeId = ContractTypeId;
    public selectedContractType: ContractTypeId;
    public selectedContractTerms: number;
    public selectedObjectGroup: ObjectGroupDTO;
    public amortizationRange: Array<number> = [];
    public mainCalculationForm: UntypedFormGroup = new UntypedFormGroup({});
    public basicForm: UntypedFormGroup;
    public calculationForm: UntypedFormGroup;
    public feeForm: UntypedFormGroup;
    public conditionForm: UntypedFormGroup;
    public provisionForm: UntypedFormGroup;
    public leasingObjectForm: UntypedFormGroup;
    public selectedLessee: ContactDTO;
    public selectedLeasingQuote: any;
    public isDisabled: boolean = true;
    public isCreateQuote: boolean;
    public isFeeFormValid: boolean;
    public readonly minimumTotalLeasingValue: number = 500;
    public readonly maximumTotalLeasingValue: number = 750000;
    public readonly minTermsHirePurchase: number = 12;
    public readonly maxTermsHirePurchase: number = 96;
    public isLoading$: Observable<boolean>;
    public selectedLessee$: Observable<ContactDTO>;
    public selectedQuote$: Observable<LeasingQuoteDTO>;
    public valueToCalculate$: Observable<ValueToCalculate>;
    public slbValues$: Observable<SlbCodeFormDTO>;
    private _valueToCalculate: ValueToCalculate = ValueToCalculate.INSTALMENT;
    private _slbValues: SlbCodeFormDTO;
    private readonly _formValidator: FormValidator;
    private isMainCalculationFormValid: boolean;
    private readonly _minResidualPercentValue: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    private readonly _downPaymentSettings: BehaviorSubject<IDownPaymentSettingsDto> = new BehaviorSubject<IDownPaymentSettingsDto>(null);
    public screenMode: CalculationUIState;
    public calculationState$: Observable<CalculationStateModel>;
    public calculationState: CalculationStateModel;
    // FORM EXTRACTIONS
    @ViewChild('feeFormComponent') feeFormComponent: CalculatorFeeFormComponent;
    @ViewChild('conditionFormComponent') conditionFormComponent: CalculatorUsedConditionFormComponent;
    private calculationSnapshot: LeasingQuoteCalculationDTO;

    constructor(
        public calculationService: CalculationService,
        private readonly _router: Router,
        private readonly _route: ActivatedRoute,
        private readonly _fb: UntypedFormBuilder,
        private readonly _quoteRepositoryService: QuoteRepositoryService,
        private readonly _dialog: DialogService,
        private readonly _store: Store,
        private readonly _calculationValuesValidator: CalculationValuesValidator,
        private readonly _toastService: ToastService,
        private readonly _translationFacade: TranslationFacade,
        private readonly _busyBoxService: BusyBoxService,
        private readonly _breakpointService: BreakpointService,
        formValidator: FormValidator
    ) {
        this.isLoading$ = this._store.select(ConfigState.getLoading);
        this.valueToCalculate$ = this._store.select(CalculationState.GetValueToCalculate);
        this.slbValues$ = this._store.select(CalculationState.GetSlbValues);
        this.selectedLessee$ = this._store.select(LesseeState.getLessee);
        this.selectedQuote$ = this._store.select(QuoteState.getQuote);
        this.calculationState$ = this._store.select(CalculationState.GetState);
        // get resolved data
        this.isCreateQuote = this._router.url.includes('quote');
        this._formValidator = formValidator;
        this._route.data
            .pipe(untilDestroyed(this))
            .subscribe(routeData => {
                this.objectGroups = routeData.objectGroups;
                this._minResidualPercentValue.next(routeData.calculationSettings.residualValueSettings.min);
                this._downPaymentSettings.next(routeData.calculationSettings.downPaymentSettings);
            });

        this.slbValues$
            .pipe(untilDestroyed(this))
            .subscribe(values => this._slbValues = values);

        this._breakpointService.isMobileViewport$
            .pipe(untilDestroyed(this))
            .subscribe(_isMobile => this.isMobile = _isMobile);
    }

    public filterObjectGroups() {
        if (!this.objectGroups) {
            return;
        }
        // get search term
        let search = this.objectGroupFilterCtrl.value;
        if (!search) {
            this.filteredObjectGroups$.next(this.objectGroups.slice());
            return;
        } else {
            search = search.toString().toLowerCase();
        }
        // do filtering
        this.filteredObjectGroups$.next(
            this.objectGroups.filter(objectGroup => filterObjectGroupsForCalculation(objectGroup, search))
        );
    }

    get quote_can_be_saved() {
        const isSlbActiveWithCode = (!this._slbValues?.active && !this._slbValues?.code) || (this._slbValues?.active && this._slbValues?.code);
        return this.isMainCalculationFormValid && !!this._valueToCalculate &&
            this.isFeeFormValid && isSlbActiveWithCode &&
            !this.calculationForm.get('yearly_interest').getError('min');
    }

    get total_leasing_value(): number {
        return this.basicForm.get('total_leasing_value').value;
    }

    get object_group(): ObjectGroupDTO {
        return this.basicForm.get('object_group').value;
    }

    get calculation_possible(): boolean {
        if (this.feeFormComponent.internalForm.getRawValue().insurance_type === InsuranceType.STANDARD && this.feeFormComponent.internalForm.getRawValue().insurance_fee && this.basicForm.valid) {
            const insuranceRange = calculateInsuranceValueRange(this.basicForm.getRawValue().total_leasing_value, this.selectedObjectGroup.insurance_value);
            if (this.feeFormComponent.internalForm.getRawValue().insurance_fee > toDecimal(insuranceRange.maxValue * 2, 2, true) ||
                this.feeFormComponent.internalForm.getRawValue().insurance_fee < insuranceRange.minValue) {
                return false;
            }
        }

        if (this._valueToCalculate === ValueToCalculate.RESIDUAL_VALUE) {
            return this.basicForm.valid && this.calculationForm.controls.down_payment_percent.valid && this.feeFormComponent?.internalForm.valid && !!this._valueToCalculate;
        }

        if (this._valueToCalculate === ValueToCalculate.DOWN_PAYMENT) {
            return this.basicForm.valid && this.calculationForm.controls.residual_value_percent.valid && this.feeFormComponent?.internalForm.valid && !!this._valueToCalculate;
        }

        return this.basicForm.valid && this.calculationForm.valid && this.feeFormComponent?.internalForm.valid && !!this._valueToCalculate;
    }

    get is_insurance_standard_and_active(): boolean {
        return this.feeForm?.getRawValue().insurance &&
            this.feeForm?.getRawValue().insurance_type === InsuranceType.STANDARD &&
            !this.feeForm?.getRawValue().insurance_fee;
    }

    get object_group_dont_have_insurance_for_ta_va(): boolean {
        return !!!getInsuranceFactorFromObjectGroup(this.object_group) &&
            this.selectedContractType !== ContractTypeId.KFZ &&
            this.selectedContractType !== ContractTypeId.MIETKAUF;
    }

    get minResidualValue(): Observable<number> {
        return this._minResidualPercentValue.asObservable();
    }

    get downPaymentSettings(): Observable<IDownPaymentSettingsDto> {
        return this._downPaymentSettings.asObservable();
    }

    ngOnInit(): void {
        const message = 'Bitte haben Sie einen Moment Geduld.';
        this._setupBasicForm();
        this._setupCalculationForm();
        this._setupProvisionForm();
        this._setupLeasingObjectForm();
        const config = {
            closeOnNavigation: true,
            disableClose: true,
            width: '400px',
            minHeight: '200px',
        };

        const data = {
            title: '',
            message: message,
            options: null,
        };
        const dialogRef = this._dialog.openDialog(BusyBoxComponent, config, data);
        // load initial object group list
        if (this.objectGroups) {
            this.filteredObjectGroups$.next(this.objectGroups);
        }
        // listen to object group search field value changes
        this.objectGroupFilterCtrl.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(() => this.filterObjectGroups());

        this.calculationState$
            .pipe(untilDestroyed(this))
            .subscribe(_calculationState => {
                this.calculationState = _calculationState;
                this.screenMode = _calculationState.calculationUIState;
                this.isMainCalculationFormValid = this.mainCalculationForm.valid;
                if (this.screenMode === CalculationUIState.CREATE) {
                    this.selectedLeasingQuote = _calculationState.leasingQuoteCalculation;
                    this.selectedLessee = _calculationState.lessee;
                }
                if (Object.keys(_calculationState.lessee).length || this.screenMode !== CalculationUIState.CREATE) {
                    dialogRef.close();
                }
            });

        if (this.screenMode === CalculationUIState.CREATE) {
            this._store.dispatch(new SetCalculationForScreen(this.calculationState.leasingQuoteCalculation));
        }

        this.valueToCalculate$
            .pipe(untilDestroyed(this))
            .subscribe(_value => this._valueToCalculate = _value);
    }

    public attachFormChangeListener() {
        this._onObjectValueChange();
    }

    ngAfterViewInit(): void {
        this.feeForm = this.feeFormComponent.internalForm;
        this.conditionForm = this.conditionFormComponent.internalForm;
        switch (this.screenMode) {
            case CalculationUIState.UPDATE:
                // LESSEE AND QUOTE SHOULD BE PRESENT
                zip(this.selectedLessee$, this.selectedQuote$)
                    .pipe(
                        delay(0),
                        untilDestroyed(this)
                    )
                    .subscribe(([_selectedLessee, _selectedQuote]) => {
                        this.selectedLessee = _selectedLessee;
                        this.selectedLeasingQuote = _selectedQuote;

                        if (!this.selectedLeasingQuote.quote_calculation) {
                            return;
                        }

                        this._store.dispatch(new SetCalculationForScreen(_selectedQuote));

                        const payload: InsuranceFormDTO = {
                            insurance: this.selectedLeasingQuote.quote_calculation.insurance,
                            insurance_type: this.selectedLeasingQuote.quote_calculation.insurance_type,
                            insurance_fee: this.selectedLeasingQuote.quote_calculation.insurance_fee,
                            total_insurance_instalments: this.selectedLeasingQuote?.total_insurance_instalments,
                            total_insurance_instalments_vat: this.selectedLeasingQuote?.total_insurance_instalments_vat,
                            handling_fee: this.selectedLeasingQuote.quote_calculation.handling_fee,
                            handling_fee_value: this.selectedLeasingQuote.quote_calculation.handling_fee_value
                        };

                        this._store.dispatch(new UpdateFormValue({
                            value: payload,
                            path: 'calculation.insuranceForm'
                        }));

                        this._store.dispatch(new GetCanHaveSlb(this.selectedLeasingQuote.quote_calculation.contract_type));

                        this._store.dispatch(new UpdateFormValue({
                            value: {
                                active: this.selectedLeasingQuote.sale_and_lease_back?.active,
                                code: this.selectedLeasingQuote.sale_and_lease_back?.code,
                            },
                            path: 'calculation.slbCodeForm'
                        }));

                        this.selectedContractType = _selectedQuote.quote_calculation.contract_type;
                        this.selectedContractTerms = _selectedQuote.quote_calculation.total_terms;

                        // patch object group
                        const preSelectedObjectGroup = findObjectGroupById(this.objectGroups, this.selectedLeasingQuote.object_group_id);
                        this.selectedObjectGroup = preSelectedObjectGroup;
                        this.contractTypes = preSelectedObjectGroup.contract_types;

                        const hirePurchaseFields = {
                            handling_first_instalment: false,
                            first_instalment: this.selectedLeasingQuote.quote_calculation.first_instalment,
                            first_instalment_percent: this.selectedLeasingQuote.quote_calculation.first_instalment_percent,
                            handling_last_instalment: false,
                            last_instalment: this.selectedLeasingQuote.quote_calculation.last_instalment,
                            last_instalment_percent: this.selectedLeasingQuote.quote_calculation.last_instalment_percent,
                            hire_purchase_terms: this.selectedLeasingQuote.quote_calculation.total_terms,
                            total_instalments: this.selectedLeasingQuote.total_instalments,
                            total_instalments_vat: this.selectedLeasingQuote.total_instalments_vat
                        };

                        const baseCalculationFields = {
                            down_payment: this.selectedLeasingQuote.quote_calculation.down_payment,
                            down_payment_percent: this.selectedLeasingQuote.quote_calculation.down_payment_percent,
                            residual_value: this.selectedLeasingQuote.quote_calculation.residual_value,
                            residual_value_percent: this.selectedLeasingQuote.quote_calculation.residual_value_percent,
                            instalment: this.selectedLeasingQuote.quote_calculation.instalment,
                            instalment_percent: this.selectedLeasingQuote.quote_calculation.instalment_percent,
                            yearly_interest: this.selectedLeasingQuote.quote_calculation.yearly_interest
                        };

                        if (this.selectedLeasingQuote.quote_calculation.contract_type !== ContractTypeId.MIETKAUF) {
                            baseCalculationFields.down_payment_percent = getPercent(
                                this.selectedLeasingQuote.quote_calculation.total_leasing_value,
                                this.selectedLeasingQuote.quote_calculation.down_payment, 2, true
                            );
                        }

                        if (this.selectedLeasingQuote.quote_calculation.contract_type === ContractTypeId.TA) {
                            baseCalculationFields.residual_value_percent = getPercent(
                                this.selectedLeasingQuote.quote_calculation.total_leasing_value,
                                this.selectedLeasingQuote.quote_calculation.residual_value, 2, true
                            );

                            this.calculationForm.get('residual_value_percent').enable({
                                emitEvent: false,
                                onlySelf: false
                            });
                        }

                        if (this.selectedLeasingQuote.quote_calculation.contract_type === ContractTypeId.MIETKAUF) {
                            baseCalculationFields.instalment_percent = getPercent(
                                this.selectedLeasingQuote.quote_calculation.total_leasing_value,
                                this.selectedLeasingQuote.quote_calculation.instalment
                            );
                        }

                        if (this.selectedLeasingQuote.quote_calculation.first_instalment) {
                            hirePurchaseFields.handling_first_instalment = true;
                            hirePurchaseFields.hire_purchase_terms--;
                            this.calculationForm.get('first_instalment').enable({
                                emitEvent: false,
                                onlySelf: false
                            });
                            this.calculationForm.get('first_instalment_percent').enable({
                                emitEvent: false,
                                onlySelf: false
                            });
                            hirePurchaseFields.first_instalment_percent = getPercent(
                                this.selectedLeasingQuote.quote_calculation.total_leasing_value,
                                this.selectedLeasingQuote.quote_calculation.first_instalment
                            );
                            this.setFirstOrLastRateFuncParam({
                                param: ValueToCalculate.FIRST_INSTALMENT,
                                value: true
                            });
                        }
                        if (this.selectedLeasingQuote.quote_calculation.last_instalment) {
                            hirePurchaseFields.handling_last_instalment = true;
                            hirePurchaseFields.hire_purchase_terms--;
                            this.calculationForm.get('last_instalment').enable({ emitEvent: false, onlySelf: false });
                            this.calculationForm.get('last_instalment_percent').enable({
                                emitEvent: false,
                                onlySelf: false
                            });
                            hirePurchaseFields.last_instalment_percent = getPercent(
                                this.selectedLeasingQuote.quote_calculation.total_leasing_value,
                                this.selectedLeasingQuote.quote_calculation.last_instalment
                            );
                            this.setFirstOrLastRateFuncParam({
                                param: ValueToCalculate.LAST_INSTALMENT,
                                value: true
                            });
                        }

                        this._store.dispatch(new UpdateFormValue({
                            value: { ...baseCalculationFields, ...hirePurchaseFields },
                            path: 'calculation.mainCalculationForm',
                            propertyPath: 'calculation'
                        }));

                        const provisionFormValues = {
                            dealer_commission: this.selectedLeasingQuote.quote_calculation.dealer_commission,
                            dealer_commission_percent: this.selectedLeasingQuote.quote_calculation.dealer_commission_percent,
                            refinancing_rate: this.selectedLeasingQuote.quote_calculation.refinancing_rate,
                            risk_discount: this.selectedLeasingQuote.quote_calculation.risk_discount,
                            present_value_profit: this.selectedLeasingQuote.quote_calculation.present_value_profit,
                            present_value_profit_percent: this.selectedLeasingQuote.quote_calculation.present_value_profit_percent,
                            leasing_factor: this.selectedLeasingQuote.quote_calculation.leasing_factor,
                            exploitation_code: preSelectedObjectGroup.exploitation_code
                        };

                        this._store.dispatch(new UpdateFormValue({
                            value: provisionFormValues,
                            path: 'calculation.mainCalculationForm',
                            propertyPath: 'provision'
                        }));

                        this._store.dispatch(new UpdateFormValue({
                            value: { name: this.selectedLeasingQuote.objects[0].name },
                            path: 'calculation.mainCalculationForm',
                            propertyPath: 'object'
                        }));

                        this.conditionForm.patchValue({ condition: this.selectedLeasingQuote.objects[0].condition }, {
                            emitEvent: false,
                            onlySelf: false
                        });

                        const basicFormValues = {
                            contract_type: this.selectedLeasingQuote.quote_calculation.contract_type,
                            total_leasing_value: this.selectedLeasingQuote.quote_calculation.total_leasing_value,
                            object_group: preSelectedObjectGroup,
                            total_terms: this.selectedLeasingQuote.quote_calculation.total_terms
                        };

                        this._store.dispatch(new UpdateFormValue({
                            value: basicFormValues,
                            path: 'calculation.mainCalculationForm',
                            propertyPath: 'basic'
                        }));
                        this.changeTotalTermsRange(this.selectedLeasingQuote.objects[0].condition);
                        this.basicForm.get('contract_type').enable({ emitEvent: false, onlySelf: false });
                        this.basicForm.get('total_terms').enable({ emitEvent: false, onlySelf: false });
                        this.basicForm.get('total_leasing_value').enable({ emitEvent: false, onlySelf: false });

                        this._store.dispatch(new SetValueToCalculate(ValueToCalculate.INSTALMENT));
                        this.attachFormChangeListener();

                        if (this.selectedLeasingQuote.quote_calculation.contract_type === ContractTypeId.KFZ) {
                            this.calculate(null);
                        }
                    });
                break;
            case CalculationUIState.CREATE:
                this._store.dispatch(new GetCanHaveSlb(ContractTypeId.MIETKAUF));
                this.attachFormChangeListener();
                break;
            case CalculationUIState.TRANSIENT:
                setTimeout(() => this._store.dispatch(new ResetCalculationState()));
                this.attachFormChangeListener();
                break;
        }

        // Update subforms
        this.basicForm.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                if (this.basicForm.valid) {
                    this.leasingObjectForm.get('name').enable({ onlySelf: false, emitEvent: false });
                    this.feeForm.get('handling_fee').enable({ onlySelf: false, emitEvent: false });
                    if (this.feeForm.getRawValue().handling_fee) {
                        this.feeForm.get('handling_fee_value').enable({ onlySelf: false, emitEvent: false });
                    }
                }
            });
    }

    private _setupBasicForm(): void {
        const objectValueValidators = [
            Validators.required,
            Validators.min(this.calculationService.OBJECT_VALUE_MIN),
            Validators.max(this.calculationService.OBJECT_VALUE_MAX)
        ];

        this.basicForm = this._fb.group({
            contract_type: [{ value: null, disabled: this.isDisabled }, [Validators.required]],
            total_leasing_value: [{ value: null, disabled: this.isDisabled }, objectValueValidators],
            object_group: [null, [Validators.required]],
            total_terms: [{ value: null, disabled: this.isDisabled }, [Validators.required]]
        });

        this.mainCalculationForm = this._fb.group({
            ...this.mainCalculationForm.controls,
            basic: this.basicForm
        });
    }

    private _setupLeasingObjectForm(): void {
        const leasingObjectValidators = [
            Validators.required, Validators.minLength(1), Validators.maxLength(50)
        ];

        this.leasingObjectForm = this._fb.group({
            name: [{ value: null, disabled: true }, leasingObjectValidators]
        }
        );

        this.mainCalculationForm = this._fb.group({
            ...this.mainCalculationForm.controls,
            object: this.leasingObjectForm
        });
    }

    private _setupCalculationForm(): void {
        const controls: { [key: string]: any } = {
            down_payment: [null, [Validators.min(0), this._calculationValuesValidator.maxDownPayment(this._downPaymentSettings.getValue().maxThreshold)]],
            down_payment_percent: [null, [Validators.min(0), Validators.max(this._downPaymentSettings.getValue().maxThreshold * 100)]],
            residual_value: [null, [this._calculationValuesValidator.minResidualValue(this._minResidualPercentValue.getValue()), this._calculationValuesValidator.maxInstalment(false)]],
            residual_value_percent: [{
                value: this._minResidualPercentValue.getValue() * 100,
                disabled: true
            }, [Validators.min(this._minResidualPercentValue.getValue() * 100), Validators.max(100)]],
            instalment: [{ value: null, disabled: true }, [Validators.min(0.001)]],
            instalment_percent: [{ value: null, disabled: true }, [Validators.min(0.001)]],
            handling_first_instalment: [{ value: false, disabled: true }],
            first_instalment: [{ value: null, disabled: true }, [Validators.min(0), this._calculationValuesValidator.maxInstalment()]],
            first_instalment_percent: [{ value: null, disabled: true }],
            hire_purchase_terms: [{ value: null, disabled: true }],
            handling_last_instalment: [{ value: false, disabled: true }],
            last_instalment: [{ value: null, disabled: true }, [Validators.min(0), this._calculationValuesValidator.maxInstalment()]],
            last_instalment_percent: [{ value: null, disabled: true }],
            total_instalments: [{ value: null, disabled: true }],
            total_instalments_vat: [{ value: null, disabled: true }]
        };

        this.calculationForm = this._fb.group({
            // @ts-ignore
            ...controls,
            ...{ yearly_interest: [5, [Validators.min(0.001)]] }
        });

        this.mainCalculationForm = this._fb.group({
            ...this.mainCalculationForm.controls,
            calculation: this.calculationForm
        });
    }

    /**
*  Setup the provision specific form and values.
*  It consists of:
*    dealer_commission          = Händlerprovision in Euro
*    dealer_commission_percent  = Händlerprovision in Prozent
*    refinancing_rate           = Einstandskondition
*    risk_discount              = Kalkualtorischer Risikoabschlag (READONLY/DISABLED)
*    present_value_profit       = BWÜ ohne Zusatzerträge (READONLY/DISABLED)
*    exploitation_code          = Objekt-Bewertung (comes from object group)
*/
    private _setupProvisionForm(): void {
        this.provisionForm = this._fb.group({
            dealer_commission: [0],
            dealer_commission_percent: [0],
            refinancing_rate: [1.5, [Validators.min(0.001)]],
            risk_discount: [{ value: null, disabled: true }],
            present_value_profit: [{ value: null, disabled: true }, [Validators.min(0.001)]],
            present_value_profit_percent: [{ value: null, disabled: true }, [Validators.min(0.001)]],
            leasing_factor: [{ value: null, disabled: true }],
            exploitation_code: [{ value: null, disabled: true }]
        });

        this.mainCalculationForm = this._fb.group({
            ...this.mainCalculationForm.controls,
            provision: this.provisionForm
        });
    }

    /**
* Routes the user to the dashboard overview component. Triggers on button click event.
*/
    public onGoToDashboard(screenMode: CalculationUIState): void {
        this.calculationForm.reset();
        this.basicForm.reset();
        this._store.dispatch(new ResetCalculationState());

        if (screenMode === CalculationUIState.CREATE) {
            return void this._router.navigate(['../../../'], { relativeTo: this._route });
        }

        if (screenMode === CalculationUIState.TRANSIENT) {
            return void this._router.navigate(['../../'], { relativeTo: this._route });
        }
    }

    public handleHasResidualValue(contractType: ContractTypeId): void {
        if (!contractTypeHasResidualValue(contractType)) {
            this.calculationForm.get('residual_value').reset();
            this.calculationForm.get('residual_value_percent').reset();
            this.calculationForm.get('residual_value').disable({ onlySelf: false, emitEvent: false });
            this.calculationForm.get('residual_value_percent').disable({
                onlySelf: false,
                emitEvent: false
            });
        } else {
            this.calculationForm.get('residual_value').enable({ onlySelf: false, emitEvent: false });
            this.calculationForm.get('residual_value_percent').enable({
                onlySelf: false,
                emitEvent: false
            });
            this.calculationForm.get('residual_value_percent').patchValue(this._minResidualPercentValue.getValue() * 100, {
                onlySelf: false,
                emitEvent: false
            });
            this.calculationForm.patchValue({
                residual_value: getPercentageValue(this.calculationForm.getRawValue().residual_value_percent, this.basicForm.getRawValue().total_leasing_value)
            }, { emitEvent: false });
        }
    }

    /**
*  Trigger the calculation of "Anzahlung" und "Restwert" if the "Objektwert" changes
*/
    private _onObjectValueChange(): void {
        this.basicForm.get('total_leasing_value').valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((_ => {
                const selectedContractType = this.basicForm.getRawValue().contract_type;
                this.handleHasResidualValue(selectedContractType);
            }));
    }

    private setValidityOfForm(form: UntypedFormGroup) {
        Object.keys(form.controls).forEach(key => {
            if (!form.get(key).valid) {
                form.setErrors({ invalid: true });
            }
        });
    }

    private handleSelectedValuesChanges(evt: MatSelectChange) {
        const objGroup = this.basicForm.getRawValue().object_group;

        switch (evt.source.ngControl?.name) {
            case 'object_group':
                this.provisionForm.get('exploitation_code').patchValue(evt.value.exploitation_code, {
                    onlySelf: false,
                    emitEvent: false
                });

                this.changeTotalTermsRange(this.conditionForm.controls.condition.value);
                this.selectedContractTerms = this.amortizationRange[0];

                this.basicForm.get('total_terms').patchValue(this.selectedContractTerms, {
                    onlySelf: false,
                    emitEvent: false
                });
                this.calculationForm.get('hire_purchase_terms').patchValue(this.selectedContractTerms, {
                    onlySelf: false,
                    emitEvent: false
                });

                this.contractTypes = evt.value.contract_types;
                this.basicForm.get('contract_type').enable({ onlySelf: true, emitEvent: false });
                this.setValidityOfForm(this.basicForm);

                this._store.dispatch(new ChangeObjectGroupOrContractType(evt.value, null, this.conditionForm.controls.condition.value));
                this.basicForm.get('total_terms').disable({ onlySelf: true, emitEvent: false });
                this.basicForm.get('total_leasing_value').disable({ onlySelf: true, emitEvent: false });

                const contractType = this.basicForm.getRawValue().contract_type ? this.basicForm.getRawValue().contract_type : ContractTypeId.MIETKAUF;
                this._store.dispatch(new GetCanHaveSlb(contractType));
                break;
            case 'total_terms':
                this.selectedContractTerms = evt.value;
                this.basicForm.get('total_terms').patchValue(this.selectedContractTerms, {
                    onlySelf: false,
                    emitEvent: false
                });
                let hpTerms = this.selectedContractTerms;
                if (this.calculationForm.getRawValue().handling_first_instalment && this.calculationForm.getRawValue().handling_last_instalment) {
                    hpTerms -= 2;
                } else if (this.calculationForm.getRawValue().handling_first_instalment || this.calculationForm.getRawValue().handling_last_instalment) {
                    hpTerms--;
                }

                this.calculationForm.get('hire_purchase_terms').patchValue(hpTerms, {
                    onlySelf: false,
                    emitEvent: false
                });
                break;
            case 'contract_type':
                this.calculationForm.get('hire_purchase_terms').patchValue(this.selectedContractTerms, {
                    onlySelf: false,
                    emitEvent: false
                });

                this.basicForm.get('contract_type').patchValue(this.selectedContractType, {
                    onlySelf: true,
                    emitEvent: false
                });
                this.basicForm.get('total_terms').enable({ onlySelf: true, emitEvent: false });
                this.basicForm.get('total_leasing_value').enable({ onlySelf: true, emitEvent: false });
                this.handleHasResidualValue(this.selectedContractType);
                this.setValidityOfForm(this.basicForm);
                this._store.dispatch(new SetValueToCalculate(ValueToCalculate.INSTALMENT));
                this._store.dispatch(new SetContractType(this.selectedContractType));
                this._store.dispatch(new ChangeObjectGroupOrContractType(objGroup, this.selectedContractType, this.conditionForm.controls.condition.value));

                this._store.dispatch(new GetCanHaveSlb(this.selectedContractType));

                this.changeTotalTermsRange(this.conditionForm.controls.condition.value);
                this.selectedContractTerms = this.amortizationRange[0];
                this.basicForm.get('total_terms').patchValue(this.selectedContractTerms, {
                    onlySelf: false,
                    emitEvent: false
                });
        }
    }

    /**
* Calculate form values. Triggers on button click event / untilChangeObs.
*/
    public calculate(evt) {
        // check for focus out on Value to Calculate
        if (Object.values(ValueToCalculate).includes(evt?.relatedTarget?.id)) {
            this.setValueToCalculate(evt.relatedTarget.id);
        }
        const formControlName: string = evt?.target?.attributes?.formControlName?.value;

        const totalValue: number = this.basicForm.getRawValue().total_leasing_value;

        // Set absolute values and percentage
        switch (formControlName) {
            case 'dealer_commission_percent':
                this.provisionForm.patchValue({
                    dealer_commission: 0
                }, { emitEvent: false });
                break;
            case 'dealer_commission':
                this.provisionForm.patchValue({
                    dealer_commission_percent: 0
                }, { onlySelf: false, emitEvent: false });
                break;
            case 'total_leasing_value':
                this._store.dispatch(new SetTotalLeasingValue(totalValue));
                this.calculationForm.patchValue({
                    residual_value: getPercentageValue(this.calculationForm.getRawValue().residual_value_percent, totalValue),
                    down_payment: getPercentageValue(this.calculationForm.getRawValue().down_payment_percent, totalValue),
                }, { emitEvent: false });
        }

        if (evt?.source) {
            this.handleSelectedValuesChanges(evt);

            const hasObjGroupOrContractTypeChanged = evt.source.ngControl?.name === 'object_group' || evt.source.ngControl?.name === 'contract_type';

            if (hasObjGroupOrContractTypeChanged) {
                return;
            }
        }
        if (!this.feeFormComponent || evt?.event?.source?.name === 'handling_fee') {
            return;
        }

        if (this.object_group_dont_have_insurance_for_ta_va) {
            this.feeFormComponent.internalForm.getRawValue().insurance_fee = null;
            this.feeFormComponent.internalForm.getRawValue().insurance_type = InsuranceType.NO;
        }

        if (this.is_insurance_standard_and_active) {
            this.feeFormComponent.internalForm.getRawValue().insurance_fee = calculateInsuranceValueRange(
                this.basicForm.getRawValue().total_leasing_value,
                getInsuranceFactorFromObjectGroup(this.basicForm.getRawValue().object_group)
            ).maxValue;
        }

        // base values are valid, but maybe function param string is missing
        if (this.calculation_possible) {
            const calculationRequest = this.calculationService.prepareCalculationRequest(this._calculationValues(), this.is_insurance_standard_and_active, this.conditionForm.getRawValue().condition);
            this._store.dispatch(new UpdateCalculation(calculationRequest)).pipe(untilDestroyed(this)).subscribe(x => {
                this._formValidator.validate(this.calculationForm);
            });
        }
    }

    /**
* Show or hide the additional fields like "Risikoabschlag", "BWÜ", e.g.
*/
    public toggleFields(status: boolean): void {
        this.showAdditionalFields = status;
    }

    /**
* Get formula values as raw values including null
*/
    private _calculationValues(): LeasingQuoteCalculationDTO {
        return {
            ...this.basicForm.getRawValue(),
            ...this.calculationForm.getRawValue(),
            ...this.feeFormComponent.internalForm.getRawValue(),
            ...this.provisionForm.getRawValue(),
            ...this.leasingObjectForm.getRawValue(),
            value_to_calculate: this._valueToCalculate
        };
    }

    public onBackToDetailView(): void {
        this._store.dispatch(new ResetCalculationState());
        this._router.navigate(['../detail'], {
            relativeTo: this._route
        }).then();
    }

    public onSave(mode: string): void {
        const objectQuoteId = this.basicForm.get('object_group').value.code;
        const objectValue = this.basicForm.get('total_leasing_value').value;
        const objectName = this.leasingObjectForm.get('name').value;
        const objectCondition = this.conditionForm.get('condition').value;

        const updatedCalculation = new LeasingQuoteCalculationMapper(this._calculationValues()).getValues();

        const leasingQuoteObject = new LeasingQuoteObject(objectName, 1, objectValue, Currency.EURO, objectCondition);
        const objects = [leasingQuoteObject.serialized_values];
        const leasingQuote = new LeasingQuoteMapper({ ...this.selectedLeasingQuote }).getValues();
        const slb = this._slbValues?.code ? this._slbValues : null;

        const payload: LeasingQuoteDTO = {
            ...leasingQuote,
            sale_and_lease_back: slb,
            object_group_id: objectQuoteId,
            quote_calculation: { ...updatedCalculation, object_group: this.object_group },
            objects
        };

        this.saveQuote(payload, mode);
    }

    private saveQuote(payload, mode) {
        this._busyBoxService.show(null, null, this._quoteRepositoryService.saveQuote(payload, mode))
            .pipe(untilDestroyed(this))
            .subscribe(response => {
                this._store.dispatch(new SetLoadingState(false));
                const route = mode === 'UPDATE' ? '../../quotes/list' : '../../../quotes/list';
                this._router.navigate([route], {
                    relativeTo: this._route,
                    queryParams: {
                        id: response.lessee_id
                    }
                }).then(() => {
                    this._store.dispatch(new ResetCalculationState());
                    this._toastService.show(this._translationFacade.translate('calculation.calculation_saved_successful'), 'success');
                });
            });
    }

    public setValueToCalculate(value: ValueToCalculate) {
        this._valueToCalculate = value;
        this._store.dispatch(new SetValueToCalculate(value));
    }

    public setFirstOrLastRateFuncParam(evt: any) {
        switch (evt.param) {
            case ValueToCalculate.FIRST_INSTALMENT:
                this.disableFuncParamFirstRate = !evt.value;
                if (this._valueToCalculate !== ValueToCalculate.LAST_INSTALMENT) {
                    this.setDefaultValueToCalculate();
                }
                break;
            case ValueToCalculate.LAST_INSTALMENT:
                this.disableFuncParamLastRate = !evt.value;
                if (this._valueToCalculate !== ValueToCalculate.FIRST_INSTALMENT) {
                    this.setDefaultValueToCalculate();
                }
                break;
        }
    }

    private setDefaultValueToCalculate() {
        if (this._valueToCalculate !== (ValueToCalculate.YEARLY_INTEREST || ValueToCalculate.INSTALMENT)) {
            this.setValueToCalculate(ValueToCalculate.INSTALMENT);
        }
    }

    public changeTotalTermsRange(objectCondition: UsedCondition): void {
        const preSelectedObjectGroup = findObjectGroupById(this.objectGroups, this.basicForm.get('object_group').value.code);
        const minAmortizationPeriod = objectCondition === UsedCondition.NEW ? preSelectedObjectGroup.amortization_period_min : 15;

        const minPeriod = this.selectedContractType === ContractTypeId.MIETKAUF ? this.minTermsHirePurchase : minAmortizationPeriod;
        const maxPeriod = this.selectedContractType === ContractTypeId.MIETKAUF ? this.maxTermsHirePurchase : preSelectedObjectGroup.amortization_period_max;
        this.amortizationRange = this.calculationService.getAmortizationRange(minPeriod, maxPeriod);
        if (objectCondition === UsedCondition.NEW && this.basicForm.getRawValue().total_terms < minPeriod) {
            this.basicForm.get('total_terms').patchValue(minPeriod);
            this.calculate(null);
        }
    }
}
