//#region Imports

import { AuthService } from '@abcfinlab/api/login';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

//#endregion

/**
 * The `SessionService` class.
 *
 * @public
 */
@Injectable()
export class SessionService {

    //#region Fields

    private readonly _auth2Service: AuthService;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `SessionService` class.
     *
     * @public
     */
    public constructor(auth2Service: AuthService) {
        this._auth2Service = auth2Service;
    }

    //#endregion

    //#region Methods

    /**
     *
     * @public
     */
    public hasSession(): Observable<boolean> {
        // A dirty way to check if there is a session or not
        return this._auth2Service.getUserInfo().pipe(
            switchMap(() => of(true)),
            catchError((x: HttpErrorResponse) => {
                if (x.status === 409) {
                    return of(false);
                }
            })
        );

    }

    //#endregion

}
