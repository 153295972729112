//#region Imports

import { IUserInfoDto } from '@abcfinlab/api/login';
import { AppConfig, once } from '@abcfinlab/core';
import { ReplaySubject } from 'rxjs';

//#endregion

/**
 * A angular app initializer that will inject the user guiding script into the head of the document.
 * What is user guiding? see: https://userguiding.com/
 *
 * @remarks
 * The guidance feature is only enabled if the guidance flag is set to true in the app config and the user realm is RETAILER.
 */
export function userGuidanceInitializer(appConfig: AppConfig, userInfo: ReplaySubject<IUserInfoDto>): Function {
    return () => {
        once(userInfo, (userInfo: IUserInfoDto) => {
            if (appConfig.get<boolean>('guidance') && userInfo.realm === 'RETAILER') {

                const token = 'QSK82208SWNID';
                const permissions = ['previewGuide', 'finishPreview', 'track', /*'identify',*/ 'hideChecklist', 'launchChecklist'];
                const userId = userInfo.userId;

                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.async = true;
                script.src = `https://static.userguiding.com/media/user-guiding-${token}-embedded.js`;

                script.onload = () => {
                    console.log('UserGuiding script loading, identifying user...');
                    // TODO: realy dumb way to wait for the script to load, but it works for now.
                    //       without this, the userGuiding object is not available in the window object.
                    setTimeout(() => {
                        if (window.userGuiding) {
                            const ug = window.userGuiding;
                            ug.identify(userId);
                            const methods = permissions;
                            methods.forEach(method => {
                                if (ug[method]) {
                                    ug[method]();
                                } else {
                                    ug[method] = function (...args) {
                                        ug.q = ug.q || [];
                                        ug.q.push([method, args]);
                                    };
                                }
                            });
                        } else {
                            console.error('UserGuiding script not loaded :(');
                        }
                    }, 1000);
                };

                document.body.appendChild(script);

            }
        });
    };
}

/**
 * Only for typescript to know that the userGuiding object is available in the window object.
 */
declare global {
    interface Window {
        userGuiding: any;
    }
}
