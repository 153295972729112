//#region Imports

import { InjectionToken } from '@angular/core';
import { type IMotionModuleConfig } from './IMotionModuleConfig';

//#endregion

/**
 * The `InjectionToken` for the motion module configuration.
 *
 * @public
 */
export const MOTION_MODULE_CONFIG = new InjectionToken<IMotionModuleConfig>('MOTION_MODULE_CONFIG');
