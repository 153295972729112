//#region Imports

import { DirectusService, INewsItemDto } from '@abcfinlab/api/global';
import { IRealmDto } from '@abcfinlab/api/login';
import { UserService } from '@abcfinlab/auth';
import { InjectorFactory, once } from '@abcfinlab/core';
import { NewsManager } from '@abcfinlab/news';
import { ITileInfo, TileRegistry } from '@abcfinlab/presentation';
import { GreetingService, GreetingType } from '@abcfinlab/ui';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

//#endregion

/**
 * @internal
 */
interface ILayout {
    columns: number;
    span: number | null;
}

/**
 * The presenter of the {@link DashboardView} view.
 *
 * @internal
 */
@Injectable()
export class DashboardViewPresenter {

    //#region Fields

    private readonly _userService: UserService;
    private readonly _tileRegistry: TileRegistry;
    private readonly _newsService: DirectusService;
    private readonly _newsManager: NewsManager;
    private readonly _greetingService: GreetingService;
    private readonly _hasAlertSubject: BehaviorSubject<boolean>;
    private readonly _alertSubject: BehaviorSubject<INewsItemDto | null>;
    private readonly _activatedRouteSubject: BehaviorSubject<ActivatedRoute>;
    private readonly _widgetLayout: Observable<ILayout>;
    private readonly _injectorFactory: InjectorFactory;
    private readonly _isWideSubject: BehaviorSubject<boolean>;

    private readonly _newsSubscription: Subscription | null = null;
    private _injector: Injector | null;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `DashboardViewPresenter` class.
     *
     * @public
     */
    public constructor(injectorFactory: InjectorFactory, userService: UserService, tileRegistry: TileRegistry, newsService: DirectusService, newsManager: NewsManager,
        greetingService: GreetingService, activatedRoute: ActivatedRoute, breakpointObserver: BreakpointObserver) {
        this._userService = userService;
        this._tileRegistry = tileRegistry;
        this._newsService = newsService;
        this._newsManager = newsManager;
        this._greetingService = greetingService;
        this._hasAlertSubject = new BehaviorSubject(false);
        this._alertSubject = new BehaviorSubject(null);
        this._activatedRouteSubject = new BehaviorSubject(activatedRoute);
        this._injectorFactory = injectorFactory;

        this._isWideSubject = new BehaviorSubject<boolean>(false);
        this._widgetLayout = breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium]).pipe(
            tap(x => {
                this._isWideSubject.next(!x.matches);
            }),
            map(x => this.matchBreakpoint(x.matches))
        );
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `greeting` property.
     *
     * @public
     * @readonly
     */
    public get greeting(): Observable<GreetingType> {
        return this._greetingService.getGreetingType();
    }

    /**
     * Returns the `userName` property.
     *
     * @public
     * @readonly
     */
    public get userName(): Observable<string> {
        return this._userService.userInfo.pipe(
            map(x => x.realm === IRealmDto.Logins
                ? `${x.loginsInfo.givenName} ${x.loginsInfo.familyName}`
                : `${x.retailerInfo.givenName} ${x.retailerInfo.familyName}`
            )
        );
    }

    /**
     * Returns the `hasAlert` property.
     *
     * @public
     * @readonly
     */
    public get hasAlert(): Observable<boolean> {
        return this._hasAlertSubject.asObservable();
    }

    /**
     * Returns the `alert` property.
     *
     * @public
     * @readonly
     */
    public get alert(): Observable<INewsItemDto | null> {
        return this._alertSubject.asObservable();
    }

    /**
     * Returns the `greeting` property.
     *
     * @public
     * @readonly
     */
    public get tiles(): Observable<ReadonlyArray<ITileInfo>> {
        return of(this._tileRegistry.all());
    }

    /**
     * Returns the `isSales` property.
     *
     * @public
     * @readonly
     */
    public get isSales(): Observable<boolean> {
        return this._userService.userInfo.pipe(
            map(x => x.realm === 'LOGINS')
        );
    }

    /**
     * Returns the `activatedRoute` property.
     *
     * @public
     * @readonly
     */
    public get activatedRoute(): Observable<ActivatedRoute> {
        return this._activatedRouteSubject.asObservable();
    }

    /**
     * Returns the `widgetLayout` property.
     *
     * @public
     * @readonly
     */
    public get widgetLayout(): Observable<ILayout> {
        return this._widgetLayout;
    }

    /**
     * Returns the `isWide` property.
     *
     * @public
     * @readonly
     */
    public get isWide(): Observable<boolean> {
        return this._isWideSubject.asObservable();
    }

    /**
     * Returns the `injector` property.
     *
     * @protected
     * @readonly
     */
    public get injector(): Injector | null {
        return this._injector;
    }

    //#endregion

    //#region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
        this._injector = this._injectorFactory.create([
        ]);

        once(this._newsService.hotNews(), x => {
            if (x.length) {
                this._hasAlertSubject.next(true);
                // TODO: aw fix reduce by expire_at
                this._alertSubject.next(x.reduce((a, b) => a > b ? a : b));
            }
        });
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
        this._newsSubscription?.unsubscribe();
    }

    /**
     * @internal
     */
    public onHandle(kind: 'news' | 'faq'): void {
        if (kind === 'news') {
            this._newsManager.show('news', this._alertSubject.value.id);
        } else if (kind === 'faq') {
            this._newsManager.show('faq');
        }
    }

    /**
     * @private
     */
    private matchBreakpoint(matches: boolean): ILayout {
        if (matches) {
            return {
                columns: 2,
                span: 1,
            };
        }
        return {
            columns: 4,
            span: null
        };
    }

    //#endregion

}
