//#region Imports

import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';

//#endregion

/**
 * @public
 */
export interface IValidationErrors {

    //#region Properties

    readonly name: string;

    readonly errors: ValidationErrors;

    //#endregion
}

/**
 * @public
 */
@Injectable({ providedIn: 'root' })
export class FormValidator {

    //#region Ctor

    /**
     * Constructs a new instance of the `ValidatorService` class.
     *
     * @public
     */
    public constructor() {
    }

    //#endregion

    //#region Methods

    /**
     * Mark each formControl in the form as touched.
     *
     * @public
     * @param formGroup - The form group to validate.
     */
    public validate(formGroup: UntypedFormGroup | UntypedFormControl): FormValidator {
        if (formGroup instanceof UntypedFormGroup) {
            Object.keys(formGroup.controls).forEach(field => {
                const control = formGroup.get(field);

                if (control instanceof UntypedFormGroup) {
                    this.validate(control);
                } else if (control instanceof UntypedFormControl) {
                    control.markAsTouched({ onlySelf: true });
                } else if (control instanceof UntypedFormArray) {
                    control.controls.forEach(innerControl => {
                        innerControl.markAsTouched({ onlySelf: true });
                    });
                }
            });
        } else {
            formGroup.markAsTouched({ onlySelf: true });
        }

        return this;
    }

    /**
     * Mark each formControl in the form as pristine.
     *
     * @public
     * @param formGroup - The form group to pristine.
     */
    public pristine(formGroup: UntypedFormGroup): FormValidator {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);

            if (control instanceof UntypedFormGroup) {
                this.pristine(control);
            } else if (control instanceof UntypedFormControl) {
                control.markAsPristine({ onlySelf: true });
            } else if (control instanceof UntypedFormArray) {
                control.controls.forEach(innerControl => {
                    innerControl.markAsPristine({ onlySelf: true });
                });
            }
        });

        return this;
    }

    /**
     * Extract all errors of a from group.
     *
     * @public
     * @param formGroup - The form group to extract the errors.
     */
    public errors(formGroup: UntypedFormGroup | UntypedFormControl, parent?: string): Array<IValidationErrors> {
        const errors = new Array<IValidationErrors>();

        if (formGroup instanceof UntypedFormGroup) {

            Object.keys(formGroup.controls).forEach(field => {
                const control = formGroup.get(field);

                if (control instanceof UntypedFormGroup) {
                    errors.push(...this.errors(control, field));
                } else if (control instanceof UntypedFormControl) {
                    if (control.errors) {
                        errors.push({ name: parent ? `${parent}.${field}` : `${field}`, errors: control.errors });
                    }
                } else if (control instanceof UntypedFormArray) {
                    control.controls.forEach((innerControl, index) => {
                        if (innerControl instanceof UntypedFormGroup) {
                            errors.push(...this.errors(innerControl, `${field}.${index}`));
                        } else {
                            if (innerControl.errors) {
                                errors.push({ name: parent ? `${parent}.${field}` : `${field}`, errors: innerControl.errors });
                            }
                        }
                    });
                }
            });

            // concat the formGroup validation errors itself
            if (formGroup.errors) {
                errors.push({ name: '', errors: formGroup.errors });
            }
        } else {
            if (formGroup.errors) {
                errors.push({ name: parent ? `${parent}` : '', errors: formGroup.errors });
            }
            return errors;
        }

        return errors;
    }

    //#endregion

}
