import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

export interface ServerErrorDialogData {
    title?: string;
    text?: string;
    params?: Array<string>;
    error?: HttpErrorResponse;
    routingBehaviour: RoutingBehaviour;
}

export enum RoutingBehaviour {
    ROOT = 'root',
    STAY = 'stay',
    BACK = 'back'
}

@Component({
    selector: 'l7-server-error-dialog',
    templateUrl: './server-error-dialog.component.html',
    styleUrls: ['./server-error-dialog.component.scss']
})
export class ServerErrorDialogComponent {
    routingBehaviour = RoutingBehaviour;
    dateNow = new Date();

    constructor(
        public dialogRef: MatDialogRef<ServerErrorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ServerErrorDialogData,
        private readonly _router: Router,
        private readonly _location: Location,
        private readonly _activatedRoute: ActivatedRoute
    ) {
    }

    public formatText(text?: string): string {
        return this.replaceAll(text, '; ', ';\n');
    }

    public onCloseDialog(): void {
        if (!this.data.routingBehaviour || this.data.routingBehaviour === RoutingBehaviour.STAY) {
            this.dialogRef.close();
        }

        if (this.data.routingBehaviour === RoutingBehaviour.BACK) {
            this._location.back();
            this.dialogRef.close();
        }

        if (this.data.routingBehaviour === RoutingBehaviour.ROOT) {
            void this._router.navigate([''], { relativeTo: this._activatedRoute });
            this.dialogRef.close();
        }
    }

    private replaceAll(self?: string, str?: string, newStr?: string) {
        // If a regex pattern
        if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
            return self?.replace(str, newStr);
        }

        // If a string
        return self?.replace(new RegExp(str, 'g'), newStr);
    }

}
