import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: 'input[l7NullValue]'
})
export class NullDefaultValueDirective {
    @Output() emptyToNull = new EventEmitter<any>();
    @HostListener('keyup', ['$event.target']) onEvent(target: HTMLInputElement) {
        if (target.value === '') {
            this.emptyToNull.emit({
                value: target.value === '' ? null : target.value,
                formControlName: target.getAttribute('formControlName')
            });
        }
    }

}

