//#region Imports

import { Globals } from '@abcfinlab/core';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    ConfigurationOverviewViewPresenter
} from './ConfigurationOverviewViewPresenter';

//#endregion

/**
 * The `SearchContactView` view.
 *
 * @public
 */
@UntilDestroy()
@Component({
    selector: 'l7-configuration-overview-view',
    templateUrl: './ConfigurationOverviewView.html',
    styleUrls: ['./ConfigurationOverviewView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        ConfigurationOverviewViewPresenter
    ]
})
export class ConfigurationOverviewView implements OnInit, OnDestroy {

    //#region Fields

    private readonly _presenter: ConfigurationOverviewViewPresenter;
    private readonly _cdr: ChangeDetectorRef;
    @ViewChild('fileInput') fileInput: ElementRef;

    public defaultLogoConfig: typeof Globals.defaultLogoConfig = Globals.defaultLogoConfig;
    public isCustomLogoUsed: boolean = false;
    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `ConfigurationOverviewView` class.
     *
     * @public
     */
    public constructor(presenter: ConfigurationOverviewViewPresenter, cdr: ChangeDetectorRef) {
        this._presenter = presenter;
        this._cdr = cdr;
    }
    //#endregion

    //#region Proeprties

    /**
     * Returns the presenter of the `ConfigurationOverviewView`
     *
     * @public
     * @readonly
     */
    public get presenter(): ConfigurationOverviewViewPresenter {
        return this._presenter;
    }

    //#endregion

    //#region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize();
    }

    public ngAfterViewInit(): void {
        this._presenter.isCustomLogoUsed.pipe(untilDestroyed(this)).subscribe(isCustomLogoUsed => {
            this.isCustomLogoUsed = isCustomLogoUsed;
            this.fileInput.nativeElement.value = '';
            this._cdr.detectChanges();
        });
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }
    public triggerFileUpload(): void {
        this.fileInput.nativeElement.value = '';
        this.fileInput.nativeElement.click();
    }
    public uploadNewLogo(uploadedLogo: any): void {
        this._presenter.openLogoUploadDialog(uploadedLogo, this.defaultLogoConfig);
    }
    public showContractDraft(): void {
        this._presenter.showContractDraft();
    }
    public resetCustomLogo(): void {
        this._presenter.resetLogo();
    }
    //#endregion

}
