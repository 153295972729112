//#region Imports

import { IsActiveMatchOptions } from '@angular/router';

//#endregion

/**
 * @public
 */
export type RouterLinkRelatedToLink = string | { link: string; options?: Partial<IsActiveMatchOptions> };

/**
 * @public
 */
export type RouterLinkActiveOptions = ({ exact: boolean } | IsActiveMatchOptions) & { relatedTo?: Array<RouterLinkRelatedToLink> };

/**
 * @public
 */
export function isRouterLinkRelatedToLink(value: any): value is RouterLinkRelatedToLink {
    return typeof value === 'string' || (typeof value === 'object' && typeof value.link === 'string');
}
