import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal';
import { HttpErrorResponse } from '@angular/common/http';
import {
    ApplicationRef,
    ComponentFactoryResolver,
    ErrorHandler,
    Injectable,
    Injector
} from '@angular/core';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { ErrorParser } from '../../../models/classes/ErrorParser.class';
import { DialogService } from '../../../private/services/dialog/dialog.service';
import { TopBarErrorComponent } from '../../components/top-bar-error/top-bar-error.component';
import { AuthErrorDialogComponent } from '../../modals/auth-error-dialog/auth-error-dialog.component';
import {
    GenericDialogComponent,
    GenericDialogData
} from '../../modals/generic-dialog/generic-dialog.component';
import {
    RoutingBehaviour,
    ServerErrorDialogComponent,
    ServerErrorDialogData
} from '../../modals/server-error-dialog/server-error-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {
    errorDialogRef: MatDialogRef<any> = null;
    errorDialogCloseSubject: BehaviorSubject<string> = new BehaviorSubject<string>('no_dialog');
    errorDialogEmailVerification$: Subject<boolean> = new Subject<boolean>();
    errorDialogSubscription: Subscription;
    private readonly dialogErrors = [
        'contact_maximum_obligo_reached',
        'contact_blocked_nav',
        'contact_lefo_not_whitelisted'
    ];
    private _errorPortal: ComponentPortal<TopBarErrorComponent>;
    private _errorPortalHost: DomPortalOutlet;
    private readonly _emailErrorKeys: Array<string> = ['invalid_email', 'invalid_domain', 'rejected_email', 'unknown_error'];

    constructor(
        private readonly _router: Router,
        private readonly _dialog: DialogService,
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private readonly injector: Injector,
        private readonly appRef: ApplicationRef,
    ) {
    }

    public isServerError(error: any): boolean {
        return error instanceof HttpErrorResponse;
    }

    public isAuthenticationError(error: any): boolean {
        return error.status === 401
            || (error.status === 403 && error.error.error !== 'ueb_remote_data_changed');
    }

    public handleAuthenticationError(error: object): Observable<any> {
        const config: MatDialogConfig = { id: 'authentication_error' };

        if (this.errorDialogRef) {
            return of(true);
        }
        this.errorDialogRef = this._dialog.openDialog(AuthErrorDialogComponent, config, error);
        return of(true);
    }

    public openDialogWithIdAndBody(id: string, error: HttpErrorResponse, body: string) {
        if (this.errorDialogSubscription) {
            this.errorDialogSubscription.unsubscribe();
        }
        if (this.containsEmailErrorKey(error.error.error)) {
            const data: GenericDialogData = {
                id,
                image: 'assets/images/image-failure.svg',
                title: 'Fehler',
                body,
                negativeText: 'global.close',
                positiveText: 'global.continue',
            };
            this.errorDialogRef = this._dialog.openDialog(GenericDialogComponent, {
                minHeight: '0px'
            }, data);
            this.errorDialogSubscription = this.errorDialogRef.afterClosed().subscribe(result => {
                this.errorDialogEmailVerification$.next(result);
                this.errorDialogRef = null;
            });
        }
    }

    public handleCriticalServerError(error: HttpErrorResponse, config: MatDialogConfig = { id: 'server_error' }, data = { routingBehaviour: RoutingBehaviour.STAY }): Observable<never> {
        if (this.errorDialogRef) {
            return throwError(error);
        }
        data = { ...data, ...{ error: error } };
        this.errorDialogRef = this._dialog.openDialog(ServerErrorDialogComponent, config, data);
        this.errorDialogRef.afterClosed().subscribe(result => {
            this.errorDialogCloseSubject.next(result);
            this.errorDialogRef = null;
        });
        return throwError(error);
    }

    public handleBadRequestError(error: HttpErrorResponse): Observable<never> {
        const errorCode = ErrorParser.getInternalErrorCode(error);
        const path = this._router.url;

        if (this.dialogErrors.includes(errorCode)) {
            const config: MatDialogConfig = {
                id: errorCode
            };
            return this.extracted(path, error, config, errorCode);
        }

        this.cleanUp();
        this._errorPortal = new ComponentPortal<TopBarErrorComponent>(TopBarErrorComponent);
        // Create a portalHost from a DOM element
        this._errorPortalHost = new DomPortalOutlet(
            document.querySelector('#application-header'),
            this.componentFactoryResolver,
            this.appRef,
            this.injector
        );

        const componentRef = this._errorPortalHost.attachComponentPortal(this._errorPortal);
        this._errorPortal.setAttachedHost(this._errorPortalHost);

        componentRef.instance.errorMessage = error.error;

        return throwError(error);
    }

    private extracted(path: string, error: HttpErrorResponse, config: MatDialogConfig<any>, errorCode: string) {
        if (errorCode === 'contact_lefo_not_whitelisted') {
            const _data: ServerErrorDialogData = {
                title: `error.${errorCode}.title`,
                text: `error.${errorCode}`,
                params: error.error.error_params,
                routingBehaviour: RoutingBehaviour.BACK
            };
            return this.handleCriticalServerError(error, config, _data);
        }

        const data: ServerErrorDialogData = {
            title: `error.${errorCode}.title`,
            text: `error.${errorCode}`,
            params: error.error.error_params,
            routingBehaviour: RoutingBehaviour.STAY
        };
        return this.handleCriticalServerError(error, config, data);
    }

    handleError(error: any): void {
    }

    public cleanUp(): void {
        if (this._errorPortalHost) {
            this._errorPortalHost.detach();
        }
    }

    public containsEmailErrorKey(key: string): boolean {
        return this._emailErrorKeys.includes(key);
    }
}
