import { Globals, TranslationFacade } from '@abcfinlab/core';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { RetailerConfigurationOverviewViewPresenter } from './RetailerConfigurationOverviewViewPresenter';

@UntilDestroy()
@Component({
    selector: 'l7-retailer-configuration-overview-view',
    templateUrl: './RetailerConfigurationOverviewView.html',
    styleUrls: ['./RetailerConfigurationOverviewView.scss'],
    providers: [RetailerConfigurationOverviewViewPresenter]
})
export class RetailerConfigurationOverviewView implements OnInit, OnDestroy {
    private readonly _presenter: RetailerConfigurationOverviewViewPresenter;
    private readonly _activatedRoute: ActivatedRoute;
    private readonly _cdr: ChangeDetectorRef;
    private _valueChangeSubscription: Subscription;
    private readonly _sanitizedMailTo: BehaviorSubject<SafeUrl> = new BehaviorSubject<SafeUrl>(null);

    private readonly _sanitizer: DomSanitizer;

    private readonly _mailtoHeader: string = 'mailto:';
    private readonly _subjectProp: string = 'subject=';
    private readonly _bodyProp: string = 'body=';
    private readonly _breakStr: string = '%0D%0A';
    private readonly _footer: string = '';

    private readonly _subject: string = 'Anfrage zur Erstellung neuer Nutzer für ';

    private readonly _lines: Array<string> = [
        'Sehr geehrte Damen und Herren,',
        'Hiermit frage ich die Anlage eines Nutzers für mein Fachhändler-Profil in Lease Seven mit nachfolgenden Daten an:',
        '%E2%80%A2 Vorname (Pflichtangabe):',
        '%E2%80%A2 Nachname (Pflichtangabe):',
        '%E2%80%A2 E-Mail (Pflichtangabe):',
        '%E2%80%A2 Telefonnummer:',
        '%E2%80%A2 Mobilnummer:',
        '%E2%80%A2 Rollen/Rechte (Pflichtangabe): Admin | Vertrieb | Verwaltung (bitte löschen Sie nicht benötigte Rechte)'
    ];

    //#region Fields
    public constructor(presenter: RetailerConfigurationOverviewViewPresenter, cdr: ChangeDetectorRef, activatedRoute: ActivatedRoute, private readonly translationFacade: TranslationFacade, sanitizer: DomSanitizer) {
        this._presenter = presenter;
        this._activatedRoute = activatedRoute;
        this._cdr = cdr;
        this._sanitizer = sanitizer;
        this.addressRows = ['company', 'street', 'postcode', 'city'];
        this.partnerInfoRows = ['partnerNumber', 'contactNumber'];
    }

    @ViewChild('fileInput') fileInput: ElementRef;

    public defaultLogoConfig: typeof Globals.defaultLogoConfig = Globals.defaultLogoConfig;
    public isCustomLogoUsed: boolean = false;
    public readonly addressRows: Array<string>;
    public readonly partnerInfoRows: Array<string>;

    public get presenter(): RetailerConfigurationOverviewViewPresenter {
        return this._presenter;
    }

    //#endregion

    //#region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this._presenter.initialize();
        this._valueChangeSubscription = this._presenter.settingsForm.valueChanges.subscribe(changes => {
            this._presenter.checkSettingFormChanges(changes);
        });
    }

    public ngAfterViewInit(): void {
        this._presenter.isCustomLogoUsed.pipe(untilDestroyed(this)).subscribe(isCustomLogoUsed => {
            this.isCustomLogoUsed = isCustomLogoUsed;
            this.fileInput.nativeElement.value = '';
            this._cdr.detectChanges();
        });
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
        this._valueChangeSubscription.unsubscribe();
    }
    public triggerFileUpload(): void {
        this.fileInput.nativeElement.value = '';
        this.fileInput.nativeElement.click();
    }
    public uploadNewLogo(uploadedLogo: any): void {
        this._presenter.openLogoUploadDialog(uploadedLogo, this.defaultLogoConfig);
    }
    public showContractDraft(): void {
        this._presenter.showContractDraft();
    }
    public resetCustomLogo(): void {
        this._presenter.resetLogo();
    }

    public resetChangedSettings(): void {
        this._presenter.resetChangedSettings();
    }

    public updateRetailerSettings(): void {
        this._presenter.updateRetailerSettings();
    }

    public translateRoles(roles: Array<string>): Array<string> {
        return roles.map(x => this.translationFacade.instant(`roles.${x}`));
    }

    public get sanitizedMailTo(): Observable<SafeUrl> {
        return this._sanitizedMailTo.asObservable();
    }

    public getSanitizedMailTo(): void {
        let str = '';
        this._lines.forEach(line => {
            str = str + this._breakStr + line + this._breakStr;
        });
        const url = `${this._mailtoHeader}${this.presenter.officeServiceEmail}?${this._subjectProp}${this._subject}${this.presenter.retailerCompany}&${this._bodyProp}${str}`;
        this._sanitizedMailTo.next(this._sanitizer.bypassSecurityTrustUrl(url));
    }
    //#endregion

}
