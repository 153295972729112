//#region Imports

import { IContactDto } from '@abcfinlab/api/contact';
import { AcknowledgementService, IRetailerContractDetailInformationResultDto, ISerialNumberRequestDto, RetailerContractWorkflowService } from '@abcfinlab/api/contract';
import { ICalculationSettingsDto, ILeasingQuoteDto, IWorkflowStepDto, QuoteService, RetailerLeasingService, VerificationService } from '@abcfinlab/api/global';
import { RetailerAdminService } from '@abcfinlab/api/retailer';
import { BlobHandler, ControlsOf, TranslationFacade, Validators, once } from '@abcfinlab/core';
import { BusyBoxService, ToastService, percentageValidator } from '@abcfinlab/ui';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators as NgValidators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, Subject, combineLatest, of, throwError } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { Validators as AdministrationValidators } from '../../../../../administration/src/Validators/Validators';
import { DigitalSignDialogView } from './DigitalSignDialogView';

interface IDigitalSignDialogViewPresenterFormData {
    general: FormGroup<{
        acknowledgementDate: FormControl<string>;
        purchasePrice: FormControl<number>;
        purchasePriceChanged: FormControl<boolean>;
        localSigningCheckBox1: FormControl<boolean>;
        localSigningCheckBox2: FormControl<boolean>;
        localSigningCheckBox3: FormControl<boolean>;
    }>;
    files: FormGroup<{
        deliveryNote: FormControl<File>;
        serialNumber: FormControl<File>;
        serialNumbers: FormArray;
    }>;
    choices: FormGroup<{
        isSerialNumberAuto: FormControl<boolean>;
        isSigningRemote: FormControl<boolean>;
    }>;
    finalRemote: FormGroup<{
        signerEmail: FormControl<string>;
    }>;
    finalLocal: FormGroup<{
        signedByDate: FormControl<string>;
        signedByFirstName: FormControl<string>;
        signedByLastName: FormControl<string>;
    }>;
}

interface IDigitalSignDialogData {
    contractDetails: IRetailerContractDetailInformationResultDto;
    contactInfo: IContactDto;
    step: IWorkflowStepDto;
    calculationSettings: ICalculationSettingsDto;
}

//#endregion

/**
 * The presenter of the {@link DigitalSignDialogView} view.
 *
 * @internal
 */
@UntilDestroy()
@Injectable()
export class DigitalSignDialogViewPresenter {

    //#region Fields

    private readonly _datePipe: DatePipe;
    private readonly _dialogRef: MatDialogRef<DigitalSignDialogView>;
    private readonly _form: FormGroup<IDigitalSignDialogViewPresenterFormData>;
    private readonly _acknowledgementService: AcknowledgementService;
    private readonly _retailerLeasingService: RetailerLeasingService;
    private readonly _retailerAdminService: RetailerAdminService;
    private readonly _retailerContractWorkflowService: RetailerContractWorkflowService;
    private readonly _busyBoxService: BusyBoxService;
    private readonly _quoteService: QuoteService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _toastService: ToastService;
    private readonly _currencyPipe: CurrencyPipe;
    private readonly _blobHandler: BlobHandler;
    private readonly _isBusyCalculating: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _calculationHasError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _newInstalmentSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private readonly _newDownPaymentSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private readonly _newResidualValueSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private readonly _warningMessageIfPriceChanged: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private readonly _checkBoxLabelIfPriceChanged: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private readonly _quoteInfo: BehaviorSubject<ILeasingQuoteDto> = new BehaviorSubject<ILeasingQuoteDto>(null);
    private readonly _calculationSettings: BehaviorSubject<ICalculationSettingsDto> = new BehaviorSubject<ICalculationSettingsDto>(null);
    private _vendorCompanyName: string = '';
    private _isPriceChanged: boolean = false;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `DigitalSignDialogViewPresenter` class.
     *
     * @public
     */
    public constructor(dialogRef: MatDialogRef<DigitalSignDialogView>, @Inject(MAT_DIALOG_DATA) public dialogData: IDigitalSignDialogData,
        acknowledgementService: AcknowledgementService, retailerContractWorkflowService: RetailerContractWorkflowService,
        busyBoxService: BusyBoxService, translationFacade: TranslationFacade, toastService: ToastService,
        quoteService: QuoteService, retailerAdminService: RetailerAdminService, verificationService: VerificationService,
        retailerLeasingService: RetailerLeasingService, currencyPipe: CurrencyPipe, blobHandler: BlobHandler, datePipe: DatePipe) {
        this._datePipe = datePipe;
        this._dialogRef = dialogRef;
        this._acknowledgementService = acknowledgementService;
        this._retailerLeasingService = retailerLeasingService;
        this._retailerAdminService = retailerAdminService;
        this._retailerContractWorkflowService = retailerContractWorkflowService;
        this._busyBoxService = busyBoxService;
        this._translationFacade = translationFacade;
        this._toastService = toastService;
        this._quoteService = quoteService;
        this._currencyPipe = currencyPipe;
        this._blobHandler = blobHandler;
        this._calculationSettings.next(dialogData.calculationSettings);
        this._form = new FormGroup<IDigitalSignDialogViewPresenterFormData>({
            general: new FormGroup({
                acknowledgementDate: new FormControl<string>('', [NgValidators.required, Validators.minDate(dialogData.step.metaInformation.approvalDate as string)]),
                purchasePrice: new FormControl<number>({ value: this.dialogData?.contractDetails.objectValue, disabled: true }, [
                    NgValidators.required,
                    percentageValidator(this._calculationSettings.getValue().leasingQuoteCalculationProperties.retailer.acknowledgement.maxObjectValueChangePercent, this.dialogData.step.metaInformation.objectValue as number, 2),
                    NgValidators.min(this._calculationSettings.getValue().leasingQuoteCalculationProperties.retailer.acknowledgement.minObjectValueChange),
                    NgValidators.max(this._calculationSettings.getValue().leasingQuoteCalculationProperties.retailer.acknowledgement.maxObjectValueChange)
                ]),
                purchasePriceChanged: new FormControl<boolean>(false),
                localSigningCheckBox1: new FormControl<boolean>(false),
                localSigningCheckBox2: new FormControl<boolean>(false),
                localSigningCheckBox3: new FormControl<boolean>(false)
            }),
            files: new FormGroup({
                deliveryNote: new FormControl(),
                serialNumber: new FormControl(null, [NgValidators.required]),
                serialNumbers: new FormArray([])
            }),
            choices: new FormGroup({
                isSerialNumberAuto: new FormControl<boolean>(false),
                isSigningRemote: new FormControl<boolean>(false),
            }),
            finalRemote: new FormGroup({
                signerEmail: new FormControl<string>('', [NgValidators.required, NgValidators.email], AdministrationValidators.emailInvalidAsync(verificationService)),
            }),
            finalLocal: new FormGroup({
                signedByFirstName: new FormControl<string>('', NgValidators.required),
                signedByLastName: new FormControl<string>('', NgValidators.required),
                signedByDate: new FormControl<string>('', [
                    NgValidators.required,
                    Validators.minDate(dialogData.step.metaInformation.approvalDate as string),
                    Validators.futureDateValidator()
                ]),
            })
        });

        this.purchasePriceSubject.pipe(
            distinctUntilChanged(),
            debounceTime(1000),
            untilDestroyed(this),
            mergeMap(search => of(search).pipe(
                delay(500)
            )),
        ).subscribe(x => {
            const ignoreKeysList = ['ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown', ',', '.'];
            if (ignoreKeysList.indexOf(x.key) < 0) {
                this.onPurchasePriceChanged();
            }
        });
    }

    //#endregion

    //#region Properties

    public purchasePriceSubject: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();

    //#endregion

    //#region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
        this._busyBoxService.show(null, this._translationFacade.translate('global.busy'),
            combineLatest([
                this._quoteService.getLeasingQuoteById({ quoteId: this.dialogData.contractDetails.leasingQuoteId }),
                this._retailerAdminService.getRetailerById()
            ]))
            .pipe(untilDestroyed(this))
            .subscribe(([lesingQuote, adminConfig]) => {
                this._quoteInfo.next(lesingQuote);
                this._vendorCompanyName = adminConfig.retailer.name;
            });

        // Patch the finalAcknowledgement date as soon as the previous date is changed.
        this._form.controls.general.controls.acknowledgementDate.valueChanges.pipe(
            untilDestroyed(this)
        ).subscribe(inputValue => {
            this._form.controls.finalLocal.controls.signedByDate.patchValue(inputValue);
        });
        this.checkSerialNumberType();
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
    }

    public get quoteInfo(): Observable<ILeasingQuoteDto> {
        return this._quoteInfo.asObservable();
    }
    public get newInstalment(): Observable<number> {
        return this._newInstalmentSubject.asObservable();
    }
    public get newDownPayment(): Observable<number> {
        return this._newDownPaymentSubject.asObservable();
    }
    public get newResidualValue(): Observable<number> {
        return this._newResidualValueSubject.asObservable();
    }
    public get warningMessageIfPriceChanged(): Observable<string> {
        return this._warningMessageIfPriceChanged.asObservable();
    }
    public get checkBoxLabelIfPriceChanged(): Observable<string> {
        return this._checkBoxLabelIfPriceChanged.asObservable();
    }
    public get isPriceChanged(): boolean {
        return this._isPriceChanged;
    }
    public get calculationHasError(): Observable<boolean> {
        return this._calculationHasError.asObservable();
    }

    public get calculationSettings(): Observable<ICalculationSettingsDto> {
        return this._calculationSettings.asObservable();
    }

    public get approvalDate(): string {
        return this.dialogData?.step.metaInformation.approvalDate as string;
    }

    public get isBusyCalculating(): Observable<boolean> {
        return this._isBusyCalculating.asObservable();
    }
    public get vendorCompanyName(): string {
        return this._vendorCompanyName;
    }

    public get form(): FormGroup<IDigitalSignDialogViewPresenterFormData> {
        return this._form;
    }

    public get years(): Array<number> {
        const result = new Array<number>();
        const currentYear = new Date(Date.now()).getFullYear();
        let offset = (currentYear - 1900);
        while (offset--) {
            result.push(currentYear - offset);
        }
        return result.reverse();
    }

    /**
     * Get the current date to validate against
     */
    public get currentDate(): String {
        return this._datePipe.transform(new Date(), 'yyyy-MM-dd');
    }

    public onSerialNumberUpload(files: Array<File>): void {
        this._form.controls.files.controls.serialNumber.patchValue(files[0]);
    }
    public get serialNumbers(): FormArray<FormGroup<ControlsOf<ISerialNumberRequestDto>>> {
        return this._form.controls.files.controls.serialNumbers;
    }
    public onAddSerialNumber(): void {
        const position = this.serialNumbers.length + 1;
        const objectDescription = this.dialogData.step.metaInformation.objectDescription as string ?? '';

        const fg = new FormGroup({
            position: new FormControl(position, NgValidators.required),
            objectDescription: new FormControl(objectDescription, NgValidators.required),
            yearOfManufacture: new FormControl(null, NgValidators.required),
            serialNumber: new FormControl(null, NgValidators.required),
        });

        this.serialNumbers.push(fg);
    }

    /**
     * @internal
     */
    public onDeleteSerialNumber(sn: any, index: number): void {
        this.serialNumbers.removeAt(index);
    }

    public onSubmit(cancel: boolean): void {
        // @todo LEASA-8027: (cleanup)
    }

    public onPurchasePriceChanged(): void {
        const purchasePrice = this.form.get('general.purchasePrice').value;
        if (this.form.get('general.purchasePrice').invalid) {
            return;
        }

        this._isPriceChanged = (this.form.get('general.purchasePrice').value !== null && this.form.get('general.purchasePrice').value !== this.dialogData?.contractDetails.objectValue);

        if (purchasePrice) {
            this._isBusyCalculating.next(true);
            this._retailerLeasingService.objectValueChange({
                leasingQuoteId: this.dialogData.contractDetails.leasingQuoteId,
                body: { totalLeasingValue: purchasePrice }
            }).pipe(untilDestroyed(this)).subscribe(x => {
                this._newInstalmentSubject.next(x.instalment);
                this._newDownPaymentSubject.next(x.downPayment);
                this._newResidualValueSubject.next(x.residualValue);
                this._setWarningMessageIfPriceChanged();
                this._isBusyCalculating.next(false);
                this._calculationHasError.next(false);
            }, error => {
                this._isBusyCalculating.next(false);
                this._calculationHasError.next(true);
                this._toastService.show(this._translationFacade.instant('error.generic_error'), 'danger');
            });
        }
    }
    public onPurchasePriceChangeToggle(evt: MatSlideToggleChange): void {
        if (!evt.checked) {
            this._isPriceChanged = evt.checked;
            this.form.controls.general.controls.purchasePrice.disable();
            this.form.controls.general.controls.purchasePrice.setValue(this.dialogData?.contractDetails.objectValue);
        } else {
            this.form.controls.general.controls.purchasePrice.enable();
        }
    }
    public checkSerialNumberType(): void {
        const isSerialNumberAuto = this.form.controls.choices.controls.isSerialNumberAuto;
        if (!isSerialNumberAuto.value && this.serialNumbers.length === 0) {
            this.onAddSerialNumber();
            this.form.controls.files.controls.serialNumber.patchValue(null);
            this.form.controls.files.controls.serialNumber.setValidators(null);
            this.form.controls.files.controls.serialNumber.updateValueAndValidity();
        }
        if (isSerialNumberAuto.value && this.serialNumbers.length === 1) {
            this.form.controls.files.controls.serialNumber.setValidators([NgValidators.required]);
            this.form.controls.files.controls.serialNumber.updateValueAndValidity();
            this.serialNumbers.removeAt(0);
        }
    }

    /**
     * The customer signs the *acknowledgment* locally with the reatiler together on his device
     * @param signature     The signature of the signer provided as a *Blob*
     */
    public doAcknowledgmentLocal(signature: Blob): void {
        let requestPayload = null;
        const form = this._form.getRawValue();

        requestPayload = {
            stepId: this.dialogData.step.id,
            body: {
                signatureFile: signature,
                retailerAcknowledgementRequest: {
                    acknowledgementDate: form.finalLocal.signedByDate,
                    serialNumbers: form.files.serialNumbers,
                    signedByLastName: form.finalLocal.signedByLastName,
                    signedByFirstName: form.finalLocal.signedByFirstName,
                    purchasePriceChanged: form.general.purchasePriceChanged,
                    purchasePrice: form.general.purchasePrice
                }
            }
        };

        if (this._serialNumbersProvidedByPDF(form)) {
            requestPayload = this._addSerialNumberPDFtoPayload(requestPayload, form);
        }

        once(this._busyBoxService.show(null, this._translationFacade.translate('global.busy'), this._retailerContractWorkflowService.doAcknowledgementSignedDigital(requestPayload)
        ), () => this._handleDoAcknowledgementSuccess(), error => this._handleDoAcknowledgementError(error));
    }

    public doAcknowledgmentRemote(): void {
        let requestPayload = null;
        const form = this._form.getRawValue();

        requestPayload = {
            workflowStepId: this.dialogData.step.id,
            body: {
                retailerAcknowledgementStartRemoteRequest: {
                    purchasePrice: form.general.purchasePrice,
                    purchasePriceChanged: form.general.purchasePriceChanged,
                    serialNumbers: form.files.serialNumbers,
                    remoteLinkMailReceiver: form.finalRemote.signerEmail,
                    expectedAcknowledgementDate: form.general.acknowledgementDate
                }
            }
        };

        if (this._serialNumbersProvidedByPDF(form)) {
            requestPayload = this._addSerialNumberPDFtoPayload(requestPayload, form);
        }

        once(this._busyBoxService.show(null, this._translationFacade.translate('global.busy'), this._retailerContractWorkflowService.acknowledgementStartRemoteSigning(requestPayload)
        ), () => this._handleDoAcknowledgementSuccess(), error => this._handleDoAcknowledgementError(error));
    }

    public showSerialNumberDocument(): void {
        const file = this.form.controls.files.controls.serialNumber.getRawValue();
        this._blobHandler.open(file);
    }

    /**
     * @internal
     */
    public onClose(canceled: boolean): void {
        this._dialogRef.close({
            canceled: canceled
        });
    }

    private _setWarningMessageIfPriceChanged(): void {
        let message = null;
        let checkBoxLabel = null;
        if (this._newDownPaymentSubject.getValue() === 0 && this._newResidualValueSubject.getValue() === 0) {
            message = 'purchasePriceChanged';
            checkBoxLabel = 'checkBoxConditional';
        } else if (this._newDownPaymentSubject.getValue() !== 0 && this._newResidualValueSubject.getValue() !== 0) {
            message = 'purchasePriceChangedWithDownPaymentAndResidualValue';
            checkBoxLabel = 'checkBoxConditionalWithResidualValueAndDownPayment';
        } else if (this._newDownPaymentSubject.getValue() !== 0 && this._newResidualValueSubject.getValue() === 0) {
            message = 'purchasePriceChangedWithDownPayment';
            checkBoxLabel = 'checkBoxConditionalWithDownPayment';
        } else if (this._newDownPaymentSubject.getValue() === 0 && this._newResidualValueSubject.getValue() !== 0) {
            message = 'purchasePriceChangedWithResidualValue';
            checkBoxLabel = 'checkBoxConditionalWithResidualValue';
        }
        const translatedMessage = this._translationFacade.instant(`contract_management.retailers.dialogs.digitalSigning.step1.${message}`, {
            instalment: this._currencyPipe.transform(this._newInstalmentSubject.getValue()),
            duration: this.dialogData.step.metaInformation.term,
            downPayment: this._currencyPipe.transform(this._newDownPaymentSubject.getValue()),
            residualValue: this._currencyPipe.transform(this._newResidualValueSubject.getValue())
        });

        const translatedLabel = this._translationFacade.instant(`contract_management.retailers.dialogs.digitalSigning.step4.${checkBoxLabel}`, {
            instalment: this._currencyPipe.transform(this._newInstalmentSubject.getValue()),
            downPayment: this._currencyPipe.transform(this._newDownPaymentSubject.getValue()),
            residualValue: this._currencyPipe.transform(this._newResidualValueSubject.getValue())
        });
        this._warningMessageIfPriceChanged.next(translatedMessage);
        this._checkBoxLabelIfPriceChanged.next(translatedLabel);
    }

    /**
     * Check if the serial numbers are provided by a PDF instead of manually typed serial numbers
     */
    private _serialNumbersProvidedByPDF(form: any): Boolean {
        return form?.files?.serialNumber ?? false;
    }

    /**
     * Add the acknowledgement pdf field to the request payload
     * @param requestPayload The payload without the acknowledgement pdf field
     * @returns The payload with the acknowldgement pdf field
     */
    private _addSerialNumberPDFtoPayload(requestPayload: any, form: any): any {
        const updatedPayload = {
            ...requestPayload,
            ...{
                body: {
                    ...requestPayload.body,
                    ...{ serialNumberDocument: form.files.serialNumber }
                }
            }
        };
        return updatedPayload;
    }

    private _handleDoAcknowledgementError(err: any): void {
        this._toastService.show('Beim Speichern ist ein Fehler aufgetreten.', 'danger');
        this.onClose(false);
        throwError(err);
    }

    private _handleDoAcknowledgementSuccess(): void {
        this._toastService.show('Angaben erfolgreich gespeichert.', 'success');
        this.onClose(false);
    }

    //#endregion

}
