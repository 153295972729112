import { Directive, ElementRef, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@UntilDestroy()
@Directive({
  selector: '[l7InputRef]'
})
export class InputRefDirective implements OnInit {
  @Output() debounceKeyDown = new EventEmitter();
  private readonly keyDown$ = new BehaviorSubject(null);
  private readonly inputsWithCursorControl: Array<string> = [
    'insurance_fee', 'down_payment', 'down_payment_percent', 'residual_value', 'residual_value_percent', 'dealer_commission', 'dealer_commission_percent',
    'instalment', 'first_instalment', 'first_instalment_percent', 'instalment_percent', 'last_instalment', 'last_instalment_percent'
  ];
  private readonly activeElem;
  private readonly activeElemFormName: string;
  private currentPos: number;

  public focus = false;
  public previousValue: any = null;

  constructor(
    private readonly _el: ElementRef
  ) {
  }

  get disabled_state() {
    return this._el.nativeElement.disabled;
  }

  get placeholder() {
    return this._el.nativeElement.placeholder;
  }

  get id() {
    return this._el.nativeElement.id;
  }

  get value() {
    return this._el.nativeElement.value;
  }

  ngOnInit() {
    this.keyDown$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe(e => {
        if (!e || e?.key === 'Tab' || e?.key === 'Enter') {
            return;
        }
        this.debounceKeyDown.emit(e);
      });
  }

  @HostListener('focus')
  onFocus() {
    this.focus = true;
  }

  @HostListener('focusout')
  onFocusOut() {
    this.focus = false;

    if (this._el.nativeElement.value === '' && !!this.previousValue) {
      this._el.nativeElement.value = this.previousValue;
      this.previousValue = null;
    } else {
      return;
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.keyDown$.next(event);
    event.stopPropagation();
  }

  @HostListener('keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    this.previousValue = this._el.nativeElement.value;
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('dblclick')
  onDoubleClick() {
    this.previousValue = this._el.nativeElement.value;
    this._el.nativeElement.select();
  }

  @HostListener('ngModelChange', ['$event'])
  onInput(e) {
    if (!this.inputsWithCursorControl.includes(this.activeElemFormName)) {
      return;
    }
    if (this._el.nativeElement === this.activeElem) {
      if (this.currentPos === 4 && this._el.nativeElement.selectionStart === 5) {
        this.currentPos = 5;
      }
      this._el.nativeElement.setSelectionRange(this.currentPos, this.currentPos, 'none');
    }
  }
}
