// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bodyText {
  color: black;
  margin-bottom: 8px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIlJldGFpbGVyUXVvdGVEb2N1bWVudEFjdGlvbi5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksWUFBQTtFQUNBLGtCQUFBO0FBQ0oiLCJmaWxlIjoiUmV0YWlsZXJRdW90ZURvY3VtZW50QWN0aW9uLnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuYm9keVRleHR7XG4gICAgY29sb3I6IGJsYWNrO1xuICAgIG1hcmdpbi1ib3R0b206IDhweDtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/quote/src/Views/Dialogs/retailer-quote-document-action/RetailerQuoteDocumentAction.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,kBAAA;AACJ;AACA,oXAAoX","sourcesContent":[".bodyText{\n    color: black;\n    margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
