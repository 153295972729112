// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host mat-card ::ng-deep.wrap .mat-mdc-card-header-text {
  flex-wrap: wrap;
}
:host mat-expansion-panel ::ng-deep .mat-expansion-panel-header.alignItemsLeft .mat-content {
  flex: 0 1 auto;
}
:host mat-expansion-panel ::ng-deep .mat-expansion-panel-header.info .mat-expansion-panel-header-title {
  color: var(--l7-info-500-color);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIlVzZXJTZXR0aW5nc1ZpZXcuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFHWTtFQUNJLGVBQUE7QUFGaEI7QUFVZ0I7RUFDSSxjQUFBO0FBUnBCO0FBYWdCO0VBQ0ksK0JBQUE7QUFYcEIiLCJmaWxlIjoiVXNlclNldHRpbmdzVmlldy5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICAgIG1hdC1jYXJkIDo6bmctZGVlcCB7XG4gICAgICAgICYud3JhcCB7XG4gICAgICAgICAgICAubWF0LW1kYy1jYXJkLWhlYWRlci10ZXh0IHtcbiAgICAgICAgICAgICAgICBmbGV4LXdyYXA6IHdyYXA7XG4gICAgICAgICAgICB9XG4gICAgICAgIH1cbiAgICB9XG5cbiAgICBtYXQtZXhwYW5zaW9uLXBhbmVsIDo6bmctZGVlcCB7XG4gICAgICAgIC5tYXQtZXhwYW5zaW9uLXBhbmVsLWhlYWRlciB7XG4gICAgICAgICAgICAmLmFsaWduSXRlbXNMZWZ0IHtcbiAgICAgICAgICAgICAgICAubWF0LWNvbnRlbnQge1xuICAgICAgICAgICAgICAgICAgICBmbGV4OiAwIDEgYXV0bztcbiAgICAgICAgICAgICAgICB9XG4gICAgICAgICAgICB9XG5cbiAgICAgICAgICAgICYuaW5mbyB7XG4gICAgICAgICAgICAgICAgLm1hdC1leHBhbnNpb24tcGFuZWwtaGVhZGVyLXRpdGxlIHtcbiAgICAgICAgICAgICAgICAgICAgY29sb3I6IHZhcigtLWw3LWluZm8tNTAwLWNvbG9yKTtcbiAgICAgICAgICAgICAgICB9XG4gICAgICAgICAgICB9XG4gICAgICAgIH1cbiAgICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/explorer/src/Views/user-settings-view/UserSettingsView.scss"],"names":[],"mappings":"AAGY;EACI,eAAA;AAFhB;AAUgB;EACI,cAAA;AARpB;AAagB;EACI,+BAAA;AAXpB;AACA,giCAAgiC","sourcesContent":[":host {\n    mat-card ::ng-deep {\n        &.wrap {\n            .mat-mdc-card-header-text {\n                flex-wrap: wrap;\n            }\n        }\n    }\n\n    mat-expansion-panel ::ng-deep {\n        .mat-expansion-panel-header {\n            &.alignItemsLeft {\n                .mat-content {\n                    flex: 0 1 auto;\n                }\n            }\n\n            &.info {\n                .mat-expansion-panel-header-title {\n                    color: var(--l7-info-500-color);\n                }\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
