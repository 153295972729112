//#region Imports

import { ILeasingQuoteCreditRatingDto } from '@abcfinlab/api/global';
import { NgModule, Pipe, PipeTransform } from '@angular/core';

//#endregion

/**
 * Transforms a `ILeasingQuoteCreditRatingDto` to the related icon name or css color class.
 * @public
 */
@Pipe({
    name: 'creditCheckIcon'
})
export class CreditCheckIconPipe implements PipeTransform {

    //#region Ctor

    public constructor() {
    }

    //#endregion

    //#region Methods

    public transform(value: ILeasingQuoteCreditRatingDto, type: 'icon' | 'color'): string {
        switch (value) {
            case 'POSITIVE':
                if (type === 'color') {
                    return 'success';
                } else if (type === 'icon') {
                    return 'Check_Circle_Filled';
                } else {
                    break;
                }
            case 'APPROVED_WITH_CONDITIONS':
            case 'APPROVED_WITH_CONDITIONS_FULFILLED':
                if (type === 'color') {
                    return 'warn';
                } else if (type === 'icon') {
                    return 'Alert_Circle_Filled';
                } else {
                    break;
                }
            case 'NEGATIVE':
            case 'EXPIRED':
            case 'OPEN':
            case 'PENDING':
            case 'DISCARDED':
                if (type === 'color') {
                    return 'danger';
                } else if (type === 'icon') {
                    return 'Alert_Circle_Filled';
                } else {
                    break;
                }
            default:
                break;
        }

        throw new Error('Argument type is invalid.');
    }

    //#endregion
}

/**
 * The `CreditCheckIconPipeModule` provides the {@link CreditCheckIconPipe}.
 * @public
 */
@NgModule({
    declarations: [
        CreditCheckIconPipe
    ],
    exports: [
        CreditCheckIconPipe
    ]
})
export class CreditCheckIconPipeModule {
}
