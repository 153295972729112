import { IDocumentTypeDto } from '@abcfinlab/api/contract';
import { Component, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { environment } from '../../../../../apps/shell/src/environments/environment';
import { ByteCalculator } from '../../Helpers/ByteCalculator.class';
import { UntypedFormControl } from '@angular/forms';

export enum AllowedFileTypes {
    PDF = 'pdf',
    JPG = 'jpg',
    JPEG = 'jpeg',
    PNG = 'png'
}

export interface CustomFile extends File {
    category: IDocumentTypeDto;
}

@Component({
    selector: 'l7-file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent {
    @Input() orientation: 'horizontal' | 'vertical' = 'horizontal';
    @Input() allowedFileTypes: Array<AllowedFileTypes> = [];
    @Input() maxFiles: number;
    @Input() set useDefaultTypes(value: boolean) {
        this.useDefault = value;
        if (value) {
            this.allowedFileTypes = this.defaultFileTypes as Array<AllowedFileTypes>;
        }
    }
    @Input() showUploadIcon: boolean;
    @Input() showProgressBar: boolean;

    @Input() optionTemplate: TemplateRef<any>;

    @Input() canUpload: boolean = true;

    @Input() set clearFiles(value: boolean) {
        if (value) {
            this.files = [];
            this.canUpload = false;
            this.fileInput.nativeElement.value = '';
        }
    }

    @Input() useUploadButton: boolean;

    @Input() fileListClass: string;

    @Input() showMessage: boolean;

    @Input() set existingFiles(value: Array<CustomFile>) {
        if (value) {
            this.files = value;
        }
    }

    @Output() filesForUpload: EventEmitter<Array<CustomFile>> = new EventEmitter<Array<CustomFile>>();
    @Output() filesChanged: EventEmitter<Array<CustomFile>> = new EventEmitter<Array<CustomFile>>();

    @ViewChild('fileInput') fileInput: ElementRef;

    public readonly defaultFileTypes: Array<string> = environment.features.upload_file.file_types;
    public readonly maxFileSize: number = environment.features.upload_file.max_file_size;

    files: Array<CustomFile> = [];

    documentInput: UntypedFormControl = new UntypedFormControl();

    errorMessage: string;

    imageDataBase64: any;

    mimeType: string;

    pdfOptions = {
        withCredentials: true,
        url: null
    };

    isPdf;

    isImage;

    useDefault: boolean;

    public option: any = {
        selectCategory: ($event: MatSelectChange, index: number) => {
            this.files[index].category = $event.value;
            let hasCategory = false;
            let countInvoices = 0;
            this.files.forEach((_file, i) => {
                if (!_file.category) {
                    hasCategory = false;
                } else {
                    hasCategory = true;
                }

                if (_file.category === IDocumentTypeDto.Invoice) {
                    countInvoices++;
                }
                if (i === this.files.length - 1 && hasCategory && countInvoices <= 1) {
                    this.canUpload = true;
                }
            });
            this.filesChanged.emit(this.files);
        }
    };

    constructor() { }

    uploadFile(files: FileList) {
        this.errorMessage = '';
        if (files.length > this.maxFiles || (this.files.length + files.length) > this.maxFiles) {
            this.errorMessage = `Bitte nur ${this.maxFiles} Datei auswählen.`;
            return;
        }

        Array.from(files).forEach(_file => {
            const file = _file as CustomFile;
            this.mimeType = file.type;

            this.isImage = this.mimeType.match(/image\/*/);

            this.isPdf = this.mimeType.match(/pdf\/*/);

            if (!new RegExp(this.allowedFileTypes.join('|')).test(file.name.toLowerCase())) {
                this.errorMessage = `Nicht unterstützter Dateityp.<br />Nur ${this.allowedFileTypes.toString().replace(',', ', ')}.`;
                return;
            }

            // Variable file formats from ENV
            if (this.mimeType.match(/image|pdf\/*/) == null || !new RegExp(this.allowedFileTypes.join('|')).test(file.name.toLowerCase()) && this.useDefault) {
                this.errorMessage = 'Nicht unterstützter Dateityp.<br />Nur PDF, PNG, JP(E)G\'';
                return;
            }

            if (file.size === 0) {
                this.errorMessage = 'Die Datei ist scheinbar leer!';
                return;
            }

            if (file.size > this.maxFileSize) {
                this.errorMessage = `Die Datei ist zu groß!<br />Die maximale Dateigröße beträgt ${ByteCalculator.byteToSize(this.maxFileSize)}.`;
                return;
            }
            this.files.push(file);
            this.filesChanged.emit(this.files);
        });
    }

    uploadAttachment(index: number) {
        const file = this.files[index];
        this.filesForUpload.emit([file]);
    }

    deleteAttachment(index: number) {
        this.files.splice(index, 1);
        if (!this.files.length && this.optionTemplate) {
            this.canUpload = false;
        }
        this.filesChanged.emit(this.files);
        this.documentInput.patchValue('');
    }

    sendFiles(evt) {
        this.filesForUpload.emit(this.files);
    }
}
