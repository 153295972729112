import { Component } from '@angular/core';

@Component({
  selector: 'l7-money-laundering',
  templateUrl: './money-laundering.component.html',
  styleUrls: ['./money-laundering.component.scss']
})
export class MoneyLaunderingComponent {

  constructor() { }

}
