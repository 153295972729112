import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../../../apps/shell/src/app/shared/guards/auth.guard';
import { SettlementOverviewView } from '../Views/SettlementOverviewView';

/**
 * @internal
 */
export const SETTLEMENT_OVERVIEW_ROUTE_PATH: string = 'settlement/overview';

/**
 * @internal
 */
export const SETTLEMENT_OVERVIEW_ROUTE: Route = {
    path: SETTLEMENT_OVERVIEW_ROUTE_PATH,
    component: SettlementOverviewView,
    canActivate: [AuthGuard]
};

/**
 * @public
 */
export const SETTLEMENT_ROUTES: Routes = [
    SETTLEMENT_OVERVIEW_ROUTE
];

/**
 * @public
 */
@NgModule({
    imports: [
        RouterModule.forChild(SETTLEMENT_ROUTES)
    ],
    exports: [RouterModule]
})
export class SettlementRoutingModule {
}
