//#region Import

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Data, Route, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { RoleManager } from './RoleManager';

//#endregion

/**
 * The `RoleGuard` class.
 *
 * @public
 */
@Injectable()
export class RoleGuard implements CanActivate, CanActivateChild, CanLoad {

    //#region Fields

    private readonly _roleManager: RoleManager;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `RoleGuard` class.
     *
     * @public
     */
    public constructor(roleManager: RoleManager) {
        this._roleManager = roleManager;
    }

    //#endregion

    //#region Methods

    /**
     * @public
     */
    public canActivate(active: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.handleActivation(active.data, state.url);
    }

    /**
     * @public
     */
    public canActivateChild(active: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.handleActivation(active.data, state.url);
    }

    /**
     * @public
     */
    public canLoad(route: Route, segments: Array<UrlSegment>): boolean {
        return this.handleActivation(route.data, route.path);
    }

    /**
     * Handles the activation.
     *
     * @private
     * @param data - The route data.
     */
    private handleActivation(data?: Data, path?: string): boolean {
        if (!data) {
            throw new Error('Argument \'data\' cannot be undefined.');
        }

        if (!data.role) {
            throw new Error(`The route data property 'role' is missing for path ${path}.`);
        }

        const hasRole = this._roleManager.can(data.role);

        if (!hasRole) {
            // TODO: maybe it should throw?
            console.debug(new Error('Access is denied.').message);
        } else {
            console.debug('Access is granted.');
        }

        return hasRole;
    }

    //#endregion

}
