//#region Imports

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

//#endregion

/**
 * The `MenuGroupComponent` component.
 *
 * @public
 */
@Component({
    selector: 'l7-menu-group',
    templateUrl: './MenuGroupComponent.html',
    styleUrls: ['./MenuGroupComponent.scss'],
    exportAs: 'l7menuGroup',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuGroupComponent {

    //#region Fields

    private _legend: string;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `MenuGroupComponent` class.
     *
     * @public
     */
    public constructor() {
        this._legend = '';
    }

    //#endregion

    //#region Methods

    /**
     * Gets or sets the `text` property.
     *
     * @public
     */
    @Input()
    public get legend(): string {
        return this._legend;
    }
    public set legend(value: string) {
        this._legend = value;
    }

    //#endregion

}
