//#region Imports

import {
    IContractDocumentDto,
    IDocumentTypeDto,
    IInvoiceCompleteValidationsDto,
    IProcessingStatusDto, IProofOfPaymenRequestDto,
    ISalesChannelDto
} from '@abcfinlab/api/contract';
import { once, TranslationFacade } from '@abcfinlab/core';
import { CheckSignaturesDialogComponent } from '@abcfinlab/presentation';
import { BusyBoxService } from '@abcfinlab/ui';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SettlementDocumentsViewPresenter } from 'libs/backoffice/src/Views/SettlementDocumentsViewPresenter';
import { combineLatest, Subscription } from 'rxjs';
import { environment } from '../../../../apps/shell/src/environments/environment';
import { DocumentDialogComponent } from './Dialogs/document-dialog.component';

interface IContractDocumentWithReady extends IContractDocumentDto {
    invoiceComplete?: boolean;
    ackComplete?: boolean;
    proofOfPaymentComplete?: boolean;
    kuevComplete?: boolean;
    invoiceIncompleteReasons?: string;
}

//#endregion

/**
 * The `SettlementDocumentsView` view.
 *
 * @public
 */
@UntilDestroy()
@Component({
    selector: 'l7-invoice-documents-view',
    templateUrl: './SettlementDocumentsView.html',
    styleUrls: ['./SettlementDocumentsView.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        SettlementDocumentsViewPresenter
    ]
})
export class SettlementDocumentsView implements OnInit, OnDestroy {
    @Input() navStatus: number;

    @Input() isSlb: boolean;

    @Input() isReadonlyUser: boolean;

    @Input() salesChanel: ISalesChannelDto;

    @Input() slbLnNeu!: boolean;

    @ViewChild(MatTable, { static: true })
    documentsTable: MatTable<any>;

    //#region Fields

    private readonly _presenter: SettlementDocumentsViewPresenter;
    private readonly _dialog: MatDialog;
    private readonly _busyBox: BusyBoxService;
    private readonly _columns: Array<string>;
    private readonly _cdr: ChangeDetectorRef;
    private readonly _translationFacade: TranslationFacade;
    private documentSubscription: Subscription;
    private _dataSource: MatTableDataSource<IContractDocumentWithReady>;
    public documentsReady: boolean = false;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `SettlementDocumentsView` class.
     *
     * @public
     */
    public constructor(
        presenter: SettlementDocumentsViewPresenter,
        dialog: MatDialog,
        busyBox: BusyBoxService,
        cdr: ChangeDetectorRef,
        translationFacade: TranslationFacade
    ) {
        this._presenter = presenter;
        this._columns = ['name', 'creationDate', 'createdBy', 'type', 'actions'];
        this._dialog = dialog;
        this._busyBox = busyBox;
        this._cdr = cdr;
        this._translationFacade = translationFacade;
    }

    //#endregion

    //#region Proeprties

    /**
     * Returns the presenter of the `SettlementDocumentsView`
     *
     * @public
     * @readonly
     */
    public get presenter(): SettlementDocumentsViewPresenter {
        return this._presenter;
    }

    /**
     * Returns the `columns` property.
     *
     * @public
     * @readonly
     */
    public get columns(): Array<string> {
        return this._columns;
    }

    public get dataSource(): MatTableDataSource<IContractDocumentDto> {
        return this._dataSource;
    }

    public get download_all_files_link(): string {
        return `${environment.host}/contract-service/api/v1/contracts/documents/composed/${this.presenter.navContractNumber$.getValue()}`;
    }

    public get can_upload_files(): boolean {
        const contractStatus = this.presenter.contractStatus$.getValue();
        return (contractStatus === IProcessingStatusDto.Billable ||
            contractStatus === IProcessingStatusDto.Signed ||
            contractStatus === IProcessingStatusDto.ToBeDiscussed) && this.navStatus < 50;
    }

    //#endregion

    //#region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this.documentsReady = false;
        this._presenter.initialize();
        combineLatest([this._presenter.documents$, this._presenter.contractBillableReady$])
            .pipe(untilDestroyed(this))
            .subscribe(([x, y]) => {
                if (x && y) {
                    const documentsWithComplete: Array<IContractDocumentWithReady> = [];
                    x.forEach(doc => {
                        const docWithComplete: IContractDocumentWithReady = doc;
                        if ((doc.type === IDocumentTypeDto.Invoice || doc.type === IDocumentTypeDto.PreVendorInvoice) && doc.id) {
                            docWithComplete.invoiceComplete = y.validation_dtos.find(x => x.documentId === doc.id)
                                ? y.validation_dtos.find(x => x.documentId === doc.id).invoiceComplete : false;
                            docWithComplete.invoiceIncompleteReasons = this.translateIncompleteReasons(
                                y.validation_dtos.find(x => x.documentId === doc.id)?.validations
                            );
                        } else if (doc.type === IDocumentTypeDto.AcknowledgementSigned || doc.type === IDocumentTypeDto.AcknowledgementSignedPaper || doc.type === IDocumentTypeDto.DeliveryNote) {
                            docWithComplete.ackComplete = y.acknowledgement;
                        } else if (doc.type === IDocumentTypeDto.Kuev) {
                            docWithComplete.kuevComplete = y.slb;
                        } else if (doc.type === IDocumentTypeDto.ProofOfPayment) {
                            docWithComplete.proofOfPaymentComplete = y.proof_of_payment;
                        }
                        documentsWithComplete.push(docWithComplete);
                    });
                    this._dataSource = new MatTableDataSource<IContractDocumentWithReady>(documentsWithComplete);
                } else if (x && !y) {
                    this._dataSource = new MatTableDataSource<IContractDocumentWithReady>(x);
                }
                this.documentsReady = true;
                this._cdr.detectChanges();
            });
        this._presenter.contractStatus$
            .pipe(untilDestroyed(this))
            .subscribe(_ => {
                this._cdr.detectChanges();
            });
    }

    public fileDownload(document: IContractDocumentDto): void {
        window.open(`/gwg/pdf-view/${btoa(`${environment.host}/contract-service/api/v1/contracts/documents/${document.id}`)}`, '_blank');
    }

    public updateDocumentsTable(document: IContractDocumentDto): void {
        this.dataSource.data.push(document);
        this.documentsTable.dataSource = this.dataSource.data;
        this.documentsTable.renderRows();
        this._cdr.detectChanges();
    }

    public openSignaturesDialog(): void {
        once(this._dialog.open(CheckSignaturesDialogComponent, {
            width: '70vw',
            data: {
                contractNumber: this.presenter.navContractNumber$.getValue()
            }
        }).afterClosed());
    }

    public openFile(row: IContractDocumentDto): void {
        let isConfirmed: boolean = false;
        const data = {
            documentId: row.id,
            lesseeName: this.presenter.lessee$.getValue().name,
            navContractNumber: this.presenter.navContractNumber$.getValue(),
            type: row.type,
            isSlb: this.isSlb,
            isSlbLnNeu: this.slbLnNeu,
            status: this.presenter.contractStatus$.getValue(),
            ackData: null,
            invoice: null,
            kuevData: null,
            salesChanel: this.salesChanel,
            isConfirmed: isConfirmed
        };
        switch (row.type) {
            case IDocumentTypeDto.AcknowledgementSigned:
            case IDocumentTypeDto.AcknowledgementSignedPaper:
            case IDocumentTypeDto.DeliveryNote:
                isConfirmed = this.presenter.contractBillableReady$.getValue().acknowledgement;
                this.documentSubscription = this._busyBox.show('', '', this._presenter.getAckDocumentData()
                    .pipe(untilDestroyed(this)))
                    .subscribe(([x, y, z, w]) => {
                        if (x && y) {
                            data.ackData = {
                                ack: x, quoteDetails: y, purchaseEntry: z, navStatus: w[0]
                            };
                            this.openDialog(data);
                        }
                    });
                break;
            case IDocumentTypeDto.Invoice:
                isConfirmed = this.presenter.contractBillableReady$.getValue().validation_dtos.find(x => x.documentId === row.id)?.invoiceComplete;
                this.documentSubscription = this._busyBox.show('', '', this._presenter.getInvoiceDocumentData(row.id)
                    .pipe(untilDestroyed(this)))
                    .subscribe(([x, y, z, a, p, n, c]) => {
                        data.invoice = {
                            data: x, ocr: y, details: z, ack: a, slb: null, purchaseEntry: p, navStatus: n[0], creditCheck: c
                        };
                        this.openDialog(data);
                    });
                break;
            case IDocumentTypeDto.PreVendorInvoice:
                isConfirmed = this.presenter.contractBillableReady$.getValue().validation_dtos.find(x => x.documentId === row.id)?.invoiceComplete;
                this.documentSubscription = this._busyBox.show('', '', this._presenter.getPreVendorInvoiceDocumentData(row.id)
                    .pipe(untilDestroyed(this)))
                    .subscribe(([x, y, z, s, p, n, c]) => {
                        data.invoice = {
                            data: x, ocr: y, details: z, ack: null, slb: s, purchaseEntry: p, navStatus: n[0], creditCheck: c
                        };
                        this.openDialog(data);
                    });
                break;
            case IDocumentTypeDto.Kuev:
                isConfirmed = this.presenter.contractBillableReady$.getValue().slb;
                this.documentSubscription = this._busyBox.show('', '', this._presenter.getKuevDocumentData()
                    .pipe(untilDestroyed(this)))
                    .subscribe(([x, y, z]) => {
                        if (x && y) {
                            data.kuevData = {
                                kuev: x, quoteDetails: y, navStatus: z[0]
                            };
                            this.openDialog(data);
                        }
                    });
                break;
            case IDocumentTypeDto.ProofOfPayment:
                this.documentSubscription = this._busyBox.show('', '', this._presenter.getProofOfPaymentData(row.id)
                    .pipe(untilDestroyed(this)))
                    .subscribe((x: IProofOfPaymenRequestDto) => {
                        data.isConfirmed = x.confirmed;
                        this.openDialog(data);
                    });
                break;
        }
    }
    private openDialog(data): void {
        data = { ...data, isReadonlyUser: this.isReadonlyUser };
        const dialogRef = this._dialog.open(DocumentDialogComponent, {
            minHeight: '100%',
            width: '100vw',
            minWidth: '100vw',
            maxWidth: '100vw',
            closeOnNavigation: true,
            disableClose: false,
            data: data
        });
        dialogRef.afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this._presenter.isReady();
                this._presenter.isDigitalProcessingReady();
                this.documentSubscription.unsubscribe();
            });
    }

    private translateIncompleteReasons(reasons: IInvoiceCompleteValidationsDto): string {
        if (!reasons) {
            return '';
        }
        const reasonsNotAccomplish = Object.keys(reasons).filter(key => !reasons[key]);

        let translatedReasons = '';
        reasonsNotAccomplish.forEach(reason => {
            translatedReasons += `\n${this._translationFacade.instant(`contract_management.documents.invoice_incomplete_reasons.${reason}`)}`;
        });
        return translatedReasons;
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {
        this._presenter.dispose();
    }

    //#endregion

    protected readonly ISalesChannelDto = ISalesChannelDto;
    protected readonly IDocumentTypeDto = IDocumentTypeDto;
}
