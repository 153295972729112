import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandlerService } from '../services/errorHandler/error-handler.service';
import { LoadingService } from '../services/loading/loading.service';
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(
        private readonly _injector: Injector,
        private readonly _loadingService: LoadingService,
        private readonly _router: Router
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const _router = this._injector.get(Router);

        const _errorHandler = this._injector.get(ErrorHandlerService);
        if (_errorHandler) {
            _errorHandler.cleanUp();
        }

        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {

                if (_errorHandler.isServerError(error)) {
                    this._loadingService.setLoadingStatus(false);

                    const _error = typeof error.error === 'string' ? JSON.parse(error.error) : error.error;

                    if (error.status === 409 && _error.error === 'lead_id_attributes_not_found') {
                        return _errorHandler.handleAuthenticationError(_error);
                    }

                    if (error.status === 401) {
                        return this.handleLogin();
                    }

                    if ((_router.url === '/' ? true : false) && _errorHandler.isAuthenticationError(error)) {
                        return this.handleLogin();
                    }

                    if (_errorHandler.isAuthenticationError(error)) {
                        return _errorHandler.handleAuthenticationError(_error);
                    }
                    if (error.status === 500) {
                        return _errorHandler.handleCriticalServerError(error);
                    }
                    if (error.status === 400) {
                        if (error.url.includes('bankAccount')) {
                            return throwError(error);
                        }
                        return _errorHandler.handleBadRequestError(error);
                    }
                    return throwError(error);
                }
                _errorHandler.handleError(error);
                return throwError(error);
            })
        );
    }

    private handleLogin(): Observable<boolean> {
        void this._router.navigate(['login']);
        return of(true);
    }

}
