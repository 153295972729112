// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .function-resolve span {
  font-size: 14px;
  margin-right: 16px;
}
:host .no-margin {
  margin-bottom: 0 !important;
}
:host mat-radio-button {
  margin-right: 16px;
}
:host l7-summary {
  max-height: 54px;
  padding: 8px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIkNhbGN1bGF0aW9uRm9ybUNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVRO0VBQ0ksZUFBQTtFQUNBLGtCQUFBO0FBRFo7QUFLSTtFQUNJLDJCQUFBO0FBSFI7QUFNSTtFQUNJLGtCQUFBO0FBSlI7QUFPSTtFQUNJLGdCQUFBO0VBQ0EsWUFBQTtBQUxSIiwiZmlsZSI6IkNhbGN1bGF0aW9uRm9ybUNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICAgIC5mdW5jdGlvbi1yZXNvbHZle1xuICAgICAgICBzcGFuIHtcbiAgICAgICAgICAgIGZvbnQtc2l6ZTogMTRweDtcbiAgICAgICAgICAgIG1hcmdpbi1yaWdodDogMTZweDtcbiAgICAgICAgfVxuICAgIH1cblxuICAgIC5uby1tYXJnaW4ge1xuICAgICAgICBtYXJnaW4tYm90dG9tOiAwICFpbXBvcnRhbnQ7XG4gICAgfVxuXG4gICAgbWF0LXJhZGlvLWJ1dHRvbiB7XG4gICAgICAgIG1hcmdpbi1yaWdodDogMTZweDtcbiAgICB9XG5cbiAgICBsNy1zdW1tYXJ5IHtcbiAgICAgICAgbWF4LWhlaWdodDogNTRweDtcbiAgICAgICAgcGFkZGluZzogOHB4O1xuICAgIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/quote/src/Components/calculation-form/CalculationFormComponent.scss"],"names":[],"mappings":"AAEQ;EACI,eAAA;EACA,kBAAA;AADZ;AAKI;EACI,2BAAA;AAHR;AAMI;EACI,kBAAA;AAJR;AAOI;EACI,gBAAA;EACA,YAAA;AALR;AACA,g0BAAg0B","sourcesContent":[":host {\n    .function-resolve{\n        span {\n            font-size: 14px;\n            margin-right: 16px;\n        }\n    }\n\n    .no-margin {\n        margin-bottom: 0 !important;\n    }\n\n    mat-radio-button {\n        margin-right: 16px;\n    }\n\n    l7-summary {\n        max-height: 54px;\n        padding: 8px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
