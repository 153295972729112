import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';

@Component({
  selector: 'l7-privacy-notice',
  templateUrl: './privacy-notice.component.html',
  styleUrls: ['./privacy-notice.component.scss']
})
export class PrivacyNoticeComponent implements AfterViewInit {

  @ViewChild('expansionPanel', { static: true }) expansionPanel: MatAccordion;

  constructor() { }

  ngAfterViewInit() {
    this.expansionPanel.openAll();
  }

}
