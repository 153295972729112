//#region Imports

import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ControlsOf } from '@abcfinlab/core';
import {
    IContactDto,
    IContractTypeDto,
    IInsuranceAndHandlingFeeDto,
    IInsuranceAndHandlingFeeRequestDto,
    IInsuranceTypeDto,
    IObjectGroupDto,
    ISaleAndLeaseBackCodeDto
} from '@abcfinlab/api/global';
import { IInsuranceAndSlbExtraFields } from '../../Views/calculation/CalculationViewPresenter';
import { MatSelectChange } from '@angular/material/select';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { hundredProMustBeDisabled } from '../../../../../apps/shell/src/app/helper/calculation.helper';

//#endregion

/**
 * The InsuranceAndHandlingFeeComponent component.
 *
 * @public
 */
@Component({
    selector: 'l7-insurance-and-handling-fee',
    templateUrl: './InsuranceAndHandlingFeeComponent.html',
    styleUrls: ['./InsuranceAndHandlingFeeComponent.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsuranceAndHandlingFeeComponent implements OnInit, OnDestroy {
    private readonly _objectGroupsWith100Pro: Array<number> = [2110, 2111, 2112, 2113, 2114, 2115, 2120, 2121, 2122, 2130, 2131, 2132, 2140, 2141];
    private _contractType: IContractTypeDto;
    private _selectedObjectGroup: IObjectGroupDto;
    private _totalLeasingValue: number;

    private _hasUserSetInsuranceToNo: boolean = false;

    private _insuranceAndHandlingFeeResponse: IInsuranceAndHandlingFeeDto;

    protected readonly insuranceTypes: Array<IInsuranceTypeDto> = [IInsuranceTypeDto.Pro100, IInsuranceTypeDto.Standard];
    //#region Fields
    @Input() form: FormGroup<ControlsOf<IInsuranceAndHandlingFeeRequestDto>>;

    @Input() insuranceAndSlbForm: FormGroup<ControlsOf<IInsuranceAndSlbExtraFields>>;

    @Input() set selectedObjectGroup(value: IObjectGroupDto) {
        this._selectedObjectGroup = value;
        if (value) {
            this.insuranceAndSlbForm.controls.insurance.disable( {emitEvent: false});
        }
    }

    public get selectedObjectGroup(): IObjectGroupDto {
        return this._selectedObjectGroup;
    }

    @Input() lessee: IContactDto;

    @Input() set contractType(value: IContractTypeDto) {
        if (!value) {
            return;
        }
        this._contractType = value;
        this._hasUserSetInsuranceToNo = false;
        switch (value) {
            case IContractTypeDto.Va:
            case IContractTypeDto.Ta:
                if (!this.selectedObjectGroup?.insurance_value) {
                    this.form.controls.insuranceType.patchValue(IInsuranceTypeDto.No);
                } else {
                    this.form.controls.insuranceType.patchValue(IInsuranceTypeDto.Standard);
                }
                break;
            case IContractTypeDto.Kfz:
                this.form.controls.insuranceType.patchValue(IInsuranceTypeDto.Pro100);
                break;
            case IContractTypeDto.Mietkauf:
            case IContractTypeDto.Mkn:
                this.form.controls.insuranceType.patchValue(IInsuranceTypeDto.No);
                break;
            default:
                this.form.controls.insuranceType.patchValue(IInsuranceTypeDto.No);
                break;
        }
        this.insuranceAndSlbForm.controls.insurance.patchValue(false, {emitEvent: false});
        this.insuranceAndSlbForm.controls.insurance.disable({emitEvent: false});
        if (value !== IContractTypeDto.Mkn) {
            this.insuranceAndSlbForm.controls.slb.patchValue(false, {emitEvent: false});
            this.resetSlbCode();
        } else {
            this.insuranceAndSlbForm.controls.slb.patchValue(true, {emitEvent: false});
            this.insuranceAndSlbForm.controls.slbCode.enable({emitEvent: false});
            this.insuranceAndSlbForm.controls.slbCode.patchValue(null, {emitEvent: false});
            this.insuranceAndSlbForm.controls.slbCode.setValidators(Validators.required);
            this.insuranceAndSlbForm.controls.slbCode.updateValueAndValidity();
        }
    }
    public get contractType(): IContractTypeDto {
        return this._contractType;
    }

    @Input() set insuranceAndHandlingFeeResponse(value: IInsuranceAndHandlingFeeDto) {
        this._insuranceAndHandlingFeeResponse = value;
        if (value && value.insuranceValue && value.insuranceType === IInsuranceTypeDto.Standard) {
            this.form.controls.insuranceValue.setValidators([Validators.required, Validators.min(value.insuranceValueMin), Validators.max(value.insuranceValueMax)]);
        } else if (value && value.insuranceValue && value.insuranceType === IInsuranceTypeDto.Pro100) {
            this.form.controls.insuranceValue.setValidators([Validators.required, Validators.min(value.insuranceValueMin)]);
        } else {
            this.form.controls.insuranceValue.clearValidators();
        }

        this.form.controls.insuranceValue.updateValueAndValidity();
    }

    public get insuranceAndHandlingFeeResponse(): IInsuranceAndHandlingFeeDto {
        return this._insuranceAndHandlingFeeResponse;
    }

    @Input() slbCodes: Array<ISaleAndLeaseBackCodeDto>;

    @Input() smbCodes: Array<ISaleAndLeaseBackCodeDto>;

    @Input() set totalLeasingValue(value: number){
        if (!value) {
            return;
        }
        this._totalLeasingValue = value;
        switch (this.contractType) {
            case IContractTypeDto.Kfz:
                if (value && !hundredProMustBeDisabled(value,this.selectedObjectGroup.code)) {
                    this.insuranceAndSlbForm.controls.insurance.enable({emitEvent: false});
                    if (!this._hasUserSetInsuranceToNo) {
                        this.insuranceAndSlbForm.controls.insurance.patchValue(true, {emitEvent: false});
                        this.form.controls.insuranceType.patchValue(IInsuranceTypeDto.Pro100, {emitEvent: false});
                    }
                }
                break;
            case IContractTypeDto.Va:
            case IContractTypeDto.Ta:
                if (value && this.selectedObjectGroup?.insurance_value !== 0) {
                    this.insuranceAndSlbForm.controls.insurance.enable({emitEvent: false});
                    if (!this._hasUserSetInsuranceToNo) {
                        this.insuranceAndSlbForm.controls.insurance.patchValue(true, {emitEvent: false});
                        this.form.controls.insuranceType.patchValue(IInsuranceTypeDto.Standard, {emitEvent: false});
                    }
                }
                break;
            case IContractTypeDto.Mietkauf:
            case IContractTypeDto.Mkn:
                if (value && this.isInsuranceDisabled()) {
                   this.insuranceAndSlbForm.controls.insurance.disable({emitEvent: false});
                    this.insuranceAndSlbForm.controls.insurance.patchValue(false, {emitEvent: false});
                    this.form.controls.insuranceType.patchValue(IInsuranceTypeDto.No, {emitEvent: false});
                    this.form.controls.insuranceValue.patchValue(null, {emitEvent: false});
                }
                break;
        }
    }

    public get totalLeasingValue(): number {
        return this._totalLeasingValue;
    }

    @Input() set insuranceAndHandlingFeeFromOriginalQuote(value: IInsuranceAndHandlingFeeDto) {
        if (!value) {
            return;
        }

        this.form.controls.insuranceValue.patchValue(value.insuranceValue, {emitEvent: false});
        this.form.controls.handlingFee.patchValue(value.handlingFee, {emitEvent: false});
        this.form.controls.handlingFeeValue.patchValue(value.handlingFeeValue, {emitEvent: false});
        switch (this.contractType) {
            case IContractTypeDto.Kfz:
                if (!hundredProMustBeDisabled(this._totalLeasingValue, this.selectedObjectGroup.code)) {
                    this.insuranceAndSlbForm.controls.insurance.enable({emitEvent: false});
                }
                break;
            case IContractTypeDto.Va:
            case IContractTypeDto.Ta:
                if (this.selectedObjectGroup?.insurance_value !== 0) {
                    this.insuranceAndSlbForm.controls.insurance.enable({emitEvent: false});
                }
                break;
            case IContractTypeDto.Mietkauf:
            case IContractTypeDto.Mkn:
                if (this.isInsuranceDisabled()) {
                    this.insuranceAndSlbForm.controls.insurance.disable({emitEvent: false});
                } else {
                    this.insuranceAndSlbForm.controls.insurance.enable({emitEvent: false});
                }
                break;
        }
        const hasNoInsurance = value.insuranceType === null || value.insuranceType === IInsuranceTypeDto.No ? true : false;
        if (hasNoInsurance) {
            this.insuranceAndSlbForm.controls.insurance.patchValue(false, {emitEvent: false});
            this.form.controls.insuranceType.disable({emitEvent: false});
            this.form.controls.insuranceType.patchValue(IInsuranceTypeDto.No, {emitEvent: false});
        } else {
            this.insuranceAndSlbForm.controls.insurance.patchValue(true, {emitEvent: false});
            this.form.controls.insuranceType.enable({emitEvent: false});
            this.form.controls.insuranceType.patchValue(value.insuranceType, {emitEvent: false});
        }
    }
    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the insuranceAndHandlingFeeComponent class.
     *
     * @public
     */
    public constructor() {

    }

    //#endregion

    //#region Properties

    //#endregion

    //#region Methods

    /**
     * @internal
     */
    public ngOnInit(): void {
        this.form.controls.insuranceValue.valueChanges.subscribe(value => {
            if (value && (this.form.controls.insuranceType.getRawValue() === IInsuranceTypeDto.Standard || this.form.controls.insuranceType.getRawValue() === IInsuranceTypeDto.Pro100)) {
               this.insuranceAndSlbForm.controls.insurance.enable({emitEvent: false});
               this.insuranceAndSlbForm.controls.insurance.patchValue(true, {emitEvent: false});
               this.form.controls.insuranceValue.enable({emitEvent: false});
               return;
            }

            if ((this.contractType === IContractTypeDto.Mietkauf || this.contractType === IContractTypeDto.Mkn) && this._totalLeasingValue && !this.isInsuranceDisabled()) {
                this.insuranceAndSlbForm.controls.insurance.enable({emitEvent: false});
                return;
            }

            if (this.isInsuranceDisabled()) {
                this.insuranceAndSlbForm.controls.insurance.disable({emitEvent: false});
            }
        });
    }

    public handleInsuranceSelection(evt: MatSelectChange | MatSlideToggleChange): void {
        if (evt instanceof MatSelectChange) {
            this.form.controls.insuranceType.patchValue(evt.value);
            return;
        }
        if (evt.checked && (this.contractType === IContractTypeDto.Va || this.contractType === IContractTypeDto.Ta)) {
            this.form.controls.insuranceType.patchValue(IInsuranceTypeDto.Standard);
            if (this.selectedObjectGroup?.insurance_value !== 0) {
                this.form.controls.insuranceValue.enable({emitEvent: false});
            }
            return;
        }
        if (evt.checked && this.contractType === IContractTypeDto.Kfz) {
            this.form.controls.insuranceType.patchValue(IInsuranceTypeDto.Pro100);
            return;
        }

        if (evt.checked && (this.contractType === IContractTypeDto.Mietkauf || this.contractType === IContractTypeDto.Mkn)) {
            this.form.controls.insuranceType.enable({emitEvent: false});
            return;
        }
        this._hasUserSetInsuranceToNo = true;
        this.form.controls.insuranceValue.disable({emitEvent: false});
        this.form.controls.insuranceValue.patchValue(null);
        this.form.controls.insuranceValue.clearValidators();
        this.form.controls.insuranceValue.updateValueAndValidity();
        this.form.controls.insuranceType.patchValue(IInsuranceTypeDto.No);
        this.form.controls.insuranceType.disable({emitEvent: false});
    }

    public insuranceTypeDisabled(type: IInsuranceTypeDto): boolean {
        return (type === IInsuranceTypeDto.Standard && this.selectedObjectGroup?.insurance_value === 0) ||
            (
                type === IInsuranceTypeDto.Pro100 &&
                (hundredProMustBeDisabled(this.totalLeasingValue, this.selectedObjectGroup.code) ||
                    !this._objectGroupsWith100Pro.includes(this.selectedObjectGroup.code))
            );
    }

    public toggleHandlingFee(evt: MatSlideToggleChange): void {
        if (evt.checked) {
            this.form.controls.handlingFeeValue.patchValue(200);
            this.form.controls.handlingFeeValue.enable({emitEvent: false});
            return;
        }
        this.form.controls.handlingFeeValue.patchValue(0);
        this.form.controls.handlingFeeValue.disable({emitEvent: false});
    }

    public toggleSlb(evt: MatSlideToggleChange): void {
        if (evt.checked) {
            this.insuranceAndSlbForm.controls.slbCode.enable({emitEvent: false});
            this.insuranceAndSlbForm.controls.slbCode.setValidators(Validators.required);
            this.insuranceAndSlbForm.controls.slbCode.updateValueAndValidity();
            return;
        }
        this.resetSlbCode();
    }

    /**
     * @internal
     */
    public ngOnDestroy(): void {

    }

    private isInsuranceDisabled(): boolean {
        return ((this.contractType === IContractTypeDto.Ta || this.contractType === IContractTypeDto.Va) && this.selectedObjectGroup.insurance_value === 0) ||
            (this.contractType === IContractTypeDto.Kfz && (!this._objectGroupsWith100Pro.includes(this.selectedObjectGroup.code) || hundredProMustBeDisabled(this.totalLeasingValue, this.selectedObjectGroup.code))) ||
            ((this.contractType === IContractTypeDto.Mietkauf || this.contractType === IContractTypeDto.Mkn) && this.selectedObjectGroup.insurance_value === 0 && (!this._objectGroupsWith100Pro.includes(this.selectedObjectGroup.code) || hundredProMustBeDisabled(this.totalLeasingValue, this.selectedObjectGroup.code)));
    }

    private resetSlbCode(): void {
        this.insuranceAndSlbForm.controls.slbCode.disable({emitEvent: false});
        this.insuranceAndSlbForm.controls.slbCode.patchValue(null, {emitEvent: false});
        this.insuranceAndSlbForm.controls.slbCode.clearValidators();
        this.insuranceAndSlbForm.controls.slbCode.updateValueAndValidity();
    }

    //#endregion
}
