import { IRetailerAdminConfigDto, RetailerAdminService } from '@abcfinlab/api/retailer';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RetailerAdminConfigResolver implements Resolve<IRetailerAdminConfigDto> {

    constructor(
        private readonly _retailerAdminService: RetailerAdminService
    ) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IRetailerAdminConfigDto> {
        return this._retailerAdminService.getRetailerAdminConfig().pipe(
            catchError(throwError as any)
        );
    }

}
