// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
  height: 100%;
  display: flex;
}
:host mat-card {
  color: var(--l7-grey-800-color);
}
:host mat-card .hour {
  font-size: 3.5em;
  font-weight: bold;
}
:host mat-card .minute {
  font-size: 3.5em;
  font-weight: bold;
}
:host mat-card .designator {
  font-size: 3.5em;
  font-weight: bold;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIkNsb2NrV2lkZ2V0Vmlldy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksV0FBQTtFQUNBLFlBQUE7RUFDQSxhQUFBO0FBQ0o7QUFDSTtFQUNJLCtCQUFBO0FBQ1I7QUFDUTtFQUNJLGdCQUFBO0VBQ0EsaUJBQUE7QUFDWjtBQUVRO0VBQ0ksZ0JBQUE7RUFDQSxpQkFBQTtBQUFaO0FBR1E7RUFDSSxnQkFBQTtFQUNBLGlCQUFBO0FBRFoiLCJmaWxlIjoiQ2xvY2tXaWRnZXRWaWV3LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XG4gICAgd2lkdGg6IDEwMCU7XG4gICAgaGVpZ2h0OiAxMDAlO1xuICAgIGRpc3BsYXk6IGZsZXg7XG5cbiAgICBtYXQtY2FyZCB7XG4gICAgICAgIGNvbG9yOiB2YXIoLS1sNy1ncmV5LTgwMC1jb2xvcik7XG5cbiAgICAgICAgLmhvdXIge1xuICAgICAgICAgICAgZm9udC1zaXplOiAzLjVlbTtcbiAgICAgICAgICAgIGZvbnQtd2VpZ2h0OiBib2xkO1xuICAgICAgICB9XG5cbiAgICAgICAgLm1pbnV0ZSB7XG4gICAgICAgICAgICBmb250LXNpemU6IDMuNWVtO1xuICAgICAgICAgICAgZm9udC13ZWlnaHQ6IGJvbGQ7XG4gICAgICAgIH1cblxuICAgICAgICAuZGVzaWduYXRvciB7XG4gICAgICAgICAgICBmb250LXNpemU6IDMuNWVtO1xuICAgICAgICAgICAgZm9udC13ZWlnaHQ6IGJvbGQ7XG4gICAgICAgIH1cbiAgICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/explorer/src/Views/Widgets/ClockWidgetView.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,aAAA;AACJ;AACI;EACI,+BAAA;AACR;AACQ;EACI,gBAAA;EACA,iBAAA;AACZ;AAEQ;EACI,gBAAA;EACA,iBAAA;AAAZ;AAGQ;EACI,gBAAA;EACA,iBAAA;AADZ;AACA,g+BAAg+B","sourcesContent":[":host {\n    width: 100%;\n    height: 100%;\n    display: flex;\n\n    mat-card {\n        color: var(--l7-grey-800-color);\n\n        .hour {\n            font-size: 3.5em;\n            font-weight: bold;\n        }\n\n        .minute {\n            font-size: 3.5em;\n            font-weight: bold;\n        }\n\n        .designator {\n            font-size: 3.5em;\n            font-weight: bold;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
