// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  padding-top: 16px;
}
:host .mat-mdc-radio-button {
  margin-right: 24px;
}
:host .strikethrough {
  text-decoration-line: line-through;
}
:host .ready-container l7-text-block {
  color: #5e6f79;
}
:host .ready-container mat-icon {
  color: #54ed9c;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIkNvbnRyYWN0T3ZlcnZpZXdBY2tub3dsZWRnZW1lbnRWaWV3LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxjQUFBO0VBQ0EsaUJBQUE7QUFDSjtBQUNJO0VBQ0ksa0JBQUE7QUFDUjtBQUVJO0VBQ0ksa0NBQUE7QUFBUjtBQUlRO0VBQ0ksY0FBQTtBQUZaO0FBSVE7RUFDSSxjQUFBO0FBRloiLCJmaWxlIjoiQ29udHJhY3RPdmVydmlld0Fja25vd2xlZGdlbWVudFZpZXcuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgICBkaXNwbGF5OiBibG9jaztcbiAgICBwYWRkaW5nLXRvcDogMTZweDtcblxuICAgIC5tYXQtbWRjLXJhZGlvLWJ1dHRvbiB7XG4gICAgICAgIG1hcmdpbi1yaWdodDogMjRweDtcbiAgICB9XG5cbiAgICAuc3RyaWtldGhyb3VnaCB7XG4gICAgICAgIHRleHQtZGVjb3JhdGlvbi1saW5lOiBsaW5lLXRocm91Z2g7XG4gICAgfVxuXG4gICAgLnJlYWR5LWNvbnRhaW5lciB7XG4gICAgICAgIGw3LXRleHQtYmxvY2sge1xuICAgICAgICAgICAgY29sb3I6ICM1ZTZmNzk7XG4gICAgICAgIH1cbiAgICAgICAgbWF0LWljb24ge1xuICAgICAgICAgICAgY29sb3I6ICM1NGVkOWM7XG4gICAgICAgIH1cbiAgICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/contractManagement/src/Views/ContractOverviewAcknowledgementView.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,iBAAA;AACJ;AACI;EACI,kBAAA;AACR;AAEI;EACI,kCAAA;AAAR;AAIQ;EACI,cAAA;AAFZ;AAIQ;EACI,cAAA;AAFZ;AACA,o4BAAo4B","sourcesContent":[":host {\n    display: block;\n    padding-top: 16px;\n\n    .mat-mdc-radio-button {\n        margin-right: 24px;\n    }\n\n    .strikethrough {\n        text-decoration-line: line-through;\n    }\n\n    .ready-container {\n        l7-text-block {\n            color: #5e6f79;\n        }\n        mat-icon {\n            color: #54ed9c;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
