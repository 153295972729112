import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

@Component({
    selector: 'l7-logo-upload',
    templateUrl: './logo-upload.component.html',
    styleUrls: ['./logo-upload.component.css']
})
export class LogoUploadComponent {

    private _imageChangedEvent: any = '';
    private _croppedFile: Blob;
    private _scale: number = 1;
    public transform: ImageTransform = {};

    public constructor(public dialogRef: MatDialogRef<LogoUploadComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    public onCancel(): void {
        this.dialogRef.close(false);
    }

    public onConfirmUpload(): void {
        this.dialogRef.close({ croppedImageFile: this._croppedFile });
    }

    public fileChangeEvent(event: any): void {
        this._imageChangedEvent = event;
    }

    public imageCropped(event: ImageCroppedEvent): void {
        this._croppedFile = event.blob

    }

    public loadImageFailed(): void {
        // show message
    }

    public zoomOut(): void {
        this._scale -= 0.1;
        this.transform = {
            ...this.transform,
            scale: this._scale
        };
    }

    public zoomIn(): void {
        this._scale += 0.1;
        this.transform = {
            ...this.transform,
            scale: this._scale
        };
    }
}
