import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CameraService } from '../camera.service';

@UntilDestroy()
@Component({
    selector: 'l7-camera-stream',
    templateUrl: './camera-stream.component.html',
    styleUrls: ['./camera-stream.component.scss']
})
export class CameraStreamComponent implements OnInit {

    @ViewChild('videoElement', { static: true }) videoElement: ElementRef<HTMLVideoElement>;
    @ViewChild('canvasElement', { static: true }) canvasElement: ElementRef<HTMLCanvasElement>;
    @ViewChild('containerElement', { static: true }) containerElement: ElementRef<HTMLDivElement>;
    @ViewChild('placeholderElement', { static: true }) placeholderElement: ElementRef<HTMLDivElement>;
    @Output() evStreamReady: EventEmitter<boolean> = new EventEmitter<boolean>();

    public capturedImage: string;
    public autoplay: boolean;

    constructor(private readonly _cameraService: CameraService) {
        this.autoplay = false;
    }

    get video(): HTMLVideoElement {
        return this.videoElement.nativeElement;
    }

    get canvas(): HTMLCanvasElement {
        return this.canvasElement.nativeElement;
    }

    get container(): HTMLDivElement {
        return this.containerElement.nativeElement;
    }

    get placeholder(): HTMLDivElement {
        return this.placeholderElement.nativeElement;
    }

    ngOnInit() {
        this._cameraService.getVideoStream()
            .pipe(untilDestroyed(this))
            .subscribe(stream => {
                this.video.srcObject = stream;
                this.video.play().then(() => {
                    this.evStreamReady.emit(true);
                });
            }, err => this._handleCameraFailure(err));
    }

    private _handleCameraFailure(err: Error): void {
        alert('Camera failed');
        console.error(err.message);
    }

    /**
   * Return an image as a base64 representation
   */
    public captureImage(): string {
        this.canvas.getContext('2d').drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);
        const base64ImageString: string = this.canvas.toDataURL('image/jpeg');
        this.capturedImage = base64ImageString;
        return base64ImageString;
    }

    /**
   * Reset the taken image
   */
    public resetImage(): void {
        this.capturedImage = null;
        this.canvas.getContext('2d').clearRect(0, 0, this.canvas.width, this.canvas.height);
    }

}
