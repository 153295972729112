import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { TranslationFacade } from '../../../../../../../libs/core/src/Index';

/**
 * @deprecated - Please use ToastService instead.
 */
@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    private readonly errorSnackbarConfig: MatSnackBarConfig;
    private readonly successSnackbarConfig: MatSnackBarConfig;
    private readonly infoSnackbarConfig: MatSnackBarConfig;
    private readonly _snackbars: Array<MatSnackBarRef<SimpleSnackBar>> = [];

    constructor(public snackbar: MatSnackBar, private readonly _translationFacade: TranslationFacade) {
        this.errorSnackbarConfig = {
            horizontalPosition: 'center',
            duration: 10000,
            panelClass: 'error'
        };

        this.successSnackbarConfig = {
            horizontalPosition: 'center',
            duration: 2500,
            panelClass: 'success'
        };

        this.infoSnackbarConfig = {
            horizontalPosition: 'right',
            duration: 2500,
            panelClass: 'info'
        };
    }

    public showSuccess(message: string, action = '', config: MatSnackBarConfig = this.successSnackbarConfig): void {
        const translation = this.getTranslation(message);
        const ref = this.snackbar.open(translation, action, config);
        this._snackbars.push(ref);
    }

    public showError(message: string, action = '', config: MatSnackBarConfig = this.errorSnackbarConfig): void {
        const translation = this.getTranslation(message);
        const ref = this.snackbar.open(translation, action, config);
        this._snackbars.push(ref);
    }

    public showInfo(message: string, action = '', config: MatSnackBarConfig = this.infoSnackbarConfig): void {
        const translation = this.getTranslation(message);
        const ref = this.snackbar.open(translation, action, config);
        this._snackbars.push(ref);
    }

    public getTranslation(key: string): string {
        return this._translationFacade.instant(key);
    }

    public getLatest(): MatSnackBarRef<SimpleSnackBar> {
        return this._snackbars[0];
    }

}
