// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host mat-form-field {
  width: 100%;
  height: 100%;
}
:host l7-text-block ~ mat-accordion {
  margin-top: 16px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIk1lc3NhZ2VCb3hDb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDSTtFQUNJLFdBQUE7RUFDQSxZQUFBO0FBQVI7QUFHSTtFQUNJLGdCQUFBO0FBRFIiLCJmaWxlIjoiTWVzc2FnZUJveENvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICAgIG1hdC1mb3JtLWZpZWxkIHtcbiAgICAgICAgd2lkdGg6IDEwMCU7XG4gICAgICAgIGhlaWdodDogMTAwJTtcbiAgICB9XG5cbiAgICBsNy10ZXh0LWJsb2NrIH4gbWF0LWFjY29yZGlvbiB7XG4gICAgICAgIG1hcmdpbi10b3A6IDE2cHg7XG4gICAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/ui/src/Controls/Components/MessageBox/MessageBoxComponent.scss"],"names":[],"mappings":"AACI;EACI,WAAA;EACA,YAAA;AAAR;AAGI;EACI,gBAAA;AADR;AACA,4fAA4f","sourcesContent":[":host {\n    mat-form-field {\n        width: 100%;\n        height: 100%;\n    }\n\n    l7-text-block ~ mat-accordion {\n        margin-top: 16px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
