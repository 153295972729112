import { UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function minDateValidator(value: Date): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
        if (control.value === null) {
            return null;
        }
        const controlDate = new Date(control.value).setHours(0, 0, 0, 0);

        return controlDate >= value.setHours(0, 0, 0, 0) ? null : {
            min: true
        };
    };
}
