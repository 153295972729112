//#region Imports

import { ContractsDeprecatedService, IContractManagementDetailsDto, ICreditCheckDto } from '@abcfinlab/api/global';
import {
    AcknowledgementService,
    ContractFunctionService,
    ContractsDocumentService,
    IAcknowledgementWithoutSigningDto,
    IContactDto,
    IContractDocumentDto,
    IContractInfoDto,
    IContractsBillableReadyDto,
    IDigitalProcessingDto,
    IInvoiceDto,
    IInvoiceOcrDto,
    IProcessingStatusDto,
    IProofOfPaymenRequestDto,
    IPurchaseEntryDto,
    ISaleAndLeaseBackCodeDto,
    ISearchContractResultDto,
    ISlbGetResponseDto,
    PurchaseEntryFunctionService,
    SlbService
} from '@abcfinlab/api/contract';
import { EventHub } from '@abcfinlab/core';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ISettlementSendToAccount } from 'libs/backoffice/src/Models/ISettlementSendToAccount';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { KnownEvents } from '../Models/KnownEvents';
import { map } from 'rxjs/operators';
import { ISettlementContractInformationInfo } from 'libs/backoffice/src/Models/ISettlementContractInformationInfo';

//#endregion

/**
 * The presenter of the @{link SettlementDocumentsView} view.
 *
 * @internal
 */
@UntilDestroy()
@Injectable()
export class SettlementDocumentsViewPresenter {

    //#region Fields

    private readonly _eventHub: EventHub;
    private readonly _ackService: AcknowledgementService;
    private readonly _contractFunctionService: ContractFunctionService;
    private readonly _contractsDeprecatedService: ContractsDeprecatedService;
    private readonly _purchaseService: PurchaseEntryFunctionService;
    private readonly _contractsDocumentService: ContractsDocumentService;
    private readonly _slbService: SlbService;
    private readonly _creditCheck: BehaviorSubject<ICreditCheckDto> = new BehaviorSubject<ICreditCheckDto>(null);

    public documents$: BehaviorSubject<Array<IContractDocumentDto>> = new BehaviorSubject<Array<IContractDocumentDto>>([]);

    public quoteId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    public lessee$: BehaviorSubject<IContactDto> = new BehaviorSubject<IContactDto>(null);

    public navContractNumber$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    public contractStatus$: BehaviorSubject<IProcessingStatusDto> = new BehaviorSubject<IProcessingStatusDto>(null);

    public contractBillableReady$: BehaviorSubject<IContractsBillableReadyDto> = new BehaviorSubject<IContractsBillableReadyDto>(null);

    public canEditPreVendorInvoice$: BehaviorSubject<boolean> = new BehaviorSubject(null);

    public canEditProofOfPayment: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    //#endregion

    //#region Ctor

    /**
    * Constructs a new instance of the `SettlementDocumentsViewPresenter` class.
    *
    * @public
    */
    public constructor(
        eventHub: EventHub,
        ackService: AcknowledgementService,
        contractFunctionService: ContractFunctionService,
        contractsDeprecatedService: ContractsDeprecatedService,
        purchaseService: PurchaseEntryFunctionService,
        contractsDocumentService: ContractsDocumentService,
        slbService: SlbService
    ) {
        this._eventHub = eventHub;
        this._ackService = ackService;
        this._contractFunctionService = contractFunctionService;
        this._contractsDeprecatedService = contractsDeprecatedService;
        this._purchaseService = purchaseService;
        this._contractsDocumentService = contractsDocumentService;
        this._slbService = slbService;
    }

    //#endregion

    //#region Properties

    //#endregion

    //#region Methods

    /**
    * Called before the view first displays the data-bound properties and sets the view's input properties.
    *
    * @internal
    */
    public initialize(): void {
        combineLatest([
            this._eventHub.getEvent<ISearchContractResultDto>(KnownEvents.SETTLEMENT_CHANGED),
            this._eventHub.getEvent<ISettlementContractInformationInfo>(KnownEvents.SETTLEMENT_DETAIL_LOADED)
        ]).pipe(untilDestroyed(this)).subscribe(([_x, _y]) => {
            this.onRowSelected(_x);
            this._creditCheck.next(_y.creditCheck);
        });
        this._eventHub.getEvent<Array<IContractDocumentDto>>(KnownEvents.SETTLEMENT_DOCUMENTS)
            .pipe(untilDestroyed(this))
            .subscribe(_x => this.documents$.next(_x));
        this._eventHub.getEvent<IContractsBillableReadyDto>(KnownEvents.SETTLEMENT_BILLABLE_READY)
            .pipe(untilDestroyed(this))
            .subscribe(x => this.contractBillableReady$.next(x));
        this._eventHub.getEvent<ISettlementSendToAccount>(KnownEvents.SETTLEMENT_SEND_TO_ACCOUNTANT_SUCCEED)
            .pipe(untilDestroyed(this))
            .subscribe(_x => {
                if (this.navContractNumber$.getValue() === _x.contractNumber) {
                    this.contractStatus$.next(_x.status);
                }
            });
    }

    /**
    * Called before the view will be destroyed.
    * Unsubscribe Observables and detach event handlers to avoid memory leaks.
    *
    * @internal
    */
    public dispose(): void {

    }

    public getAckDocumentData(): Observable<[
        ack: IAcknowledgementWithoutSigningDto,
        quoteDetails: IContractManagementDetailsDto,
        purchaseEntry: IPurchaseEntryDto,
        navContractStatus: Array<IContractInfoDto>
    ]> {
        return combineLatest([
            this._ackService.readByContractNumber({ contractNumber: this.navContractNumber$.getValue() }),
            this._contractsDeprecatedService.getContractDetails({ contractNumber: this.navContractNumber$.getValue() }),
            this._purchaseService.getPurchaseEntryForQuoteId({ quoteId: this.quoteId$.getValue() }),
            this._contractFunctionService.getContractStatus({ contractNumbers: [this.navContractNumber$.getValue()] })
        ]);
    }
    public getProofOfPaymentData(documentId: string): Observable<IProofOfPaymenRequestDto>{
        return this._contractsDocumentService.getPopData({ documentId: documentId })
            .pipe(untilDestroyed(this), map(x => x));
    }
    public getInvoiceDocumentData(documentId: string): Observable<[
        invoice: IInvoiceDto,
        invoiceOcr: IInvoiceOcrDto,
        details: IContractManagementDetailsDto,
        ack: IAcknowledgementWithoutSigningDto,
        purchaseEntry: IPurchaseEntryDto,
        navContractStatus: Array<IContractInfoDto>,
        creditCheck: ICreditCheckDto
    ]> {
        // @ts-ignore
        return combineLatest([
            this._contractsDocumentService.getInvoiceData({ documentId }),
            this._contractsDocumentService.getInvoiceOcr({ documentId }),
            this._contractsDeprecatedService.getContractDetails({ contractNumber: this.navContractNumber$.getValue() }),
            this._ackService.readByContractNumber({ contractNumber: this.navContractNumber$.getValue() }),
            this._purchaseService.getPurchaseEntryForQuoteId({ quoteId: this.quoteId$.getValue() }),
            this._contractFunctionService.getContractStatus({ contractNumbers: [this.navContractNumber$.getValue()] }),
            this._creditCheck.asObservable()
        ]);
    }
    public getPreVendorInvoiceDocumentData(documentId: string): Observable<[
        invoice: IInvoiceDto,
        invoiceOcr: IInvoiceOcrDto,
        details: IContractManagementDetailsDto,
        slb: ISlbGetResponseDto,
        purchaseEntry: IPurchaseEntryDto,
        navContractStatus: Array<IContractInfoDto>,
        creditCheck: ICreditCheckDto
    ]> {
        // @ts-ignore
        return combineLatest([
            this._contractsDocumentService.getInvoiceData({ documentId }),
            this._contractsDocumentService.getInvoiceOcr({ documentId }),
            this._contractsDeprecatedService.getContractDetails({ contractNumber: this.navContractNumber$.getValue() }),
            this._slbService.getSlb({ contractId: this.navContractNumber$.getValue() }),
            this._purchaseService.getPurchaseEntryForQuoteId({ quoteId: this.quoteId$.getValue() }),
            this._contractFunctionService.getContractStatus({ contractNumbers: [this.navContractNumber$.getValue()] }),
            this._creditCheck.asObservable()
        ]);
    }
    public getKuevDocumentData(): Observable<[
        kuev: ISlbGetResponseDto,
        quoteDetails: IContractManagementDetailsDto,
        navContractStatus: Array<IContractInfoDto>
    ]> {
        return combineLatest([
            this._slbService.getSlb({ contractId: this.navContractNumber$.getValue() }),
            this._contractsDeprecatedService.getContractDetails({ contractNumber: this.navContractNumber$.getValue() }),
            this._contractFunctionService.getContractStatus({ contractNumbers: [this.navContractNumber$.getValue()] })
        ]);
    }

    public isReady(): void {
        this._contractFunctionService.getContractsBillableReady({ contractNo: this.navContractNumber$.getValue() })
            .pipe(untilDestroyed(this))
            .subscribe(x => {
                this._eventHub.publish<IContractsBillableReadyDto>(KnownEvents.SETTLEMENT_BILLABLE_READY, x);
                return x;
            });
    }

    public isDigitalProcessingReady(): void {
        this._contractFunctionService.getDigitalProcessingBillableReady({ contractNo: this.navContractNumber$.getValue() })
            .pipe(untilDestroyed(this))
            .subscribe(x => {
                this._eventHub.publish<IDigitalProcessingDto>(KnownEvents.SETTLEMENT_DIGITAL_PROCESSING_BILLABLE_READY, x);
                return x;
            });
    }

    /**
     * @private
     */
    private onRowSelected(item: ISearchContractResultDto): void {
        this.canEditPreVendorInvoice$.next(item.slb_code !== ISaleAndLeaseBackCodeDto.UnechtlnV2 && item.slb_code !== ISaleAndLeaseBackCodeDto.Unechtlnmk);
        this.canEditProofOfPayment.next(item.slb_code !== ISaleAndLeaseBackCodeDto.UnechtlnV2 && item.slb_code !== ISaleAndLeaseBackCodeDto.Unechtlnmk && item.slb_code !== ISaleAndLeaseBackCodeDto.Unechtlimk);
        this.quoteId$.next(item.quote_id);
        this.lessee$.next(item.lessee);
        this.navContractNumber$.next(item.nav_contract_number);
        this.contractStatus$.next(item.status);
    }

    //#endregion

}
