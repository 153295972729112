//#region Imports

import { once } from '@abcfinlab/core';
import { Injectable, NgZone } from '@angular/core';
import { Subject, fromEvent, type Observable } from 'rxjs';

//#endregion

/**
 * The `MotionManager` class.
 *
 * @public
 */
@Injectable()
export class MotionManager {

    //#region Fields

    private readonly _zone: NgZone;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `MotionManager` class.
     *
     * @public
     */
    public constructor(zone: NgZone) {
        this._zone = zone;
    }

    //#endregion

    //#region Properties
    //#endregion

    //#region Methods

    /**
     * @public
     */
    public animate(element: HTMLElement, props: PropertyIndexedKeyframes, options?: number | KeyframeAnimationOptions): Observable<void> {
        const finishSubject = new Subject<void>();
        this._zone.runOutsideAngular(() => {
            const animation = element.animate(props, options);

            once(fromEvent(animation, 'finish'), () => {
                this.applyAnimation(element, props);
                finishSubject.next();
            });

            animation.play();
        });

        return finishSubject.asObservable();
    }

    /**
     * @private
     */
    private applyAnimation(element: HTMLElement, prop: PropertyIndexedKeyframes): void {
        Object.entries(prop).forEach(([k, v]) => {
            if (k in element.style) {
                element.style.setProperty(k, v?.toString() ?? '');
            }
        });
    }

    //#endregion

}
