import { PictureType } from '../../models/picture-type.enum';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import {PicturesService} from '../../services/pictures.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'gwg-identification-verification',
  templateUrl: './identification-verification.component.html',
  styleUrls: ['./identification-verification.component.scss']
})
export class IdentificationVerificationComponent implements OnInit {

  @Input() text: string;

  @Input() pictureType: PictureType;

  @Output() confirmed: EventEmitter<boolean> = new EventEmitter<boolean>();

  imageAsDataUrl: string = '';

  constructor(
    private readonly _pictureService: PicturesService,
    private readonly cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this._pictureService.getIdentificationDocument(this.pictureType)
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.imageAsDataUrl = result;
        this.cdr.detectChanges();
      });
  }

  verified(): void {
    this.confirmed.emit(true);
  }

  retry(): void {
    this._pictureService.removeIdentificationDocument(this.pictureType)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.confirmed.emit(false);
          this.cdr.detectChanges();
      });
  }

}
