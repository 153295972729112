//#region Imports

import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';

//#endregion

export class DefaultTranslateHttpLoader implements TranslateLoader {

    //#region Fields

    private readonly _http: HttpClient;
    private readonly _prefix: string;
    private readonly _suffix: string;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `DefaultTranslateHttpLoader` class.
     *
     * @public
     */
    public constructor(http: HttpClient, prefix: string = '/assets/i18n/', suffix: string = '.json') {
        this._http = http;
        this._prefix = prefix;
        this._suffix = suffix;
    }

    //#endregion

    //#region Methods

    /**
     * Gets the translations from the server
     *
     * @public
     */
    public getTranslation(lang: string): Observable<Object> {
        return this._http.get(`${this._prefix}${lang}${this._suffix}`).pipe(
            // map(x => this.parseAndCompile(x, '$ref:'))
        );
    }

    /**
     * @private
     */
    private parseAndCompile(self: Object, key: string, path: string = ''): Object {
        const subObjects = Object.entries(self).map(([k, v]) => {
            path = path.concat(`${k}`);

            if (typeof v === 'object') {
                return this.parseAndCompile(v, key, path);
            } else if (typeof v === 'string' && v.startsWith(key)) {
                const value = this.getMemberValue(self, path);
                const obj = this.createMemberObject(path, value);

                return obj;
            }
        });

        return {};
        // TODO: merge all sub objects with the parent object.
    }

    /**
     * @private
     */
    private getMemberValue<T>(self: T, memberKey: string): unknown {
        return memberKey.split('.').reduce((a, b) => a?.[b], self);
    }

    /**
     * @private
     */
    private createMemberObject(memberKey: string, value: any): object {
        let obj = {};
        memberKey.split('.').forEach(x => {
            obj = { ...obj, ...{ [x]: value } };
        });

        return obj;
    }

    //#endregion

}
