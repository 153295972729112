//#region Imports

import { NgModule, Pipe, PipeTransform } from '@angular/core';

//#endregion

/**
 * Extract a value of an object by path.
 *
 * @public
 */
@Pipe({
    name: 'memberValue'
})
export class MemberValuePipe implements PipeTransform {

    //#region Methods

    public transform<T extends object, TResult>(value: T, paht: string): TResult {
        return this.getMemberValue(value, paht);
    }

    private getMemberValue<T extends object, TResult>(self: T, memberKey: string): TResult {
        return memberKey.split('.').reduce((a, b) => a?.[b], self);
    }

    //#endregion
}

@NgModule({
    declarations: [MemberValuePipe],
    exports: [MemberValuePipe]
})
// @ts-ignore
export class MemberValuePipeModule {
}
