//#region Imports

import { FinanceAdminService, IRetailerDto } from '@abcfinlab/api/retailer';
import { Globals, once, TranslationFacade } from '@abcfinlab/core';
import { UiStatesPreservationService } from '@abcfinlab/presentation';
import { BusyBoxService } from '@abcfinlab/ui';
import { DestroyRef, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { IRetailersSearchCondition } from '../Models/Interfaces/IRetailersSearchCondition';
import { KnownSettings } from '../Models/KnownSettings';
import { RETAILER_EDIT_ROUTE_PATH } from '../Routing/RoutePaths';
import { CreateRetailerView } from './Dialogs/CreateRetailerView';

//#endregion

/**
 * @private
 */
const EMPTY_SEARCH_CONDITION: IRetailersSearchCondition = {
    term: '',
};

/**
 * @private
 */
const isEmptySearchCondition = (other: IRetailersSearchCondition): boolean => EMPTY_SEARCH_CONDITION.term.toLowerCase() === other.term.toLowerCase();

/**
 * The presenter of the {@link RetailersOverviewView} view.
 *
 * @internal
 */
@Injectable()
export class RetailersOverviewViewPresenter {

    //#region Fields

    private readonly _dialog: MatDialog;
    private readonly _uiStatesPreservationService: UiStatesPreservationService;
    private readonly _financeAdminService: FinanceAdminService;
    private readonly _busyBoxService: BusyBoxService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _router: Router;
    private readonly _route: ActivatedRoute;
    private readonly _conditionSubject: BehaviorSubject<IRetailersSearchCondition>;
    private readonly _isDefaultSearchConditionSubject: BehaviorSubject<boolean>;
    private readonly _dataSource: MatTableDataSource<IRetailerDto>;
    private readonly _dataSourceTotalSubject: BehaviorSubject<number>;
    private readonly _pageSizeSubject: BehaviorSubject<number>;
    private readonly _pageSizesSubject: BehaviorSubject<Array<number>>;
    private readonly _columns: Array<string>;

    private _destroyRef: DestroyRef;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `RetailersOverviewViewPresenter` class.
     *
     * @public
     */
    public constructor(dialog: MatDialog, financeAdminService: FinanceAdminService, busyBoxService: BusyBoxService,
        translationFacade: TranslationFacade, uiStatesPreservationService: UiStatesPreservationService,
        router: Router, route: ActivatedRoute) {
        this._dialog = dialog;
        this._uiStatesPreservationService = uiStatesPreservationService;
        this._financeAdminService = financeAdminService;
        this._busyBoxService = busyBoxService;
        this._translationFacade = translationFacade;
        this._router = router;
        this._route = route;
        this._conditionSubject = new BehaviorSubject(EMPTY_SEARCH_CONDITION);
        this._isDefaultSearchConditionSubject = new BehaviorSubject(true);
        this._dataSourceTotalSubject = new BehaviorSubject(0);
        this._pageSizeSubject = new BehaviorSubject(Globals.Page.DEFAULT_PAGE_SIZE);
        this._pageSizesSubject = new BehaviorSubject(Globals.Page.DEFAULT_PAGE_SIZES);
        this._dataSource = new MatTableDataSource();
        this._columns = [
            'name',
            'city',
            'contactNumber',
            'client',
            'factor',
            'partnerNumber',
            'active'
        ];
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `columns` property.
     *
     * @public
     * @readonly
     */
    public get columns(): Array<string> {
        return this._columns;
    }

    /**
     * Returns the `dataSource` property.
     *
     * @public
     * @readonly
     */
    public get dataSource(): MatTableDataSource<IRetailerDto> {
        return this._dataSource;
    }

    /**
     * Returns the `dataSourceTotal` property.
     *
     * @public
     * @readonly
     */
    public get dataSourceTotal(): Observable<number> {
        return this._dataSourceTotalSubject.asObservable();
    }

    /**
     * Returns the `pageSize` property.
     *
     * @public
     * @readonly
     */
    public get pageSize(): Observable<number> {
        return this._pageSizeSubject.asObservable();
    }

    /**
     * Returns the `pageSizes` property.
     *
     * @public
     * @readonly
     */
    public get pageSizes(): Observable<Array<number>> {
        return this._pageSizesSubject.asObservable();
    }

    /**
     * Returns the `condition` property.
     *
     * @public
     * @readonly
     */
    public get condition(): Observable<IRetailersSearchCondition> {
        return this._conditionSubject.asObservable();
    }

    /**
     * Returns the `isDefaultSearchCondition` property.
     *
     * @public
     * @readonly
     */
    public get isDefaultSearchCondition(): Observable<boolean> {
        return this._isDefaultSearchConditionSubject.asObservable();
    }

    //#endregion

    //#region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(destroyRef: DestroyRef): void {
        this._destroyRef = destroyRef;
        this.initializeCondition();
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
        //
    }

    /**
     * @internal
     */
    public onTermChanged(value: string): void {
        const condition = {
            ...this._conditionSubject.value,
            term: value.trim().toLowerCase()
        };

        this._conditionSubject.next(condition);
        this._isDefaultSearchConditionSubject.next(isEmptySearchCondition(this._conditionSubject.value));
        this._uiStatesPreservationService.set(KnownSettings.ADMINISTRATION_FILTER_CONDITION, condition);
        this.initializeRetailers(this._pageSizeSubject.value, 0);
    }

    /**
     * @internal
     */
    public onResetConditions(): void {
        this._conditionSubject.next(EMPTY_SEARCH_CONDITION);
        this._isDefaultSearchConditionSubject.next(true);
        this._uiStatesPreservationService.remove(KnownSettings.ADMINISTRATION_FILTER_CONDITION);
        this.initializeRetailers(this._pageSizeSubject.value, 0);
    }

    /**
     * @internal
     */
    public onPageChanged(page: PageEvent): void {
        this._pageSizeSubject.next(page.pageSize);
        this.initializeRetailers(page.pageSize, page.pageIndex);
    }

    /**
     * @internal
     */
    public onRowSelected(row: IRetailerDto): void {
        void this._router.navigate([`../../${RETAILER_EDIT_ROUTE_PATH}`], {
            relativeTo: this._route,
            queryParams: {
                id: row.id
            }
        });
    }

    /**
     * @internal
     */
    public onCreateRetailer(): void {
        once(this._dialog.open(CreateRetailerView, {
            closeOnNavigation: true,
            disableClose: true,
            autoFocus: true,
            // height: '100%',
            // width: '100%',
            maxWidth: '100vw',
            maxHeight: '100vh',
        }).afterClosed(), result => {
            if (result) {
                this.initializeCondition();
            }
        });
    }

    /**
     * @private
     */
    private initializeCondition(): void {
        once(this._uiStatesPreservationService.get<IRetailersSearchCondition>(KnownSettings.ADMINISTRATION_FILTER_CONDITION), x => {
            if (x) {
                this._conditionSubject.next(x);
                this._isDefaultSearchConditionSubject.next(false);
            }

            this.initializeRetailers(this._pageSizeSubject.value, 0);
        });
    }

    /**
     * @private
     */
    private initializeRetailers(size: number, index: number): void {
        this._busyBoxService.show(null, this._translationFacade.translate('global.busy'), this._financeAdminService.search({
            page: index,
            pageSize: size,
            searchString: this._conditionSubject.value.term
        }), { id: 'getRetailers' }).subscribe(x => {
            this._dataSource.data = x.content;
            this._dataSourceTotalSubject.next(x.totalElements);
        });
    }

    //#endregion

}
