// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .page-container {
  max-width: unset !important;
  width: unset;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInBhZ2UtcXVvdGUtbGlzdC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRTtFQUNFLDJCQUFBO0VBQ0EsWUFBQTtBQUFKIiwiZmlsZSI6InBhZ2UtcXVvdGUtbGlzdC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgLnBhZ2UtY29udGFpbmVyIHtcbiAgICBtYXgtd2lkdGg6IHVuc2V0ICFpbXBvcnRhbnQ7XG4gICAgd2lkdGg6IHVuc2V0O1xuICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/quote/src/Components/page-quote-list/page-quote-list.component.scss"],"names":[],"mappings":"AACE;EACE,2BAAA;EACA,YAAA;AAAJ;AACA,wZAAwZ","sourcesContent":[":host {\n  .page-container {\n    max-width: unset !important;\n    width: unset;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
