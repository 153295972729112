import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from '../../../../../../../apps/shell/src/environments/environment';
import { ByteCalculator } from '../../../../../../presentation/src/Helpers/ByteCalculator.class';
import { PictureType } from '../../models/picture-type.enum';
import { PicturesService } from '../../services/pictures.service';

@UntilDestroy()
@Component({
  selector: 'gwg-identification-upload-file',
  templateUrl: './identification-upload-file.component.html',
  styleUrls: ['./identification-upload-file.component.css'],
  animations: [
    trigger('openClose', [
      state('true', style({ transform: 'scale(2.5) translateY(125px)' })),
      state('false', style({ transform: 'scale(1) translateY(0px)' })),
      transition('false <=> true', animate(500))
    ]),
    trigger('openCloseBtn', [
      state('true', style({ transform: 'scale(.5)' })),
      state('false', style({ transform: 'scale(1)' })),
      transition('false <=> true', animate('500ms 500ms'))
    ])
  ]
})
export class IdentificationUploadFileComponent {
  @Input() side: PictureType;

  @Input() text: string;

  public readonly fileTypes: Array<string> = environment.features.upload_file.file_types;

  public readonly maxFileSize: number = environment.features.upload_file.max_file_size;

  isExpanded: boolean;

  iconExpand = 'Arrows_Expand_2';

  errorMessage: string;

  imageDataBase64: any;

  imagePath;

  mimeType: string;

  pdfOptions = {
    withCredentials: true,
    url: null
  };

  isPdf;

  isImage;

  constructor(
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
    private readonly _pictureService: PicturesService,
    private readonly _cdr: ChangeDetectorRef
  ) { }

  get maximum_file_size_text(): string {
    return ByteCalculator.byteToSize(this.maxFileSize);
  }

  uploadFile(event: FileList) {
    if (event.length > 1) {
      this.errorMessage = 'Bitte nur eine Datei auswählen.';
      return;
    }

    const file = event[0];

    this.mimeType = file.type;

    this.isImage = this.mimeType.match(/image\/*/);

    this.isPdf = this.mimeType.match(/pdf\/*/);

    // @TODO: GWGPATCH 2
    // Variable file formats from ENV
    if (this.mimeType.match(/image|pdf\/*/) == null || !new RegExp(this.fileTypes.join('|')).test(file.name.toLowerCase())) {
      this.errorMessage = 'Nicht unterstützter Dateityp. Nur PDF, PNG, JP(E)G';
      return;
    }

    if (file.size === 0) {
      this.errorMessage = 'Die Datei ist scheinbar leer!';
      return;
    }

    if (file.size > this.maxFileSize) {
      this.errorMessage = `Die Datei ist zu groß! Die maximale Dateigröße beträgt ${ByteCalculator.byteToSize(this.maxFileSize)}.`;
      return;
    }

    const reader = new FileReader();
    this.imagePath = event;
    reader.readAsDataURL(file);
    reader.onload = (_event: any) => {
      this.imageDataBase64 = reader.result;
      this.pdfOptions.url = _event.target.result;
      this.errorMessage = null;
      this._pictureService.storeIdentificationDocument(this.side, this.imageDataBase64)
        .pipe(untilDestroyed(this))
        .subscribe(_ => this._cdr.detectChanges());
    };
  }

  deleteAttachment() {
    this.imageDataBase64 = null;
    this.pdfOptions.url = null;
    this._pictureService.removeIdentificationDocument(this.side)
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  recordSubmited() {
    if (this.side === PictureType.Frontside) {
      this._router.navigate(['../backside'], { relativeTo: this._route.parent }).then();
    } else {
      this._router.navigate(['../conclusion'], { relativeTo: this._route.parent }).then();
    }
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
    this.iconExpand = this.isExpanded ? 'Cross_Octagon' : 'Arrows_Expand_2';
  }

}
