//#region Imports

import { ChangeDetectionStrategy, Component } from '@angular/core';

//#endregion

/**
 * The `PageContentComponent` component.
 *
 * @public
 */
@Component({
    selector: 'l7-page-content',
    templateUrl: './PageContentComponent.html',
    styleUrls: ['PageContentComponent.scss'],
    exportAs: 'l7PageContent',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageContentComponent {

    //#region Ctor

    /**
     * Constructs a new instance of the `PageContentComponent` class.
     *
     * @public
     */
    public constructor() {
    }

    //#endregion

}
