//#region Imports

import { FeatureManager, TranslationFacade } from '@abcfinlab/core';
import { ToastService } from '@abcfinlab/ui';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../Services/AuthService';

//#endregion

/**
 * The presenter of the {@link AuthorizeCallbackView} view.
 *
 * @internal
 */
@Injectable()
export class AuthorizeCallbackViewPresenter {

    //#region Fields

    private readonly _router: Router;
    private readonly _activatedRoute: ActivatedRoute;
    private readonly _toastService: ToastService;
    private readonly _authService: AuthService;
    private readonly _translationFacade: TranslationFacade;
    private readonly _featureManager: FeatureManager;

    //#endregion

    //#region Ctor

    /**
     * Constructs a new instance of the `AuthorizeCallbackViewPresenter` class.
     *
     * @public
     */
    public constructor(router: Router, activatedRoute: ActivatedRoute, toastService: ToastService,
        authService: AuthService, translationFacade: TranslationFacade, featureManager: FeatureManager) {
        this._router = router;
        this._activatedRoute = activatedRoute;
        this._toastService = toastService;
        this._authService = authService;
        this._translationFacade = translationFacade;
        this._featureManager = featureManager;
    }

    //#endregion

    //#region Properties

    /**
     * Returns the `hasSession` property.
     *
     * @public
     * @readonly
     */
    public get hasSession(): Observable<boolean> {
        return this._authService.hasSession;
    }

    //#endregion

    //#region Methods

    /**
     * Called before the view first displays the data-bound properties and sets the view's input properties.
     *
     * @internal
     */
    public initialize(): void {
        const code = this._activatedRoute.snapshot.queryParams.code;
        const realm = this._activatedRoute.snapshot.queryParams.realm;

        if (!code) {
            throw new Error('Parameter \'code\' is required.');
        }

        if (!realm) {
            throw new Error('Parameter \'realm\' is required.');
        }

        this._authService.callback(code, realm.toUpperCase()).subscribe(() => {
            void this._router.navigate(['presentation'], { replaceUrl: true });
        }, (error: HttpErrorResponse) => {
            const message = error.error?.error_description ?? this._translationFacade.instant('auth.error');
            this._toastService.show(message, 'danger');
            this._authService.removeSession();
        });
    }

    /**
     * Called before the view will be destroyed.
     * Unsubscribe Observables and detach event handlers to avoid memory leaks.
     *
     * @internal
     */
    public dispose(): void {
    }

    //#endregion

}
