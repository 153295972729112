import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'l7-calculator-toggle-switch',
  templateUrl: './calculator-toggle-switch.component.html',
  styleUrls: ['./calculator-toggle-switch.component.scss']
})
export class CalculatorToggleSwitchComponent {

  private _showingFields: boolean;

  @Output() toggleFields: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() set initialState(state: boolean) {
    this._showingFields = state;
  }

  constructor() {
  }

  get show_fields(): boolean {
    return this._showingFields;
  }

  public toggleAdditionalButtons(): void {
    this.toggleFields.emit(!this._showingFields);
    this._showingFields = !this._showingFields;
  }
}
